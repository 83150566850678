export enum UserSettingsSectionsEnums {
  INFO = 'user_info',
  MEDIA = 'media_links',
  SITE = 'website',
  NOTIFICATIONS = 'notifications',
  SUBSCRIPTION = 'subscription',
  GENERAL = 'general',
}

export enum UserRolesEnums {
  GARAGE_SUPER_ADMIN = 'site:garage_super_admin:garage_super_admin_access',
  GARAGE_ADMIN = 'site:garage_admin:garage_admin_access',
  GARAGE_ORGANIZER = 'site:garage_organizer:garage_organizer_access',
  GARAGE_SERVICE = 'site:garage_service:garage_service_access',
  GARAGE_SERVICE_ADMIN = 'site:garage_service_admin:garage_service_access_admin',
  GARAGE_EVENTS = 'site:garage_events:garage_events_access',
  GARAGE_PROGRAMS = 'site:garage_programs:garage_programs_access',
  GARAGE_FACILITIES = 'site:garage_facilities:garage_facilities_access',
  GARAGE_COMMUNITY = 'site:garage_community:garage_community_access',
  GARAGE_BOOKING_MANAGEMENT = 'site:garage_booking_management:garage_booking_management_access',
}
