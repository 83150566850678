import { NavLink } from 'react-router-dom';

import { IMapRoutes } from '../../../core/types/user.type';
type Props = {
  route: IMapRoutes;
};
const SidebarItem = ({ route }: Props) => {
  return route.path ? (
    <NavLink
      className={({ isActive }) =>
        isActive ? 'text-orange500' : 'text-textGrayColor'
      }
      to={route.path}
    >
      <div
        className="flex w-full h-[53px] pl-7 items-center font-bold border border-b-greyBorderBottom border-t-transparent"
        onClick={() => null}
      >
        {route.title}
      </div>
    </NavLink>
  ) : null;
};

export default SidebarItem;
