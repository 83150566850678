import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { HttpMethods } from 'core/enums/httpMethods.enum';

import { makeRequest } from './http.service';

const BASEURL = process.env.REACT_APP_BASE_URL;
export const getPlusApplication = (
  applicationState: ApplicationStateEnum | string,
  limit: number,
  searchText: string | null,
  offset: number,
) => {
  return makeRequest({
    url: `${BASEURL}/plus/application/list`,
    method: HttpMethods.GET,
    params: applicationState
      ? {
          application_state: applicationState,
          q: searchText,
          limit: limit,
          offset: offset,
        }
      : {
          q: searchText,
          limit: limit,
          offset: offset,
        },
  });
};

export const getPlusApplicationStatisticsService = () => {
  return makeRequest({
    url: `${BASEURL}/plus/applications/statistics`,
    method: HttpMethods.GET,
  });
};

export const getPlusApplicationExportListService = () => {
  return makeRequest({
    url: `${BASEURL}/plus/?offset=0&limit=20`,
    method: HttpMethods.GET,
  });
};

export const getExportNewPlus = () => {
  return makeRequest({
    url: `${BASEURL}/plus/application/export`,
    method: HttpMethods.GET,
  });
};

export const plusExport = (plus_id: string) => {
  return makeRequest({
    url: `${BASEURL}/plus/application/export`,
    method: HttpMethods.GET,
    params: { plus_id },
  });
};

export const acceptPlusApplication = (applicationId: string) => {
  return makeRequest({
    url: `${BASEURL}/plus/application/${applicationId}/accept`,
    method: HttpMethods.POST,
    params: { applicationId },
  });
};

export const rejectPlusApplication = (applicationId: string) => {
  return makeRequest({
    url: `${BASEURL}/plus/application/${applicationId}/reject`,
    method: HttpMethods.POST,
    params: { applicationId },
  });
};

export const publishPlus = () => {
  return makeRequest({
    url: `${BASEURL}/plus/publish`,
    method: HttpMethods.POST,
  });
};

export const unPublishPlus = () => {
  return makeRequest({
    url: `${BASEURL}/plus/unpublish`,
    method: HttpMethods.POST,
  });
};

export const plusStatus = () => {
  return makeRequest({
    url: `${BASEURL}/plus/status`,
    method: HttpMethods.GET,
  });
};

export const CreateNewPlus = () => {
  return makeRequest({
    url: `${BASEURL}/plus`,
    method: HttpMethods.POST,
  });
};
