import CircleSkeleton from './circle-skeleton';
import LineSkeleton from './line-skeleton';
import TextSkeleton from './text-team-skeneton';

export default function TeamSkeletonComponent() {
  return (
    <div className="rounded-lg shadow-lg bg-slate50 w-full h-[66px] px-4 items-center grid grid-cols-4 gap-4">
      <div className="flex justify-center items-center">
        <TextSkeleton />
      </div>
      <div className="flex justify-center items-center">
        <TextSkeleton />
      </div>
      <div className="flex items-center justify-center">
        <div className="h-12 w-12">
          <CircleSkeleton />
        </div>
      </div>
      <div className="flex flex-row gap-4 justify-center items-center">
        <div className="h-5 w-16">
          <LineSkeleton />
        </div>
        <div className="h-5 w-16">
          <LineSkeleton />
        </div>
      </div>
    </div>
  );
}
