import i18n from 'core/configs/i18n';
import { t } from 'i18next';
import { useState } from 'react';

interface TabbarEventsReservationComponentProps {
  page: JSX.Element;
  page2: JSX.Element;
}
function TabbarEventsReservationComponent(
  props: TabbarEventsReservationComponentProps,
) {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div className="flex justify-center px-[4.1%] pt-[3%]">
        <div className="w-full h-full rounded-lg">
          <div className="mx-auto cursor-pointer">
            <div className="flex h-[60px] justify-evenly bg-Neutralgray border rounded-lg laptop:w-9/12">
              <div
                className={`flex w-full h-full justify-center font-bold items-center ${
                  toggle
                    ? 'text-gray500'
                    : 'text-orange500 transition-all duration-200 bg-whiteColor rounded-lg'
                } ${
                  i18n.language === 'en'
                    ? 'text-2xl mobile:text-xs font-en'
                    : 'text-[28px] mobile:text-xs font-ar'
                }`}
                onClick={() => setToggle(false)}
              >
                {t('New events reservation')}
              </div>
              <div
                className={` flex w-full h-full justify-center font-bold items-center ${
                  toggle
                    ? 'text-orange500 transition-all duration-200 bg-whiteColor rounded-lg'
                    : 'text-gray500'
                } ${
                  i18n.language === 'en'
                    ? 'text-2xl mobile:text-xs font-en'
                    : 'text-[28px] mobile:text-xs font-ar'
                }`}
                onClick={() => setToggle(true)}
              >
                {t('Old event reservation')}
              </div>
            </div>
            <div className="flex mt-4 ">
              <div
                className={`${
                  i18n.language === 'ar'
                    ? ' pr-[250px] tablet:pr-[120px]'
                    : "pl-[250px] tablet:pl-[110px]'"
                }`}
              ></div>
              <div
                className={`${
                  i18n.language === 'ar'
                    ? 'pr-[450px] mobile:pr-[135px] tablet:pr-[300px]'
                    : 'pl-[450px] tablet:pl-[320px]'
                }`}
              ></div>
            </div>
          </div>
          {toggle ? props.page : props.page2}
        </div>
      </div>
    </>
  );
}

export default TabbarEventsReservationComponent;
