import 'react-datepicker/dist/react-datepicker.css';

import { useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { HiOutlineChevronDown } from 'react-icons/hi';

type DateInputProps = {
  id: string;
  onSelect?: (startDate: Date | null, endDate: Date | null) => void;
  setOpenDropdown: (id: string | null) => void;
  error?: boolean;
  errorMsg?: string;
  title: string;
  openDropdown?: string | null;
  selectsRange?: boolean;
};

function DateInputComponent({
  id,
  onSelect,
  openDropdown,
  setOpenDropdown,
  error,
  errorMsg,
  title,
  selectsRange = false,
}: DateInputProps) {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const isOpen = openDropdown === id;
  const datePickerRef = useRef<ReactDatePicker | null>(null);
  const { t, i18n } = useTranslation();
  const handleDateChange = (
    dates: [Date | null, Date | null] | Date | null,
  ) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start ?? undefined);
      setEndDate(end ?? undefined);
      if (onSelect) onSelect(start, end);
    } else {
      setStartDate(dates ?? undefined);
      setEndDate(undefined);
      if (onSelect) onSelect(dates, null);
    }
  };

  const toggleDropdown = () => {
    setOpenDropdown(isOpen ? null : id);
  };

  const openDatePicker = () => {
    toggleDropdown();
    if (!isOpen && datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };

  return (
    <div>
      <p className="px-2 py-2 font-en">{title}</p>
      <div className="relative inline-block" onClick={openDatePicker}>
        <ReactDatePicker
          placeholderText={t('Select') || ''}
          selected={startDate}
          onChange={(date: Date | [Date | null, Date | null]) =>
            handleDateChange(date)
          }
          startDate={selectsRange ? startDate : undefined}
          endDate={selectsRange ? endDate : undefined}
          selectsRange={selectsRange as true}
          minDate={new Date()}
          open={isOpen}
          onClickOutside={() => setOpenDropdown(null)}
          className={`w-[319px] placeholder:text-gray500 placeholder:text-sm h-[52px] px-4 py-1 text-sm font-medium text-gray500 bg-neutralGre50 border
          ${
            isOpen ? 'border-secondaryGreen400' : ''
          }  shadow-sm cursor-pointer flex items-center justify-between`}
          calendarClassName="custom-calendar"
          dayClassName={(date: Date) => {
            const isStartDate =
              startDate && date.getTime() === startDate.getTime();
            const isEndDate = endDate && date.getTime() === endDate.getTime();
            if (isStartDate) return 'orange-border-day';
            if (isEndDate) return 'orange-background-day';
            return '';
          }}
        />
        <HiOutlineChevronDown
          size={24}
          className={`text-sm font-medium text-gray-700 absolute top-3 transition-transform duration-200
            ${isOpen ? 'transform rotate-180' : ''}
            ${i18n.language === 'en' ? `right-3.5` : `left-3.5`}`}
          aria-hidden="true"
          onClick={toggleDropdown}
        />
      </div>
      {error && errorMsg && (
        <div className="mt-2 text-sm text-red-600">{errorMsg}</div>
      )}
    </div>
  );
}

export default DateInputComponent;
