import TeamContainer from 'containers/admin/Aboutus/Team/team.container';

const TeamPage = () => {
  return (
    <div>
      <TeamContainer />
    </div>
  );
};

export default TeamPage;
