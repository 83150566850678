export interface GenralBoxComponentProp
  extends React.InputHTMLAttributes<HTMLInputElement> {
  Heading: string;
  HeadingColor: string;
  supLabel?: string;
  supLabelColor?: string;
}

export function GenralBoxComponent({ ...props }: GenralBoxComponentProp) {
  return (
    <div className="justify-center ">
      <div className="px-5 pt-1 pb-2">
        <div
          className={`text-${props.HeadingColor} font-bold font-Helvetica text-3xl`}
        >
          {props.Heading}
        </div>
        <div
          className={`text-lightGray100 font-bold font-Helvetica text-base capitalize`}
        >
          {props.supLabel}
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default GenralBoxComponent;
