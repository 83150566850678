import axios from 'axios';
import { HttpMethods } from 'core/enums/httpMethods.enum';
import { UploadImage } from 'core/types/admin.type';

import { makeRequest } from './http.service';

export const uploadImage = async (
  uploadType: string,
  extension: string,
  file: File,
  url: string,
) => {
  const req = {
    url: `${url}?file_type=${uploadType}&file_extension=${extension}`,
    method: HttpMethods.POST,
    data: {
      original_name: file.name,
      file_type: uploadType,
      file_extension: extension,
    },
    skipHeader: true,
  };
  const data = (await makeRequest(req)) as UploadImage;

  return uploadImageToServer(data, file);
};

export const uploadImageToServer = async (
  data: UploadImage,
  file: File,
  signal?: AbortSignal,
) => {
  const request = {
    url: data.upload_url,
    method: HttpMethods.PUT,
    data: file,
    signal,
  };
  await axios(request);
  return data.file_path;
};
