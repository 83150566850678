import { BsX } from 'react-icons/bs';

interface IProps {
  closeModal: () => void;
  title: string;
  children: JSX.Element | JSX.Element[];
}
function NextDisruptInfoPopup(props: IProps) {
  return (
    <div className="fixed z-10 top-0 left-0 right-0 bottom-0 w-full h-full bg-black bg-opacity-50">
      <div className="flex flex-col m-auto mt-[15%] h-auto w-[621px] gap-2 bg-whiteColor p-6">
        {/* Title and Close Icon */}
        <div className="flex flex-row justify-between items-center w-full">
          <div className="text-gray800 text-[32px] font-bold ml-2">
            {props.title}
          </div>
          <div
            onClick={props.closeModal}
            className="text-orange500 text-4xl cursor-pointer"
          >
            <BsX />
          </div>
        </div>
        {/* Content of the Popup */}
        <div>{props.children}</div>
      </div>
    </div>
  );
}

export default NextDisruptInfoPopup;
