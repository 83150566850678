// import Button from 'components/admin/ButtonComponent';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import SkeletonDataComponent from 'components/common/skeleton-element/incubators-skeleton';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { EventsModel } from 'core/types/user.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { getAllApplicantsEventsServices } from 'services/events-services';

function OldEventsReservationContainer() {
  const [eventsData, setEventsData] = useState<EventsModel>();
  const [toggle, setToggle] = useState(
    new Array(eventsData?.result_list.length).fill(false),
  );
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(eventsData?.result_list.length).fill(false),
  );
  const paginationLimit = 15;
  const [page, setPage] = useState<number>(1);
  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };
  const [visibleItems] = useState(7);
  const [loading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [val, setVal] = useState('');
  const [, setFiltered] = useState(eventsData?.result_list);

  // const handleSearchClick = () => {
  //   if (val === '') {
  //     setFiltered(eventsData?.result_list);
  //     return;
  //   }
  //   const filterBySearch = eventsData?.result_list.filter((item) => {
  //     item.first_name.toLowerCase().includes(val.toLowerCase());
  //   });
  //   setFiltered(filterBySearch);
  // };
  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const eventsData: EventsModel = await getAllApplicantsEventsServices(
        false,
        paginationLimit,
        (page - 1) * paginationLimit,
        null,
      );

      unstable_batchedUpdates(() => {
        setEventsData(eventsData);
        setFiltered(eventsData.result_list);
      });
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [page]);

  useEffect(() => {
    getData();
  }, [getData, page]);

  const { t } = useTranslation();

  return (
    <>
      <div className="pt-6 desktop:py-7 flex justify-center items-center">
        <div className="flex flex-col rounded-lg bg-white w-full px-10 pt-8 pb-2">
          {/* <div className="flex flex-row justify-start items-start pb-3 gap-4">
            <input
              onChange={(e) => setVal(e.target.value)}
              type="search"
              id="default-search"
              className="block h-12 w-1/2 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
              placeholder={`${t('search')}`}
              required
            />
            <Button
              disabled={false}
              width="w-[143px]"
              hight="h-[43px]"
              text={t('search') as string}
              fontsize={'text-base'}
              onClick={handleSearchClick}
            />
          </div> */}
          <div className="flex flex-col w-full">
            <div className="grid grid-cols-6 w-full font-bold text-2xl py-3 pr-48 pl-7 gap-20 items-center">
              <div className="text-center font-bold text-2xl">{t('Name')}</div>
              <div className="text-center font-bold text-2xl">{t('Email')}</div>
              <div className="text-center font-bold text-2xl">
                {t('N People')}
              </div>
              <div className="text-center font-bold text-2xl">{t('Phone')}</div>
              <div className="text-center font-bold text-2xl">
                {t('Company')}
              </div>
              <div className="text-center font-bold text-2xl">
                {t('Status')}
              </div>
            </div>
            {isLoading ? (
              <div className="flex flex-col items-center py-2">
                {Array(4)
                  .fill('')
                  .map((_, index) => {
                    return (
                      <div key={index}>
                        <SkeletonDataComponent />
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div>
                {eventsData?.total_count === 0 ? (
                  <div className="w-auto h-96 flex flex-col gap-5 items-center justify-center">
                    <div className="h-[273px] w-[337px] mb-7">
                      <img src="/assets/images/notFound.png" alt="not found" />
                    </div>
                    <h1 className="text-2xl text-blue300 font-bold ">
                      We couldn’t find what you’re looking for
                    </h1>
                  </div>
                ) : (
                  eventsData?.result_list
                    .slice(0, visibleItems)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center mb-4"
                      >
                        <div className="flex flex-row items-center w-full justify-between">
                          <div
                            className={`grid grid-cols-7 justify-center items-center bg-greyMenuColor h-full w-full py-4 rounded-t-lg ${
                              toggle[index] ? '' : 'rounded-b-lg'
                            }`}
                          >
                            <div className="text-center">
                              {item.first_name + ' ' + item.last_name}
                            </div>
                            <div className="text-center">{item.email}</div>
                            <div className="text-center">
                              {item.guest_count}
                            </div>
                            <div className="text-center">{item.mobile}</div>
                            <div className="text-center">{item.company}</div>
                            <div className="flex items-center flex-wrap justify-center gap-2">
                              {item.application_state ===
                              ApplicationStateEnum.ACCEPTED ? (
                                <button className="w-[95px] h-[32px] bg-green-50 text-green-500 rounded-[20px]">
                                  {t('Accepted')}
                                </button>
                              ) : (
                                <button className="w-[95px] h-[32px] bg-red-50 text-red-500 rounded-[20px]">
                                  {t('Rejected')}
                                </button>
                              )}
                            </div>
                            <div className="pt-2 pl-9 desktop:pl-32">
                              {toggle[index] ? (
                                <IoIosArrowUp
                                  className="flex w-[24px] h-[24px] cursor-pointer"
                                  onClick={() =>
                                    handleToggleClick(index, false)
                                  }
                                />
                              ) : (
                                <IoIosArrowDown
                                  className="flex w-[24px] h-[24px] cursor-pointer"
                                  onClick={() => handleToggleClick(index, true)}
                                />
                              )}
                            </div>
                          </div>{' '}
                        </div>
                        {loading && (
                          <div className="fixed flex items-center justify-center inset-[10px]">
                            <Loading />
                          </div>
                        )}
                        {toggle[index] && (
                          <div className="px-4 pb-4 bg-slate50 laptop:bg-white w-full flex justify-center h-full rounded-b-lg">
                            <div className="flex flex-col w-full py-2">
                              <div className="flex flex-row gap-5 pt-9 w-full">
                                <div className="flex flex-col w-full">
                                  <div className="flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center">
                                    <div className="flex flex-row justify-between w-full items-center px-4">
                                      <div className="text-secondaryGreen400">
                                        General info
                                      </div>
                                      {toggleGenralInfo[index] ? (
                                        <IoIosArrowUp
                                          className="flex w-[50px] cursor-pointer pl-5"
                                          onClick={() =>
                                            handleToggleGenralInfoClick(index)
                                          }
                                        />
                                      ) : (
                                        <IoIosArrowDown
                                          className="flex w-[50px] cursor-pointer pl-5"
                                          onClick={() =>
                                            handleToggleGenralInfoClick(index)
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {toggleGenralInfo[index] && (
                                    <div className="flex flex-col w-full bg-white border border-gray500 px-3 gap-2 py-4">
                                      <div>{t('first Name')}</div>
                                      <div className=" text-greyChateau">
                                        {item.first_name}
                                      </div>
                                      <hr className="w-full px-0 opacity" />
                                      <div>{t('Last Name')}</div>
                                      <div className=" text-greyChateau">
                                        {item.last_name}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Company')}</div>
                                      <div className=" text-greyChateau">
                                        {item.company}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Position')}</div>
                                      <div className=" text-greyChateau">
                                        {item.position}
                                      </div>
                                      <hr className="w-full opacity-50" />

                                      <hr className="w-full opacity-50" />
                                      <div>{t('Mobile Number')}</div>
                                      <div className=" text-greyChateau">
                                        {item.mobile}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Email Address')}</div>
                                      <div className=" text-greyChateau">
                                        {item.email}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Event Name')}</div>
                                      <div className=" text-greyChateau">
                                        {item.event_name}
                                      </div>
                                      <div>{t('Number of invited people')}</div>
                                      <div className=" text-greyChateau">
                                        {item.guest_count}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Start Date')}</div>
                                      <div className=" text-greyChateau">
                                        {item.start_at}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('End Date')}</div>
                                      <div className=" text-greyChateau">
                                        {item.end_at}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                )}
              </div>
            )}
          </div>
          <div className="px-6">
            <Pagination
              totalPage={Math.ceil(
                (eventsData?.total_count || 0) / paginationLimit,
              )}
              page={page}
              limit={paginationLimit}
              onPageChange={setPage}
              totalCount={eventsData?.total_count || 0}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default OldEventsReservationContainer;
