import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { HttpMethods } from 'core/enums/httpMethods.enum';

export const createNewIncubator = async () => {
  return await makeRequest({
    url: `${BASE_URL}/incubator`,
    method: HttpMethods.POST,
  });
};

export const fetchIncubatorList = async () => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/list`,
    method: HttpMethods.GET,
  });
};

export const fetchIncubatorApplicationList = async (
  limit: number,
  offset: number,
  searchText?: string | null,
  application_state?: string,
) => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/application/list`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      application_state: application_state,
      limit: limit,
      offset: offset,
    },
  });
};

export const acceptIncubatorApplication = async (application_id?: string) => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/application/${application_id}/accept`,
    method: HttpMethods.POST,
    params: { incubator_application_id: application_id },
  });
};

export const rejectIncubatorApplication = async (application_id?: string) => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/application/${application_id}/reject`,
    method: HttpMethods.POST,
    params: { incubator_application_id: application_id },
  });
};

export const publishIncubator = async () => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/publish`,
    method: HttpMethods.POST,
  });
};

export const unPublishIncubator = async () => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/unpublish`,
    method: HttpMethods.POST,
  });
};

export const getNewIncubatorExport = async () => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/application/export`,
    method: HttpMethods.GET,
  });
};

export const getIncubatorExport = async (incubator_id: string) => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/application/export`,
    method: HttpMethods.GET,
    params: { incubator_id },
  });
};
export const getIncubatorStatus = async () => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/status`,
    method: HttpMethods.GET,
  });
};

export const getIncubatorStatistics = async () => {
  return await makeRequest({
    url: `${BASE_URL}/incubator/applications/statistics`,
    method: HttpMethods.GET,
  });
};
