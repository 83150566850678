import ButtonComponent from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import SelectTourPopupComponent from 'components/admin/selectTourPopupComponet';
import EmptySearchComponent from 'components/common/empty-search';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import SkeletonDataComponent from 'components/common/skeleton-element/incubators-skeleton';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { BookATourModel, IsOpenModel } from 'core/types/user.type';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import {
  getAllApplicantsForBookATourServices,
  getBookATourExportServices,
} from 'services/book-a-tour-services';

import SearchComponent from '../Partners/search-component';

function OldTourContainer() {
  const [bookATourData, setBookATourData] = useState<BookATourModel>();
  const [filtered, setFiltered] = useState(bookATourData?.result_list);
  const [toggle, setToggle] = useState(
    new Array(bookATourData?.result_list.length).fill(false),
  );
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(bookATourData?.result_list.length).fill(false),
  );
  const paginationLimit = 5;
  const [page, setPage] = useState<number>(1);

  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [visibleItems] = useState<number>(7);
  const [loading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const tourData: BookATourModel =
        await getAllApplicantsForBookATourServices(
          ApplicationStateEnum.ACCEPTED,
          paginationLimit,
          page,
          searchValue ?? null,
        );

      unstable_batchedUpdates(() => {
        setBookATourData(tourData);
        setFiltered(tourData.result_list);
      });
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [setBookATourData, page, searchValue]);

  useEffect(() => {
    getData();
  }, [getData]);

  const [dataExport, setDataExport] = useState<string>('');
  const [acceptPopup, setAcceptPopup] = useState(false);
  const [tourId] = useState<string>('');
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  // const [enabled, setEnabled] = useState<boolean>(false);
  const [isOpen] = useState<IsOpenModel>();

  const handleCloseAcceptPopup = () => {
    setAcceptPopup(false);
  };
  const getDataExport = async () => {
    try {
      const tourDataExport: string = await getBookATourExportServices();
      setDataExport(tourDataExport);
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleExportClick = () => {
    getDataExport();
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };

  return (
    <div className="pt-6 desktop:py-7 flex justify-center items-center">
      <div className="flex flex-col rounded-lg bg-white w-full px-10 pt-8 pb-2">
        <div className="flex flex-row justify-start items-start pb-3 gap-4">
          <SearchComponent
            onClickValue={(e) => setSearchValue(e.target.value)}
            onClickShearch={getData}
          />
        </div>

        <div className="flex flex-col w-full">
          <div className="grid grid-cols-4 gap-4 text-center font-bold text-2xl items-center w-full py-4">
            <div className="">{t('Name')}</div>
            <div className="">{t('Position')}</div>
            <div className="">{t('Company')}</div>
            <div className="mr-32">{t('Status')}</div>
          </div>
          <div className="">
            {isLoading ? (
              <div className="flex flex-col items-center py-2">
                {Array(paginationLimit)
                  .fill('')
                  .map((_, index) => {
                    return (
                      <div key={index}>
                        <SkeletonDataComponent />
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div>
                {bookATourData?.total_count === 0 ? (
                  <div className="flex justify-center mx-auto">
                    <EmptySearchComponent />
                  </div>
                ) : (
                  filtered?.slice(0, visibleItems).map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center w-full mb-4"
                    >
                      <div className="flex flex-row items-center w-full justify-between">
                        <div
                          className={`grid grid-cols-4 justify-center items-center bg-greyMenuColor h-full w-full py-2 px-9 rounded-t-lg${
                            toggle[index] ? '' : 'rounded-b-lg'
                          }`}
                        >
                          <div className="text-center text-lg">
                            {item.first_name + ' ' + item.last_name}
                          </div>
                          <div className="text-center text-lg">
                            {item.position}
                          </div>
                          <div className="text-center text-lg">
                            {item.company}
                          </div>
                          <div className="flex flex-row items-center justify-center h-[37px] gap-1">
                            <button className="w-[95px] h-[32px] bg-green50 text-lightGreenColor rounded-[20px]">
                              {t('Accepted')}
                            </button>
                            {toggle[index] ? (
                              <IoIosArrowUp
                                size={22}
                                className="text-base cursor-pointer ml-28"
                                onClick={() => handleToggleClick(index, false)}
                              />
                            ) : (
                              <IoIosArrowDown
                                size={22}
                                className="text-base cursor-pointer ml-28"
                                onClick={() => handleToggleClick(index, true)}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {loading && (
                        <div className="fixed flex items-center justify-center inset-[10px]">
                          <Loading />
                        </div>
                      )}
                      {toggle[index] && (
                        <div className="px-4 pb-4 bg-bgGray laptop:bg-white w-full flex justify-center h-full rounded-b-lg">
                          <div className="flex flex-col w-full py-2">
                            <div className="flex flex-row w-full gap-5 pt-6 px-8">
                              <div className="flex flex-col w-full">
                                <div
                                  className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                                >
                                  <div className="flex flex-row justify-between w-full items-center px-4">
                                    <div className="text-secondaryGreen400 font-normal text-sm">
                                      {t('generalinfo')}
                                    </div>
                                    {toggleGenralInfo[index] ? (
                                      <IoIosArrowUp
                                        size={20}
                                        className="flex w-[65px] cursor-pointer pl-12"
                                        onClick={() =>
                                          handleToggleGenralInfoClick(index)
                                        }
                                      />
                                    ) : (
                                      <IoIosArrowDown
                                        size={20}
                                        className="flex w-[65px] cursor-pointer pl-12"
                                        onClick={() =>
                                          handleToggleGenralInfoClick(index)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                {toggleGenralInfo[index] && (
                                  <div className="flex flex-col w-full bg-white border  border-gray500  gap-2 py-2">
                                    <div className="ml-4">{t('Last name')}</div>
                                    <div className="ml-4 text-greyChateau">
                                      {item.last_name}
                                    </div>
                                    <hr className=" border-gray500" />
                                    <div className="ml-4">
                                      {t('Mobile Number')}
                                    </div>
                                    <div className="ml-4 text-greyChateau">
                                      {item.mobile}
                                    </div>
                                    <hr className="border-gray500" />

                                    <div className="ml-4">{t('Email')}</div>
                                    <div className="ml-4 text-greyChateau">
                                      {item.email}
                                    </div>
                                    <hr className="border-gray500" />
                                    <div className="ml-4">
                                      {t('The reason for the visit')}
                                    </div>
                                    <div className="ml-4 text-greyChateau">
                                      {item.visit_reason}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
        <div className="">
          <Pagination
            totalPage={Math.ceil(
              (bookATourData?.total_count || 0) / paginationLimit,
            )}
            page={page}
            limit={paginationLimit}
            onPageChange={setPage}
            totalCount={bookATourData?.total_count || 0}
          />
        </div>
        <div className="flex items-center justify-between pt-3">
          <div className="flex flex-row gap-2 pl-4">
            <span className="font-bold"></span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={isOpen?.is_open}
                readOnly
              />
            </label>
          </div>
          <ButtonComponent
            disabled={bookATourData?.total_count === 0 ? true : false}
            width={'w-[305px]'}
            hight={'h-12'}
            text={' Export All'}
            fontsize={'text-base'}
            img={<RiFileExcel2Line size={24} />}
            onClick={() => handleExportClick()}
          />
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {acceptPopup && (
          <SelectTourPopupComponent
            onClose={handleCloseAcceptPopup}
            title={'Add Startup '}
            subtitle={'Date & Time'}
            tourId={tourId}
            onClick={handleCloseAcceptPopup}
            onClickConfirm={getData}
          />
        )}
        {exportPopup && (
          <ConfirmPopupComponent
            fileName="Tour Data"
            onClose={handleCloseExportPopup}
            title={'Are you sure you want to Export All?'}
            subtitle={
              'If you confirm, all forms on this page will be downloaded.'
            }
            onClick={handleCloseExportPopup}
            onClickConfirm={getDataExport}
            data={dataExport}
          />
        )}
      </div>
    </div>
  );
}

export default OldTourContainer;
