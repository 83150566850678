import ButtonComponent from 'components/admin/ButtonComponent';
import UploadComponent from 'containers/admin/Aboutus/Team/upload-component';
import { GalleryTypeEnum, UploadImageTypeEnum } from 'core/enums/example.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { GalleryModel } from 'core/types/admin.type';
import { Formik, getIn, useFormik } from 'formik';
import { IoMdClose } from 'react-icons/io';
import { putPictureServices } from 'services/gallery-service';
import * as Yup from 'yup';

import GalleryPopupMenue from './gallery-popup-menue';

interface GalleryEditContainerProps {
  id: string;
  title: string;
  data: GalleryModel;
  onClose: () => void;
  onSuccess: () => void;
}
function GalleryEditContainer(props: GalleryEditContainerProps) {
  const handleClose = () => {
    props.onClose();
  };
  const initialValues: GalleryModel = {
    image_url: '',
    gallery_type: GalleryTypeEnum.Disrupt,
  };
  const validationSchema = Yup.object({
    image_url: Yup.string().required('Upload the image'.toString()),
    gallery_type: Yup.string().required('Select gender'.toString()),
  });
  const handleClickSend = () => {
    editPicture();
  };
  const formik = useFormik({
    initialValues: props.data.id ? props.data : initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      await handleClickSend();
    },
  });
  const editPicture = async () => {
    try {
      await putPictureServices(formik.values, props.id);
      props.onSuccess();
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full backdrop-opacity-10 backdrop-invert bg-white/30">
        <div className="rounded-lg bg-whiteColor px-5 py-2 h-[600px] w-[621px]">
          <div className="flex items-end justify-end">
            <IoMdClose
              size={24}
              className="cursor-pointer text-orange500"
              onClick={handleClose}
            />
          </div>
          <div className="text-[32px] font-bold pl-4 -mt-5 mb-5">
            {props.title}
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {
              formik.submitForm();
            }}
            validationSchema={formik.initialValues}
            enableReinitialize
          >
            <form className="flex flex-col m-4" onSubmit={formik.handleSubmit}>
              <div className="flex flex-col mb-4 w-2/3 gap-2 ">
                <GalleryPopupMenue
                  onGallaryChange={(val: GalleryTypeEnum) =>
                    formik.setFieldValue('gallery_type', val)
                  }
                  selectedGalleryType={formik.values.gallery_type}
                />
              </div>
              <div className="flex flex-col gap-2 my-9 justify-center items-start rounded-lg">
                <UploadComponent
                  type={UploadImageTypeEnum.News}
                  className={''}
                  name={'image_url'}
                  onChange={(img: string) =>
                    formik.setFieldValue('image_url', img)
                  }
                  id={'image_url'}
                  labelClassName={`${
                    formik.touched.image_url && formik.errors.image_url
                      ? 'border-error'
                      : ''
                  }`}
                  image={`${props.data.image_url}`}
                  button={'Upload Image'}
                  onClick={() => {
                    throw new Error('Function not implemented.');
                  }}
                  formik={
                    <p className="text-error text-sm ">
                      {getIn(formik.errors, 'image_url')}
                    </p>
                  }
                />
              </div>
              <div className="flex flex-col justify-center items-center pt-9">
                <ButtonComponent
                  disabled={false}
                  width={'w-[305.46px]'}
                  hight={'h-[48px]'}
                  text={'Save'}
                  fontsize={'text-base'}
                />
              </div>
            </form>
          </Formik>
        </div>
      </div>
    </>
  );
}
export default GalleryEditContainer;
