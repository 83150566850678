import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const SidebarNavButton =  ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  const { i18n } = useTranslation();

  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? `text-gray800 h-[60px] justify-between inline-flex w-full font-en font-bold py-0 border shadow-[10px_1px_19px_1px_rgba(0,0,11,0.08)] 
          ${
            i18n.language === 'en'
              ? 'border-l-secondaryGreen400 border-l-[6px] rounded-l-md'
              : 'border-r-secondaryGreen400 border-r-[6px] rounded-r-md'
          }`
          : 'text-blue300 '
      }
      to={to}
    >
      <div
        className={`cursor-pointer px-6 font-bold ${
          i18n.language === 'ar'
            ? 'px-4 py-4 font-ar text-xl'
            : 'py-4 font-en text-xl'
        }`}
      >
        {children}
      </div>
    </NavLink>
  );
};

export default SidebarNavButton;
