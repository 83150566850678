import ButtonComponent from 'components/admin/ButtonComponent';
import DataListComponent from 'components/common/Data-list/data-list-component';
import EmptySearchComponent from 'components/common/empty-search';
import ExportPlusPopup from 'components/common/export-popup/export-plus-popup';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import SkeletonDataComponent from 'components/common/skeleton-element/incubators-skeleton';
import { getPlusApplication } from 'core/data-access/plus.service';
import { PDFEnum } from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { Plus } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlineLink, AiOutlineSearch } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp, IoLogoInstagram } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { TfiTwitter } from 'react-icons/tfi';

function PlusOldApplications() {
  const { t } = useTranslation();
  const [FeaturesList, setFeaturesList] = useState<Plus>();
  const [val, setVal] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filtered, setFiltered] = useState(FeaturesList?.result_list);
  const paginationLimit = 4;
  const [exportPopup, setExportPopup] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [toggle, setToggle] = useState(
    new Array(FeaturesList?.result_list.length).fill(false),
  );
  const [toggleAboutTheStartup, setToggletoggleAboutTheStartup] = useState(
    new Array(FeaturesList?.result_list.length).fill(false),
  );
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(FeaturesList?.result_list.length).fill(false),
  );
  const [toggleStartUpDetails, settoggleStartUpDetails] = useState(
    new Array(FeaturesList?.result_list.length).fill(false),
  );
  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };
  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleAboutTheStartupClick = (index: number) => {
    const newToggleSmall = [...toggleAboutTheStartup];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggletoggleAboutTheStartup(newToggleSmall);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };
  const handleToggleStartUpDetailsClick = (index: number) => {
    const newToggleSmall = [...toggleStartUpDetails];
    newToggleSmall[index] = !newToggleSmall[index];
    settoggleStartUpDetails(newToggleSmall);
  };
  const handleSearchClick = () => {
    if (val === '') {
      setFiltered(FeaturesList?.result_list);
      return;
    }
    const filterBySearch = FeaturesList?.result_list.filter((item) => {
      item.startup_name.toLowerCase().includes(val.toLowerCase());
    });
    setFiltered(filterBySearch);
  };
  const getSearchData = async () => {
    try {
      const PlusSearch: Plus = await getPlusApplication(
        '',
        paginationLimit,
        val,
        (page - 1) * paginationLimit,
      );
      unstable_batchedUpdates(() => {
        setFeaturesList(PlusSearch);
        setFiltered(PlusSearch.result_list);
      });
      handleSearchClick();
    } catch (error) {
      handleRequestError(error);
    }
  };
  const getPlusApplications = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getPlusApplication(
        '',
        paginationLimit,
        val,
        (page - 1) * paginationLimit,
      );
      setFeaturesList(res);
      setFiltered(res.result_list);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [page]);

  useEffect(() => {
    getPlusApplications();
  }, [page]);

  return (
    <div className="pt-5 flex justify-center items-center">
      <div className="flex flex-col w-full h-full rounded-lg bg-whiteColor">
        <div
          className={`inline-flex justify-start items-start ml-5 mt-8 gap-2 mb-4`}
        >
          <div className="relative flex items-center">
            <AiOutlineSearch className="w-[24px] h-[24px] ml-2 absolute text-gray-400" />
            <input
              onChange={(e) => setVal(e.target.value)}
              type="search"
              id="default-search"
              className="block h-[48px] w-[382px] p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
              placeholder={`${t('UserInputText')}`}
            />
          </div>
          <ButtonComponent
            disabled={false}
            width={'w-[146px]'}
            hight={'h-[48px]'}
            text={`${t('search')}`}
            fontsize={'text-base'}
            onClick={getSearchData}
          />
        </div>
        <div className="px-6">
          <div className="h-auto">
            {isLoading ? (
              <>
                {Array(8)
                  .fill('')
                  .map((_, index) => {
                    return (
                      <div key={index} className="ml-10">
                        <SkeletonDataComponent />
                      </div>
                    );
                  })}
              </>
            ) : filtered?.length === 0 ? (
              <div className="flex justify-center mx-auto">
                <EmptySearchComponent />
              </div>
            ) : filtered && filtered?.length > 0 ? (
              <>
                <div className="grid grid-cols-5 w-full font-bold text-lg pr-[15%] py-5 items-center">
                  <div className="text-center font-bold text-lg whitespace-nowrap">
                    {t('startupName')}
                  </div>
                  <div className="text-center font-bold text-lg whitespace-nowrap">
                    {t('startupSector')}
                  </div>
                  <div className="text-center font-bold text-lg">
                    {t('name')}
                  </div>
                  <div className="text-center font-bold text-lg whitespace-nowrap">
                    {t('startupprofile')}
                  </div>
                  <div className="text-center font-bold text-lg pl-10 whitespace-nowrap">
                    {t('allstatus')}
                  </div>
                </div>
                {filtered?.map((item, index) => (
                  <div key={index} className="flex flex-col items-center mb-4">
                    <div className="flex flex-row items-center">
                      <DataListComponent
                        index={index}
                        toggle={toggle}
                        id={item.id}
                        item1={item.startup_name}
                        item2={item.startup_major}
                        item3={item.first_name + ' ' + item.last_name}
                        Icon={PDFEnum.YES}
                        isNew={false}
                        item5={item.application_state}
                        onClickArrowDown={() =>
                          handleToggleClick(index, !toggle[index])
                        }
                        onClickArrowUp={() =>
                          handleToggleClick(index, !toggle[index])
                        }
                      />
                    </div>

                    {toggle[index] && (
                      <div className="flex flex-col w-full py-4 px-8 bg-slate50">
                        <div className="flex flex-col py-2 w-full">
                          <div
                            className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                          >
                            <div className="flex flex-row justify-between w-full px-4">
                              <div className="text-secondaryGreen400">
                                About the startup
                              </div>
                              {toggleAboutTheStartup[index] ? (
                                <IoIosArrowUp
                                  className="flex w-[50px] cursor-pointer pl-5"
                                  onClick={() =>
                                    handleToggleAboutTheStartupClick(index)
                                  }
                                />
                              ) : (
                                <IoIosArrowDown
                                  className="flex w-[50px] cursor-pointer pl-5"
                                  onClick={() =>
                                    handleToggleAboutTheStartupClick(index)
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {toggleAboutTheStartup[index] && (
                            <div className="flex flex-col w-full bg-whiteColor border border-gray500 py-4">
                              <div className="px-3 text-base">
                                {item.about_startup}
                              </div>
                              <div>
                                <div className="flex items-end justify-end gap-2 px-5">
                                  <IoLogoInstagram className="w-[25px] h-[25px] text-orange500" />
                                  <AiOutlineLink className="w-[25px] h-[25px] text-orange500" />
                                  <TfiTwitter className="w-[25px] h-[25px] text-orange500" />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="grid grid-cols-2 gap-5 pt-9">
                            <div className="flex flex-col">
                              <div
                                className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                              >
                                <div className="flex flex-row justify-between w-full px-4">
                                  <div className="text-secondaryGreen400">
                                    {t('generalinfo')}
                                  </div>
                                  {toggleGenralInfo[index] ? (
                                    <IoIosArrowUp
                                      className="flex justify-end items-end w-[50px] cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleGenralInfoClick(index)
                                      }
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="flex justify-end items-end w-[50px] cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleGenralInfoClick(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {toggleGenralInfo[index] && (
                                <div className="flex flex-col w-full bg-whiteColor border border-gray500 px-3 gap-2 py-3">
                                  <div>{t('firstName')}</div>
                                  <div>{item.first_name}</div>
                                  <hr className="w-full px-0 opacity" />
                                  <div>{t('lastname')}</div>
                                  <div>{item.last_name}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('nationality')}</div>
                                  <div>{item.nationality}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('idType')}</div>
                                  <div>{item.identity_type}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('idNumber')}</div>
                                  <div>{item.identity_number}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('mobilenumber')}</div>
                                  <div>{item.mobile}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('mobilenumber')}</div>
                                  <div>{item.email}</div>
                                </div>
                              )}
                            </div>

                            <div className="flex flex-col">
                              <div
                                className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                              >
                                <div className="flex flex-row justify-between w-full px-4">
                                  <div className="text-secondaryGreen400">
                                    {t('startupDetails')}
                                  </div>
                                  {toggleStartUpDetails[index] ? (
                                    <IoIosArrowUp
                                      className="flex w-[50px] cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleStartUpDetailsClick(index)
                                      }
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="flex w-[50px] cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleStartUpDetailsClick(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {toggleStartUpDetails[index] && (
                                <div className="flex flex-col w-full bg-whiteColor border border-gray500 px-3 gap-2 py-3">
                                  <div className="text-base font-bold">
                                    {t('yearofestablishment')}
                                  </div>
                                  <div>{item.year_of_establishment}</div>
                                  <hr className="w-full px-0 opacity-50" />
                                  <div>{t('startupsize')}</div>
                                  <div>{item.startup_capacity}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('nationality')}</div>
                                  <div>{item.hear_about_garage}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('whydoyouwanttojoinAlGarage')}</div>
                                  <div>{item.reason_to_join}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
        <div className="px-5">
          <Pagination
            totalPage={Math.ceil(
              (FeaturesList?.total_count || 0) / paginationLimit,
            )}
            page={page}
            limit={paginationLimit}
            onPageChange={setPage}
            totalCount={FeaturesList?.total_count || 0}
          />
        </div>
        <div className="flex justify-between items-center px-5 pb-2">
          <div></div>
          <ButtonComponent
            disabled={false}
            width={'w-[284px]'}
            img={<RiFileExcel2Line size={24} />}
            hight={'h-[48px]'}
            text={'Export All'}
            fontsize={'text-base'}
            onClick={handleExportClick}
          />
        </div>
      </div>
      {exportPopup && (
        <ExportPlusPopup
          title={'Plus'}
          subtitle={'The plus you picked will be exported.'}
          programName={'Plus'}
          fileName="Plus Application"
          pathList={'plus'}
          pathExport={'plus'}
          onClose={handleCloseExportPopup}
          onClickConfirm={handleCloseExportPopup}
        />
      )}
    </div>
  );
}
export default PlusOldApplications;
