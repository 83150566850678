import CircleSkeleton from './circle-skeleton';
import LineSkeleton from './line-skeleton';
import SquareSkeleton from './square-skeleton';

export default function SkeletonDataComponent() {
  return (
    <div className="pb-5">
      <div className="rounded-lg shadow-lg bg-[#F8FAFC] w-full h-[66px] px-4">
        <div className="flex flex-row space-x-28">
          <div className="ml-5 h-15 w-24 items-center justify-center flex">
            <LineSkeleton />
          </div>
          <div className="mt-2 h-10 w-28">
            <CircleSkeleton />
          </div>
          <div className="h-15 w-32 items-center justify-center flex">
            <LineSkeleton />
          </div>
          <div className="">
            <div className="ml-16 mt-4 h-8 w-8">
              <SquareSkeleton />
            </div>
          </div>

          <div className="mt-2 h-10 w-28">
            <CircleSkeleton />
          </div>
        </div>
      </div>
    </div>
  );
}
