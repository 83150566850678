import Button from 'components/admin/ButtonComponent';
import ButtonComponent from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { downloadDataLink } from 'components/common/csv';
import DataListComponent from 'components/common/Data-list/data-list-component';
import EmptySearchComponent from 'components/common/empty-search';
import Pagination from 'components/common/Pagination/pagination';
import SkeletonDataComponent from 'components/common/skeleton-element/incubators-skeleton';
import {
  getDisruptCompany,
  postDisruptApplicationCompanyAccepted,
  postDisruptApplicationCompanyRejected,
} from 'core/data-access/disrupt.service';
import { PDFEnum } from 'core/enums/application_state.enum';
import { DisruptEnum, DisruptExportEnum } from 'core/enums/disrupt.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { DisruptModel } from 'core/types/admin.type';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlineLink } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp, IoLogoInstagram } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { TfiTwitter } from 'react-icons/tfi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { exportDisruptApplicationService } from 'services/export-services';

function DisruptNewApplicationsStartups() {
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const place = [1, 1, 1, 1];
  const [data, setData] = useState<DisruptModel>();
  const [id, setId] = useState<string>('');
  const [dataExport, setDataExport] = useState<string>('');
  const toggledata: SetStateAction<[]> = [];
  const [toggle, setToggle] = useState(
    new Array(toggledata.length).fill(false),
  );
  const [toggleAboutTheStartup, setToggletoggleAboutTheStartup] = useState(
    new Array(toggledata.length).fill(false),
  );
  const paginationLimit = 4;
  const [page, setPage] = useState<number>(1);
  const [val, setVal] = useState<string>('');
  const [, setFiltered] = useState(data?.result_list);
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(toggledata.length).fill(false),
  );
  const [toggleStartUpDetails, settoggleStartUpDetails] = useState(
    new Array(toggledata.length).fill(false),
  );
  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleAboutTheStartupClick = (index: number) => {
    const newToggleSmall = [...toggleAboutTheStartup];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggletoggleAboutTheStartup(newToggleSmall);
  };

  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };

  const handleToggleStartUpDetailsClick = (index: number) => {
    const newToggleSmall = [...toggleStartUpDetails];
    newToggleSmall[index] = !newToggleSmall[index];
    settoggleStartUpDetails(newToggleSmall);
  };

  // Toggle Enable form
  const [enabled, setEnabled] = useState<boolean>(false);
  const handleRecivingClosePopup = () => {
    setEnabled(false);
  };
  const [acceptPopup, setAcceptPopup] = useState<boolean>(false);
  const handleAcceptClick = () => {
    setAcceptPopup(true);
  };
  const handleCloseAcceptPopup = () => {
    setAcceptPopup(false);
  };
  const [rejectPopup, setRejectPopup] = useState<boolean>(false);
  const handleRejectClick = () => {
    setRejectPopup(true);
  };
  const handleCloseRejectPopup = () => {
    setRejectPopup(false);
  };
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };

  const getStartUp = useCallback(async () => {
    try {
      const Disrupt: DisruptModel = await getDisruptCompany(
        paginationLimit,
        (page - 1) * paginationLimit,
        val,
        DisruptEnum.PENDING,
        null,
      );
      setData(Disrupt);
      setSkeletonData(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, [page]);

  const accept = async (disrupt_application_id: string) => {
    try {
      await postDisruptApplicationCompanyAccepted(disrupt_application_id);
      toast.success('This startup has been accepted successfully!');
      getStartUp();
    } catch (error) {
      handleRequestError(error);
    }
  };
  const rejectedCompany = async (disrupt_application_id: string) => {
    try {
      await postDisruptApplicationCompanyRejected(disrupt_application_id);
      toast.success('This startup has been rejected successfully!');
      getStartUp();
    } catch (error) {
      handleRequestError(error);
    }
  };
  const getDataExport = async () => {
    try {
      const disruptDataExport: string = await exportDisruptApplicationService(
        'disrupt',
        DisruptExportEnum.COMPANY,
      );
      setDataExport(disruptDataExport);
      downloadDataLink(disruptDataExport, 'New Disrupt Data');

      toast.success(`Data has been exported successfully!`);
    } catch (error) {
      handleRequestError(error);
    }
  };
  const handleSearchClick = () => {
    if (val === '') {
      setFiltered(data?.result_list);
      return;
    }
    const filterBySearch = data?.result_list.filter((item) => {
      item.startup_name.toLowerCase().includes(val.toLowerCase());
    });
    setFiltered(filterBySearch);
  };
  const getSearchData = async () => {
    try {
      const DisruptStartups: DisruptModel = await getDisruptCompany(
        paginationLimit,
        (page - 1) * paginationLimit,
        val,
        DisruptEnum.PENDING,
        null,
      );

      unstable_batchedUpdates(() => {
        setData(DisruptStartups);
        setFiltered(DisruptStartups.result_list);
      });
      handleSearchClick();
    } catch (error) {
      handleRequestError(error);
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    getStartUp();
  }, [getStartUp]);
  return (
    <div className="pt-5 flex justify-center items-center">
      <div className="flex flex-col w-full h-full rounded-lg bg-whiteColor px-6 py-6">
        <div className="flex flex-row justify-start items-start p-1 mb-4 gap-4">
          <input
            onChange={(e) => setVal(e.target.value)}
            type="search"
            id="default-search"
            className="block h-12 w-1/2 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
            placeholder={`${t('search')}`}
            required
          />
          <ButtonComponent
            disabled={false}
            width={'w-[143px]'}
            hight={'h-[43px]'}
            text={`${t('search')}`}
            fontsize={'text-base'}
            onClick={getSearchData}
          />
        </div>
        <div className="grid grid-cols-5 w-full whitespace-nowrap gap-16 font-bold text-lg pr-[15%] pl-9 pb-2 items-center">
          <div>{t('startupName')}</div>
          <div>{t('startupMajor')}</div>
          <div>{t('Startup stage')}</div>
          <div>{t('startupprofile')}</div>
          <div className="pl-[30%]">{t('status')}</div>
        </div>
        {skeletonData ? (
          <div className="flex flex-col items-center">
            {place.map((e, index) => {
              return (
                <div key={index}>
                  <SkeletonDataComponent />
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            {data?.total_count === 0 ? (
              <div className="flex justify-center">
                <EmptySearchComponent />
              </div>
            ) : (
              <div>
                {data?.result_list?.map((item, index) => (
                  <div
                    key={item.id}
                    className="flex flex-col w-full items-center py-3"
                  >
                    <DataListComponent
                      index={index}
                      toggle={toggle}
                      id={item.id}
                      item1={item.startup_name}
                      item2={item.startup_major}
                      item3={item.startup_last_fundraising_round}
                      Icon={PDFEnum.YES}
                      isNew={true}
                      item5={item.application_state}
                      onClickButtonAccept={() => {
                        setId(item.id);
                        handleAcceptClick();
                      }}
                      onClickButtonReject={() => {
                        setId(item.id);
                        handleRejectClick();
                      }}
                      onClickArrowDown={() =>
                        handleToggleClick(index, !toggle[index])
                      }
                      onClickArrowUp={() =>
                        handleToggleClick(index, !toggle[index])
                      }
                    />

                    {toggle[index] && (
                      <div className="px-4 pb-4 bg-bgGray w-full flex justify-center h-full rounded-b-lg">
                        <div className="flex flex-col py-2 w-full">
                          <div
                            className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                          >
                            <div className="flex flex-row justify-between w-full h-5 px-4">
                              <div className="text-secondaryGreen400">
                                About the startup
                              </div>
                              {toggleAboutTheStartup[index] ? (
                                <IoIosArrowUp
                                  className="flex w-[50px] cursor-pointer h-5 pl-5"
                                  onClick={() =>
                                    handleToggleAboutTheStartupClick(index)
                                  }
                                />
                              ) : (
                                <IoIosArrowDown
                                  className="flex w-[50px] cursor-pointer pl-5 h-5"
                                  onClick={() =>
                                    handleToggleAboutTheStartupClick(index)
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {toggleAboutTheStartup[index] && (
                            <div className="flex flex-col w-full h-[120px] bg-whiteColor border border-gray500 py-4">
                              <div className="px-3 text-base">
                                {item.about_startup}
                              </div>
                              <div>
                                <div className="flex items-end justify-end gap-2 px-5 py-4">
                                  <Link
                                    to={`https://www.instagram.com/${item.instagram}/?hl=ar`}
                                    target="_blank"
                                  >
                                    <IoLogoInstagram className="w-[25px] h-[25px] text-orange500" />
                                  </Link>
                                  <Link to={item.website_link} target="_blank">
                                    <AiOutlineLink className="w-[25px] h-[25px] text-orange500" />
                                  </Link>
                                  <Link
                                    to={`https://twitter.com/${item.twitter}`}
                                    target="_blank"
                                  >
                                    <TfiTwitter className="w-[25px] h-[25px] text-orange500" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="grid grid-cols-2 gap-5 pt-9">
                            <div className="flex flex-col">
                              <div
                                className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                              >
                                <div className="flex flex-row justify-between w-full px-4">
                                  <div className="text-secondaryGreen400">
                                    {t('generalinfo')}
                                  </div>
                                  {toggleGenralInfo[index] ? (
                                    <IoIosArrowUp
                                      className="flex w-[50px] cursor-pointer h-5 pl-5"
                                      onClick={() =>
                                        handleToggleGenralInfoClick(index)
                                      }
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="flex w-[50px] cursor-pointer pl-5 h-5"
                                      onClick={() =>
                                        handleToggleGenralInfoClick(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {toggleGenralInfo[index] && (
                                <div className="flex flex-col w-full bg-whiteColor border border-gray500 gap-2 py-3">
                                  <div className="px-3">{t('firstName')}</div>
                                  <div className="text-textGrayColor px-3">
                                    {item.first_name}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">{t('lastname')}</div>
                                  <div className="text-textGrayColor px-3">
                                    {item.last_name}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">{t('nationality')}</div>
                                  <div className="text-textGrayColor px-3">
                                    {item.nationality}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">{t('gender')}</div>
                                  <div className="text-textGrayColor px-3">
                                    {item.gender}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">{t('position')}</div>
                                  <div className="text-textGrayColor px-3">
                                    {item.position}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">
                                    {t('mobilenumber')}
                                  </div>
                                  <div className="text-textGrayColor px-3">
                                    {item.mobile}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">{t('email')}</div>
                                  <div className="text-textGrayColor px-3">
                                    {item.email}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="flex flex-col">
                              <div
                                className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                              >
                                <div className="flex flex-row justify-between w-full px-4">
                                  <div className="text-secondaryGreen400">
                                    {t('startupDetails')}
                                  </div>
                                  {toggleStartUpDetails[index] ? (
                                    <IoIosArrowUp
                                      className="flex w-[50px] cursor-pointer h-5 pl-5"
                                      onClick={() =>
                                        handleToggleStartUpDetailsClick(index)
                                      }
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="flex w-[50px] cursor-pointer pl-5 h-5"
                                      onClick={() =>
                                        handleToggleStartUpDetailsClick(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {toggleStartUpDetails[index] && (
                                <div className="flex flex-col w-full bg-whiteColor border border-gray500 gap-2 py-3">
                                  <div className="text-base px-3">
                                    {t('yearofestablishment')}
                                  </div>
                                  <div className="text-textGrayColor px-3">
                                    {item.year_of_establishment}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">
                                    {t('Startup Stage')}
                                  </div>
                                  <div className="text-textGrayColor px-3">
                                    {item.startup_last_fundraising_round}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">
                                    {t('whydoyouwanttojoinAlGarage')}
                                  </div>
                                  <div className="text-textGrayColor px-3">
                                    {item.reason_to_join}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">
                                    {t('How did you hear about AlGarage+?')}
                                  </div>
                                  <div className="text-textGrayColor px-3">
                                    {item.hear_about_garage}
                                  </div>
                                  <div className="px-3">
                                    {item.hear_about_garage}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        <div className="pr-5">
          <Pagination
            totalCount={data?.total_count || 0}
            totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
            page={page}
            limit={paginationLimit}
            onPageChange={setPage}
          />
        </div>
        <div className="flex justify-end items-center">
          <Button
            disabled={data?.total_count === 0 ? true : false}
            width={'w-[305px]'}
            hight={'h-12'}
            text={'Export All'}
            img={<RiFileExcel2Line size={24} />}
            fontsize={'text-base'}
            onClick={handleExportClick}
          />
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {acceptPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseAcceptPopup}
            title={'Are you sure you want to accept this startup application? '}
            subtitle={'If you confirm, the startup application will be accept.'}
            onClick={handleCloseAcceptPopup}
            onClickConfirm={() => {
              accept(id);
            }}
          />
        )}
        {rejectPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseRejectPopup}
            title={'Are you sure you want to reject this startup application?'}
            subtitle={'If you confirm, the startup application will be reject.'}
            onClick={handleCloseRejectPopup}
            onClickConfirm={() => {
              rejectedCompany(id);
            }}
          />
        )}
        {exportPopup && (
          <ConfirmPopupComponent
            fileName="New Disrupt Data"
            onClose={handleCloseExportPopup}
            title={'Are you sure you want to Export latest version req?'}
            subtitle={
              'If you confirm, all forms on this page will be downloaded.'
            }
            onClick={handleCloseExportPopup}
            onClickConfirm={getDataExport}
            data={dataExport}
          />
        )}
        {enabled && (
          <ConfirmPopupComponent
            onClose={handleRecivingClosePopup}
            title={'Are you sure you want to Stop Receiving Forms? '}
            subtitle={
              'If you confirm, application form will not appear on the website.'
            }
            onClick={handleRecivingClosePopup}
            onClickConfirm={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}
      </div>
    </div>
  );
}
export default DisruptNewApplicationsStartups;
