import { useTranslation } from 'react-i18next';

export interface TextareaProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
}

export function Textarea1({ label, ...props }: TextareaProps) {
  const { i18n } = useTranslation();

  return (
    <div className="grid grid-cols-1 gap-2">
      <label
        htmlFor={props.name}
        className={`${
          i18n.language === 'en'
            ? 'font-en text-[12px] text-curren mobile:text-[16px]'
            : 'text-curren text-[20px] font-arabic_normal mobile:text-[16px]'
        }`}
      >
        {label}
      </label>
      <textarea
        placeholder="Enter the answer for this questions..."
        {...props}
        className="w-full h-[165px] text-sm font-en py-4 px-3  mobile:py-10 text-black  border border-[#C6BBAA] bg-white rounded-[4px] focus:outline-none focus:shadow-none placeholder:text-[16px] placeholder:mobile:text-[12px]"
      />
    </div>
  );
}

export default Textarea1;
