import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { downloadDataLink } from 'components/common/csv';
// import Loading from 'components/common/loading';
import SharedTableComponent from 'components/TableComponent';
import { handleRequestError } from 'core/helpers/error.helper';
import { PNUModel } from 'core/types/pnu.type';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { RiFileExcel2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { getExportPun, getPNUApplication } from 'services/pun.service';

import SearchComponent from '../Community/Partners/search-component';

function PnuContainer() {
  const [data, setData] = useState<PNUModel>();
  const [exportPopup, setExportPopup] = useState(false);
  const [dataExport, setDataExport] = useState<string>('');
  const paginationLimit = 9;
  const [visibleItems, setVisibleItems] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading] = useState(false);
  const [val, setVal] = useState<string>('');

  const getPNUApplications = useCallback(async () => {
    const res = await getPNUApplication(
      paginationLimit,
      (visibleItems - 1) * paginationLimit,
      val ?? null,
    );

    unstable_batchedUpdates(() => {
      setData(res);
      setTotalCount(res.total_count);
    });
  }, [visibleItems, val]);

  useEffect(() => {
    getPNUApplications();
  }, [getPNUApplications, val]);

  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };

  const getDataExport = async () => {
    try {
      const pnuDataExport: string = await getExportPun();
      setDataExport(pnuDataExport);
      downloadDataLink(pnuDataExport, 'New PNU Data');

      toast.success(`Data has been exported successfully!`);
    } catch (error) {
      handleRequestError(error);
    }
  };
  const headers = [
    // { key: 'url', title: t('container.media.listnews.image') },
    { key: 'name', title: t('name') },
    { key: 'last_name', title: t('Last Name') },
    { key: 'mobile', title: t('Mobile') },
    { key: 'email', title: t('Email') },
  ];

  return (
    <div>
      <div className="grid p-5 max-h-screen min-h-screen">
        <div className="flex flex-col items-center justify-center gap-y-3 py-2 bg-whiteColor w-full rounded-xl shadow">
          <div className="text-4xl font-bold text-orange500">
            {data?.total_count}
          </div>
          <div className="text-[28px] font-bold">{t('allApplications')}</div>
        </div>

        <div className="flex flex-col w-full pt-3">
          <SearchComponent
            onClickValue={(e) => setVal(e.target.value)}
            onClickShearch={getPNUApplications}
            onClick={() => setVal(val)}
          />
        </div>
        <div>
          <SharedTableComponent
            headers={headers}
            data={
              data?.result_list.map((pnu) => ({
                name: pnu.name,
                last_name: pnu.last_name,
                mobile: pnu.mobile,
                email: pnu.email,
              })) || []
            }
            loading={loading}
            totalCount={totalCount}
            paginationLimit={paginationLimit}
            page={visibleItems}
            onPageChange={setVisibleItems}
          />
        </div>

        <div className="flex items-center justify-end px-3 ">
          <Button
            disabled={false}
            width={'w-[305px]'}
            hight={'h-12'}
            text={'Export All'}
            img={<RiFileExcel2Line size={24} />}
            fontsize={'text-base'}
            onClick={() => handleExportClick()}
          />
        </div>
      </div>
      {exportPopup && (
        <ConfirmPopupComponent
          fileName="New PNU Data"
          onClose={handleCloseExportPopup}
          title={'Are you sure you want to Export PNU Data'}
          subtitle={
            'If you confirm, all forms on this page will be downloaded.'
          }
          onClick={handleCloseExportPopup}
          onClickConfirm={getDataExport}
          data={dataExport}
        />
      )}
    </div>
  );
}

export default PnuContainer;
