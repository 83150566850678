import { downloadDataLink } from 'components/common/csv';
import { handleRequestError } from 'core/helpers/error.helper';
import { IncubatorExportListNumberModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  exportIncubatorApplicationService,
  programsListExportService,
} from 'services/export-services';

import ExportPopup from './export-popup';

export interface IExportPopupProps {
  title: string;
  subtitle: string;
  programName: string;
  fileName?: string;
  pathList: string;
  pathExport: string;
  applicationType?: string;

  onClose: () => void;
  onClickConfirm: () => void;
}

export default function ExportIncubatorPopup(props: IExportPopupProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [programId, setProgramId] = useState<string>('');
  const [programNumber, setProgramNumber] = useState<number>(0);
  const [programsList, setProgramsList] =
    useState<IncubatorExportListNumberModel[]>();

  const handleClose = () => {
    props.onClose();
  };

  const programsNumList = useCallback(async () => {
    try {
      const res = await programsListExportService(
        props.pathList,
        props.applicationType || '',
      );
      setProgramsList(res.reverse());
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  const programsExport = useCallback(
    async (incubator_id: string, programNum: number) => {
      try {
        const res = await exportIncubatorApplicationService(
          `${props.pathExport}`,
          incubator_id ?? '',
        );

        handleConfirm(res, programNum);
      } catch (error) {
        handleRequestError(error);
      }
    },
    [],
  );

  const handleConfirm = (dataExport: string, programNumber: number) => {
    props.onClickConfirm();
    downloadDataLink(dataExport, props.fileName || '');
    toast.success(
      `${props.programName} ${programNumber} has been exported successfully!`,
    );
    props.onClose();
  };

  const handleClickProgramNumber = (programId: string, programNum: number) => {
    setProgramId(programId);
    setProgramNumber(programNum);
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    programsNumList();
  }, []);

  return (
    <ExportPopup
      title={props.title}
      subtitle={props.subtitle}
      programName={props.programName}
      programNumber={programNumber}
      onClose={handleClose}
      onClickConfirm={() => programsExport(programId, programNumber)}
    >
      <>
        {programsList?.map((item) => (
          <>
            <li
              key={item.incubator_number}
              className="px-4 py-2 cu"
              onClick={() => {
                handleClickProgramNumber(item.id, item.incubator_number);
              }}
            >
              {item.incubator_number}
            </li>
            {programsList.length === item.incubator_number ? (
              ''
            ) : (
              <hr className=" border-[1px] border-gray500" />
            )}
          </>
        ))}
      </>
    </ExportPopup>
  );
}
