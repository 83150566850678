import EmptySearchComponent from 'components/common/empty-search';
import Pagination from 'components/common/Pagination/pagination';
import SearchComponent from 'containers/admin/Community/Partners/search-component';
import { QuestionsState } from 'core/enums/application_state.enum';
import { contactUsEnum } from 'core/enums/example.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllQuestionModel } from 'core/types/user.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { HiOutlineTrash } from 'react-icons/hi';
import * as Services from 'services/FAQ-service';

import ConfirmPopupComponent from './ConfirmPopupComponet';
import MessageOpenedEmailComponet from './message-opened-email-componet';
import MessageSkeletonComponent from './message-skeleton-component/message-skeleton-component';

function FaqOpened() {
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const paginationLimit = 6;
  const [visibleItems, setVisibleItems] = useState(1);
  const [data, setData] = useState<AllQuestionModel>();
  const [textAreaValue, setTextAreaValue] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [val, setVal] = useState<string>('');

  const fetchReadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const questionData: AllQuestionModel = await Services.getQuestionService(
        val ?? null,
        contactUsEnum.READ,
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
      );
      setData(questionData);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [val, visibleItems, paginationLimit]);

  const handleCheckeAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedIds(data?.result_list?.map((r) => r.id || '') || []);
      setIsCheckedAll(true);
    } else {
      setSelectedIds([]);
      setIsCheckedAll(false);
    }
  };
  const deletNewData = async () => {
    try {
      await Services.postQuestionService(contactUsEnum.DEL, selectedIds);
      unstable_batchedUpdates(() => {
        setSelectedIds([]);
        setIsCheckedAll(false);
      });
      fetchReadData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setTextAreaValue(event.target.value);
  };

  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleAcceptClick = () => {
    setShowPopup(true);
  };

  const handleAddNewContactId = (id: string) => {
    const foundId = selectedIds.find((i) => i === id);
    if (foundId) {
      setSelectedIds(selectedIds.filter((i) => i !== foundId));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };
  useEffect(() => {
    fetchReadData();
  }, [fetchReadData]);

  return (
    <div className="desktop:pt-6 pt-4">
      <div className="bg-white rounded-[8px] px-6 py-6 desktop:pt-6">
        <p className=" text-[18px] font-bold font-en pl-7">All Questions</p>
        <div className="flex flex-col w-full rounded-lg px-6">
          <div className="flex gap-6 bg-slate50 items-center justify-start pl-2 py-2 desktop:py-4 desktop:pl-4 rounded-[8px]">
            <div className="flex items-center justify-start gap-5">
              <div className="flex gap-1">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id={'lang'}
                    name="SelectAll"
                    checked={isCheckedAll}
                    onChange={(e) => handleCheckeAll(e.target.checked)}
                  />
                </div>
                <div className="text-sm ">
                  <label className="text-[16px] text-gray-900 dark:text-gray-300">
                    Select All
                  </label>
                </div>
              </div>
              <div
                className="flex flex-row gap-1 cursor-pointer"
                onClick={handleAcceptClick}
              >
                <HiOutlineTrash className="text-[25px] text-red500" />
                Delete
              </div>
            </div>
            <div className="w-3/4">
              <SearchComponent
                onClickValue={(e) => setVal(e.target.value)}
                onClickShearch={fetchReadData}
              />
            </div>
          </div>
          {isLoading ? (
            <div className="grid grid-cols-1 mt-1">
              {Array(6)
                .fill('')
                .map((_, index) => {
                  return (
                    <div key={`skeleton-${index}`}>
                      <MessageSkeletonComponent />
                    </div>
                  );
                })}
            </div>
          ) : data?.total_count === 0 ? (
            <div className="mt-20">
              <EmptySearchComponent />
            </div>
          ) : (
            <div className="pt-1 w-full">
              <MessageOpenedEmailComponet
                list={data?.result_list || []}
                handleAddId={(id: string) => handleAddNewContactId(id)}
                checkedIds={selectedIds}
                textAreaVal={textAreaValue}
                textAreaOnChange={(e) => setTextAreaValue(e.target.value)}
                handleSendPost={() => handleTextAreaChange}
              />
            </div>
          )}
          <div className="pr-8 pt-5 desktop:pt-0 desktop:pb-7">
            <Pagination
              totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
              page={visibleItems}
              limit={paginationLimit}
              onPageChange={setVisibleItems}
              totalCount={data?.total_count || 0}
              questions={QuestionsState.YES}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {showPopup && (
          <ConfirmPopupComponent
            onClose={handleClosePopup}
            title={'Are you sure you want to delete this question? '}
            subtitle={'If you confirm, question will be delete.'}
            onClick={handleClosePopup}
            onClickConfirm={() => deletNewData()}
          />
        )}
      </div>
    </div>
  );
}

export default FaqOpened;
