import '../../../../../src/App.css';

import ButtonComponent from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import SelectTourPopupComponent from 'components/admin/selectTourPopupComponet';
import { downloadDataLink } from 'components/common/csv';
import EmptyDataComponent from 'components/common/empty-data-component';
import EmptySearchComponent from 'components/common/empty-search';
import Pagination from 'components/common/Pagination/pagination';
import SkeletonDataComponent from 'components/common/skeleton-element/incubators-skeleton';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { OpenAndCloseEnum } from 'core/enums/open-and-close-state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { BookATourModel, IsOpenModel } from 'core/types/user.type';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import {
  getAllApplicantsForBookATourServices,
  getBookATourExportServices,
  getBookATourIsOpenServices,
  postBookATourOpenAndCloseServices,
} from 'services/book-a-tour-services';

import SearchComponent from '../Partners/search-component';

function NewTourContainer() {
  const [bookATourData, setBookATourData] = useState<BookATourModel>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tourId, setTourId] = useState<string>('');
  const paginationLimit = 5;
  const [visibleItems, setVisibleItems] = useState(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [toggle, setToggle] = useState(
    new Array(bookATourData?.result_list.length).fill(false),
  );
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(bookATourData?.result_list.length).fill(false),
  );
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  // Toggle Enable form
  const [enabled, setEnabled] = useState<boolean>(false);
  const handleRecivingClosePopup = () => {
    setEnabled(false);
  };

  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };

  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };

  // const [, setFiltered] = useState(bookATourData?.result_list);
  const [dataExport, setDataExport] = useState<string>('');

  const [acceptPopup, setAcceptPopup] = useState(false);
  const handleAcceptClick = (tourId: string) => {
    setAcceptPopup(true);
    setTourId(tourId);
  };
  const handleCloseAcceptPopup = () => {
    setAcceptPopup(false);
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const tourData = await getAllApplicantsForBookATourServices(
        ApplicationStateEnum.PENDING,
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
        searchValue ?? null,
      );

      unstable_batchedUpdates(() => {
        setBookATourData(tourData);
        tourData.result_list;
      });
      return tourData;
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [setBookATourData, visibleItems, searchValue]);

  const getDataExport = async () => {
    try {
      const tourDataExport: string = await getBookATourExportServices();
      setDataExport(tourDataExport);
      downloadDataLink(tourDataExport, 'Tour Data');

      toast.success(`Data has been exported successfully!`);
    } catch (error) {
      handleRequestError(error);
    }
  };

  const getIsOpen = useCallback(async () => {
    try {
      const isOpen: IsOpenModel = await getBookATourIsOpenServices();
      setIsOpen(isOpen.is_open);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  const postBookATourOpenAndClose = async (open: OpenAndCloseEnum) => {
    try {
      await postBookATourOpenAndCloseServices(open);
      toast.success(
        `Receiving forms has been ${
          open === OpenAndCloseEnum.CLOSE ? 'stoped' : 'enable'
        } successfully.`,
      );
      getIsOpen();
    } catch (error) {
      handleRequestError(error);
    }
  };

  useEffect(() => {
    getData();
    getIsOpen();
  }, [getData, getIsOpen]);

  return (
    <div className="pt-6 desktop:py-7 flex justify-center items-center">
      <div className="flex flex-col rounded-lg bg-white w-full px-10 pt-8 pb-2">
        <div className="flex flex-row justify-start items-start pb-3 gap-4">
          <SearchComponent
            onClickValue={(e) => setSearchValue(e.target.value)}
            onClickShearch={getData}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="grid grid-cols-4 w-full font-bold text-2xl text-center desktop:py-7 py-4 pr-44 pl-5 items-center">
            <div className="">{t('Name')}</div>
            <div className="">{t('Position')}</div>
            <div className="">{t('Company')}</div>
            <div className="">{t('Action')}</div>
          </div>
          {isLoading ? (
            <div className="flex flex-col items-center py-2">
              {Array(paginationLimit)
                .fill('')
                .map((_, index) => {
                  return (
                    <div key={index}>
                      <SkeletonDataComponent />
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="">
              {bookATourData?.total_count === 1 ? (
                <div className="flex justify-center mx-auto">
                  <EmptyDataComponent />{' '}
                </div>
              ) : bookATourData?.total_count === 0 ? (
                <div className="flex justify-center mx-auto">
                  <EmptySearchComponent />
                </div>
              ) : (
                bookATourData?.result_list.map((item, index) => (
                  <div key={index} className="flex flex-col items-center mb-4">
                    <div
                      className={`grid grid-cols-5 justify-center items-center bg-greyMenuColor h-full w-full py-2 px-9 rounded-t-lg ${
                        toggle[index] ? '' : 'rounded-b-lg'
                      }`}
                    >
                      <div className="text-center text-lg">
                        {item.first_name + '' + item.last_name}
                      </div>
                      <div className="text-center text-lg">{item.position}</div>
                      <div className="text-end text-lg truncate">
                        {item.company}
                      </div>
                      <div className="text-end">
                        <button
                          className="w-[95px] h-[37px] bg-lightGreenColor text-whiteColor rounded-[4px]"
                          onClick={() => handleAcceptClick(item.id)}
                        >
                          {t('Accept')}
                        </button>
                      </div>
                      <div className=" pl-9 desktop:pl-32">
                        {toggle[index] ? (
                          <IoIosArrowUp
                            size={24}
                            color="#1F2937"
                            className=" justify-end  w-[50px] cursor-pointer mt-2 ml-20"
                            onClick={() => handleToggleClick(index, false)}
                          />
                        ) : (
                          <IoIosArrowDown
                            size={24}
                            color="#1F2937"
                            className="justify-end  w-[50px] cursor-pointer mt-2 ml-20"
                            onClick={() => handleToggleClick(index, true)}
                          />
                        )}
                      </div>
                    </div>
                    {toggle[index] && (
                      <div className="px-4 pb-4 bg-bgGray w-full flex justify-center h-full rounded-b-lg">
                        <div className="flex flex-col py-2 w-full">
                          <div className="flex flex-row gap-5 w-full pt-4">
                            <div className="flex flex-col w-full px-10">
                              <div
                                className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                              >
                                <div className="flex flex-row items-center justify-between w-full">
                                  <div className="text-secondaryGreen400 pl-4 text-sm">
                                    {t('generalinfo')}
                                  </div>
                                  {toggleGenralInfo[index] ? (
                                    <IoIosArrowUp
                                      className="flex w-[65px] h-6 cursor-pointer pl-4"
                                      onClick={() =>
                                        handleToggleGenralInfoClick(index)
                                      }
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="flex w-[65px] h-6 cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleGenralInfoClick(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {toggleGenralInfo[index] && (
                                <div className="flex flex-col w-full bg-white border border-gray500 gap-2 py-2">
                                  <div className="px-3.5 text-base">
                                    {t('Last name')}
                                  </div>
                                  <div className="px-3.5 text-base text-greyChateau">
                                    {item.last_name}
                                  </div>
                                  <hr className="w-full px-0 border" />
                                  <div className="px-3.5 text-base">
                                    {t('Mobile Number')}
                                  </div>
                                  <div className="px-3.5 text-base text-greyChateau">
                                    {item.mobile}
                                  </div>
                                  <hr className="w-full border" />
                                  <div className="px-3.5 text-base">
                                    {t('Email')}
                                  </div>
                                  <div className="px-3.5 text-greyChateau text-base	">
                                    {item.email}
                                  </div>
                                  <hr className="w-full border" />
                                  <div className="px-3.5 text-base">
                                    {t('The reason for the visit')}
                                  </div>
                                  <div className="px-3.5 text-greyChateau text-base">
                                    {item.visit_reason}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
        <div className="">
          <Pagination
            totalPage={Math.ceil(
              (bookATourData?.total_count || 0) / paginationLimit,
            )}
            page={visibleItems}
            limit={paginationLimit}
            onPageChange={setVisibleItems}
            totalCount={bookATourData?.total_count || 0}
          />
        </div>
        <div className="flex items-center justify-between pt-3">
          <div className="flex flex-row gap-2 pl-4">
            <span className="font-bold">{t('Receiving forms')}</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={isOpen}
                readOnly
              />
              <div
                onClick={() => {
                  setEnabled(!enabled);
                }}
                className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange500"
              ></div>
            </label>
          </div>
          <div className="px-5">
            <ButtonComponent
              disabled={bookATourData?.total_count === 0 ? true : false}
              width={'w-[305px]'}
              hight={'h-12'}
              text={'Export All'}
              img={<RiFileExcel2Line size={24} />}
              fontsize={'text-base'}
              onClick={() => handleExportClick()}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {acceptPopup && (
          <SelectTourPopupComponent
            onClose={handleCloseAcceptPopup}
            title={'Tour Timing'}
            subtitle={'Date & Time'}
            tourId={tourId}
            onClick={handleCloseAcceptPopup}
            onClickConfirm={getData}
          />
        )}
        {exportPopup && (
          <ConfirmPopupComponent
            fileName="Tour Data"
            onClose={handleCloseExportPopup}
            title={'Are you sure you want to Export All?'}
            subtitle={
              'If you confirm, all forms on this page will be downloaded.'
            }
            onClick={handleCloseExportPopup}
            onClickConfirm={getDataExport}
            data={dataExport}
          />
        )}

        {enabled && (
          <ConfirmPopupComponent
            onClose={handleRecivingClosePopup}
            title={
              isOpen === true
                ? 'Are you sure you want to Stop Receiving Forms?'
                : 'Are you sure you want to Enable Receiving Forms? '
            }
            subtitle={
              'If you confirm, application form will not appear on the website.'
            }
            onClick={handleRecivingClosePopup}
            onClickConfirm={() =>
              postBookATourOpenAndClose(
                isOpen === true
                  ? OpenAndCloseEnum.CLOSE
                  : OpenAndCloseEnum.OPEN,
              )
            }
          />
        )}
      </div>
    </div>
  );
}

export default NewTourContainer;
