import { t } from 'i18next';

interface IInputprops extends React.InputHTMLAttributes<HTMLInputElement> {
  limit?: number;
  helperText?: string;
  label?: string;
  isValid?: boolean;
  errorMsg?: string;
}
function InputField({
  label = 'Label',
  limit,
  helperText = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isValid = true,
  errorMsg = '',
  ...props
}: IInputprops): JSX.Element {
  const valueLength = props.value?.toString().length || 0;
  const getError = () => {
    if (errorMsg !== '') {
      isValid = false;
      return 'border-red-600 focus:border-red-600 focus:border-2';
    }
    if (limit !== undefined && valueLength > limit) {
      isValid = false;
      return 'border-red-600 focus:border-red-600 focus:border-2';
    } else {
      isValid = true;
      return '';
    }
  };
  const getInputVarient = () => {
    return `border-[1px] py-2 px-2 rounded-md bg-neutralGre50 ${getError()}`;
  };

  return (
    <div
      className="flex flex-col w-full justify-center mx-auto"
      dir={props.dir}
    >
      <label
        className="text-blue500 text-2xl font-arabic_normal disabled:!bg-transparent disabled:bg-none"
      >
        {t(`${label}`)}
      </label>
      <input
        {...props}
        type="text"
        className={`font-arabic_normal h-12 focus:outline-none focus:border-secondaryGreen400 focus:ring-secondaryGreen400 text-lg border-gray-300 transition disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${getInputVarient()}`}
      />
      <div className={`flex justify-between px-2 py-1 text-sm text-gray-500`}>
        {(limit && limit < (props.value?.toString().length || 0)) ||
        errorMsg !== '' ? (
          <p className={` ${getError()} text-error`}>{t(`${errorMsg}`)}</p>
        ) : (
          <p className={` ${getError()} `}>{t(`${helperText}`)}</p>
        )}
        {limit && (
          <span
            className={`${
              (props.value?.toString().length || 0) > limit
                ? 'text-error'
                : ''
            }`}
          >
            {props.value?.toString().length}/{limit}
          </span>
        )}
      </div>
    </div>
  );
}
export default InputField;
