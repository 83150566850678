import TabbarTourComponent from 'components/admin/tabbarTourComponent';

import NewTourContainer from './newTourContainer';
import OldTourContainer from './oldTourContainer';

function TourContainer() {
  return (
    <div>
      <TabbarTourComponent
        page={<OldTourContainer />}
        page2={<NewTourContainer />}
      />
    </div>
  );
}

export default TourContainer;
