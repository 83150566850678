import { useTranslation } from "react-i18next";

interface EmptySearchComponentProps {
  message?: string;
}

export default function EmptySearchComponent({ message = "Sorry, No Results Found" }: EmptySearchComponentProps) {
  const { t } = useTranslation();

  return (
    <div className="grid items-center justify-center gap-8 mx-auto">
      <div className=" h-[247.51px] w-[254px]">
        <img src="/assets/images/notFound.png" alt="not found" />
      </div>
      <h1 className="text-xl text-center text-blue300 whitespace-nowrap">
        {t(message || "")}
      </h1>
    </div>
  );
}
