import LineSkeleton from 'components/common/skeleton-element/line-skeleton';

export default function MessageSkeletonComponent() {
  return (
    <div className="flex flex-row items-center w-[1010px] justify-between ">
      <div className=" bg-white rounded-lg shadow-lg gap-20 grid grid-cols-6 py-3 pl-10 w-full h-full">
        <div className="h-5 w-16 flex justify-center items-center">
          <LineSkeleton />
        </div>
        <div className="h-5 w-16 flex justify-center items-center">
          <LineSkeleton />
        </div>
        <div className="h-5 w-16 flex justify-center items-center">
          <LineSkeleton />
        </div>

        <div className="h-5 w-16 flex justify-center items-center">
          <LineSkeleton />
        </div>
        <div className="h-5 w-16 flex justify-center items-center">
          <LineSkeleton />
        </div>
      </div>
    </div>
  );
}
