import { MediaNewsModel } from "core/types/admin.type";

// export const initialValuesNewsLetter: MediaNewsModel = {
//   title_ar: '',
//   description_ar: '',
//   title_en: '',
//   description_en: '',
//   url: '',
//   news_type: 'news_letter',
// };

export const getInitialValues = (newsType: string): MediaNewsModel => ({
  title_ar: '',
  description_ar: '',
  title_en: '',
  description_en: '',
  url: '',
  news_type: newsType,
});