import IncubatorContainer from 'containers/admin/Programs/Incubators/incubator-container';

const IncubatorsPage = () => {
  return (
    <div className="w-full">
      <IncubatorContainer />
    </div>
  );
};

export default IncubatorsPage;
