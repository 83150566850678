import React from 'react';

export default function EmptyDataComponent() {
  return (
    <div className="pt-32 pb-12">
      <div className="w-[347.86px] h-[258.66px] ">
        <img src="/assets/images/empty_state.png" alt="not found" />
      </div>
      <h4 className="pt-7 text-2xl leading-9 font-bold text-gray800 text-center">
        No tour applications available yet
      </h4>
      <h1 className="pt-2 text-base text-textGrayColor text-center">
        You have no requests right now.
      </h1>
      <h1 className="text-base text-textGrayColor text-center">
        Come back later
      </h1>
    </div>
  );
}
