class AppRoutes {
  /**
   * The AppRoutes class defines the `getInstance` method that lets clients access
   * the unique AppRoutes instance.
   */
  private static instance: AppRoutes;
  /**
   * The AppRoutes's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  /**
   * The static method that controls the access to the singleton instance.
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AppRoutes {
    if (!this.instance) {
      return (AppRoutes.instance = new AppRoutes());
    }
    return this.instance;
  }

  // paths
  getBasePath(): string {
    return '/';
  }

  getExamplePagePath(): string {
    return `/address/:addressId/view`;
  }

  getExamplePageUrl(): string {
    return this.getExamplePagePath();
  }

  // Example on a path with dynamic variables
  getExampleByIdPagePath(): string {
    return `/example/:id`;
  }
  getLogInPagePath(): string {
    return `/login`;
  }
  getDashboardPagePath(): string {
    return `/dashboard`;
  }
  getForgetPasswordPagePath(): string {
    return `/forget`;
  }
  getSetNewPassPath(): string {
    return `/set-new-password`;
  }

  getExampleByIdPageUrl(id: string): string {
    return this.getExampleByIdPagePath().replace(':id', id);
  }
  // // ./Old Applications
  // getOldApplicationsPagePath():string{
  //   return "/OldApplications";
  // }

  // getPreviousApplicationsPageUrl():string{
  //   return this.getOldApplicationsPagePath();
  // }
  // // New Applications
  // getNewApplicationsPagePath():string{
  //   return "/NewApplications";
  // }

  // getNewApplicationsPageUrl():string{
  //   return this.getNewApplicationsPagePath();
  // }
  // Entry Permission

  getEntryPermissionPageUrl(): string {
    return this.getEntryPermissionPagePath();
  }
  getEntryPermissionPagePath(): string {
    return '/entry-permission';
  }

  // Events Reservation

  getEventsReservationPageUrl(): string {
    return this.getEventsReservationPagePath();
  }
  getEventsReservationPagePath(): string {
    return '/events-reservation';
  }

  // ./Example
}

// getPreviousApplicationsPageUrl():string{
//   return this.getOldApplicationsPagePath();
// }
// // New Applications
// getNewApplicationsPagePath():string{
//   return "/NewApplications";
// }

// getNewApplicationsPageUrl():string{
//   return this.getNewApplicationsPagePath();
// }

//Incubators
// getIncubatorsPath():string{
//   return '/incubator-dashboard';
// }

// getIncubatorUrl():string{
//   return this.getIncubatorsPath();
// }
export const appRoutesObj = AppRoutes.getInstance();
