import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { HttpMethods } from 'core/enums/httpMethods.enum';
import { BookATourAcceptedModel } from 'core/types/user.type';

export const getAllApplicantsForBookATourServices = (
  applicationState: ApplicationStateEnum,
  limit: number,
  offset: number,
  searchText: string | null,
) => {
  return makeRequest({
    url: `${BASE_URL}/book-tour`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      application_state: applicationState,
      limit: limit,
      offset: offset,
    },
  });
};
export const postBookATourAcceptedServices = (
  data: BookATourAcceptedModel,
  tourId: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/book-tour/${tourId}/accept`,
    method: HttpMethods.POST,
    data,
  });
};
export const postBookATourStatusServices = (
  tourId: string,
  data: BookATourAcceptedModel,
) => {
  return makeRequest({
    url: `${BASE_URL}/book-tour/${tourId}/accept`,
    method: HttpMethods.POST,
    data,
  });
};
export const postBookATourOpenAndCloseServices = (openOrClose: string) => {
  return makeRequest({
    url: `${BASE_URL}/book-tour/${openOrClose}`,
    method: HttpMethods.POST,
  });
};
export const getBookATourIsOpenServices = () => {
  return makeRequest({
    url: `${BASE_URL}/book-tour/status`,
    method: HttpMethods.GET,
  });
};
export const getBookATourExportServices = () => {
  return makeRequest({
    url: `${BASE_URL}/tour/export`,
    method: HttpMethods.GET,
  });
};
