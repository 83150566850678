import { handleRequestError } from 'core/helpers/error.helper';
import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { postBookATourAcceptedServices } from 'services/book-a-tour-services';

interface CardPreviousApplicationsComponentProps {
  title: string;
  subtitle: string;
  tourId: string;
  onClose: () => void;
  onClick: () => void;
  onClickConfirm: () => void;
}

function SelectTourPopupComponent(
  props: CardPreviousApplicationsComponentProps,
) {
  const open = true;
  const handleDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };
  const currentYear = new Date().getFullYear();
  const [selectedDate, setSelectedDate] = useState<string>(``);

  const handleClose = () => {
    props.onClose();
  };
  const handleConfirm = () => {
    props.onClickConfirm();
    props.onClose();
  };

  const postBookATourAccepted = async () => {
    try {
      await postBookATourAcceptedServices(
        {
          tour_date: selectedDate,
        },
        props.tourId,
      );

      handleConfirm();
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <>
      {open && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full backdrop-opacity-10 backdrop-invert bg-white/30">
          <div className="px-6 py-6 rounded-lg bg-whiteColor">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[32px] font-bold">{props.title}</div>
              <div className="">
                <IoMdClose
                  className="text-3xl cursor-pointer text-orange500 "
                  onClick={handleClose}
                />
              </div>
            </div>

            <div className="pl-2 pt-[24px] pb-[9px] text-xl text-black">
              {props.subtitle}
            </div>
            <div
              id="-webkit-calendar-picker-indicator"
              className="w-[575px] h-[48px] border border-neutralGrey bg-gray-50 rounded-lg"
            >
              <input
                className={`w-[572px] h-[46px] px-2 rounded-lg ${selectedDate ? 'text-black ' : 'text-neutralGrey '}`}
                type="datetime-local"
                value={selectedDate}
                min={`${currentYear}-01-01T00:00`}
                onChange={handleDate}
                placeholder="Select a date and time"
              />
            </div>

            <div className="flex flex-row justify-center gap-3 pt-12">
              <button
                className="w-[305.46px] h-[48px] rounded-lg border border-white text-white bg-orange500 text-base"
                onClick={postBookATourAccepted}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectTourPopupComponent;
