import { HttpMethods } from 'core/enums/httpMethods.enum';

import { BASE_URL, makeRequest } from './http.service';

export const refreshTokenService = async (refreshToken: string) => {
  return await makeRequest({
    url: `${BASE_URL}/user/refresh-token`,
    method: HttpMethods.POST,
    data: { refresh_token: refreshToken },
  });
};
