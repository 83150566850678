import FaqNew from 'components/admin/faq-newQ-component';
import FaqOpened from 'components/admin/faq-opened-page';
import TabbarPreviousApplicationsComponentProps from 'components/admin/TabbarApplicationsComponet';

function FaqContainer() {
  return (
    <div className="px-5 py-7 desktop:py-7">
      <div className="flex flex-row items-center justify-center px-9 gap-5 mobile:flex-col">
        <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
          <div className="flex flex-col items-center justify-center gap-y-3 desktop:pt-5 desktop:pb-6 pt-4 pb-5">
            <div className="text-4xl font-bold text-orange500">1000</div>
            <div className="text-[28px] font-bold">{'All Questions'}</div>
          </div>
        </div>
        <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
          <div className="flex flex-col items-center justify-center gap-y-3 desktop:pt-5 desktop:pb-6 pt-4 pb-5">
            <div className="text-4xl font-bold text-orange500">1000</div>
            <div className="text-[28px] font-bold">{'New Questions'}</div>
          </div>
        </div>
        <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
          <div className="flex flex-col items-center justify-center gap-y-3 desktop:pt-5 desktop:pb-6 pt-4 pb-5">
            <div className="text-4xl font-bold text-orange500">1000</div>
            <div className="text-[28px] font-bold">{'opened questions'}</div>
          </div>
        </div>
      </div>

      <div className="">
        <TabbarPreviousApplicationsComponentProps
          page={<FaqNew />}
          page2={<FaqOpened />}
          title1={'New'}
          title2={'Opened'}
        />
      </div>
    </div>
  );
}

export default FaqContainer;
