/**
 * Converts 12-hour time format to 24-hour time format.
 * @param {string} time12h - Time in 12-hour format (e.g., "12 PM", "3:30 AM").
 * @returns {string} - Time in 24-hour format (e.g., "12:00", "15:30").
 */
export function convertTo24HourFormat(time12h: string): string {
  const regex = /(\d{1,2}):?(\d{2})?:?(\d{2})?\s*(AM|PM)/i;
  const match = time12h.match(regex);

  if (!match) return '';

  const [, hoursStr, minutesStr = '00', secondsStr = '00', period] = match;

  // Convert strings to numbers
  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);
  const seconds = Number(secondsStr);

  let hours24 = hours;

  if (period.toUpperCase() === 'AM') {
    if (hours === 12) hours24 = 0; // Midnight case
  } else if (period.toUpperCase() === 'PM') {
    if (hours !== 12) hours24 = hours + 12; // Convert PM hours
  }

  const formattedHours = hours24.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

type DateRange = {
  start_at: string;
  end_at: string;
};

/**
 * Generates a date range with start and end times.
 * @param {string} date - The base date in the format "YYYY/MM/DD".
 * @param {string} from - The start time in "HH" format.
 * @param {string} to - The end time in "HH" format.
 * @returns {DateRange} - An object containing start_at and end_at in ISO format.
 */
export function generateDateRange(
  date: string,
  from: string,
  to: string,
): DateRange {
  // Replace slashes with dashes for ISO format compatibility
  const formattedDate = date.replace(/\//g, '-');

  // Construct start_at and end_at
  const start_at = `${formattedDate}T${from.padStart(2, '0')}`;
  const end_at = `${formattedDate}T${to.padStart(2, '0')}`;

  return { start_at, end_at };
}

export const convertTimeToSeconds = (time: string): number => {
  const [timePart, period] = time.split(' ');
  const [hoursStr, minutesStr = '0', secondsStr = '0'] = timePart.split(':');

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);
  const seconds = Number(secondsStr);

  const adjustedHours =
    period === 'PM' && hours !== 12
      ? hours + 12
      : period === 'AM' && hours === 12
      ? 0
      : hours;

  return adjustedHours * 3600 + minutes * 60 + seconds;
};

export const filterFutureTimes = (timeList: string[]): string[] => {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  return timeList.filter((time: string) => {
    const [hour, minutePart] = time.split(':');
    const [minute, period] = [minutePart.slice(0, 2), minutePart.slice(3)];

    let timeHour = parseInt(hour);
    if (period === 'PM' && timeHour !== 12) {
      timeHour += 12;
    }
    if (period === 'AM' && timeHour === 12) {
      timeHour = 0;
    }

    if (
      timeHour > currentHour ||
      (timeHour === currentHour && parseInt(minute) >= currentMinute)
    ) {
      return true;
    }
    return false;
  });
};
