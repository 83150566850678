import { getIncubatorStatistics } from 'core/data-access/incubator.service';
import { IncubatorStatisticsModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function IncubatorStatisticssComponent() {
  const { t } = useTranslation();
  const [data, setGetDate] = useState<IncubatorStatisticsModel>();
  const getData = useCallback(async () => {
    try {
      const StatisticsData: IncubatorStatisticsModel =
        await getIncubatorStatistics();
      setGetDate(StatisticsData);
    } catch (error) {
      return error;
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div className="px-12 pt-9 flex flex-row items-center justify-center gap-5">
      <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
        <div className="flex flex-col items-center justify-center gap-y-3 pt-5 pb-6">
          <div className="text-4xl font-bold text-orange500">
            {data?.application_count}
          </div>
          <div className="text-[28px] font-bold">{t('allapplications')}</div>
        </div>
      </div>
      <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
        <div className="flex flex-col items-center justify-center gap-y-3 pt-5 pb-6">
          <div className="text-4xl font-bold text-orange500">
            {data?.accepted_count}
          </div>
          <div className="text-[28px] font-bold">{t('accepted')}</div>
        </div>
      </div>
      <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
        <div className="flex flex-col items-center justify-center gap-y-3 pt-5 pb-6">
          <div className="text-4xl font-bold text-orange500">
            {data?.rejected_count}
          </div>
          <div className="text-[28px] font-bold">{t('allrejected')}</div>
        </div>
      </div>
    </div>
  );
}

export default IncubatorStatisticssComponent;
