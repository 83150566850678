import { MdKeyboardArrowDown, MdKeyboardArrowUp } from '@meronex/icons/md';
import { Collapse, List } from '@mui/material';
import { IChild } from 'core/types/user.type';
import { useTranslation } from 'react-i18next';

import SidebarItemSub from './SidbarItemSub';

type Props = {
  item: IChild;
  open: boolean;
  setOpen: () => void;
};

const SidebarItemCollapseSub = (props: Props) => {
  const { i18n } = useTranslation();

  return (
    <>
      <div
        onClick={() => props.setOpen()}
        className={`cursor-pointer inline-flex w-full ${
          i18n.language === 'ar'
            ? 'items-center justify-between h-[40px] px-4 inline-flex w-full py-4 font-ar text-lg'
            : 'pl-7 py-4 font-en h-[50px] text-lg'
        }
      ${
        props.open
          ? 'items-center justify-between px-4 inline-flex py-4 text-lg'
          : ''
      }`}
      >
        <span
          className={`${
            props.open ? 'text-orange500' : 'text-textGrayColor'
          } font-bold`}
        >
          {props.item.title}
        </span>
        <div>
          {props.open ? (
            <MdKeyboardArrowUp className=" text-gray800" size={24} />
          ) : (
            <MdKeyboardArrowDown
              className={`text-gray80 absolute ${
                i18n.language === 'en' ? 'left-[260px]' : ' right-[260px]'
              }`}
              size={24}
            />
          )}
        </div>
      </div>
      <Collapse in={props.open} timeout="auto">
        <List
          className=" bg-white"
          sx={{
            paddingBottom: '0px',
          }}
        >
          {props.item.subchild?.map((route, index) => (
            <SidebarItemSub route={route} key={index} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default SidebarItemCollapseSub;
