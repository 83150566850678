import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { HttpMethods } from 'core/enums/httpMethods.enum';
import { CreateGalleryModel, GalleryModel } from 'core/types/admin.type';

//get All Media Gallery
export const getAllMediaGalleryServices = (
  galleryType: string,
  limit: number,
  offset: number,
) => {
  const type = galleryType
    ? { gallery_type: galleryType.toLocaleLowerCase() }
    : {};

  return makeRequest({
    url: `${BASE_URL}/gallery`,
    method: HttpMethods.GET,
    params: { limit: limit, offset: offset, ...type },
  });
};
// delete Picture by id
export const deletePictureServices = async (pictureID: string) => {
  return makeRequest({
    url: `${BASE_URL}/gallery/${pictureID}`,
    method: HttpMethods.DELETE,
  });
};
// Create Picture
export const postPictureServices = async (addPicture: CreateGalleryModel) => {
  return await makeRequest({
    url: `${BASE_URL}/gallery`,
    method: HttpMethods.POST,
    data: addPicture,
  });
};
//update Picture by id
export const putPictureServices = (
  editPicture: GalleryModel,
  pictureID: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/gallery/${pictureID}`,
    method: HttpMethods.PUT,
    data: editPicture,
  });
};
