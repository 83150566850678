import { MdKeyboardArrowDown, MdKeyboardArrowUp } from '@meronex/icons/md';
import { Collapse, List } from '@mui/material';
import { IMapRoutes } from 'core/types/user.type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SidebarItem from './SidebarItem';
import SidebarItemCollapseSub from './SidebarItemCollapseSub';

type Props = {
  item: IMapRoutes;
  isActive: boolean;
  setaActiveIndex: () => void;
};

const SidebarItemCollapse = (props: Props) => {
  const { i18n, t } = useTranslation();
  const [activIndex, setActivIndex] = useState<number>(-1);

  return (
    <>
      <div
        onClick={() => props.setaActiveIndex()}
        className={`cursor-pointer inline-flex w-full
      ${i18n.language === 'ar'
            ? 'items-center justify-between h-[66px] px-4 inline-flex w-full py-2 font-ar text-lg'
            : ' px-6 py-4 font-en h-[60px] text-xl'
          }
      ${props.isActive
            ? `text-gray800 h-[60px] justify-between inline-flex w-full font-en font-bold py-0 border shadow-[10px_1px_19px_1px_rgba(0,0,11,0.08)]
              ${i18n.language === 'en'
              ? 'border-l-secondaryGreen400 border-l-[6px] rounded-l-md rounded-r-md'
              : 'border-r-secondaryGreen400 border-r-[6px] rounded-r-md'
            }`
            : ''
          }`}
      >
        <span
          className={`${props.isActive ? 'text-gray800' : 'text-blue300'
            } font-bold `}
        >
          {t(props.item.title || "")}
        </span>
        <div>
          {props.isActive ? (
            <MdKeyboardArrowUp className="text-gray800" size={24} />
          ) : (
            <MdKeyboardArrowDown
              className={`text-gray80 absolute ${i18n.language === 'en' ? 'left-[260px]' : ' right-[260px]'
                }`}
              size={24}
            />
          )}
        </div>
      </div>
      <Collapse in={props.isActive} timeout="auto">
        <List
          className="bg-greyMenuColor"
          sx={{
            paddingBottom: '0px',
          }}
        >
          {props.item.child?.map((route, index) =>
            route.subchild ? (
              <SidebarItemCollapseSub
                item={route}
                key={index}
                setOpen={() => setActivIndex(activIndex === index ? -1 : index)}
                open={activIndex === index}
              />
            ) : (
              <SidebarItem route={route} key={index} />
            ),
          )}
        </List>
      </Collapse>
    </>
  );
};

export default SidebarItemCollapse;
