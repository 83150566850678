import { useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoPlayOutline } from "react-icons/io5";

type MediaType = 'image' | 'video';

interface ViewMediaComponentProps {
    title: string;
    content: string;
    url: string;
    mediaType: MediaType;
    onClose: () => void;
}

function ViewMediaComponent({ title, content, mediaType, url, onClose }: ViewMediaComponentProps) {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const handlePlayToggle = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
                setIsPlaying(false);
            } else {
                videoRef.current.play().then(() => {
                    setIsPlaying(true);
                });
            }
        }
    };

    return (
        <div className="fixed z-[1500] top-0 left-0 right-0 bottom-0 w-full h-full bg-black bg-opacity-20">
            <div className="max-w-3xl p-4 mx-auto h-auto bg-white rounded-lg shadow-md m-auto mt-[15%]">
                <div className="flex justify-end">
                    <IoMdClose
                        className="text-[#010101] cursor-pointer"
                        onClick={onClose}
                        size={32}
                    />
                </div>
                <p className="py-4 text-xs font-bold font-en text-[#0D1117]">{title}</p>
                <div className="w-full h-64 overflow-hidden bg-gray-200 rounded-lg">
                    {mediaType === 'video' ? (
                        <div className="relative w-full h-full">
                            {!isPlaying && (
                                <div
                                    className="absolute inset-0 z-10 flex items-center justify-center rounded-2xl"
                                    onClick={handlePlayToggle}
                                >
                                    <div className="bg-white border-[3px] border-black rounded-full p-2 shadow-lg">
                                        <IoPlayOutline
                                            size={40}
                                            className="pl-1 text-black"
                                        />
                                    </div>
                                </div>
                            )}
                            <video
                                ref={videoRef}
                                className="object-cover w-full h-full"
                                controls={false}
                                onClick={handlePlayToggle}
                                onPlay={() => setIsPlaying(true)}
                                onPause={() => setIsPlaying(false)}
                            >
                                <source src={url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : (
                        <img src={url} alt={title} className="w-full h-full" />
                    )}
                </div>
                <p className="text-[#A3A3A3] text-xs text-bold py-4 whitespace-normal break-words">{content}</p>
            </div>
        </div>
    );
}

export default ViewMediaComponent;
