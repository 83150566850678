import React from 'react';
// import { useTranslation } from 'react-i18next';
export interface ICheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  // label: string;
  value?: string;
  checkboxSize?: boolean;
  // textSize?: boolean;
  // icon?: React.ReactNode;
  disabled?: boolean; // TODO: remove
}
export function Checkbox({
  // label,
  checkboxSize = false,
  // textSize,
  // icon,
  ...props
}: ICheckboxProps) {
  // const { t } = useTranslation();

  const getCheckboxSize = () => {
    switch (checkboxSize) {
      case true:
        return 'h-6 w-6';
      case false:
        return 'h-5 w-5';
      default:
        return 'h-6 w-6';
    }
  };
  // const getTextSize = () => {
  //   switch (textSize) {
  //     case true:
  //       return 'text-base';
  //     case false:
  //       return 'text-sm';
  //     default:
  //       return 'text-xs';
  //   }
  // };

  return (
    <label htmlFor={props?.id} className="flex items-center gap-x-2">
      <input
        {...props}
        type="checkbox"
        name={props.name}
        value={props.value}
        className={`appearance-none relative peer justify-center items-baseline rounded bg-transparent checked:bg-orange500 border border-orange500 checked:border-0 ${getCheckboxSize()} ${
          props.disabled === true &&
          'disabled:bg-gray-500/50 disabled:border-gray-500/50'
        }`}
      />
      <svg
        className="absolute w-5 h-5 mt-0.5 text-white hidden peer-checked:block"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    </label>
  );
}
export default Checkbox;
