import TableCont from 'containers/Table';

function TablePage() {
  return (
    <>
      <TableCont />
    </>
  );
}

export default TablePage;
