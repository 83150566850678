import FaqContainer from 'containers/admin/Aboutus/FAQ/faq-container';

const FAQPage = () => {
  return (
    <div>
      <FaqContainer />
    </div>
  );
};
export default FAQPage;
