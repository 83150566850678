import i18n from 'core/configs/i18n';
import { GalleryTypeEnum } from 'core/enums/example.enum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import AllImagesComponent from './all-images-component';
import DisruptImagesComponent from './disrupt-images-component';
import IncubatorImagesComponent from './incubator-images-component';
import PlusImagesComponent from './plus-images-component';

function GalleryMenueComponent() {
  const { t } = useTranslation();
  const location = useLocation();
  const gallery = new URLSearchParams(location.search).get('gallery') || '';
  const navigate = useNavigate();
  const [openTab, setOpenTab] = useState<GalleryTypeEnum | string>(gallery);

  const tabs = [
    {
      id: '',
      label: 'All',
      galleryType: '',
    },
    {
      id: GalleryTypeEnum.Plus,
      label: 'Plus',
      galleryType: GalleryTypeEnum.Plus,
    },
    {
      id: GalleryTypeEnum.Disrupt,
      label: 'disrupt',
      galleryType: GalleryTypeEnum.Disrupt,
    },
    {
      id: GalleryTypeEnum.INCUBATOR,
      label: 'Incubator',
      galleryType: GalleryTypeEnum.INCUBATOR,
    },
  ];

  return (
    <div className="">
      <div className="flex flex-row justify-end items-center">
        <div className="w-full">
          <div className="flex flex-row gap-3">
            {tabs.map((tab) => (
              <div key={tab.id}>
                <div
                  onClick={() => {
                    setOpenTab(tab.id);
                    navigate({ search: `gallery=${tab.galleryType}` });
                  }}
                  className={`inline-block py-1 w-auto cursor-pointer ${
                    i18n.language === 'ar'
                      ? 'font-ar text-lg'
                      : 'font-en text-base mobile:text-sm'
                  } ${
                    openTab === tab.id
                      ? ' border border-orange500 rounded-2xl bg-orange500 text-white px-3'
                      : 'text-orange500 border rounded-2xl border-orange500 px-3'
                  }`}
                >
                  {t(tab.label)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {openTab === '' && (
        <div>
          <AllImagesComponent />
        </div>
      )}
      {openTab === GalleryTypeEnum.Plus && (
        <div>
          <PlusImagesComponent />
        </div>
      )}
      {openTab === GalleryTypeEnum.Disrupt && (
        <div>
          <DisruptImagesComponent />
        </div>
      )}
      {openTab === GalleryTypeEnum.INCUBATOR && (
        <div>
          <IncubatorImagesComponent />
        </div>
      )}
    </div>
  );
}
export default GalleryMenueComponent;
