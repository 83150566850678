import ButtonComponent from 'components/admin/ButtonComponent';
import { UploadImageTypeEnum } from 'core/enums/example.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { TeamModel } from 'core/types/admin.type';
import { Formik, getIn, useFormik } from 'formik';
import { ChangeEvent } from 'react';
import { IoMdClose } from 'react-icons/io';
import { postEmployeeServices } from 'services/team-service';
import * as Yup from 'yup';

import RadioButtonComponent from './radio-button-component';
import UploadComponent from './upload-component';

//please don't edit, I will refactor the code later
interface PopupNewEmployeeComponentProps {
  title: string;
  onClose: () => void;
  onSuccess: () => void;
}
function PopupNewEmployeeComponent(props: PopupNewEmployeeComponentProps) {
  const toggle = Boolean;
  const handleClose = () => {
    props.onClose();
  };
  const initialValues: TeamModel = {
    name_ar: '',
    position_ar: '',
    name_en: '',
    position_en: '',
    image: '',
    gender: '',
  };
  const validationSchema = Yup.object({
    name_ar: Yup.string().required('Enter your name in Arabic'.toString()),
    position_ar: Yup.string().required(
      'Enter your position in Arabic'.toString(),
    ),
    name_en: Yup.string().required('Enter your name in English'.toString()),
    position_en: Yup.string().required(
      'Enter your position in English'.toString(),
    ),
    image: Yup.string().required('Upload the image'.toString()),
    gender: Yup.string().required('Select gender'.toString()),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      await handleClickSend();
    },
  });
  const handleClickSend = () => {
    postEmployee();
  };
  const postEmployee = async () => {
    try {
      await postEmployeeServices(formik.values);

      props.onSuccess();
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full backdrop-opacity-10 backdrop-invert bg-white/30">
        <div className="rounded-lg bg-whiteColor px-5 py-2 h-[689px] w-[850px]">
          <div className="flex items-end justify-end">
            <IoMdClose
              size={24}
              className="cursor-pointer text-orange500"
              onClick={handleClose}
            />
          </div>
          <div className="text-[32px] font-bold pl-4 mb-5">{props.title}</div>
          <Formik
            initialValues={initialValues}
            onSubmit={() => formik.submitForm()}
            validationSchema={formik.initialValues}
            enableReinitialize
          >
            <form className="flex flex-col" onSubmit={formik.handleSubmit}>
              <div className="flex justify-center items-start gap-14">
                <div className="flex flex-col w-2/3 gap-2 ">
                  <label className="text-2xl pl-[10px] leading-6 font-medium font-arabic_normal">
                    الاسم بالعربي
                  </label>
                  <input
                    type="text"
                    name="name_ar"
                    placeholder="احمد"
                    className={`w-auto h-12 mb-2 appearance-none border rounded-lg py-3 px-2 bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400
                      ${
                        formik.touched.name_ar && formik.errors.name_ar
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }
                      `}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('name_ar', e.target.value);
                    }}
                  />
                  <p
                    className={`text-error text-sm ${
                      formik.touched.name_ar && formik.errors.name_ar
                        ? '-mt-4'
                        : ''
                    }`}
                  >
                    {getIn(formik.errors, 'name_ar')}
                  </p>
                  <label className="text-2xl pl-[10px] leading-6 font-medium font-arabic_normal">
                    المنصب بالعربي
                  </label>
                  <input
                    type="text"
                    name="position_ar"
                    placeholder="مبرمج"
                    className={`w-auto h-12 mb-2 appearance-none border rounded-lg py-3 px-2 bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400
                      ${
                        formik.touched.position_ar && formik.errors.position_ar
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }
                      `}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('position_ar', e.target.value);
                    }}
                  />
                  <p
                    className={`text-error text-sm ${
                      formik.touched.position_ar && formik.errors.position_ar
                        ? '-mt-4'
                        : ''
                    }`}
                  >
                    {getIn(formik.errors, 'position_ar')}
                  </p>
                  <label className="text-xl pl-[10px] leading-6 font-Helvetica">
                    Name in English
                  </label>
                  <input
                    type="text"
                    name="name_en"
                    placeholder="Ahmed"
                    className={`w-auto h-12 mb-2 appearance-none border rounded-lg py-3 px-2 bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400
                      ${
                        formik.touched.name_en && formik.errors.name_en
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }
                      `}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('name_en', e.target.value);
                    }}
                  />
                  <p
                    className={`text-error text-sm ${
                      formik.touched.name_en && formik.errors.name_en
                        ? '-mt-4'
                        : ''
                    }`}
                  >
                    {getIn(formik.errors, 'name_en')}
                  </p>
                  <label className="text-xl leading-6 pl-[10px] font-Helvetica">
                    Position in English
                  </label>
                  <input
                    type="text"
                    name="position_en"
                    placeholder="Programer"
                    className={`w-auto h-12 mb-2 appearance-none border rounded-lg py-3 px-2 bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400
                      ${
                        formik.touched.position_en && formik.errors.position_en
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }
                      `}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('position_en', e.target.value);
                    }}
                  />
                  <p
                    className={`text-error text-sm ${
                      formik.touched.position_en && formik.errors.position_en
                        ? '-mt-4'
                        : ''
                    }`}
                  >
                    {getIn(formik.errors, 'position_en')}
                  </p>
                  <div className="flex flex-row items-center gap-16 pt-6">
                    <RadioButtonComponent
                      value="Female"
                      name="gender"
                      id="female"
                      onChange={(e) => {
                        toggle(false);
                        formik.setFieldValue('gender', e.target.value);
                      }}
                      label={'Female'}
                    />
                    <RadioButtonComponent
                      value="Male"
                      name="gender"
                      id="Male"
                      onChange={(e) => {
                        toggle(true);
                        formik.setFieldValue('gender', e.target.value);
                      }}
                      label={'Male'}
                    />
                  </div>
                  <div className="text-error text-sm">
                    {formik.errors.gender}
                  </div>
                </div>
                <div className="flex justify-start items-start flex-col gap-9 mt-9">
                  <UploadComponent
                    type={UploadImageTypeEnum.Team}
                    className={''}
                    name={'image'}
                    onChange={(img: string) =>
                      formik.setFieldValue('image', img)
                    }
                    id={'image'}
                    labelClassName={`${
                      formik.touched.image && formik.errors.image
                        ? 'border-error'
                        : ''
                    }`}
                    image={formik.values.image}
                    button={'Upload Image'}
                    onClick={() => {
                      throw new Error('Function not implemented.');
                    }}
                    formik={
                      <p className="text-error text-sm ">
                        {getIn(formik.errors, 'image')}
                      </p>
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center pt-4">
                <ButtonComponent
                  disabled={false}
                  width={'w-[305.46px]'}
                  hight={'h-[48px]'}
                  text={'Publish'}
                  fontsize={'text-base'}
                />
              </div>
            </form>
          </Formik>
        </div>
      </div>
    </>
  );
}

export default PopupNewEmployeeComponent;
