import { List, Stack } from '@mui/material';
import assets from 'assets';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { useRoles } from 'core/helpers/useRolesHook';
import { routesConfig } from 'core/statics/routesConfig';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { HiOutlineLogin } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'routes/app.paths';

import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import SidebarNavLink from './SidebarNavLink';

export interface tokenType {
  iat: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  session_state: string;
  acr: string;
  'allowed-origins': string[];
  realm_access: RealmAccess;
  resource_access: ResourceAccess;
  scope: string;
  sid: string;
  email_verified: boolean;
  preferred_username: string;
  email: string;
}
export interface RealmAccess {
  roles: string[];
}
export interface ResourceAccess {
  account: Account;
}
export interface Account {
  roles: string[];
}
export interface ISidebarContentProps {
  openIndex: number;
  setOpenIndex: React.Dispatch<React.SetStateAction<number>>;
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  toggleLanguage: () => void;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function SidebarContent({
  openIndex,
  setOpenIndex,
  enabled,
  setEnabled,
  toggleLanguage,
}: ISidebarContentProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const direction = i18n.dir();
  const token = localStorage.getItem('token') as string;
  const decoded: tokenType = jwt_decode(token);
  const roles = decoded.realm_access.roles;
  const { isAdmin } = useRoles(roles);

  const filteredRoutes = isAdmin
    ? routesConfig
    : routesConfig.filter((route) => roles.includes(route.accessRole));

  return (
    <>
      <List className="flex-1">
        <Link
          to={
            isAdmin
              ? appRoutesObj.getDashboardPagePath()
              : appRoutesObj.getLogInPagePath()
          }
        >
          <Stack
            className="px-11"
            sx={{ width: '100%', direction }}
            paddingTop="32px"
            paddingBottom="48px"
            direction="row"
            justifyContent="center"
          >
            <img src={assets.images.logo} alt="" />
          </Stack>
        </Link>
        {isAdmin && (
          <SidebarNavLink to={appRoutesObj.getDashboardPagePath()}>
            {t('component.sidebar.overview')}
          </SidebarNavLink>
        )}
        {filteredRoutes.map((route, index) =>
          route.child ? (
            <SidebarItemCollapse
              item={route}
              key={index}
              setaActiveIndex={() =>
                setOpenIndex(openIndex === index ? -1 : index)
              }
              isActive={openIndex === index}
            />
          ) : (
            <SidebarItem route={route} key={index} />
          ),
        )}
      </List>
      <div className="relative flex items-center justify-between w-auto gap-12 px-6 font-bold cursor-pointer bottom-20 text-secondaryGreen400">
        <p>{decoded.email}</p>
        <div onClick={toggleLanguage} className="text-gray800 font-en">
          {i18n.language === 'ar' ? 'EN' : 'AR'}
        </div>
      </div>
      <div
        className="cursor-pointer absolute inline-flex items-center bottom-11 w-auto px-20 font-bold gap-[7px]"
        onClick={() => setEnabled(!enabled)}
      >
        <HiOutlineLogin size={24} />
        <h1>Log Out</h1>
        {enabled && (
          <ConfirmPopupComponent
            onClose={() => setEnabled(false)}
            title={t('Are you sure you want to Log Out?')}
            onClick={() => setEnabled(false)}
            onClickConfirm={() => {
              localStorage.clear();
              navigate('/Login');
            }}
          />
        )}
      </div>
    </>
  );
}

export default SidebarContent;
