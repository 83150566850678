import GenralBoxComponent from 'components/admin/box-Genaral-component';
import { useTranslation } from 'react-i18next';

function ContactUsComponents() {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-1/2 bg-white rounded-lg pt-2 shadow">
        <GenralBoxComponent Heading="Upcoming Events" HeadingColor="black" />
        <div className="flex justify-between px-20 pb-1 text-center">
          <p className="text-orange400 font-bold">{t('Dates')}</p>
          <p className="text-orange400 font-bold">{t('Time')}</p>
        </div>
        <div className="px-3">
          <div className="bg-slate50 rounded-lg">
            <div className="flex justify-between px-10 py-4">
              {/* dummy data */}
              <p>Oct 12, 2023</p>
              <p>8:00,10:00</p>
            </div>
          </div>

          <div className="bg-slate50 rounded-lg mt-[1.5vh]">
            <div className="flex justify-between px-10 py-4">
              {/* dummy data */}
              <p>Oct 12, 2023</p>
              <p>8:00,10:00</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsComponents;
