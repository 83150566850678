import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import {
  BookingDurationEnum,
  BookingStateEnum,
} from 'core/enums/application_state.enum';
import { HttpMethods } from 'core/enums/httpMethods.enum';
import { UpdateBookingModel } from 'core/types/admin.type';

export const getBookingService = (
  searchText: string | null,
  limit: number,
  offset: number,
  booking_status: BookingStateEnum,
  booking_duration: BookingDurationEnum,
  booking_date: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/booking`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      limit: limit,
      offset: offset,
      booking_status: booking_status,
      booking_duration: booking_duration,
      booking_date: booking_date,
    },
  });
};
export const getResourceByIdService = (
  resource_id: string,
  booking_date: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/resource/${resource_id}`,
    method: HttpMethods.GET,
    params: {
      booking_date: booking_date,
    },
  });
};

export const putBookingByIdService = (
  data: UpdateBookingModel,
  booking_id: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/booking/${booking_id}`,
    method: HttpMethods.PUT,
    data,
    // params: { resource_id: resource_id },
  });
};

export const putBookingService = (ids: string[]) => {
  return makeRequest({
    url: `${BASE_URL}/booking`,
    method: HttpMethods.PUT,
    data: { ids: ids },
  });
};
