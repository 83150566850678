import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { HttpMethods } from 'core/enums/httpMethods.enum';

//get all Partner from service
export const getAllPartnerServices = (
  applicationState: ApplicationStateEnum,
  limit: number,
  offset: number,
  searchText: string | null,
) => {
  return makeRequest({
    url: `${BASE_URL}/be-our-partner`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      is_pending: applicationState === ApplicationStateEnum.PENDING,
      limit: limit,
      offset: offset,
    },
  });
};
//open Partner from
export const getPartnerIsOpenServices = () => {
  return makeRequest({
    url: `${BASE_URL}/be-our-partner/status`,
    method: HttpMethods.GET,
  });
};
// open or close Partner from
export const postPartnerOpenAndCloseServices = (openOrClose: string) => {
  return makeRequest({
    url: `${BASE_URL}/be-our-partner/${openOrClose}`,
    method: HttpMethods.POST,
  });
};
export const getPartnerExportServices = () => {
  return makeRequest({
    url: `${BASE_URL}/be-our-partner/export`,
    method: HttpMethods.GET,
  });
};

export const postPartnerAcceptOrReject = (
  status: string,
  partner_id: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/be-our-partner/${partner_id}/${status}`,
    method: HttpMethods.POST,
  });
};
