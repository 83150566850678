import CohortsPlusComponent from 'components/admin/CohortsPlusComponet';
import CohortsPlusStartupComponet from 'components/admin/CohortsPlusStartupComponet';

function CohortsPlusContainer() {
  return (
    <>
      <div className="">
        <CohortsPlusComponent Title="Cohorts" />
        <CohortsPlusStartupComponet />
      </div>
    </>
  );
}

export default CohortsPlusContainer;
