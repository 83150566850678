import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CardPreviousApplicationsComponentProps {
  fileName?: string;
  data?: string;
  title: string;
  subtitle?: string;
  onClose: () => void;
  onClick?: () => void;
  onClickConfirm: () => void;
  onSuccess?: () => void;
}

function ConfirmPopupComponent(props: CardPreviousApplicationsComponentProps) {
  const { t } = useTranslation();
  const [open] = useState(true);
  const handleClose = () => {
    props.onClose();
  };
  const handleConfirm = () => {
    props.onClickConfirm();
    props.onClose();
  };

  const handleConfirmExport = () => {
    props.onClickConfirm();

    props.onClose();
  };

  return (
    <>
      {open && (
        <div className="fixed z-[1500] top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-20">
          <div className="w-[750px] h-[200px] rounded-lg bg-whiteColor px-10 py-9">
            <div className="text-xl text-textGray font-bold">
              {t(`${props.title}`)}
            </div>
            <div className="pt-3 text-sm text-greyChateau">
              {t(`${props.subtitle || ''}`)}
            </div>
            <div className="flex flex-row justify-end items-center gap-3 px-3 py-7">
              <button
                className="w-[180px] h-[48px] rounded-lg border border-orange500 bg-whiteColor text-orange500 text-base"
                onClick={handleClose}
              >
                {t('app.shared.button.cancel')}
              </button>

              <button
                className="w-[180px] h-[48px] rounded-lg bg-orange500 text-whiteColor text-base"
                onClick={
                  props.data !== undefined ? handleConfirmExport : handleConfirm
                }
              >
                {t('app.shared.button.confirm')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ConfirmPopupComponent;
