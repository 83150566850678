import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { TbPhoto } from 'react-icons/tb';

// please don't edit, I will refactor the code later
interface NewPartnerComponentProps {
  title: string;
  onClose: () => void;
  onClick: () => void;
  onClickConfirm: () => void;
}
function NewPartnerComponent(props: NewPartnerComponentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    props.onClose();
  };
  const handleConfirm = () => {
    props.onClickConfirm();
    props.onClose();
  };

  return (
    <>
      {open && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full backdrop-opacity-10 backdrop-invert bg-white/30">
          <div className="w-[993px] h-[604px] rounded-lg bg-whiteColor px-5 py-4">
            <div className="flex items-end justify-end">
              <IoMdClose
                size={24}
                className="cursor-pointer text-orange500"
                onClick={handleClose}
              />
            </div>
            <div className="text-[32px] pl-5 font-bold -mt-5">
              {props.title}
            </div>
            <form className="flex flex-col">
              <div className="flex gap-16 justify-center items-center">
                <div className="flex flex-col w-2/3 gap-2">
                  <label className="text-xl pb-2">Partner name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="safcsp"
                    className="w-auto h-12 mb-7 appearance-none border rounded-lg py-3 pl-2 pr-2 border-neutralGrey bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400"
                  />
                  <label className="text-xl pb-2">Partner link</label>
                  <input
                    type="url"
                    name="name"
                    placeholder="https://safcsp.org.sa"
                    className="w-auto h-12 mb-7 appearance-none border rounded-lg py-3 pl-2 pr-2 border-neutralGrey bg-[#FAFAFB] text-sm focus:border-secondaryGreen400"
                  />
                </div>
                <div className="flex flex-col gap-5 mt-20">
                  <div className="flex w-[200px] h-[200px] bg-[#FAFAFB] justify-center items-center">
                    <TbPhoto size={42} />
                  </div>
                  <button
                    className="w-[200px] h-[48px] rounded-lg bg-orange500 text-whiteColor text-base"
                    onClick={handleConfirm}
                  >
                    Upload logo
                  </button>
                </div>
              </div>
              <div className="flex flex-col pt-20 justify-center items-center">
                <input
                  className="w-[305.46px] h-12 text-whiteColor rounded-lg bg-orange500 cursor-pointer"
                  type="submit"
                  value="Publish"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default NewPartnerComponent;
