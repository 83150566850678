import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import i18n from 'core/configs/i18n';
import { QuestionsState } from 'core/enums/application_state.enum';
import { t } from 'i18next';
import {
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';

type Props = {
  totalCount: number;
  totalPage: number;
  page: number;
  limit: number;
  questions?: string;
  onPageChange: (page: number) => void;
};

function Pagination(props: Props) {
  if (props.totalCount === 0) {
    return null;
  }

  // Calculate the start and end item numbers for the current page
  const startItem = (props.page - 1) * props.limit + 1;
  const endItem = Math.min(props.page * props.limit, props.totalCount);

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-row justify-between w-full mobile:flex-col">
        <nav aria-label="Page navigation" className="desktop:pt-5 inset-y-0 top-[584px] container">
          <ul className="inline-flex gap-2">
            <li>
              <button
                className="flex rounded-full border-[1px] w-8 h-8 items-center bg-white border-[#E6E6E6] justify-center"
                onClick={() => props.onPageChange(1)}
              >
                {i18n.language === 'ar' ? (
                  <ChevronDoubleRightIcon strokeWidth={2} className="h-3 w-3 text-[#615d5d]" />
                ) : (
                  <MdKeyboardDoubleArrowLeft size={18} className="text-neutralgreygrey400" />
                )}
              </button>
            </li>
            <li>
              <button
                className="flex rounded-full border-[1px] w-8 h-8 items-center bg-white border-lightgray justify-center"
                onClick={() => props.onPageChange(props.page === 1 ? 1 : props.page - 1)}
              >
                {i18n.language === 'ar' ? (
                  <ChevronRightIcon strokeWidth={2} className="w-3 h-3 cursor-pointer" />
                ) : (
                  <MdKeyboardArrowLeft size={18} className="text-neutralgreygrey400" />
                )}
              </button>
            </li>
            {props.totalPage < 5 ? (
              Array(props.totalPage)
                .fill('')
                .map((_, index) => (
                  <li key={index}>
                    <button
                      className={`w-8 h-8 rounded-[18px] ${
                        index + 1 === props.page
                          ? 'bg-orange500 text-whiteColor text-base font-bold'
                          : 'border border-lightgray bg-white font-sans text-base font-bold'
                      }`}
                      onClick={() => props.onPageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))
            ) : (
              <>
                {props.page >= props.totalPage - 2 ? (
                  <>
                    <button
                      className="w-8 h-8 rounded-[18px] bg-white text-base font-bold"
                      onClick={() => props.onPageChange(props.page)}
                    >
                      ...
                    </button>
                    {[props.totalPage - 2, props.totalPage - 1, props.totalPage].map((val, index) => (
                      <li key={index}>
                        <button
                          className={`w-8 h-8 rounded-[18px] ${
                            val === props.page
                              ? 'bg-orange500 text-whiteColor text-[16px] font-bold'
                              : 'border border-lightgray bg-white font-sans text-[16px] font-bold'
                          }`}
                          onClick={() => props.onPageChange(val)}
                        >
                          {val}
                        </button>
                      </li>
                    ))}
                  </>
                ) : (
                  <>
                    {props.page === Math.ceil(props.totalPage / 2) ? (
                      <>
                        <button
                          className="w-8 h-8 rounded-[18px] bg-white text-base font-bold"
                          onClick={() => props.onPageChange(props.page)}
                        >
                          ...
                        </button>
                        {[props.page, props.page + 1, props.page + 2].map((val, index) => (
                          <li key={index}>
                            <button
                              className={`w-8 h-8 rounded-[18px] ${
                                val === props.page
                                  ? 'bg-orange500 text-whiteColor text-[16px] font-bold'
                                  : 'border border-lightgray bg-white font-sans text-[16px] font-bold'
                              }`}
                              onClick={() => props.onPageChange(val)}
                            >
                              {val}
                            </button>
                          </li>
                        ))}
                        <button
                          className="w-8 h-8 rounded-[18px] bg-white text-base font-bold"
                          onClick={() => props.onPageChange(props.page)}
                        >
                          ...
                        </button>
                      </>
                    ) : (
                      <>
                        {[props.page, props.page + 1, props.page + 2].map((val, index) => (
                          <li key={index}>
                            <button
                              className={`w-8 h-8 rounded-[18px] ${
                                val === props.page
                                  ? 'bg-orange500 text-whiteColor text-base font-bold'
                                  : 'border border-lightgray bg-white font-sans text-base font-bold'
                              }`}
                              onClick={() => props.onPageChange(val)}
                            >
                              {val}
                            </button>
                          </li>
                        ))}
                        <button
                          className="w-8 h-8 rounded-[18px] bg-white text-[13px]"
                          onClick={() => props.onPageChange(props.page)}
                        >
                          ...
                        </button>
                        {[props.totalPage - 2, props.totalPage - 1, props.totalPage].map((val, index) => (
                          <li key={index}>
                            <button
                              className={`w-8 h-8 rounded-[18px] ${
                                val === props.page
                                  ? 'bg-orange500 text-whiteColor text-[13px]'
                                  : 'border border-lightgray bg-white font-sans text-[16px] font-bold'
                              }`}
                              onClick={() => props.onPageChange(val)}
                            >
                              {val}
                            </button>
                          </li>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <li>
              <button
                className="flex rounded-full border-[1px] w-8 h-8 items-center bg-white border-[#E6E6E6] justify-center"
                onClick={() => props.onPageChange(props.page === props.totalPage ? props.totalPage : props.page + 1)}
              >
                {i18n.language === 'ar' ? (
                  <ChevronLeftIcon strokeWidth={2} className="h-3 w-3 text-[#615d5d]" />
                ) : (
                  <MdOutlineKeyboardArrowRight size={18} />
                )}
              </button>
            </li>
            <li>
              <button
                className="flex rounded-full border-[1px] w-8 h-8 items-center bg-white border-[#E6E6E6] justify-center"
                onClick={() => props.onPageChange(props.totalPage)}
              >
                {i18n.language === 'ar' ? (
                  <ChevronDoubleLeftIcon strokeWidth={2} className="h-3 w-3 text-[#615d5d]" />
                ) : (
                  <MdKeyboardDoubleArrowRight size={18} />
                )}
              </button>
            </li>
          </ul>
        </nav>
        <div className="text-[#ADADAD] text-xs pb-6 pt-1 desktop:pt-5 whitespace-nowrap">
          {t('Showing')} {startItem} {'to'} {endItem} {t('of')} {props.totalCount}
          {props.questions === QuestionsState.YES ? 'Questions' : null}
        </div>
      </div>
    </div>
  );
}

export default Pagination;
