import ButtonComponent from 'components/admin/ButtonComponent';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
interface SearchComponentProps {
  onClickValue: React.ChangeEventHandler<HTMLInputElement>;
  onClickShearch: () => void;
  onClick?: () => void;
}
function SearchComponent(props: SearchComponentProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="inline-flex justify-start w-full gap-4 items-cnter">
      <div className="relative flex items-center w-full">
        <AiOutlineSearch
          size={24}
          className={`absolute text-gray-400 ${i18n.language === 'en' ? 'ml-2' : 'mr-2'
            }`}
        />
        <input
          onChange={props.onClickValue}
          type="search"
          id="default-search"
          placeholder={t('app.shared.button.search') as string}
          required
          className={`w-[100%] h-12 appearance-none border rounded-lg py-3 ${i18n.language === 'en' ? 'pl-8 pr-2 ' : 'pr-8 pl-2 '
            }border-neutralGrey bg-gray-50 text-sm focus:outline-none focus:border-secondaryGreen400`}
        />
      </div>
      <ButtonComponent
        disabled={false}
        width={'w-[143px]'}
        hight={'h-12'}
        text={t('app.shared.button.search') as string}
        fontsize={'text-base'}
        onClick={props.onClickShearch}
      />
    </div>
  );
}

export default SearchComponent;
