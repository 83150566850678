import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import MediaCreateComponent from 'components/admin/Media/media-create-component';
import { getInitialValues } from 'components/admin/Media/media-form-container.data';
import { validationSchemaNewsLetter } from 'components/admin/Media/media-form-container.validation';
import Loading from 'components/common/loading';
import { handleRequestError } from 'core/helpers/error.helper';
import { MediaNewsModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { appRoutesObj } from 'routes/app.paths';
import { getNewsByIdService, putNewsServices } from 'services/news-service';

function EditNewsLetterContainer() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [pendingSubmission, setPendingSubmission] =
    useState<MediaNewsModel | null>(null);
  const [news, setNews] = useState<MediaNewsModel>();
  const [loading, setLoading] = useState<boolean>(true);
  const { newsId = '' } = useParams<{ newsId: string }>();

  const handleAcceptClose = () => {
    setShowPopup(false);
    setPendingSubmission(null); // Clear pending submission if the popup is closed without confirmation
  };
  const onFormSubmit = (values: MediaNewsModel): Promise<void> => {
    return new Promise((resolve) => {
      setPendingSubmission(values); // Store the form values for later submission
      setShowPopup(true);
      resolve(); // Resolve the promise immediately
    });
  };
  const confirmSubmission = () => {
    if (pendingSubmission) {
      handleSubmitNewsLetter(pendingSubmission); // Proceed with the form submission
    }
    handleAcceptClose(); // Close the popup
  };

  const handleSubmitNewsLetter = async (
    values: MediaNewsModel,
  ): Promise<void> => {
    try {
      await putNewsServices(newsId, values);
      navigate(appRoutesObj.getNewsPath());
    } catch (error) {
      handleRequestError(error);
    }
  };
  const getNewsById = useCallback(async () => {
    try {
      const newsLater: MediaNewsModel = await getNewsByIdService(newsId);
      setNews(newsLater);
      setLoading(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, [newsId]);

  useEffect(() => {
    if (newsId) {
      getNewsById();
    }
  }, [getNewsById, newsId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <MediaCreateComponent
        initialValues={news || getInitialValues('news_letter')}
        validationSchema={validationSchemaNewsLetter}
        onSubmit={onFormSubmit}
      />
      {showPopup && (
        <ConfirmPopupComponent
          title={'container.media.listnews.edit.news'}
          onClose={handleAcceptClose}
          onClickConfirm={confirmSubmission}
        />
      )}
    </>
  );
}

export default EditNewsLetterContainer;
