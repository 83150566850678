import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { HttpMethods } from 'core/enums/httpMethods.enum';
import { BookVisitModel } from 'core/types/user.type';

export const getVisitService = (
  applicationState: ApplicationStateEnum,
  limit: number,
  offset: number,
  searchText: string | null,
) => {
  return makeRequest({
    url: `${BASE_URL}/visit/application`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      is_pending: applicationState === ApplicationStateEnum.PENDING,
      limit: limit,
      offset: offset,
    },
  });
};

export const postBookVisitAcceptedServices = (
  data: BookVisitModel,
  visitId: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/visit/${visitId}/accept`,
    method: HttpMethods.POST,
    data,
  });
};
export const postBookVisitRejectServices = (
  data: BookVisitModel,
  visitId: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/visit/${visitId}/reject`,
    method: HttpMethods.POST,
    data,
  });
};

export const postBookVisitStatusServices = (
  visitId: string,
  data: BookVisitModel,
) => {
  return makeRequest({
    url: `${BASE_URL}/visit/${visitId}/accept`,
    method: HttpMethods.POST,
    data,
  });
};

export const postBookVisitOpenAndCloseServices = (openOrClose: string) => {
  return makeRequest({
    url: `${BASE_URL}/visit/${openOrClose}`,
    method: HttpMethods.POST,
  });
};

export const getBookVisitIsOpenServices = () => {
  return makeRequest({
    url: `${BASE_URL}/visit/status`,
    method: HttpMethods.GET,
  });
};

export const getBookVisitExportServices = () => {
  return makeRequest({
    url: `${BASE_URL}/visit/export`,
    method: HttpMethods.GET,
  });
};
