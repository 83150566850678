import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCheckCircle } from 'react-icons/hi';
interface IPublishedPopupProps {
  title: string;
  onClickConfirm: () => void;
}
function PublishedPopup(props: IPublishedPopupProps) {
  const { t } = useTranslation();

  const handleConfirm = () => {
    props.onClickConfirm();
  };

  return (
    <div className="fixed z-[1500] top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-20">
      <div className="grid gap-6 place-items-center w-[750px] h-auto rounded-lg justify-center items-center bg-whiteColor px-10 py-14">
        <div>
          <HiOutlineCheckCircle size={158} className="text-orange500" />
        </div>
        <div className="text-base text-blue500 font-bold">
          {t(`${props.title}`)}
        </div>
        <div>
          <button
            className="w-[180px] h-12 rounded-lg bg-orange500 text-whiteColor text-base"
            onClick={handleConfirm}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export default PublishedPopup;
