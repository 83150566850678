import { UserRolesEnums } from 'core/enums/roles.enum';
import { appRoutesObj } from 'routes/app.paths';

export const routesConfig = [
  {
    title: 'About us',
    accessRole: 'site:about:_about_access',
    child: [
      { title: 'Team', path: appRoutesObj.getTeamPagePath() },
      { title: 'FAQ', path: appRoutesObj.getFAQPath() },
      { title: 'Issue', path: appRoutesObj.getIssuePath() },
    ],
  },
  {
    title: 'component.sidebar.programs',
    accessRole: UserRolesEnums.GARAGE_PROGRAMS,
    child: [
      {
        title: 'Plus',
        path: appRoutesObj.getPlusPath(),
        subchild: [
          { title: 'Plus Applications', path: appRoutesObj.getPlusPath() },
        ],
      },
      {
        title: 'Disrupt',
        path: appRoutesObj.getDisruptDashboardPagePath(),
        subchild: [
          {
            title: 'Scheduling disrupt',
            path: appRoutesObj.getDisruptDashboardPagePath(),
          },
          {
            title: 'Startups',
            path: appRoutesObj.getNewApplicationsPagePath(),
          },
          { title: 'Guests', path: appRoutesObj.getOldApplicationsPagePath() },
        ],
      },
      { title: 'Incubators', path: appRoutesObj.getIncubatorsPath() },
    ],
  },
  {
    title: 'component.sidebar.community',
    accessRole: UserRolesEnums.GARAGE_COMMUNITY,
    child: [
      { title: 'Partners', path: appRoutesObj.getPartnersPath() },
      { title: 'Events', path: appRoutesObj.getEventsPath() },
      { title: 'Tour', path: appRoutesObj.getTourPageUrl() },
    ],
  },
  {
    title: 'component.sidebar.booking',
    accessRole: UserRolesEnums.GARAGE_BOOKING_MANAGEMENT,
    child: [
      { title: 'Meeting rooms', path: appRoutesObj.getBookingPagePath() },
    ],
  },
  {
    title: 'component.sidebar.permits',
    accessRole: UserRolesEnums.GARAGE_FACILITIES,
    child: [
      {
        title: 'Transportation permits',
        path: appRoutesObj.getTransportationPermitsPageUrl(),
      },
    ],
  },
  {
    title: 'component.sidebar.Media',
    accessRole: 'site:media:_media_access',
    child: [
      { title: 'News', path: appRoutesObj.getNewsPath() },
      { title: 'Gallery', path: appRoutesObj.getGalleryPath() },
      {
        title: 'Latest Events',
        path: appRoutesObj.getLatestEventsPagePath(),
      },
    ],
  },
  {
    title: 'PNU',
    accessRole:
      'site:garage_super_admin:garage_super_admin_access' &&
      'site:garage_admin:garage_admin_access',
    child: [{ title: 'PNU', path: appRoutesObj.getPNUPagePath() }],
  },
];
