import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import DataListComponent from 'components/common/Data-list/data-list-component';
import EmptySearchComponent from 'components/common/empty-search';
import Pagination from 'components/common/Pagination/pagination';
import SkeletonDataComponent from 'components/common/skeleton-element/incubators-skeleton';
import {
  acceptIncubatorApplication,
  createNewIncubator,
  fetchIncubatorApplicationList,
  rejectIncubatorApplication,
} from 'core/data-access/incubator.service';
import { PDFEnum } from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllIncubatorModel } from 'core/types/admin.type';
import { t } from 'i18next';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { AiOutlineLink } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp, IoLogoInstagram } from 'react-icons/io';
import { TfiTwitter } from 'react-icons/tfi';
import { toast } from 'react-toastify';

import SearchAndCreateIncubatorComponent from './Incubator-Search-Create-Incubator-button-component';

function IncubatorNewApplicationComponent() {
  const [incubatorApplicationList, setIncubatorApplicationList] =
    useState<AllIncubatorModel>();
  const toggledata: SetStateAction<[]> = [];

  const [toggle, setToggle] = useState(
    new Array(toggledata.length).fill(false),
  );
  const paginationLimit = 4;
  const [page, setPage] = useState<number>(1);
  const [id, setID] = useState('');
  const [enableCreateIncubator, setEnableCreateIncubator] =
    useState<boolean>(false);
  const [val, setVal] = useState<string>('');
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const place = [1, 1, 1, 1];
  const [, setFiltered] = useState(incubatorApplicationList?.result_list);
  const getIncubatorApplicationList = useCallback(async () => {
    try {
      const res = await fetchIncubatorApplicationList(
        paginationLimit,
        (page - 1) * paginationLimit,
        val,
      );
      setIncubatorApplicationList(res);
      setSkeletonData(false);
      return res;
    } catch (error) {
      return error;
    }
  }, [page]);
  const acceptIncubator = async (id: string | undefined) => {
    try {
      const res = await acceptIncubatorApplication(id);
      toast.success('This startup has been accepted successfully!');
      getIncubatorApplicationList();
      setID('');
      return res;
    } catch (error) {
      return error;
    }
  };
  const rejectIncubator = async (id: string | undefined) => {
    try {
      const res = await rejectIncubatorApplication(id);
      toast.success('This startup has been Rejected successfully!');
      getIncubatorApplicationList();
      setID('');
      return res;
    } catch (error) {
      return error;
    }
  };
  const createIncubator = async () => {
    try {
      return await createNewIncubator();
    } catch (error) {
      return error;
    }
  };
  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };

  const [toggle2, setToggle2] = useState(
    new Array(toggledata.length).fill(false),
  );

  const handleToggleClick2 = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle2(newToggle);
  };
  const [toggle3, setToggle3] = useState(
    new Array(toggledata.length).fill(false),
  );

  const handleToggleClick3 = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle3(newToggle);
  };

  const [toggle4, setToggle4] = useState(
    new Array(toggledata.length).fill(false),
  );

  const handleToggleClick4 = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle4(newToggle);
  };
  const [acceptPopup, setAcceptPopup] = useState(false);
  const handleAcceptClick = () => {
    setAcceptPopup(true);
  };
  const handleCloseAcceptPopup = () => {
    setAcceptPopup(false);
  };
  const [rejectPopup, setRejectPopup] = useState(false);
  const handleRejectClick = () => {
    setRejectPopup(true);
  };
  const handleCloseRejectPopup = () => {
    setRejectPopup(false);
  };
  const handleCreateIncubatorPopup = () => {
    setEnableCreateIncubator(false);
  };
  const handleSearchClick = () => {
    if (val === '') {
      setFiltered(incubatorApplicationList?.result_list);
      return;
    }
    const filterBySearch = incubatorApplicationList?.result_list.filter(
      (item) => {
        item.startup_name.toLowerCase().includes(val.toLowerCase());
      },
    );
    setFiltered(filterBySearch);
  };
  const getSearchData = async () => {
    try {
      const IncubatorSearch: AllIncubatorModel =
        await fetchIncubatorApplicationList(
          paginationLimit,
          (page - 1) * paginationLimit,
          val,
        );

      unstable_batchedUpdates(() => {
        setIncubatorApplicationList(IncubatorSearch);
        setFiltered(IncubatorSearch.result_list);
      });
      handleSearchClick();
    } catch (error) {
      handleRequestError(error);
    }
  };
  useEffect(() => {
    getIncubatorApplicationList();
  }, [getIncubatorApplicationList]);
  return (
    <div>
      <SearchAndCreateIncubatorComponent
        InputOnChange={(e) => {
          setVal(e.target.value);
        }}
        SearchOnClick={getSearchData}
        buttonOnClick={() => setEnableCreateIncubator(!enableCreateIncubator)}
      />
      <div className="flex flex-col px-6">
        <div className="grid grid-cols-5 w-full font-bold text-lg pr-[15%] items-center">
          <div className="text-center font-bold text-lg whitespace-nowrap">
            {t('startupName')}
          </div>
          <div className="text-center font-bold text-lg whitespace-nowrap">
            {t('startupMajor')}
          </div>
          <div className="text-center font-bold text-lg pl-7 whitespace-nowrap">
            {t('startupsize')}
          </div>
          <div className="text-center font-bold text-lg pl-6 whitespace-nowrap">
            {t('startupprofile')}
          </div>
          <div className="text-center font-bold text-lg whitespace-nowrap">
            {t('allstatus')}
          </div>
        </div>
        {skeletonData ? (
          <div className="flex flex-col items-center py-2">
            {place.map((e, index) => {
              return (
                <div key={index}>
                  <SkeletonDataComponent />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="h-auto">
            {incubatorApplicationList?.total_count === 0 ? (
              <div className="flex justify-center mx-auto">
                <EmptySearchComponent />
              </div>
            ) : (
              incubatorApplicationList?.result_list?.map((item, index) => (
                <div
                  key={item.id}
                  className="flex flex-col items-center w-full py-3"
                >
                  <DataListComponent
                    index={index}
                    toggle={toggle}
                    id={item.id}
                    item1={item.startup_name}
                    item2={item.startup_major}
                    item3={item.startup_capacity}
                    Icon={PDFEnum.YES}
                    isNew={true}
                    item5={item.application_state}
                    onClickButtonAccept={() => {
                      handleAcceptClick();
                      setID(item.id);
                    }}
                    onClickButtonReject={() => {
                      handleRejectClick();
                      setID(item.id);
                    }}
                    onClickArrowDown={() =>
                      handleToggleClick(index, !toggle[index])
                    }
                    onClickArrowUp={() =>
                      handleToggleClick(index, !toggle[index])
                    }
                  />

                  {toggle[index] && (
                    <div className="px-4 pb-4 bg-greyMenuColor w-full flex justify-center h-full rounded-b-lg">
                      <div className="flex flex-col py-2 w-full px-8">
                        <div
                          className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                        >
                          <div className="flex flex-row justify-between w-full px-4">
                            <div className="text-secondaryGreen400 text-sm">
                              {t('aboutstartup')}
                            </div>
                            {toggle2[index] ? (
                              <IoIosArrowUp
                                size={22}
                                className="flex w-[50px] cursor-pointer pl-5 text-xl text-blue500 "
                                onClick={() => handleToggleClick2(index, false)}
                              />
                            ) : (
                              <IoIosArrowDown
                                size={22}
                                className="flex w-[50px] cursor-pointer pl-5 text-xl text-blue500"
                                onClick={() => handleToggleClick2(index, true)}
                              />
                            )}
                          </div>
                        </div>
                        {toggle2[index] && (
                          <div className="flex flex-col w-full h-[100px] bg-white border border-gray500 py-2 px-4">
                            <div className="text-base h-12 w-auto">
                              {item.about_startup}
                            </div>
                            <div>
                              <div className="flex items-end justify-end gap-2 pt-3 pb-2">
                                <IoLogoInstagram className="w-[25px] h-[25px] text-orange500" />
                                <AiOutlineLink className="w-[25px] h-[25px] text-orange500" />
                                <TfiTwitter className="w-[25px] h-[25px] text-orange500" />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="grid grid-cols-2 gap-5 pt-9">
                          <div className="flex flex-col">
                            <div
                              className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                            >
                              <div className="flex flex-row justify-between w-full px-4">
                                <div className="text-secondaryGreen400 text-sm">
                                  {t('generalinfo')}
                                </div>
                                {toggle4[index] ? (
                                  <IoIosArrowUp
                                    size={22}
                                    className="flex w-[50px] cursor-pointer pl-5 text-xl text-blue500"
                                    onClick={() =>
                                      handleToggleClick4(index, false)
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDown
                                    size={22}
                                    className="flex w-[50px] cursor-pointer pl-5 text-xl text-blue500"
                                    onClick={() =>
                                      handleToggleClick4(index, true)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {toggle4[index] && (
                              <div className="flex flex-col w-full h-[370px] bg-whiteColor border border-gray500 px-3 gap-2 py-3">
                                <div>{t('firstName')}</div>
                                <div className="text-textGrayColor">
                                  {item.first_name}
                                </div>
                                <hr className="w-full px-0 opacity" />
                                <div>{t('lastname')}</div>
                                <div className="text-textGrayColor">
                                  {item.last_name}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('nationality')}</div>
                                <div className="text-textGrayColor">
                                  {item.nationality}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('idType')}</div>
                                <div className="text-textGrayColor">
                                  {item.identity_type}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('idNumber')}</div>
                                <div className="text-textGrayColor">
                                  {item.identity_number}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('mobilenumber')}</div>
                                <div className="text-textGrayColor">
                                  {item.mobile}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('emailaddress')}</div>
                                <div className="text-textGrayColor">
                                  {item.email}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="flex flex-col">
                            <div
                              className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                            >
                              <div className="flex flex-row justify-between w-full px-4">
                                <div className="text-secondaryGreen400 text-sm">
                                  {t('startupinformation')}
                                </div>
                                {toggle3[index] ? (
                                  <IoIosArrowUp
                                    className="flex w-[50px] cursor-pointer pl-5 text-xl text-blue500"
                                    onClick={() =>
                                      handleToggleClick3(index, false)
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDown
                                    className="flex w-[50px] cursor-pointer pl-5 text-xl text-blue500"
                                    onClick={() =>
                                      handleToggleClick3(index, true)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {toggle3[index] && (
                              <div className="flex flex-col w-full  h-[370px] bg-whiteColor border border-gray500 px-3 gap-2 py-3">
                                <div>{t('startupName')}</div>
                                <div className="text-textGrayColor">
                                  {item.startup_name}
                                </div>
                                <hr className="w-full px-0 opacity-50" />
                                <div>{t('yearofestablishment')}</div>
                                <div className="text-textGrayColor">
                                  {item.year_of_establishment}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('startupsize')}</div>
                                <div className="text-textGrayColor">
                                  {item.startup_capacity}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('startupMajor')}</div>
                                <div className="text-textGrayColor">
                                  {item.startup_major}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('startuplocation')}</div>
                                <div className="text-textGrayColor">
                                  {item.startup_location}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('currentCompanyRating')}</div>
                                <div className="text-textGrayColor">
                                  {item.startup_current_rate}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('lastInvestmentRound')}</div>
                                <div className="text-textGrayColor">
                                  {item.startup_last_fundraising_round}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('country')}</div>
                                <div>{item.country}</div>
                                <hr className="w-full opacity-50" />
                                <div>{t('businessModel')}</div>
                                <div className="text-textGrayColor">
                                  {item.startup_business_model}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('numberOfClients')}</div>
                                <div className="text-textGrayColor">
                                  {item.customers_count}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('numberOfEmployees')}</div>
                                <div className="text-textGrayColor">
                                  {item.full_time_employees_count}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('productSatge')}</div>
                                <div className="text-textGrayColor">
                                  {item.startup_product_stage}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('howDidYouHearAboutGarage')}</div>
                                <div className="text-textGrayColor">
                                  {item.hear_about_garage}
                                </div>
                                <hr className="w-full opacity-50" />
                                <div>{t('whydoyouwanttojoinAlGarage')}</div>
                                <div className="text-textGrayColor">
                                  {item.reason_to_join}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        )}

        <div className="px-1">
          <Pagination
            totalCount={incubatorApplicationList?.total_count || 0}
            totalPage={Math.ceil(
              (incubatorApplicationList?.total_count || 0) / paginationLimit,
            )}
            page={page}
            limit={paginationLimit}
            onPageChange={setPage}
          />
        </div>
        <div className="absolute top-[400px] right-2">
          {acceptPopup && (
            <ConfirmPopupComponent
              onClose={handleCloseAcceptPopup}
              title={
                'Are you sure you want to accept this startup application?'
              }
              subtitle={
                'If you confirm, the startup application will be accept.'
              }
              onClick={handleCloseAcceptPopup}
              onClickConfirm={() => acceptIncubator(id)}
            />
          )}
          {rejectPopup && (
            <ConfirmPopupComponent
              onClose={handleCloseRejectPopup}
              title={
                'Are you sure you want to reject this startup application?'
              }
              subtitle={
                'If you confirm, the startup application will be reject.'
              }
              onClick={handleCloseRejectPopup}
              onClickConfirm={() => rejectIncubator(id)}
            />
          )}
          {enableCreateIncubator && (
            <ConfirmPopupComponent
              onClose={handleCreateIncubatorPopup}
              title={'Are you sure you want to start new  New incubator?'}
              subtitle={
                'If you confirm, the startp appliation will move to old applications'
              }
              onClick={handleCreateIncubatorPopup}
              onClickConfirm={() => createIncubator()}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default IncubatorNewApplicationComponent;
