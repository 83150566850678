import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { downloadDataLink } from 'components/common/csv';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import TeamSkeletonComponent from 'components/common/skeleton-element/team-skeleton';
import { OpenAndCloseEnum } from 'core/enums/open-and-close-state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { EventsModel, IsOpenModel } from 'core/types/user.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import {
  getAllApplicantsEventsServices,
  getEventsExportServices,
  getEventsIsOpenServices,
  postEventAcceptOrReject,
  postEventsOpenAndCloseServices,
} from 'services/events-services';

function NewEventsReservationContainer() {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(false);
  const [eventsData, setEventsData] = useState<EventsModel>();
  const [toggle, setToggle] = useState(
    new Array(eventsData?.result_list.length).fill(false),
  );
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(eventsData?.result_list.length).fill(false),
  );
  const [eventId, setEventId] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const paginationLimit = 15;
  const place = [1, 1, 1, 1];
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };
  const handleRecivingClosePopup = () => {
    setEnabled(false);
  };
  const [dataExport, setDataExport] = useState<string>('');
  const [visibleItems] = useState(7);
  const [loading] = useState(false);
  // const [val, setVal] = useState('');
  const [filtered, setFiltered] = useState(eventsData?.result_list);
  const [exportPopup, setExportPopup] = useState<boolean>(false);

  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };

  // const handleSearchClick = () => {
  //   if (val === '') {
  //     setFiltered(eventsData?.result_list);
  //     return;
  //   }
  //   const filterBySearch = eventsData?.result_list.filter((item) => {
  //     item.first_name.toLowerCase().includes(val.toLowerCase());
  //   });
  //   setFiltered(filterBySearch);
  // };

  const getDataExport = async () => {
    try {
      const eventsDataExport: string = await getEventsExportServices();
      setDataExport(eventsDataExport);

      downloadDataLink(eventsDataExport, 'Events Data');
      toast.success(`Data has been exported successfully!`);
    } catch (error) {
      handleRequestError(error);
    }
  };
  const getIsOpen = useCallback(async () => {
    try {
      const isOpen: IsOpenModel = await getEventsIsOpenServices();
      setIsOpen(isOpen.is_open);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  const postEventsOpenAndClose = async (open: OpenAndCloseEnum) => {
    try {
      await postEventsOpenAndCloseServices(open);
      toast.success(
        `Receiving forms has been ${
          open === OpenAndCloseEnum.CLOSE ? 'stoped' : 'enable'
        } successfully.`,
      );
      getIsOpen();
    } catch (error) {
      handleRequestError(error);
    }
  };
  const [acceptPopup, setAcceptPopup] = useState(false);
  const handleAcceptClick = (eventId: string) => {
    setAcceptPopup(true);
    setEventId(eventId);
  };
  const handleCloseAcceptPopup = () => {
    setAcceptPopup(false);
  };
  const [rejectPopup, setRejectPopup] = useState(false);
  const handleRejectClick = (eventId: string) => {
    setRejectPopup(true);
    setEventId(eventId);
  };
  const handleCloseRejectPopup = () => {
    setRejectPopup(false);
  };
  const getData = useCallback(async () => {
    try {
      const eventsData: EventsModel = await getAllApplicantsEventsServices(
        true,
        paginationLimit,
        (page - 1) * paginationLimit,
        null,
      );
      getIsOpen();
      unstable_batchedUpdates(() => {
        setEventsData(eventsData);
        setFiltered(eventsData.result_list);
        setSkeletonData(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [page]);

  const eventAcceptOrReject = async (status: string, partnerId: string) => {
    try {
      await postEventAcceptOrReject(status, partnerId);
      getData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  useEffect(() => {
    getData();
    getIsOpen();
  }, [getData, getIsOpen]);

  return (
    <>
      <div className="flex items-center justify-center pt-6 desktop:py-7">
        <div className="flex flex-col w-full px-10 pt-8 pb-2 bg-white rounded-lg">
          {/* <div className="flex flex-row items-start justify-start gap-4 pb-3">
            <input
              onChange={(e) => setVal(e.target.value)}
              type="search"
              id="default-search"
              className="block w-1/2 h-12 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
              placeholder={`${t('search')}`}
              required
            />
            <Button
              disabled={false}
              width={'w-[143px]'}
              hight={'h-[43px]'}
              text={`${t('search')}`}
              fontsize={'text-base'}
              onClick={handleSearchClick}
            />
          </div> */}
          <div className="flex flex-col w-full">
            <div className="grid items-center w-full grid-cols-6 gap-20 py-3 pr-48 text-2xl font-bold pl-7">
              <div className="font-bold text-center">{t('Name')}</div>
              <div className="font-bold text-center">{t('Email')}</div>
              <div className="text-2xl font-bold text-center">
                {t('N People')}
              </div>
              <div className="font-bold text-center">{t('Phone')}</div>
              <div className="font-bold text-center">{t('Company')}</div>
              <div className="font-bold text-center">{t('Status')}</div>
            </div>
            {skeletonData ? (
              <div className="flex flex-col items-center py-2">
                {place.map((e, index) => {
                  return (
                    <div key={index}>
                      <TeamSkeletonComponent />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {filtered?.length === 0 ? (
                  <div className="flex flex-col items-center justify-center w-auto gap-5 h-96">
                    <div className="h-[273px] w-[337px] mb-7">
                      <img src="/assets/images/notFound.png" alt="not found" />
                    </div>
                    <h1 className="text-2xl font-bold text-blue300 ">
                      We couldn’t find what you’re looking for
                    </h1>
                  </div>
                ) : (
                  filtered?.slice(0, visibleItems).map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center mb-4"
                    >
                      <div className="flex flex-row items-center justify-between w-full">
                        <div
                          className={`grid grid-cols-7 justify-center items-center bg-greyMenuColor h-full w-full py-4 rounded-t-lg ${
                            toggle[index] ? '' : 'rounded-b-lg'
                          }`}
                        >
                          <div className="text-center">
                            {item.first_name + ' ' + item.last_name}
                          </div>
                          <div className="text-center">{item.email}</div>
                          <div className="text-center">{item.guest_count}</div>
                          <div className="text-center">{item.mobile}</div>
                          <div className="text-center">{item.company}</div>
                          <div className="flex items-center justify-center gap-2">
                            <button
                              className="w-[95px] h-[37px] bg-darkRedColor text-whiteColor rounded-[4px]"
                              onClick={() => handleRejectClick(item.id)}
                            >
                              {t('Reject')}
                            </button>
                            <button
                              className="w-[95px] h-[37px] bg-lightGreenColor text-whiteColor rounded-[4px]"
                              onClick={() => handleAcceptClick(item.id)}
                            >
                              {t('Accept')}
                            </button>
                          </div>
                          <div className="pt-2 pl-9 desktop:pl-32">
                            {toggle[index] ? (
                              <IoIosArrowUp
                                className="flex w-[24px] h-[24px] cursor-pointer"
                                onClick={() => handleToggleClick(index, false)}
                              />
                            ) : (
                              <IoIosArrowDown
                                className="flex w-[24px] h-[24px] cursor-pointer"
                                onClick={() => handleToggleClick(index, true)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {loading && (
                        <div className="fixed flex items-center justify-center inset-[10px]">
                          <Loading />
                        </div>
                      )}
                      {toggle[index] && (
                        <div className="flex justify-center w-full h-full px-4 pb-4 rounded-b-lg bg-slate50">
                          <div className="flex flex-col w-full py-2">
                            <div className="flex flex-row gap-5 pt-9">
                              <div className="flex flex-col w-full">
                                <div
                                  className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                                >
                                  <div className="flex flex-row items-center justify-between w-full px-4">
                                    <div className="text-secondaryGreen400">
                                      General info
                                    </div>
                                    {toggleGenralInfo[index] ? (
                                      <IoIosArrowUp
                                        className="flex w-[50px] cursor-pointer pl-5"
                                        onClick={() =>
                                          handleToggleGenralInfoClick(index)
                                        }
                                      />
                                    ) : (
                                      <IoIosArrowDown
                                        className="flex w-[50px] cursor-pointer pl-5"
                                        onClick={() =>
                                          handleToggleGenralInfoClick(index)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                {toggleGenralInfo[index] && (
                                  <div className="flex flex-col w-full gap-2 px-3 py-4 bg-white border border-gray500">
                                    <div>{t('first Name')}</div>
                                    <div className=" text-greyChateau">
                                      {item.first_name}
                                    </div>
                                    <hr className="w-full px-0 opacity" />
                                    <div>{t('Last Name')}</div>
                                    <div className=" text-greyChateau">
                                      {item.last_name}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('Company')}</div>
                                    <div className=" text-greyChateau">
                                      {item.company}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('Position')}</div>
                                    <div className=" text-greyChateau">
                                      {item.position}
                                    </div>
                                    <hr className="w-full opacity-50" />

                                    <hr className="w-full opacity-50" />
                                    <div>{t('Mobile Number')}</div>
                                    <div className=" text-greyChateau">
                                      {item.mobile}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('Email Address')}</div>
                                    <div className=" text-greyChateau">
                                      {item.email}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('Event Name')}</div>
                                    <div className=" text-greyChateau">
                                      {item.event_name}
                                    </div>
                                    <div>{t('Number of invited people')}</div>
                                    <div className=" text-greyChateau">
                                      {item.guest_count}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('Start Date')}</div>
                                    <div className=" text-greyChateau">
                                      {item.start_at}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('End Date')}</div>
                                    <div className=" text-greyChateau">
                                      {item.end_at}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
          <div className="px-6">
            <Pagination
              totalPage={Math.ceil(
                (eventsData?.total_count || 0) / paginationLimit,
              )}
              page={page}
              limit={paginationLimit}
              onPageChange={setPage}
              totalCount={eventsData?.total_count || 0}
            />
          </div>
          <div className="flex items-center justify-between px-3 ">
            <div className="flex flex-row gap-2 pl-4">
              <span className="font-bold">{t('Receiving forms')}</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isOpen}
                  readOnly
                />
                <div
                  onClick={() => {
                    setEnabled(!enabled);
                  }}
                  className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange500"
                ></div>
              </label>
            </div>
            <Button
              disabled={false}
              width={'w-[305px]'}
              hight={'h-12'}
              text={'Export All'}
              img={<RiFileExcel2Line size={24} />}
              fontsize={'text-base'}
              onClick={() => handleExportClick()}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {acceptPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseAcceptPopup}
            title={'Are You sure you want to accept this event reservation?  '}
            subtitle={'If you confirm, the event reservation will be accept.'}
            onClick={handleCloseAcceptPopup}
            onClickConfirm={() => eventAcceptOrReject('accept', eventId)}
          />
        )}
        {rejectPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseRejectPopup}
            title={'Are You sure you want to reject this event reservation? '}
            subtitle={'If you confirm, the event reservation will be reject.'}
            onClick={handleCloseRejectPopup}
            onClickConfirm={() => eventAcceptOrReject('reject', eventId)}
          />
        )}
      </div>
      {exportPopup && (
        <ConfirmPopupComponent
          fileName="Events Data"
          onClose={handleCloseExportPopup}
          title={'Are you sure you want to Export All?'}
          subtitle={
            'If you confirm, all forms on this page will be downloaded.'
          }
          onClick={handleCloseExportPopup}
          onClickConfirm={getDataExport}
          data={dataExport}
        />
      )}

      {enabled && (
        <ConfirmPopupComponent
          onClose={handleRecivingClosePopup}
          title={
            isOpen === true
              ? 'Are you sure you want to Stop Receiving Forms?'
              : 'Are you sure you want to Enable Receiving Forms? '
          }
          subtitle={
            'If you confirm, application form will not appear on the website.'
          }
          onClick={handleRecivingClosePopup}
          onClickConfirm={() =>
            postEventsOpenAndClose(
              isOpen === true ? OpenAndCloseEnum.CLOSE : OpenAndCloseEnum.OPEN,
            )
          }
        />
      )}
    </>
  );
}

export default NewEventsReservationContainer;
