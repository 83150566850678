import { NavLink } from 'react-router-dom';

import { IMapRoutes } from '../../../core/types/user.type';
type Props = {
  route: IMapRoutes;
};
const SidebarItemSub = ({ route }: Props) => {
  return route.path ? (
    <NavLink
      className={({ isActive }) => (isActive ? 'text-black' : 'text-gray-700')}
      to={route.path}
    >
      <div
        className="flex w-full h-[50px] pl-7 items-center p-4 font-bold text-left border border-b-greyBorderBottom border-transparent"
        onClick={() => null}
      >
        {route.title}
      </div>
    </NavLink>
  ) : null;
};

export default SidebarItemSub;
