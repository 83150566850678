import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import RecevingFormComponent from 'components/admin/receving-form-component';
import { downloadDataLink } from 'components/common/csv';
import {
  fetchIncubatorList,
  getIncubatorStatus,
  getNewIncubatorExport,
  publishIncubator,
  unPublishIncubator,
} from 'core/data-access/incubator.service';
import { handleRequestError } from 'core/helpers/error.helper';
import { IncubatorListModel } from 'core/types/admin.type';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { RiFileExcel2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import IncubatorNewApplicationComponent from './Incubator-NewAppliacation-Component';

function IncubatorNewApplication() {
  const [, setIncubatorList] = useState<IncubatorListModel[]>([]);
  const [dataExport, setDataExport] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(false);
  const [unenabled, setUnEnabled] = useState<boolean>(false);
  const [incubatorStatus, setIncubatorStatus] = useState<boolean>(false);

  const getIncubatorList = useCallback(async () => {
    try {
      const res = await fetchIncubatorList();
      setIncubatorList(res);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);
  const IncubatorStatus = useCallback(async () => {
    try {
      const res = await getIncubatorStatus();
      setIncubatorStatus(res.is_open);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  const publishIncubatorApplication = async () => {
    try {
      await publishIncubator()
        .then(() => IncubatorStatus())
        .then(() =>
          toast.success('Receiving forms has been continue successfully.'),
        );
    } catch (error) {
      handleRequestError(error);
    }
  };
  const unPublishIncubatorApplication = async () => {
    try {
      await unPublishIncubator()
        .then(() => IncubatorStatus())
        .then(() =>
          toast.success('Receiving forms has been stopped successfully.'),
        );
    } catch (error) {
      handleRequestError(error);
    }
  };

  const getDataExport = async () => {
    try {
      const incubatorDataExport: string = await getNewIncubatorExport();
      setDataExport(incubatorDataExport);
      downloadDataLink(incubatorDataExport, 'New Incubator Data');

      toast.success(`Data has been exported successfully!`);
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleRecivingClosePopup = () => {
    setEnabled(false);
  };
  const handleUnRecivingClosePopup = () => {
    setUnEnabled(false);
  };
  const [exportPopup, setExportPopup] = useState(false);
  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };

  useEffect(() => {
    getIncubatorList();
    IncubatorStatus();
  }, [getIncubatorList, IncubatorStatus]);

  return (
    <div className="pt-5 felx justify-center items-center">
      <div className="flex flex-col w-full h-full rounded-lg bg-whiteColor">
        <IncubatorNewApplicationComponent />
        <div className="flex justify-between items-center px-5 pb-2">
          <div className="">
            <RecevingFormComponent
              checked={incubatorStatus ? true : false}
              onClick={() => {
                if (incubatorStatus) {
                  setUnEnabled(!unenabled);
                } else {
                  setEnabled(!enabled);
                }
              }}
              programme={t('receivingforms')}
            />
          </div>
          <Button
            disabled={false}
            width={'w-[284px]'}
            hight={'h-12'}
            text={'Export All'}
            fontsize={''}
            img={<RiFileExcel2Line size={24} />}
            onClick={handleExportClick}
          />
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {exportPopup && (
          <ConfirmPopupComponent
            fileName="New Incubator Data"
            onClose={handleCloseExportPopup}
            title={'Are you sure you want to Export latest version req?'}
            subtitle={
              'If you confirm, all forms on this page will be downloaded.'
            }
            onClick={handleCloseExportPopup}
            onClickConfirm={getDataExport}
            data={dataExport}
          />
        )}
        {unenabled && (
          <ConfirmPopupComponent
            onClose={handleUnRecivingClosePopup}
            title={'Are you sure you want to Stop Receiving Forms? '}
            subtitle={
              'If you confirm, application form will not appear on the website.'
            }
            onClick={handleUnRecivingClosePopup}
            onClickConfirm={() => unPublishIncubatorApplication()}
          />
        )}
        {enabled && (
          <ConfirmPopupComponent
            onClose={handleRecivingClosePopup}
            title={'Are you sure you want to Continue Receiving Forms? '}
            subtitle={
              'If you confirm, application form will appear on the website.'
            }
            onClick={handleRecivingClosePopup}
            onClickConfirm={() => publishIncubatorApplication()}
          />
        )}
      </div>
    </div>
  );
}
export default IncubatorNewApplication;
