import DateInputComponent from 'components/admin/booking/date-input-component';
import DropdownComponent from 'components/admin/booking/dropdown-with-title';
import ButtonComponent from 'components/admin/ButtonComponent';
import ModalPopupComponent from 'components/admin/modal-popup-component';
import { BookingInitialValues } from 'containers/admin/booking/booking.data';
import { validationSchema } from 'containers/admin/booking/validation-schema-booking';
import { handleRequestError } from 'core/helpers/error.helper';
import {
  convertTo24HourFormat,
  filterFutureTimes,
} from 'core/helpers/time.helper';
import { timeList } from 'core/statics/time-data';
import {
  BookingManagementModel,
  RoomModel,
} from 'core/types/booking-management.type';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getBookingManagementServices,
  postBookingManagementServices,
} from 'services/booking-management-services';

function BookingManagementContainer({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();

  const [data, setData] = useState<{ [key: string]: RoomModel[] }>({});
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const fetchNewData = useCallback(async () => {
    try {
      const date = new Date().toISOString().split('T')[0];
      const bookingData = await getBookingManagementServices(date, 100);
      const groupedData = bookingData.result_list.reduce(
        (acc: { [key: string]: RoomModel[] }, item: any) => {
          const { floor_type, floor_id, name, id } = item;
          if (!acc[floor_type]) {
            acc[floor_type] = [];
          }
          acc[floor_type].push({ floor_id, floor_type, name, id });
          return acc;
        },
        {},
      );
      setData(groupedData);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  const handleFormSubmit = async (values: typeof BookingInitialValues) => {
    const formattedDate = values.date
      ? format(new Date(values.date), 'yyyy-MM-dd')
      : '';
    const startTime = convertTo24HourFormat(values.timePeriod);
    const endTime = convertTo24HourFormat(values.extraField);

    if (
      new Date(`${formattedDate}T${endTime}`) <=
      new Date(`${formattedDate}T${startTime}`)
    ) {
      return;
    }

    const start_at = `${formattedDate}T${startTime}`;
    const end_at = `${formattedDate}T${endTime}`;

    const formattedValues = {
      start_at,
      end_at,
    };

    await postBookingManagement(formattedValues, values.roomId);
  };

  const postBookingManagement = async (
    bookingData: BookingManagementModel,
    id: string,
  ) => {
    try {
      await postBookingManagementServices(bookingData, id);
      onClose();
    } catch (error) {
      handleRequestError(error);
    }
  };

  const formik = useFormik({
    initialValues: BookingInitialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    fetchNewData();
  }, [fetchNewData]);
  return (
    <ModalPopupComponent hight={'h-auto'} width={'w-[750px]'}>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col w-full px-6 pt-3">
          <p className="text-xl font-bold text-SecondaryDarkColor font-en">
            {t('BookingManagement')}
          </p>
          <p className="mt-2 text-sm text-lightGray100">{t('OnlyAvailable')}</p>
          <div className="flex flex-col gap-5 mt-5">
            <div className="flex gap-4">
              <DropdownComponent
                id="dropdown1"
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                data={data}
                showTitles={true}
                title={t('RoomNumber') || ''}
                onSelect={(value) => {
                  const selectedRoom = Object.values(data)
                    .flat()
                    .find((room) => room.name === value);
                  if (selectedRoom) {
                    formik.setFieldValue('roomId', selectedRoom.id);
                  }
                }}
                error={!!formik.errors.roomId && formik.touched.roomId}
                errorMsg={formik.errors.roomId}
                placeholder={t('Select') || ''}
              />
              <DateInputComponent
                onSelect={(value) => formik.setFieldValue('date', value)}
                error={!!formik.errors.date && formik.touched.date}
                errorMsg={formik.errors.date}
                title={t('Date')}
                id="dropdown2"
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                selectsRange={false}
              />
            </div>
            <div className="flex gap-4">
              <DropdownComponent
                id="dropdown3"
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                data={filterFutureTimes(timeList)}
                showTitles={false}
                title={t('TimePeriod') || 'TimePeriod'}
                onSelect={(value) => formik.setFieldValue('timePeriod', value)}
                error={!!formik.errors.timePeriod && formik.touched.timePeriod}
                errorMsg={formik.errors.timePeriod}
                placeholder={t('From') || ''}
              />
              <div className="mt-10">
                <DropdownComponent
                  id="dropdown4"
                  openDropdown={openDropdown}
                  setOpenDropdown={setOpenDropdown}
                  data={filterFutureTimes(timeList)}
                  showTitles={false}
                  title={''}
                  onSelect={(value) =>
                    formik.setFieldValue('extraField', value)
                  }
                  error={
                    !!formik.errors.extraField && formik.touched.extraField
                  }
                  errorMsg={formik.errors.extraField}
                  placeholder={t('To') || ''}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="flex items-center justify-end h-[40%] gap-4 mt-5">
        <button
          className="w-[180px] h-[48px] rounded-lg border border-orange500 bg-whiteColor text-orange500 text-base"
          onClick={onClose}
        >
          {t('Cancel')}
        </button>
        <ButtonComponent
          disabled={false}
          width={'w-[180px]'}
          hight={'h-[48px]'}
          text={t('Prevent')}
          fontsize={''}
          onClick={async () => {
            await formik.handleSubmit();
          }}
        />
      </div>
    </ModalPopupComponent>
  );
}

export default BookingManagementContainer;
