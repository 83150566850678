import Button from 'components/admin/ButtonComponent';
import ExportIncubatorPopup from 'components/common/export-popup/export-incubator-popup';
import { fetchIncubatorList } from 'core/data-access/incubator.service';
import { IncubatorListModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { RiFileExcel2Line } from 'react-icons/ri';

import IncubatorOldApplicationComponent from './Incubator-OldApplication-component';
function IncubatorOldApplication() {
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  const [, setIncubatorList] = useState<IncubatorListModel[]>([]);

  const getIncubatorList = useCallback(async () => {
    try {
      const res = await fetchIncubatorList();
      setIncubatorList(res);
      return res;
    } catch (error) {
      return error;
    }
  }, []);
  useEffect(() => {
    getIncubatorList();
  }, [getIncubatorList]);

  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };

  return (
    <div className="pt-5 felx justify-center items-center">
      <div className="flex flex-col w-full h-full rounded-lg bg-whiteColor">
        <IncubatorOldApplicationComponent />
        <div className="flex items-end justify-end px-5 pb-2">
          <Button
            disabled={false}
            width={'w-[284px]'}
            hight={'h-12'}
            text={'Export All'}
            fontsize={''}
            img={<RiFileExcel2Line size={24} />}
            onClick={handleExportClick}
          />
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {exportPopup && (
          <ExportIncubatorPopup
            title={'Incubator'}
            subtitle={'The incubator you picked will be exported.'}
            programName={'Incubator'}
            fileName="Incubator Application"
            pathList={'incubator/list'}
            pathExport={'incubator'}
            onClose={handleCloseExportPopup}
            onClickConfirm={handleCloseExportPopup}
          />
        )}
      </div>
    </div>
  );
}
export default IncubatorOldApplication;
