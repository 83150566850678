import Checkbox from 'components/common/ui/Checkbox';
import {
  BookingDurationEnum,
  BookingStateEnum,
} from 'core/enums/application_state.enum';
import { ListBookingModel } from 'core/types/admin.type';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { BiSort } from 'react-icons/bi';
import { HiOutlinePencil } from 'react-icons/hi';
import { HiOutlineXMark } from 'react-icons/hi2';

interface IBookingListComponentProps {
  handleAddId: (id: string) => void;
  checkedIds: string[];
  list: ListBookingModel[];
  EditBooking: (id: string, start_at: string, end_at: string) => void;
  CancelBooking: (id: string) => void;
  sortStatus: () => void;
  sortDuration: () => void;
  sortDurationOrder: BookingDurationEnum;
  sortStatusOrder: BookingStateEnum | null;
}

function BookingListComponent({
  handleAddId,
  checkedIds,
  list,
  EditBooking,
  CancelBooking,
  sortStatus,
  sortDuration,
  sortDurationOrder,
  sortStatusOrder,
}: IBookingListComponentProps) {
  const { t } = useTranslation();

  const handleEditBooking = (
    id: string,
    start_at: string,
    end_at: string,
    status: BookingStateEnum,
    expired: boolean,
  ) => {
    if (status === BookingStateEnum.CONFIRMED && !expired) {
      EditBooking(id, start_at, end_at);
    }
  };

  const handleCancelBooking = (
    id: string,
    status: BookingStateEnum,
    expired: boolean,
  ) => {
    if (status === BookingStateEnum.CONFIRMED && !expired) {
      CancelBooking(id);
    }
  };

  const sortedList = [...list].sort((a, b) => {
    const durationDesc = moment(a.end_at).diff(moment(a.start_at));
    const durationAsc = moment(b.end_at).diff(moment(b.start_at));

    const durationSort =
      sortDurationOrder === BookingDurationEnum.desc
        ? durationAsc - durationDesc
        : durationDesc - durationAsc;

    if (sortStatusOrder) {
      const statusSort =
        sortStatusOrder === BookingStateEnum.CONFIRMED
          ? (a.booking_status === BookingStateEnum.CONFIRMED ? -1 : 1) -
            (b.booking_status === BookingStateEnum.CONFIRMED ? -1 : 1)
          : (a.booking_status === BookingStateEnum.CANCELED ? -1 : 1) -
            (b.booking_status === BookingStateEnum.CANCELED ? -1 : 1);

      return statusSort || durationSort;
    }

    return durationSort;
  });

  return (
    <div>
      <table className="w-full h-20 mt-4 text-center tablet:text-xs">
        <thead className="h-14">
          <tr className="border-b whitespace-nowrap h-14 text-gray800">
            <th>{t('component.bookingList.roomNumber')}</th>
            <th>{t('component.bookingList.bookingContact')}</th>
            <th>{t('component.bookingList.companyName')}</th>
            <th>
              <span className="flex items-center justify-center text-center">
                <BiSort className="cursor-pointer" onClick={sortDuration} />
                {t('component.bookingList.bookingDuration')}
              </span>
            </th>
            <th>
              <span className="flex items-center justify-center text-center">
                <BiSort className="cursor-pointer" onClick={sortStatus} />
                {t('component.bookingList.bookingStatus')}
              </span>
            </th>
          </tr>
        </thead>
        <tbody className="border-b h-14">
          {sortedList?.map((item, index) => (
            <tr
              key={index}
              className="items-center justify-center mx-auto text-center border-b h-14 text-blue300"
            >
              <td>
                <div className="flex items-center justify-center gap-3">
                  {item.booking_status === BookingStateEnum.CONFIRMED &&
                  !item.expired ? (
                    <>
                      <Checkbox
                        id={item.id}
                        checked={Boolean(
                          checkedIds.find((id) => id === item.id),
                        )}
                        type="checkbox"
                        onChange={() => handleAddId(item.id || '')}
                        aria-label={`Select booking ${item.id}`}
                      />
                    </>
                  ) : (
                    <Checkbox id={item.id} disabled />
                  )}

                  <p>{item.resource_number}</p>
                </div>
              </td>
              <td>{item.name}</td>
              <td>
                {item.company_name.length > 20
                  ? item.company_name.substring(0, 20) + '...'
                  : item.company_name}
              </td>
              <td>
                <div className="grid text-center">
                  <span>{moment(item.start_at).format('D MMM, hh:mm A')}</span>
                  <span>to {moment(item.end_at).format('hh:mm A')}</span>
                </div>
              </td>
              <td>
                <span
                  className={`w-[86px] h-[22px] tablet:w-[76px] tablet:h-[18px] text-sm tablet:text-xs px-[10px] py-[2px] rounded items-center justify-center ${
                    item.booking_status === BookingStateEnum.CONFIRMED
                      ? 'bg-[#ECFDF5] text-[#064E3B]'
                      : 'bg-[#FEF2F2] text-[#991B1B]'
                  }`}
                >
                  {item.booking_status === BookingStateEnum.CONFIRMED
                    ? t('component.bookingList.bookingStatus.confirmed')
                    : t('component.bookingList.bookingStatus.cancelled')}
                </span>
              </td>
              <td>
                <div className="flex gap-3">
                  <HiOutlinePencil
                    onClick={() =>
                      handleEditBooking(
                        item.id,
                        item.start_at,
                        item.end_at,
                        item.booking_status as BookingStateEnum,
                        item.expired,
                      )
                    }
                    className={`w-6 h-6 tablet:w-4 tablet:h-4 ${
                      item.booking_status === BookingStateEnum.CONFIRMED &&
                      !item.expired
                        ? 'text-secondaryGreen400 cursor-pointer'
                        : 'text-gray500 cursor-not-allowed'
                    }`}
                    aria-label={`Edit booking ${item.id}`}
                  />
                  <HiOutlineXMark
                    onClick={() =>
                      handleCancelBooking(
                        item.id,
                        item.booking_status as BookingStateEnum,
                        item.expired,
                      )
                    }
                    className={`w-6 h-6 tablet:w-4 tablet:h-4 ${
                      item.booking_status === BookingStateEnum.CONFIRMED &&
                      !item.expired
                        ? 'text-orange400 cursor-pointer'
                        : 'text-gray500 cursor-not-allowed'
                    }`}
                    aria-label={`Cancel booking ${item.id}`}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BookingListComponent;
