import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import Pagination from 'components/common/Pagination/pagination';
import CardSkeleton from 'components/common/skeleton-element/card-skeleton';
import { GalleryTypeEnum } from 'core/enums/example.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllMediaGalleryModel, GalleryModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { HiOutlinePlus } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Services from 'services/gallery-service';

import GalleryCardCopmonent from './gallery-card-copmonent';
import GalleryEditContainer from './gallery-edit-container';
import GalleryNewContainer from './gallery-new-container';

function DisplayImagesComponent() {
  const [data, setData] = useState<AllMediaGalleryModel>();
  const [deletePicturePopup, setDeletePicturePopup] = useState<boolean>(false);
  const [deletePicturlID, setDeletePicturlID] = useState('');
  const [eidtPictureIndex, setEditPictureIndex] = useState<number>(0);
  const [editPicturePopup, setEditPicturePopup] = useState<boolean>(false);
  const [newPicturePopup, setNewPicturePopup] = useState<boolean>(false);
  const [acceptNewPicturePopup, setAcceptNewPicturePopup] =
    useState<boolean>(false);
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const [eidtPictureID, setEditPictureID] = useState('');
  const location = useLocation();
  const gallery = new URLSearchParams(location.search).get('gallery');
  const place = [1, 1, 1, 1, 1, 1, 1, 1];
  const [visibleItems, setVisibleItems] = useState(1);
  const paginationLimit = 8;
  const handleEditClosePopup = () => {
    setEditPicturePopup(false);
  };
  const handleEditPictureClick = () => {
    setEditPicturePopup(true);
  };
  const handleDeleteClosePopup = () => {
    setDeletePicturePopup(false);
  };
  const handleDeletePictureClick = () => {
    setDeletePicturePopup(true);
  };
  const handleNewClosePopup = () => {
    setNewPicturePopup(false);
  };
  const handleNewPictureClick = () => {
    setNewPicturePopup(true);
  };
  const handleAcceptNewPictureClick = () => {
    setAcceptNewPicturePopup(true);
  };
  const handleAcceptNewClosePopup = () => {
    setAcceptNewPicturePopup(false);
  };
  //get all picture
  const getData = useCallback(async () => {
    try {
      const dataGallery: AllMediaGalleryModel =
        await Services.getAllMediaGalleryServices(
          gallery || '',
          paginationLimit,
          (visibleItems - 1) * paginationLimit,
        );
      setData(dataGallery);
      setSkeletonData(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, [gallery, visibleItems]);
  //delete  picture by id
  const deletePicture = async (pictureID: string) => {
    try {
      await Services.deletePictureServices(pictureID);
      getData();
    } catch (error) {
      handleRequestError(error);
    }
  };
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div className="w-full">
      <>
        <div className="grid justify-end items-end -mt-9">
          <div
            onClick={() => {
              handleNewPictureClick();
            }}
            className="w-[141px] laptop:ml-[-130px] h-[48px] flex flex-row justify-center gap-2 items-center bg-orange500 rounded-lg text-whiteColor cursor-pointer"
          >
            <HiOutlinePlus /> New picture
          </div>
        </div>
        {skeletonData ? (
          <div className="grid grid-cols-2 gap-5 items-center">
            {place.map((_, index) => {
              return (
                <div key={index}>
                  <CardSkeleton />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-x-16 gap-y-8 items-center px-20 py-16">
            {data?.result_list.map((item, index) => (
              <GalleryCardCopmonent
                key={index}
                gallery_type={GalleryTypeEnum.Disrupt}
                index={
                  visibleItems === 1
                    ? index + 1
                    : index +
                      1 +
                      paginationLimit * visibleItems -
                      paginationLimit
                }
                image_url={item.image_url as string}
                onClickEdit={() => {
                  setEditPictureIndex(index);
                  setEditPictureID(item.id || '');
                  handleEditPictureClick();
                }}
                onClickDelete={() => {
                  setDeletePicturlID(item.id || '');
                  handleDeletePictureClick();
                }}
              />
            ))}
          </div>
        )}
        <div className="absolute top-[400px] right-2">
          {newPicturePopup && (
            <GalleryNewContainer
              title={'Gallery Picture '}
              onClose={handleNewClosePopup}
              onSuccess={handleAcceptNewPictureClick}
            />
          )}
          {acceptNewPicturePopup && (
            <ConfirmPopupComponent
              title={'Are You sure you want to publish pictures?'}
              subtitle={'If you confirm, pictures will be published.'}
              onClose={handleAcceptNewClosePopup}
              onClick={handleAcceptNewClosePopup}
              onClickConfirm={() => {
                getData();
                toast.success('Picture has been published successfully!');
                handleNewClosePopup();
              }}
            />
          )}
          {editPicturePopup && (
            <GalleryEditContainer
              title={'Edit Gallery Picture'}
              data={data?.result_list[eidtPictureIndex] as GalleryModel}
              onClose={handleEditClosePopup}
              id={eidtPictureID}
              onSuccess={() => {
                getData();
                handleEditClosePopup();
              }}
            />
          )}
          {deletePicturePopup && (
            <ConfirmPopupComponent
              onClose={handleDeleteClosePopup}
              title={'Are You sure you want to delete this picture?'}
              subtitle={'If you confirm, the picture will be deleted'}
              onClick={handleDeleteClosePopup}
              onClickConfirm={() => {
                deletePicture(deletePicturlID);
                toast.success('picture has been deleted successfully!');
              }}
              onSuccess={() => {
                getData();
                handleDeleteClosePopup();
              }}
            />
          )}
        </div>
      </>
      {/* pagInation */}
      <div>
        <Pagination
          totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
          page={visibleItems}
          limit={paginationLimit}
          onPageChange={setVisibleItems}
          totalCount={data?.total_count || 0}
        />
      </div>
    </div>
  );
}
export default DisplayImagesComponent;
