import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import PublishDisruptUnpublishDisrupt from 'components/admin/disrupt-componet/publish-disrupt-unpublish-disrupt';
import EmptySearchComponent from 'components/common/empty-search';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import IncubatorsSkeleton from 'components/common/skeleton-element/incubators-skeleton';
import SearchComponent from 'containers/admin/Community/Partners/search-component';
import {
  getDisrupt,
  getDisruptDisruptExport,
} from 'core/data-access/disrupt.service';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllDisruptShowModel } from 'core/types/admin.type';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { RiFileExcel2Line } from 'react-icons/ri';

function DisruptListComponent() {
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const [val, setVal] = useState<number | null>(null);
  const [data, setData] = useState<AllDisruptShowModel>();
  const paginationLimit = 4;
  const [visibleItems, setVisibleItems] = useState(1);
  const [, setFiltered] = useState(data?.result_list);
  const [dataExport, setDataExport] = useState<string>('');
  const place = [1, 1, 1, 1];
  const getData = useCallback(async () => {
    try {
      const Disrupt: AllDisruptShowModel = await getDisrupt(
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
        val ?? null,
      );

      unstable_batchedUpdates(() => {
        setData(Disrupt);
        setFiltered(Disrupt.result_list);
        setSkeletonData(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [visibleItems, val]);
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  const handleExportClick = () => {
    getDataExport();
    setExportPopup(true);
  };

  const getDataExport = async () => {
    try {
      const disruptDataExport: string = await getDisruptDisruptExport();
      setDataExport(disruptDataExport);
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };
  const dataObjects = data?.result_list.map((data) => {
    const dateTimeStart = new Date(data.start_at);
    const datePartStrart = dateTimeStart.toDateString();
    const timePartStart = dateTimeStart.toLocaleTimeString();

    const dateTimeEnd = new Date(data.end_at);
    const datePartEnd = dateTimeEnd.toDateString();
    const timePartEnd = dateTimeEnd.toLocaleTimeString();

    const disruptNumber = data.disrupt_number;
    const status = data.status;
    const id = data.id;

    return {
      id: id,
      dateStart: datePartStrart,
      timeStart: timePartStart,
      dateEnd: datePartEnd,
      timeEnd: timePartEnd,
      disruptNumber: disruptNumber,
      status: status,
    };
  });

  useEffect(() => {
    getData();
  }, [getData, val]);

  return (
    <div className="pt-4 px-8 felx justify-center items-center">
      <div className="flex flex-col w-full h-full rounded-lg bg-whiteColor px-8 pt-4 pb-3">
        <div className="flex items-start justify-start">
          <SearchComponent
            onClickValue={(e) => setVal(parseInt(e.target.value) || null)}
            onClickShearch={getData}
          />
        </div>
        <div className="grid grid-cols-4 gap-4 items-center w-full my-1 text-2xl font-bold text-gray800">
          <p className="w-[250px] text-center">Disrupt number</p>
          <p className="w-[250px] text-center pl-16">Date</p>
          <p className="w-[250px] text-center pl-36">Time</p>
          <p className="w-[250px] text-center">Status</p>
        </div>
        {skeletonData ? (
          <div className="flex flex-col items-center">
            {place.map((e, index) => {
              return (
                <div key={index}>
                  <IncubatorsSkeleton />
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            {dataObjects === undefined || null ? (
              <Loading />
            ) : data?.total_count === 0 ? (
              <div className="flex justify-center mx-auto">
                <EmptySearchComponent />
              </div>
            ) : (
              dataObjects &&
              dataObjects.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-cols-4 gap-4 mb-4 py-3 bg-greyMenuColor h-full rounded-lg"
                >
                  <p className="pl-20 text-lg whitespace-nowrap">
                    {'Disrupt ' + item.disruptNumber}
                  </p>
                  <p className="text-center text-lg whitespace-nowrap">
                    {item.dateStart + ' - ' + item.dateEnd}
                  </p>
                  <p className="pl-20 text-center text-lg whitespace-nowrap">
                    {item.timeStart + ' - ' + item.timeEnd}
                  </p>
                  {item.status === 'PUBLISHED' ? (
                    <div className="pl-20">
                      <div className="flex w-[83px] h-[32px] text-sm px-5 bg-green50 text-green500 rounded-[20px] items-center justify-center">
                        {t('Publish')}
                      </div>
                    </div>
                  ) : item.status === 'DRAFT' ? (
                    <div className="pl-20">
                      <div className="flex w-[83px] h-[32px] text-sm px-5 bg-orange100 text-orange500 rounded-[20px] items-center justify-center">
                        {t('Draft')}
                      </div>
                    </div>
                  ) : null}
                </div>
              ))
            )}
          </div>
        )}
        <div className="pr-5">
          <Pagination
            totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
            page={visibleItems}
            limit={paginationLimit}
            onPageChange={setVisibleItems}
            totalCount={data?.total_count || 0}
          />
        </div>
        <div className="flex justify-between items-center">
          {/* Receiving Forms */}
          <PublishDisruptUnpublishDisrupt />
          {/* Button */}
          <Button
            disabled={false}
            width={'w-[284px]'}
            img={<RiFileExcel2Line size={24} />}
            hight={'h-[48px]'}
            text={'Export All'}
            fontsize={'text-base'}
            onClick={handleExportClick}
          />
        </div>
        <div className="absolute top-[400px] right-2">
          {exportPopup && (
            <ConfirmPopupComponent
              fileName="Disrupt Data"
              onClose={handleCloseExportPopup}
              title={'Are you sure you want to Export All?'}
              subtitle={
                'If you confirm, all forms on this page will be downloaded.'
              }
              onClick={handleCloseExportPopup}
              onClickConfirm={getDataExport}
              data={dataExport}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DisruptListComponent;
