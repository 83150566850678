import GenralBoxComponent from 'components/admin/box-Genaral-component';
import { RecentNews } from 'core/types/admin.type';
import moment from 'moment';

interface recentProp {
  data?: RecentNews[];
}
function RecentNewscomponents(props: recentProp) {
  return (
    <>
      <div className="w-full bg-white rounded-lg shadow pt-5 pb-10">
        <GenralBoxComponent Heading="Recent News" HeadingColor="black" />
        {props.data?.slice(0, 2).map((item, index) => {
          const data = props.data?.[index];

          return (
            <>
              <div className="flex mt-4 my-1" key={index}>
                <img
                  src={item.image}
                  alt="img"
                  className="w-[125.664px] object-contain h-[64.074px] ml-5"
                />
                <div className="pl-5">
                  <p className="font-bold text-base pb-2">{item.title}</p>
                  <p className="text-orange400 text-sm">
                    {moment(item.publish_date).format('ll')}
                  </p>
                </div>
              </div>
              {data && (
                <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
              )}
            </>
          );
        })}
      </div>
    </>
  );
}

export default RecentNewscomponents;
