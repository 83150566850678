import './dynamic-classes';

import { useTranslation } from 'react-i18next';

interface ITextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  limit?: number;
  label?: string;
  helperText?: string;
  errorMsg?: string;
  isValid?: boolean;
}
function TextAreaField({
  limit = 20,
  label,
  helperText = '',
  errorMsg = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isValid = true,
  ...props
}: ITextAreaProps): JSX.Element {
  const { t } = useTranslation();

  const rows = props.rows || 6;

  const getError = () => {
    return errorMsg !== ''
      ? ((isValid = false),
        'focus:border-red-600 border-red-600 hover:border-red-600')
      : (props.value?.toString().length || 0) > limit
      ? ((isValid = false),
        'border-red-600 focus:border-red-600 focus:border-[1px]' + true)
      : ((isValid = true), '');
  };

  const getInputVarient = () => {
    return `border-[0.5px] py-2 px-2 rounded-md bg-neutralGre50 ${
      isValid ? '' : 'focus:border-[1px]'
    } ${getError()}`;
  };

  return (
    <div
      dir={props.dir}
      className="flex flex-col w-full mt-5 justify-center mx-auto"
    >
      <label className="text-blue500 text-2xl font-arabic_normal disabled:!bg-transparent disabled:bg-none">
        {t(`${label}`)}
      </label>
      <textarea
        {...props}
        rows={rows}
        className={`${getInputVarient()} font-arabic_normal text-lg resize-none border-neutralGrey hover:border-gray-400 disabled:bg-white focus:outline-none focus:border-secondaryGreen400 focus:ring-secondaryGreen400 disabled:cursor-not-allowed`}
      />
      <div className={`flex justify-between px-2 py-1 text-sm text-gray-500`}>
        {(limit && limit < (props.value?.toString().length || 0)) ||
        errorMsg !== '' ? (
          <p className={`${getError()} text-error`}>{t(`${errorMsg}`)}</p>
        ) : (
          <p className={`${getError()}`}>{t(`${helperText}`)}</p>
        )}
        {limit && (
          <span
            className={`flex ${
              (props.value?.toString().length || 0) > limit ? 'text-error' : ''
            }`}
          >
            <p
              className={`${
                props.value?.toString().length !== 0 ? 'text-blue500' : ''
              }`}
            >
              {props.value?.toString().length}
            </p>
            /{limit}
          </span>
        )}
      </div>
    </div>
  );
}
export default TextAreaField;
