import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import DropdownComponent from 'components/admin/dropdown-componet';
import MessageSkeletonComponent from 'components/admin/message-skeleton-component/message-skeleton-component';
import EmptySearchComponent from 'components/common/empty-search';
import Pagination from 'components/common/Pagination/pagination';
import SearchComponent from 'containers/admin/Community/Partners/search-component';
import { IssueEnum } from 'core/enums/application_state.enum';
import { contactUsEnum } from 'core/enums/example.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllIssueModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { HiOutlineMailOpen, HiOutlineTrash } from 'react-icons/hi';
import * as Services from 'services/issus-service';

import MessageNewEmailIssueComponet from './message-new-email-issue-componet';

function IssueNewComponent() {
  const [, setSelectedDropdownOption] = useState(IssueEnum.ALL);
  const [issueType, setIssueType] = useState<IssueEnum>(IssueEnum.ALL);
  const paginationLimit = 8;
  const [visibleItems, setVisibleItems] = useState(1);
  const [data, setData] = useState<AllIssueModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [textAreaValue, setTextAreaValue] = useState<string>();
  const [val, setVal] = useState<string>('');

  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
  };
  const handleAcceptReadClick = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleAcceptClick = () => {
    setShowDeletePopup(true);
    // deleteData();
  };

  const handleCheckeAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedIds(data?.result_list?.map((r) => r.id || '') || []);
      setIsCheckedAll(true);
    } else {
      setSelectedIds([]);
      setIsCheckedAll(false);
    }
  };
  const handleAddNewContactId = (id: string) => {
    const foundId = selectedIds.find((i) => i === id);
    if (foundId) {
      setSelectedIds(selectedIds.filter((i) => i !== foundId));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSendData = (id: string) => {
    replayIssues(id);
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const issueData: AllIssueModel = await Services.getIssueService(
        val ?? null,
        issueType,
        contactUsEnum.NEW,
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
      );
      setData(issueData);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [visibleItems, val, issueType]);

  const deleteData = async () => {
    try {
      await Services.postIssueService(contactUsEnum.DEL, selectedIds);
      unstable_batchedUpdates(() => {
        setSelectedIds([]);
        setIsCheckedAll(false);
      });
      getData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  const readData = async () => {
    try {
      await Services.postIssueService(contactUsEnum.READ, selectedIds);
      unstable_batchedUpdates(() => {
        setSelectedIds([]);
      });
      getData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  const replayIssues = async (id: string) => {
    try {
      const replies = {
        reply: textAreaValue,
      };
      await Services.replayIssuesService(id, replies.reply || '');
      getData();
    } catch (error) {
      handleRequestError(error);
    }
  };
  useEffect(() => {
    getData();
  }, [getData]);

  const handleDropdownChange = (selectedValue: string | null) => {
    if (selectedValue !== null) {
      setSelectedDropdownOption(selectedValue as IssueEnum);
      switch (selectedValue as IssueEnum) {
        case IssueEnum.ALL:
          setIssueType(IssueEnum.ALL);
          break;
        case IssueEnum.PLUS:
          setIssueType(IssueEnum.PLUS);
          break;
        case IssueEnum.INCUBATOR:
          setIssueType(IssueEnum.INCUBATOR);
          break;
        case IssueEnum.DISRUPT_COMPANY:
          setIssueType(IssueEnum.DISRUPT_COMPANY);
          break;
        case IssueEnum.DISRUPT_GUEST:
          setIssueType(IssueEnum.DISRUPT_GUEST);
          break;
        case IssueEnum.TOUR:
          setIssueType(IssueEnum.TOUR);
          break;
        case IssueEnum.CONTACT_US:
          setIssueType(IssueEnum.CONTACT_US);
          break;
        case IssueEnum.PARTNER:
          setIssueType(IssueEnum.PARTNER);
          break;
        case IssueEnum.VISIT:
          setIssueType(IssueEnum.VISIT);
          break;
        case IssueEnum.ACCESS:
          setIssueType(IssueEnum.ACCESS);
          break;
        case IssueEnum.EVENT:
          setIssueType(IssueEnum.EVENT);
          break;
        case IssueEnum.ACCESS_DEMO:
          setIssueType(IssueEnum.ACCESS_DEMO);
          break;
        case IssueEnum.DISRUPT_HIDDEN:
          setIssueType(IssueEnum.DISRUPT_HIDDEN);
          break;
        case IssueEnum.TRANSPORTATION_PERMIT:
          setIssueType(IssueEnum.TRANSPORTATION_PERMIT);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="desktop:pt-6 pt-4">
      <div className="bg-white rounded-lg px-6 py-6 desktop:pt-6">
        <div className="flex items-center justify-between pb-1">
          <p className="text-2xl font-bold font-en pl-2">All Issues</p>
          <DropdownComponent
            options={[
              IssueEnum.ALL,
              IssueEnum.PLUS,
              IssueEnum.ACCESS_DEMO,
              IssueEnum.ACCESS,
              IssueEnum.CONTACT_US,
              IssueEnum.DISRUPT_COMPANY,
              IssueEnum.DISRUPT_GUEST,
              IssueEnum.DISRUPT_HIDDEN,
              IssueEnum.EVENT,
              IssueEnum.INCUBATOR,
              IssueEnum.PARTNER,
              IssueEnum.TOUR,
              IssueEnum.VISIT,
              IssueEnum.TRANSPORTATION_PERMIT,
            ]}
            onChange={handleDropdownChange}
          />
        </div>
        <div className="flex flex-col w-full rounded-lg">
          <div className="flex gap-6 bg-slate50 items-center justify-start pl-2 py-2 desktop:py-4 desktop:pl-4 rounded-[8px]">
            <div className="flex items-center justify-start gap-5">
              <div className="flex items-center">
                <div className="flex">
                  <input
                    type="checkbox"
                    id={'lang'}
                    name="SelectAll"
                    className="w-5 h-4"
                    checked={Boolean(isCheckedAll)}
                    onChange={(e) => handleCheckeAll(e.target.checked)}
                  />
                </div>
                <div className="text-sm">
                  <label className="text-[16px] text-gray-900 dark:text-gray-300">
                    Select All
                  </label>
                </div>
              </div>
              <div className="w-px h-8 rounded-full bg-greyBorderBottom"></div>
              <div
                className="flex flex-row gap-1 cursor-pointer"
                onClick={handleAcceptClick}
              >
                <HiOutlineTrash className="w-[22px] h-[22px] text-red500" />
                Delete
              </div>
              <button
                className="flex flex-row items-center justify-center gap-2 px-2 py-2 ml-1 text-base border rounded-lg whitespace-nowrap border-orange500 text-orange500"
                onClick={handleAcceptReadClick}
              >
                <HiOutlineMailOpen className="text-orange500 w-[24px] h-6" />
                Mark as opened
              </button>
            </div>
            <div className="w-1/2">
              <SearchComponent
                onClickValue={(e) => setVal(e.target.value)}
                onClickShearch={getData}
              />
            </div>
          </div>
          {isLoading ? (
            <div className="">
              {Array(6)
                .fill('')
                .map((_, index) => {
                  return (
                    <div key={`skeleton-${index}`}>
                      <MessageSkeletonComponent />
                    </div>
                  );
                })}
            </div>
          ) : data?.total_count === 0 ? (
            <div className="mt-20">
              <EmptySearchComponent />
            </div>
          ) : (
            <div className="w-full pt-1">
              <MessageNewEmailIssueComponet
                list={data?.result_list || []}
                handleAddId={(id: string) => handleAddNewContactId(id)}
                checkedIds={selectedIds}
                textAreaVal={textAreaValue}
                textAreaOnChange={(e) => setTextAreaValue(e.target.value)}
                handleSendPost={(id: string) => handleSendData(id)}
              />
            </div>
          )}
          <div className="pr-8 pt-5 desktop:pt-0 desktop:pb-7">
            <Pagination
              totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
              page={visibleItems}
              limit={paginationLimit}
              onPageChange={setVisibleItems}
              totalCount={data?.total_count || 0}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {showDeletePopup && (
          <ConfirmPopupComponent
            onClose={handleCloseDeletePopup}
            title={'Are you sure you want to delete this question? '}
            subtitle={'If you confirm, question will be delete.'}
            onClick={handleCloseDeletePopup}
            onClickConfirm={() => deleteData()}
          />
        )}
        {showPopup && (
          <ConfirmPopupComponent
            onClose={handleClosePopup}
            title={'Are you sure you want to Mark as opened ?'}
            subtitle={'If you confirm, question will be opened.'}
            onClick={handleAcceptReadClick}
            onClickConfirm={() => readData()}
          />
        )}
      </div>
    </div>
  );
}

export default IssueNewComponent;
