import { PDFEnum, StatusEnum } from 'core/enums/application_state.enum';
import { t } from 'i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { VscFilePdf } from 'react-icons/vsc';

interface PropsDataListComponent {
  index: number;
  toggle: any;
  id: string;
  item1: string;
  item2: string;
  item3: string;
  Icon?: PDFEnum;
  profile_url?: string;
  item4?: string;
  item5: string;
  isNew?: boolean;
  onClickButtonAccept?: () => void;
  onClickButtonReject?: () => void;
  onClickArrowDown: () => void;
  onClickArrowUp: () => void;
}
function DataListComponent(props: PropsDataListComponent) {
  return (
    <div className="flex flex-row items-center w-full justify-evenly">
      <div
        className={`grid grid-cols-6 gap-20 py-2 pl-10 bg-greyMenuColor h-full rounded-t-lg ${
          props.toggle[props.index] ? '' : 'rounded-b-lg'
        }`}
      >
        <div className="flex justify-center items-center text-base whitespace-nowrap">
          {props.item1}
        </div>
        <div className="flex justify-center items-center">
          <div className="w-auto whitespace-nowrap py-1 px-3 bg-orange500 flex justify-center items-center rounded-[20px] text-whiteColor">
            {props.item2.slice(0, 19) + '...'}
          </div>
        </div>
        <div className="flex justify-center items-center whitespace-nowrap">
          {props.item3}
        </div>
        <div className="justify-center w-[200px] items-center cursor-pointer pl-14">
          {props.Icon === PDFEnum.YES ? (
            <a href={props.profile_url}>
              <VscFilePdf className="text-orange500 w-6 h-6" />
            </a>
          ) : (
            <>{props.item4}</>
          )}
        </div>
        <div className="flex w-[180px] justify-center items-center">
          {props.item5 === StatusEnum.ACCEPTED && props.isNew === false ? (
            <div className='"w-[95px] h-[37px]'>
              <div className="flex w-[83px] h-[32px] text-sm px-5 bg-green50 text-green500 rounded-[20px] items-center justify-center">
                {t('accepted')}
              </div>
            </div>
          ) : props.item5 === StatusEnum.REJECTED && props.isNew === false ? (
            <div className="flex w-[83px] h-[32px] text-sm px-5 bg-red50 text-red500 rounded-[20px] items-center justify-center">
              {t('rejected')}
            </div>
          ) : props.item5 === StatusEnum.PENDING && props.isNew === false ? (
            <div className="flex w-[83px] h-[32px] text-sm px-5 bg-greyBorderBottom text-gray500 rounded-[20px] items-center justify-center">
              {t('pending')}
            </div>
          ) : (
            <div className="flex flex-row gap-2">
              <button
                className="w-[95px] h-[37px] bg-red500 text-whiteColor rounded-[4px]"
                onClick={props.onClickButtonReject}
              >
                {t('reject')}
              </button>
              <button
                className="w-[95px] h-[37px] bg-green500 text-whiteColor rounded-[4px]"
                onClick={props.onClickButtonAccept}
              >
                {t('accept')}
              </button>
            </div>
          )}
        </div>
        <div className="pt-2 w-[20px]">
          {props.toggle[props.index] ? (
            <IoIosArrowUp
              className="w-[50px] text-xl cursor-pointer"
              onClick={props.onClickArrowUp}
            />
          ) : (
            <IoIosArrowDown
              className="w-[60px] text-xl cursor-pointer"
              onClick={props.onClickArrowDown}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default DataListComponent;
