import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { HttpMethods } from 'core/enums/httpMethods.enum';

export const getAllApplicantsEventsServices = (
  applicationState: boolean,
  limit: number,
  offset: number,
  searchText: string | null,
) => {
  return makeRequest({
    url: `${BASE_URL}/book-event`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      is_pending: applicationState,
      limit: limit,
      offset: offset,
    },
  });
};

export const postEventsOpenAndCloseServices = (openOrClose: string) => {
  return makeRequest({
    url: `${BASE_URL}/book-event/${openOrClose}`,
    method: HttpMethods.POST,
  });
};

export const getEventsIsOpenServices = () => {
  return makeRequest({
    url: `${BASE_URL}/book-event/status`,
    method: HttpMethods.GET,
  });
};

export const getEventsExportServices = () => {
  return makeRequest({
    url: `${BASE_URL}/book-event/export`,
    method: HttpMethods.GET,
  });
};

export const postEventAcceptOrReject = (status: string, event_id: string) => {
  return makeRequest({
    url: `${BASE_URL}/book-event/${event_id}/${status}`,
    method: HttpMethods.POST,
  });
};
