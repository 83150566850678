import ActionIcons from 'components/action-icons';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import EmptySearchComponent from 'components/common/empty-search';
import Loading from 'components/common/loading';
import SharedTableComponent from 'components/TableComponent';
import ViewMediaComponent from 'components/view-media-component';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllMediaNewsModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'routes/app.paths';
import { deleteNewsServices, getNweServices } from 'services/news-service';

type MediaType = 'video' | 'image';

interface SelectedMedia {
  title: string;
  content: string;
  url: string;
  mediaType: MediaType;
}
function ListNews() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const paginationLimit = 9;
  const [visibleItems, setVisibleItems] = useState(1);
  const [data, setData] = useState<AllMediaNewsModel>();
  const [totalCount, setTotalCount] = useState(0);
  const [deleteNewsPopup, setDeleteNewsPopup] = useState<boolean>(false);
  const [NewsID, setNewsID] = useState<string>('');
  const [isViewMediaVisible, setIsViewMediaVisible] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedMedia, setSelectedMedia] = useState<SelectedMedia | null>(
    null,
  );

  const getData = useCallback(async () => {
    try {
      const Disrupt: AllMediaNewsModel = await getNweServices(
        'news_letter',
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
      );
      unstable_batchedUpdates(() => {
        setData(Disrupt);
        setTotalCount(Disrupt.total_count);
        setLoading(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [visibleItems]);

  const deleteNews = async () => {
    if (NewsID) {
      try {
        await deleteNewsServices(NewsID);
        getData();
      } catch (error) {
        handleRequestError(error);
      } finally {
        setDeleteNewsPopup(false);
      }
    }
  };

  const handleDelete = (newsID?: string) => {
    if (newsID) {
      setNewsID(newsID);
      setDeleteNewsPopup(true);
    }
  };

  const handleView = (newsID?: string) => {
    if (newsID && data) {
      const newsItem = data.result_list.find((news) => news.id === newsID);
      if (newsItem) {
        const mediaType =
          newsItem.url && newsItem.url.endsWith('.mp4') ? 'video' : 'image';
        setSelectedMedia({
          title:
            currentLanguage === 'en'
              ? newsItem.title_en ?? ''
              : newsItem.title_ar ?? '',
          content:
            currentLanguage === 'en'
              ? newsItem.description_en ?? ''
              : newsItem.description_ar ?? '',
          mediaType: mediaType,
          url: newsItem.url ?? '',
        });
        setIsViewMediaVisible(true);
      }
    }
  };




  useEffect(() => {
    getData();
  }, [getData]);

  const headers = [
    { key: 'url', title: t('container.media.listnews.image') },
    currentLanguage === 'en'
      ? { key: 'title_en', title: t('container.media.listnews.Title') }
      : { key: 'title_ar', title: t('container.media.listnews.Title') },
    currentLanguage === 'en'
      ? { key: 'description_en', title: t('container.media.listnews.Content') }
      : { key: 'description_ar', title: t('container.media.listnews.Content') },
    { key: 'created', title: t('container.media.listnews.date') },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (!data || data.total_count === 0) {
    return (
      <div className='w-full px-4 py-6'>
        <p className="text-xl font-bold font-en">
          {t('container.media.listnews.News')}
        </p>
        <div className="flex flex-col h-[calc(100vh-300px)] items-center justify-center w-full pt-24 gap-y-8">
          <EmptySearchComponent message='component.Media.No.News' />
          <button
            onClick={() => navigate(appRoutesObj.getCreateNewsLetterPath())}
            className="p-3 text-white bg-orange500 w-[143px] rounded-lg"
          >
            {t('container.media.listnews.createNews')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full px-4 ">
        <div className="flex items-center justify-between py-6">
          <p className="text-xl font-bold font-en">
            {t('container.media.listnews.News')}
          </p>
          <button
            onClick={() => navigate(appRoutesObj.getCreateNewsLetterPath())}
            className="p-3 text-white bg-orange500 w-[143px] rounded-lg"
          >
            {t('container.media.listnews.createNews')}
          </button>
        </div>
        <SharedTableComponent
          headers={headers}
          data={
            data?.result_list.map((news) => ({
              url: news.url,
              title_en: news.title_en,
              title_ar: news.title_ar,
              description_en: news.description_en,
              description_ar: news.description_ar,
              created: news.created ? new Date(news.created).toISOString().split('T')[0] : '',
            })) || []
          }
          renderActions={(rowIndex) => (
            <ActionIcons
              // onEdit={() => handleEdit(data?.result_list[rowIndex].id)}
              onEdit={appRoutesObj.getEditNewsLetterIdPageUrl(
                data?.result_list[rowIndex].id || '',
              )}
              onDelete={() => handleDelete(data?.result_list[rowIndex].id)}
              onView={() => handleView(data?.result_list[rowIndex].id)}
            />
          )}
          loading={isLoading}
          totalCount={totalCount}
          paginationLimit={paginationLimit}
          page={visibleItems}
          onPageChange={setVisibleItems}
        />
      </div>
      {deleteNewsPopup && (
        <ConfirmPopupComponent
          onClose={() => setDeleteNewsPopup(false)}
          title={'container.media.listnews.delete.news'}
          onClick={handleDelete}
          onClickConfirm={deleteNews}
          onSuccess={handleDelete}
        />
      )}
      {isViewMediaVisible && selectedMedia && (
        <ViewMediaComponent
          title={selectedMedia.title}
          content={selectedMedia.content}
          mediaType={selectedMedia.mediaType}
          onClose={() => setIsViewMediaVisible(false)}
          url={selectedMedia.url}
        />
      )}
    </>
  );
}

export default ListNews;
