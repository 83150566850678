import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { postDisruptServise } from 'core/data-access/disrupt.service';
import { handleRequestError } from 'core/helpers/error.helper';
import React, { useState } from 'react';
import { HiOutlinePlus } from 'react-icons/hi';
import { toast } from 'react-toastify';

import NextDisruptInfoPopup from './next-disrupt-info-popup';

function NextDisruptComponent() {
  const [openPopUpConfirm, setOpenPopUpConfirm] = useState<boolean>(false);
  const currentYearStart = new Date().getFullYear();
  const [selectedDateStart, setSelectedDateStart] = useState<string>(
    `${currentYearStart}-01-01T00:00`,
  );
  const handleDateChangeStart = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedDateStart(event.target.value);
  };
  const currentYearEnd = new Date().getFullYear();
  const [selectedDateEnd, setSelectedDateEnd] = useState<string>(
    `${currentYearEnd}-01-01T00:00`,
  );
  const handleDateChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDateEnd(event.target.value);
  };
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      await postDisruptServise({
        start_at: selectedDateStart,
        end_at: selectedDateEnd,
      });
      toast.success('Next disrupt brief has been published successfully!');
    } catch (error) {
      handleRequestError(error);
    }
  };

  const close = () => {
    setOpenPopUp(false);
  };
  const handleConfirmClick = () => {
    setOpenPopUpConfirm(true);
  };
  const handleCloseConfirmPopup = () => {
    setOpenPopUpConfirm(false);
  };

  return (
    <div className="px-8 pt-8">
      <div className="h-full w-full bg-whiteColor rounded-lg px-6 pt-7 pb-[17.5px]">
        <div className="flex flex-row justify-between items-center">
          <p className="text-2xl font-bold text-gray800">Next disrupt</p>
          {/* Button */}
          <Button
            disabled={false}
            width={'w-[164px]'}
            img={<HiOutlinePlus size={24} />}
            hight={'h-[42px]'}
            text={'Next disrupt'}
            fontsize={'text-base'}
            onClick={() => setOpenPopUp(true)}
          />
          {/* Add Next Disrupt Pop-up */}
          {openPopUp ? (
            <NextDisruptInfoPopup
              title="Next Disrupt Info"
              closeModal={() => close()}
            >
              <form
                className="flex flex-col"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleConfirmClick();
                  // formik.handleSubmit();
                }}
              >
                {/* date */}
                <label className="py-2 pb-2 text-[20px] ml-2">Start at</label>
                <input
                  type="datetime-local"
                  id="meeting-time"
                  name="meeting-time"
                  className="h-[50px] border rounded-lg border-gray500 bg-gray-50 px-4"
                  value={selectedDateStart}
                  min={`${currentYearStart}-01-01T00:00`}
                  onChange={handleDateChangeStart}
                />
                {/* Time */}
                <label className="py-2 text-[20px] pt-6 ml-2">End at</label>
                <input
                  type="datetime-local"
                  id="meeting-time"
                  name="meeting-time"
                  className="h-[50px] border rounded-lg border-gray500 bg-gray-50 px-4"
                  value={selectedDateEnd}
                  min={`${currentYearEnd}-01-01T00:00`}
                  onChange={handleDateChangeEnd}
                />
                <div className="flex flex-col items-center justify-center pt-8 pb-2">
                  <input
                    className="w-[305.46px] h-12 text-whiteColor rounded-lg bg-orange500 cursor-pointer"
                    type="submit"
                    value="Publish"
                  />
                </div>
              </form>
            </NextDisruptInfoPopup>
          ) : (
            <></>
          )}
        </div>
        {/* No upcoming disrupt */}
        <div className="flex items-center justify-center gap-10">
          <img
            src="/assets/images/docs/add_event.png"
            className="pt-8 w-[13.05%]"
            alt=""
          ></img>
          <div className="flex flex-col items-center justify-center gap-4 pt-20">
            <p className="text-blue300">
              There is no upcoming scheduled disrupt event!
            </p>
            <p className="text-sm text-blue300">Coming soon</p>
          </div>
        </div>
      </div>
      {openPopUpConfirm && (
        <ConfirmPopupComponent
          onClose={handleCloseConfirmPopup}
          title={'Are you sure you want to publish this disrupt?'}
          subtitle={'If you confirm, disrupt will be publishd.'}
          onClick={handleCloseConfirmPopup}
          onClickConfirm={() => {
            handleSubmit();
            close();
          }}
          onSuccess={() => {
            handleCloseConfirmPopup();
          }}
        />
      )}
    </div>
  );
}

export default NextDisruptComponent;
