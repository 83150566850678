import DisruptNewApplicationsStartups from 'components/admin/disrupt-componet/DisruptNewApplicationsStartups';
import DisruptOldApplicationsStartups from 'components/admin/disrupt-componet/DisruptOldApplicationsStartups';
import TabbarPreviousApplicationsComponet from 'components/admin/TabbarApplicationsComponet';
import { getDisruptStatistics } from 'core/data-access/disrupt.service';
import { DisruptStatisticsModel } from 'core/types/admin.type';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';

function NewApplicationsContainers() {
  const [data, setGetDate] = useState<DisruptStatisticsModel>();

  const getData = useCallback(async () => {
    try {
      const StatisticsData: DisruptStatisticsModel =
        await getDisruptStatistics();
      setGetDate(StatisticsData);
    } catch (error) {
      return error;
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div className="px-5 py-9 desktop:py-7">
      <div className="flex flex-row items-center justify-center px-12 gap-5 mobile:flex-col">
        <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
          <div className="flex flex-col items-center justify-center gap-y-3 pt-5 pb-6">
            <div className="text-4xl font-bold text-orange500">
              {data?.application_count}
            </div>
            <div className="text-[28px] font-bold">{t('allApplications')}</div>
          </div>
        </div>
        <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
          <div className="flex flex-col items-center justify-center gap-y-3 pt-5 pb-6">
            <div className="text-4xl font-bold text-orange500">
              {data?.companies_count}
            </div>
            <div className="text-[28px] font-bold">{t('allstartups')}</div>
          </div>
        </div>
        <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
          <div className="flex flex-col items-center justify-center gap-y-3 pt-5 pb-6">
            <div className="text-4xl font-bold text-orange500">
              {data?.guests_count}
            </div>
            <div className="text-[28px] font-bold">{t('allGuests')}</div>
          </div>
        </div>
      </div>
      <div className="px-2">
        <TabbarPreviousApplicationsComponet
          title1="New Applications"
          title2="Old Applications"
          page={<DisruptNewApplicationsStartups />}
          page2={<DisruptOldApplicationsStartups />}
        />
      </div>
    </div>
  );
}

export default NewApplicationsContainers;
