interface ModalProps {
  hight: string;
  width: string;
  children: JSX.Element | JSX.Element[];
}
function ModalPopupComponent(props: ModalProps) {
  return (
    <div className="fixed z-[1500] top-0 left-0 right-0 bottom-0 w-full h-full bg-black bg-opacity-20">
      <div
        className={`flex flex-col m-auto mt-[15%] ${props.hight} ${props.width} min-h-[241px] max-w-[750px] gap-6 bg-whiteColor p-6 rounded-lg`}
      >
        <div>{props.children}</div>
      </div>
    </div>
  );
}

export default ModalPopupComponent;
