import { makeRequest } from 'core/data-access/http.service';
import { HttpMethods } from 'core/enums/httpMethods.enum';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getQuestionService = (
  searchText: string | null,
  message_status: string,
  limit: number,
  offset: number,
) => {
  return makeRequest({
    url: `${BASE_URL}/contact-us`,
    method: HttpMethods.GET,
    params: { 
      q: searchText, 
      message_status, 
      limit, 
      offset 
    },
  });
};

export const postQuestionService = async (
  new_status: string,
  questionIds: string[],
) => {
  return makeRequest({
    url: `${BASE_URL}/contact-us/toggle`,
    method: HttpMethods.POST,
    params: { new_status },
    data: { ids: questionIds },
  });
};

export const replayQuestionService = async (
  contact_id: string,
  reply: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/contact-us/${contact_id}/reply`,
    method: HttpMethods.POST,
    data: { reply },
  });
};
