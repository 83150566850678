import TabbarEventsReservationComponent from 'components/admin/tabbar-events-reservation-component';

import NewEventsReservationContainer from './new-events-reservation-container';
import OldEventsReservationContainer from './old-events-reservation-container';

function EventsReservationContainer() {
  return (
    <div>
      <TabbarEventsReservationComponent
        page={<OldEventsReservationContainer />}
        page2={<NewEventsReservationContainer />}
      />
    </div>
  );
}

export default EventsReservationContainer;
