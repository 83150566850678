import { useState } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi2';

type InfoComponentsProps = {
  applicationType: string;
  details: Record<string, any>;
};

function InfoComponents({ applicationType, details }: InfoComponentsProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full px-9">
      <div
        className="flex items-center justify-between px-2 border cursor-pointer h-14 border-lightGreeneColor bg-neutralGre50"
        onClick={toggleOpen}
      >
        <p className="text-sm text-lightGreeneColor font-en">
          {applicationType}
        </p>
        <HiOutlineChevronDown
          size={24}
          className={`transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </div>
      {isOpen && (
        <div className="px-2 border-b border-l border-r bg-neutralGre50 border-lightGreeneColor">
          {Object.entries(details).map(([key, value]) => (
            <div key={key} className="flex flex-col py-1 gap-y-2">
              <strong>{key}</strong>
              <p className="text-greyChateau">
                {typeof value === 'string'
                  ? value
                  : JSON.stringify(value) || 'N/A'}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default InfoComponents;
