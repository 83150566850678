import VisitContainer from 'containers/admin/Community/Visit/visit-container';

const VisitPage = () => {
  const token = localStorage.getItem('token') as string;

  return (
    <div>
      {token === null ? (
        <div className="flex items-center justify-center mr-60 pt-32">
          <h1 className="text-[64px]">403 FORBIDDEN</h1>
        </div>
      ) : (
        <VisitContainer />
      )}
    </div>
  );
};

export default VisitPage;
