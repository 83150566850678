import ButtonComponent from 'components/admin/ButtonComponent';
import EmptySearchComponent from 'components/common/empty-search';
import Pagination from 'components/common/Pagination/pagination';
import TeamSkeleton from 'components/common/skeleton-element/team-skeleton';
import PopupEditEmployeeComponent from 'containers/admin/Aboutus/Team/popup-edit-employee-component';
import PopupNewEmployeeComponent from 'containers/admin/Aboutus/Team/popup-new-employee-component';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllTeamModel, TeamModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import * as Icon from 'react-icons/hi';
import { toast } from 'react-toastify';
import * as Services from 'services/team-service';

import ConfirmPopupComponent from './ConfirmPopupComponet';

function TeamDataTableComponet() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newEmployeePopup, setNweEmployeePopup] = useState<boolean>(false);
  const [editEmployeePopup, setEditEmployeePopup] = useState<boolean>(false);
  const [deleteEmployeePopup, setDeleteEmployeePopup] =
    useState<boolean>(false);
  const [acceptPublishPopup, setAcceptPublish] = useState<boolean>(false);
  const [acceptEditedPopup, setAcceptEditedPopup] = useState<boolean>(false);
  const [data, setData] = useState<AllTeamModel>();
  const [deleteEmployeeID, setDeleteEmployeeID] = useState<string>('');
  const [editEmployeeIndex, setEditEmployeeIndex] = useState<number>(0);
  const [editEmployeeID, setEditEmployeeID] = useState<string>('');
  const [val, setVal] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [visibleItems, setVisibleItems] = useState(1);
  const paginationLimit = 7;
  const handleNewEmployeeClick = () => {
    setNweEmployeePopup(true);
  };
  const handleNewClosePopup = () => {
    setNweEmployeePopup(false);
  };
  const handleAcceptPublishClick = () => {
    setAcceptPublish(true);
  };
  const handleAcceptPublishPopup = () => {
    setAcceptPublish(false);
  };
  const handleAcceptEditedClick = () => {
    setAcceptEditedPopup(true);
  };
  const handleAcceptEditedPopup = () => {
    setAcceptEditedPopup(false);
  };
  const handleEditEmployeeClick = () => {
    setEditEmployeePopup(true);
  };
  const handleEditClosePopup = () => {
    setEditEmployeePopup(false);
  };
  const handleDeleteEmployeeClick = () => {
    setDeleteEmployeePopup(true);
  };
  const handleDeleteClosePopup = () => {
    setDeleteEmployeePopup(false);
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const dataTeam: AllTeamModel = await Services.getAllTeamServices(
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
        searchValue,
      );
      setData(dataTeam);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [visibleItems, searchValue]);

  const deleteEmployee = async (employeeID: string) => {
    try {
      await Services.deleteEmployeeServices(employeeID);
      toast.success('employee has been Deleted successfully!');
      getData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="">
      <div className="rounded-lg bg-whiteColor shadow-sm px-10 pt-7">
        <div className={`inline-flex justify-start items-start`}>
          <div className="flex flex-row justify-start items-start gap-2">
            <AiOutlineSearch
              style={{
                marginLeft: '8px',
                marginTop: '12px',
                position: 'absolute',
              }}
              color="#CCD0D2"
              size="24px"
            />
            <input
              onChange={(e) => setVal(e.target.value)}
              type="search"
              id="default-search"
              className="w-[573px] largeLaptop:w-[300px] laptop:w-[300px]  tablet:w-full h-12 appearance-none border rounded-lg py-3 pl-8 pr-2 border-neutralGrey bg-[#FAFAFB] text-sm focus:outline-none focus:border-orange500"
              placeholder={t('search') as string}
              required
            />
            <ButtonComponent
              disabled={false}
              width="w-[143px]"
              hight="h-[48px]"
              text={t('search') as string}
              fontsize={'text-base'}
              onClick={() => setSearchValue(val)}
            />
            <div className="h-12 w-[1px] rounded-full bg-greyBorderBottom"></div>
            <button
              onClick={handleNewEmployeeClick}
              className="w-[149px] h-[48px] border border-orange500 text-orange500 flex flex-row items-center justify-center rounded-lg gap-1"
            >
              <Icon.HiOutlinePlusSm className="text-orange500 w-[24px] h-6" />
              New Employee
            </button>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-4 gap-4 items-center w-full py-4">
            <div className="font-bold text-2xl text-center">
              {t('Employee')}
            </div>
            <div className="font-bold text-2xl text-center">
              {t('Position')}
            </div>
            <div className="font-bold text-2xl text-center">{t('Image')}</div>
          </div>
          {isLoading ? (
            <div className="flex flex-col gap-4 w-full items-center">
              {Array(paginationLimit)
                .fill('')
                .map((_, index) => {
                  return <TeamSkeleton key={`skeleton-${index}`} />;
                })}
            </div>
          ) : (
            <div className="h-full flex flex-col gap-4">
              {data?.total_count === 0 ? (
                <div className="flex justify-center mx-auto">
                  <EmptySearchComponent />
                </div>
              ) : (
                data?.result_list?.map((item, index) => (
                  <div
                    key={`team-${index}`}
                    className="bg-aliceBlue rounded-lg grid grid-cols-4 gap-4 items-center"
                  >
                    <div className="text-xl text-center">{item.name_en}</div>
                    <div className="text-xl text-center">
                      {item.position_en}
                    </div>
                    <div className="flex justify-center items-center">
                      <img
                        src={item.image}
                        className="w-14 h-14 rounded-full"
                        alt={item.name_en}
                      />
                    </div>

                    <div className="flex gap-4 justify-center items-center">
                      <div
                        className="flex flex-row gap-1 cursor-pointer justify-center items-center"
                        onClick={() => {
                          setEditEmployeeIndex(index);
                          setEditEmployeeID(item.id || '');
                          handleEditEmployeeClick();
                        }}
                      >
                        <Icon.HiOutlinePencilAlt className="text-xl text-secondaryGreen400" />
                        <span>Edit</span>
                      </div>
                      <div
                        className="flex flex-row gap-1 cursor-pointer justify-center items-center"
                        onClick={() => {
                          setDeleteEmployeeID(item.id || '');
                          handleDeleteEmployeeClick();
                        }}
                      >
                        <Icon.HiOutlineTrash className="text-xl text-red500" />
                        Delete
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
          <div className="pr-5 pt-3 pb-9">
            <Pagination
              totalCount={data?.total_count || 0}
              totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
              page={visibleItems}
              limit={paginationLimit}
              onPageChange={setVisibleItems}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px]">
        {newEmployeePopup && (
          <PopupNewEmployeeComponent
            title={'Add New Employee'}
            onClose={handleNewClosePopup}
            onSuccess={() => {
              handleAcceptPublishClick();
            }}
          />
        )}

        {acceptPublishPopup && (
          <ConfirmPopupComponent
            title={'Are you sure you want to publish this Employee?'}
            subtitle={'If you confirm, employee will be Publish.'}
            onClose={handleAcceptPublishPopup}
            onClick={handleAcceptPublishPopup}
            onClickConfirm={() => {
              getData();
              toast.success('Employee has been published successfully!');
              handleNewClosePopup();
            }}
          />
        )}
        {editEmployeePopup && (
          <PopupEditEmployeeComponent
            title={'Edit Employee'}
            data={data?.result_list[editEmployeeIndex] as TeamModel}
            onClose={handleEditClosePopup}
            id={editEmployeeID}
            onSuccess={() => {
              handleAcceptEditedClick();
            }}
          />
        )}
        {acceptEditedPopup && (
          <ConfirmPopupComponent
            title={'Are you sure you want to edited this Employee?'}
            subtitle={'If you confirm, employee will be Edited.'}
            onClose={handleAcceptEditedPopup}
            onClick={handleAcceptEditedPopup}
            onClickConfirm={() => {
              getData();
              toast.success('Employee has been edited successfully!');
              handleEditClosePopup();
            }}
          />
        )}
        {deleteEmployeePopup && (
          <ConfirmPopupComponent
            onClose={handleDeleteClosePopup}
            title={'Are you sure you want to Delete this employee?'}
            subtitle={'If you confirm, employee will be Deleted.'}
            onClick={handleDeleteClosePopup}
            onClickConfirm={() => {
              deleteEmployee(deleteEmployeeID);
            }}
            onSuccess={handleDeleteClosePopup}
          />
        )}
      </div>
    </div>
  );
}

export default TeamDataTableComponet;
