import { errorStrings } from 'core/statics/error-message';
import {
  AxiosAbortErrorType,
  ErrorType,
  NewStyleErrorType,
  SpaceErrorType,
  ValidationErrorDetailType,
} from 'core/types/error.type';
import { toast } from 'react-toastify';

/**
 * @param error
 * @description
 * @returns error string
 */
export const getErrorMessage = (
  error: string | NewStyleErrorType | ValidationErrorDetailType[],
) => {
  if (!error) {
    return errorStrings.something_went_wrong;
  }

  if (typeof error === 'string') {
    return error;
  }

  if ((error as NewStyleErrorType)?.error_code) {
    const code: string = (error as NewStyleErrorType)?.error_code;

    return getErrorsInArabicTextOnly(code);
  }

  if ((error as ValidationErrorDetailType[]).length > 0) {
    const errors = error as ValidationErrorDetailType[];

    return getValidationErrorMessages(errors);
  }

  return errorStrings.something_went_wrong;
};

/**
 *
 * @param error
 * @description check if the error from user cancellation of the requests
 * to not show toast message
 * @returns boolean
 */
export const isUserAbortedError = (error: unknown) => {
  return (error as AxiosAbortErrorType)?.code === 'ERR_CANCELED';
};

/**
 *
 * @param error
 * @description handles all requests catch errors
 */
export const handleRequestError = (error: unknown) => {
  if (!isUserAbortedError(error)) {
    toast.error(
      getErrorMessage(
        (error as ErrorType)?.response?.data?.detail ||
          (error as SpaceErrorType)?.response?.data?.message,
      ),
    );
  }
};

/**
 *
 * @param error
 * @description checks for backend error codes
 * @returns translated error message
 */
export const getErrorsInArabicTextOnly = (error: string): string => {
  const translation = Object.entries(errorStrings.error_codes).find(
    (item) => item[0] === error,
  );

  return translation && translation?.length > 0
    ? translation[1]
    : errorStrings.something_went_wrong;
};

/**
 * @param error: ValidationErrorType
 * @description combined all error messages
 * @returns error messaged as one string (new line between them)
 */

export const getValidationErrorMessages = (
  errors: ValidationErrorDetailType[],
): string => {
  const messages: string[] = [];
  errors.forEach((err: ValidationErrorDetailType) => {
    messages.push(`${err.loc.splice(1).join(' --> ')}: ${err.msg}`);
  });

  return messages.join(', \n');
};
