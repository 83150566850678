import { truncateText } from 'core/helpers/text.helper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
import { HiOutlineChevronDown } from 'react-icons/hi';

import Pagination from './common/Pagination/pagination';
import TeamSkeletonComponent from './common/skeleton-element/team-skeleton';
import StatusLabel from './StatusLabel';

interface Header {
  key: string; // back-end المفتاح الذي يطابق البيانات في
  title: string; // النص الذي سيعرض كعنوان
}

interface SharedTableComponentProps {
  headers: Header[]; // مصفوفة تحتوي على العناوين (title) والمفاتيح (key)
  data: { [key: string]: any }[] | []; // البيانات عبارة عن مصفوفة من الكائنات
  hasCheckbox?: boolean;
  renderActions?: (rowIndex: number) => JSX.Element; // دالة لإعادة JSX للأكشنز
  renderStatus?: (rowIndex: number) => JSX.Element; // دالة لإعادة JSX لحالة كل صف
  renderAdditionalInfo?: (rowIndex: number) => JSX.Element;
  loading: boolean;
  totalCount?: number; // العدد الإجمالي للعناصر
  paginationLimit?: number; // عدد العناصر لكل صفحة
  onPageChange?: (page: number) => void; // دالة لتغيير الصفحة
  page?: number;
  hasChevron?: boolean;
}

function SharedTableComponent({
  headers,
  data,
  hasCheckbox,
  renderActions,
  renderStatus,
  renderAdditionalInfo,
  loading = false,
  totalCount = 0,
  paginationLimit = 7,
  onPageChange = () => {
    // TODO: Implement this function later
  },
  page = 1,
  hasChevron = false,
}: SharedTableComponentProps) {
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);


  const toggleRowExpansion = (index: number) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
    setIsOpen(!isOpen);
  };

  if (loading) {
    return (
      <div className="mx-4 overflow-x-auto">
        {Array.from({ length: 7 }).map((_, index) => (
          <TeamSkeletonComponent key={index} />
        ))}
      </div>
    );
  }

  return (
    <>
      <div className="max-h-auto min-h-[650px] px-3 py-2 mt-4 overflow-x-auto bg-white rounded-lg">
        <table className="w-full min-w-full">
          <thead className="text-lg font-bold tracking-wider border-b tablet:text-xs mobile:text-xs whitespace-nowrap h-14 text-gray800 font-en">
            <tr>
              {hasCheckbox && (
                <th>
                  <input type="checkbox" />
                </th>
              )}
              {headers.map((header, index) => (
                <th key={index}>{header.title}</th>
              ))}
              {renderStatus && <th>State</th>}
              {renderActions && <th>{t("component.table.date")}</th>}
              {renderAdditionalInfo && hasChevron && <th></th>}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <React.Fragment key={`row-${rowIndex}`}>
                <tr className="items-center justify-center h-16 mx-auto text-center border-y tablet:text-xs mobile:text-xs">
                  {hasCheckbox && (
                    <td>
                      <input type="checkbox" />
                    </td>
                  )}
                  {headers.map((header, cellIndex) => (
                    <td key={cellIndex} className="relative">
                      {header.key === 'url' ? (
                        <div className="flex flex-col items-center">
                          <img
                            src={row[header.key]}
                            alt="Item"
                            className="w-8 h-8 rounded-full "
                          />
                        </div>
                      ) : (
                        (<p className='text-blue300'>{row[header.key] !== null && row[header.key] !== undefined
                          ? truncateText(row[header.key].toString(), 15)
                          : '-'}</p> )
                      )}
                    </td>
                  ))}
                  {renderStatus && (
                    <td className="h-full">
                      <StatusLabel status={data[rowIndex].application_state} />
                    </td>
                  )}
                  {renderActions && <td>{renderActions(rowIndex)}</td>}
                  {hasChevron && (
                    <td>
                      <HiOutlineChevronDown
                        size={24}
                        onClick={() => toggleRowExpansion(rowIndex)}
                        className={`transition-transform duration-200 text-2xl mobile:text-xs
                      ${expandedRows.includes(rowIndex) && isOpen
                            ? 'transform rotate-180'
                            : ''
                          }`}
                      />
                    </td>
                  )}
                </tr>
                {expandedRows.includes(rowIndex) && renderAdditionalInfo && (
                  <tr>
                    <td
                      colSpan={headers.length + (hasCheckbox ? 6 : 3)}
                      className="w-full px-4 py-4 table:px-0"
                    >
                      {renderAdditionalInfo(rowIndex) || <></>}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mx-auto mt-6">
        <Pagination
          totalPage={Math.ceil(totalCount / paginationLimit)}
          page={page}
          limit={paginationLimit}
          onPageChange={onPageChange}
          totalCount={totalCount}
        />
      </div>
    </>
  );
}

export default SharedTableComponent;
