import IncubatorStatisticssComponent from 'components/admin/incubator_components/incubator-statistics-component';
import IncubatorNewApplication from 'components/admin/incubator_components/IncubatorNewApplication';
import TabbarPreviousApplicationsComponet from 'components/admin/TabbarApplicationsComponet';
import { useTranslation } from 'react-i18next';

import IncubatorOldApplication from '../../../../components/admin/incubator_components/IncuabtorOldApplication';

function IncubatorContainer() {
  const { t } = useTranslation();

  return (
    <div className="">
      <IncubatorStatisticssComponent />
      <div className="px-2">
        <TabbarPreviousApplicationsComponet
          page={<IncubatorNewApplication />}
          page2={<IncubatorOldApplication />}
          title1={t('newapplications')}
          title2={t('oldapplications')}
        />
      </div>
    </div>
  );
}

export default IncubatorContainer;
