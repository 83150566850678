interface Values {
  titel: string;
}
function TitleCardComponet(Props: Values) {
  return (
    <div className=" flex flex-row items-center px-3 bg-whiteColor h-[184px] rounded-lg">
      <div className="flex flex-row items-center justify-between px-5 rounded-lg bg-slate50 w-full h-[138px]">
        <p className="text-5xl text-secondaryGreen400 pl-9">{Props.titel}</p>
      </div>
    </div>
  );
}

export default TitleCardComponet;
