import VisitsComponent from 'components/admin/Community/visit/visit-component';
import NewVisitContainer from 'containers/admin/Community/Visit/new-visit-container';
import OldVisitContainer from 'containers/admin/Community/Visit/old-visit-container';

function VisitContainer() {
  return (
    <div>
      <VisitsComponent
        page={<OldVisitContainer />}
        page2={<NewVisitContainer />}
      />
    </div>
  );
}

export default VisitContainer;
