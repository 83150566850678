import 'react-datepicker/dist/react-datepicker.css';

import BarChart from 'components/admin/booking/bar-chart';
import DoughnutChart from 'components/admin/booking/DoughnutChart';
import { StatisticsBookingTypeEnum } from 'core/enums/example.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { BookingAvailableModel } from 'core/types/booking-management.type';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { FiSettings } from 'react-icons/fi';
import { RiReservedLine } from 'react-icons/ri';
import { RxCalendar } from 'react-icons/rx';
import {
  getBookingAvailableServices,
  getBookingStatisticsServices,
} from 'services/booking-management-services';

import BookingManagementContainer from './booking-management-container';

type DateInputProps = {
  startDate: Date | null;
  onSelect: (date: Date | null) => void;
};

function BookingContiner({ startDate, onSelect }: DateInputProps) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [BookingManagementPopup, setBookingManagementPopup] =
    useState<boolean>(false);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  // const [data, setData] = useState();

  const [dataWeekly, setDataWeekly] = useState<{
    labels: string[];
    data: number[];
  }>({ labels: [], data: [] });
  const [dataMonthly, setDataMonthly] = useState<{
    labels: string[];
    data: number[];
  }>({ labels: [], data: [] });
  const [activeTab, setActiveTab] = useState<'weekly' | 'monthly'>('weekly');
  const [data, setData] = useState<BookingAvailableModel>();

  const handleAcceptEditedClick = () => {
    setBookingManagementPopup(true);
  };

  const handleDateClick = () => {
    setDatePickerOpen((prev) => !prev);
  };

  const handleDateChange = (date: Date | null) => {
    onSelect(date);
    setDatePickerOpen(false);
  };

  const handleClosePopup = () => {
    setBookingManagementPopup(false);
  };

  const FetchBookingStatisticsData = useCallback(
    async (StatisticsBookingType: StatisticsBookingTypeEnum) => {
      const baseDate = new Date().toISOString().split('T')[0];
      try {
        const response = await getBookingStatisticsServices(
          StatisticsBookingType,
          startDate ? startDate.toISOString().split('T')[0] : baseDate,
        );

        const weeklyLabels: string[] = [];
        const weeklyData: number[] = [];
        const monthlyLabels: string[] = [];
        const monthlyData: number[] = [];

        response.forEach((item: any) => {
          weeklyLabels.push('Room' + ' ' + item.resource_number.toString());
          weeklyData.push(item.booked);
          monthlyLabels.push('Room' + ' ' + item.resource_number.toString());
          monthlyData.push(item.booked);
        });

        setDataWeekly({ labels: weeklyLabels, data: weeklyData });
        setDataMonthly({ labels: monthlyLabels, data: monthlyData });
        setLoading(false);
      } catch (error) {
        handleRequestError(error);
      }
    },
    [startDate],
  );

  const FetchBookingAvailableData = useCallback(async () => {
    try {
      const res = await getBookingAvailableServices();
      setData(res);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  const handleTabChange = (tab: 'weekly' | 'monthly') => {
    setActiveTab(tab);
    FetchBookingStatisticsData(
      tab === 'weekly'
        ? StatisticsBookingTypeEnum.WEEKLY
        : StatisticsBookingTypeEnum.MONTHLY,
    );
  };

  useEffect(() => {
    FetchBookingAvailableData();
    FetchBookingStatisticsData(
      activeTab === 'weekly'
        ? StatisticsBookingTypeEnum.WEEKLY
        : StatisticsBookingTypeEnum.MONTHLY,
    );
  }, [activeTab, FetchBookingStatisticsData, startDate]);


  return (
    <div className="w-full px-4 pt-4">
      <div className="flex flex-wrap items-center justify-between w-full gap-2">
        <p className="text-xl font-bold">{t('ManageRoomBookings')}</p>
        <div className="flex flex-wrap gap-2">
          <button
            onClick={handleAcceptEditedClick}
            className="px-3 py-2 bg-white border rounded-lg border-orange500 text-orange500"
          >
            <div className="flex items-center justify-center gap-2">
              <p className="text-base font-en">{t('BookingManagement')}</p>
              <FiSettings size={24} />
            </div>
          </button>
          <div className="relative">
            <button
              onClick={handleDateClick}
              className="px-3 py-2 text-white border rounded-lg bg-orange500"
            >
              <div className="flex items-center justify-center gap-2">
                <p className="text-base font-en">{t('Date')}</p>
                <RxCalendar size={24} />
              </div>
            </button>
            {datePickerOpen && (
              <div
                className={`absolute z-10 mt-2 ${i18n.language === 'en' ? `right-0.5` : `left-0.5`
                  }`}
              >
                <ReactDatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate || new Date()}
                  inline
                  calendarClassName="custom-calendar"
                  popperPlacement="bottom-start"
                  dayClassName={(date: Date) => {
                    const isStartDate =
                      startDate && date.getTime() === startDate.getTime();
                    if (isStartDate) return 'orange-border-day';
                    return '';
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex w-full gap-6 py-4">
        <div className="w-full bg-white rounded-xl tablet:w-[60%] min-w-[450px]">
          <BarChart
            dataWeekly={dataWeekly}
            dataMonthly={dataMonthly}
            onTabChange={handleTabChange}
            loading={loading} />
        </div>
        <div className="flex flex-col w-full gap-y-6">
          <div className="h-[265px] w-full bg-white rounded-xl ">
            <div className="p-5 text-base font-bold capitalize font-en text-blue500">
              {t('AvailableRooms')}
            </div>
            <DoughnutChart
              data={[data?.available_rooms || 0, data?.unavailable_rooms || 0]}
              rooms={data?.available_rooms || 0}
              loading={loading}
              noData={data?.available_rooms === undefined} />
          </div>
          <div className="flex flex-col w-full px-6 py-6 bg-white rounded-xl">
            {loading ? (
              <div className="flex flex-col gap-4 animate-pulse">
                <div className="w-32 h-6 bg-gray-200 rounded-full" />
                <div className="flex gap-3">
                  <div className="w-12 h-12 bg-gray-200 rounded-full" />
                  <div className="flex flex-col gap-2">
                    <div className="w-40 h-8 bg-gray-200 rounded-full" />
                    <div className="w-24 h-6 bg-gray-200 rounded-full" />
                  </div>
                </div>
              </div>
            ) : data?.most_booked ? (
              <>
                <p className="text-base font-bold text-gray-800 capitalize">
                  Most Booked
                </p>
                <div className="flex items-center gap-3 pt-4 pb-2 text-center">
                  <RiReservedLine size={45} className="text-[#50C5B1]" />
                  <p className="text-4xl font-bold text-gray-900 font-en">
                    Room {data?.most_booked}
                  </p>
                </div>
                <p className="text-[#989DA3] text-sm font-normal">
                  Last Booking {data?.last_booked}
                </p>
              </>
            ) : (
              <p className="py-10 text-base font-bold text-center text-gray-800">
                No data available for the most booked room.
              </p>
            )}

          </div>
        </div>
      </div>
      {BookingManagementPopup && (
        <BookingManagementContainer onClose={handleClosePopup} />
      )}
    </div>
  );
}

export default BookingContiner;
