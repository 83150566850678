import { useEffect, useState } from 'react';

const useCountdown = (targetDate: number) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};
export { useCountdown };

export function convertTo12HourFormat(isoString: string): string {
  const date = new Date(isoString);
  let hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour >= 12 ? 'PM' : 'AM';

  hour = hour % 12 || 12;
  const formattedHour = hour < 10 ? `0${hour}` : hour; // تأكد من أن الساعة بصيغة ساعتين
  const formattedMinute = minute < 10 ? `0${minute}` : minute;

  return `${formattedHour}:${formattedMinute} ${period}`;
}

export function convertTimeToDateTime(
  time: string,
  baseDate: string = new Date().toISOString(),
  timeZoneOffset = 0,
): string {
  const [hours, minutes, period] =
    time.match(/(\d+):(\d+)\s(AM|PM)/)?.slice(1) || []; // Extract matched groups
  const date = new Date(baseDate);

  if (hours && minutes && period) {
    const hoursIn24 =
      period === 'AM' ? parseInt(hours) % 12 : (parseInt(hours) % 12) + 12;
    date.setHours(hoursIn24 + timeZoneOffset); // Add time zone offset
    date.setMinutes(parseInt(minutes));
  }

  return date.toISOString();
}
