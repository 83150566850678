import Accordion, {
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react/components/Accordion';
import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import Textarea1 from 'components/admin/textarea';
import { handleRequestError } from 'core/helpers/error.helper';
import { IssueModel } from 'core/types/admin.type';
import { Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { MdOutlineMarkEmailUnread } from 'react-icons/md';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import * as Yup from 'yup';

interface MessageNewEmailIssueComponetProps {
  handleAddId: (id: string) => void;
  checkedIds: string[];
  list: IssueModel[];
  textAreaVal: string | undefined;
  handleSendPost: (id: string) => void;
  textAreaOnChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

function MessageNewEmailIssueComponet(
  props: MessageNewEmailIssueComponetProps,
) {
  const [reasonCount, setReasonCount] = useState(0);
  const [open, setOpen] = useState(0);
  const [isOpen, setIsOpen] = useState<number[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [ids, setIds] = useState<string>();

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleAcceptClick = (id?: string) => {
    setShowPopup(true);
    setIds(id);
  };

  const handleOpen = (value: number) => {
    let UpdatedOpen = [];
    if (isOpen.includes(value)) {
      UpdatedOpen = isOpen.filter((item) => item !== value);
      setIsOpen(UpdatedOpen);
    } else {
      if (open === 5) {
        setOpen(value);
        isOpen.push(value);
        UpdatedOpen = [...isOpen];
        setIsOpen(UpdatedOpen);
      } else {
        UpdatedOpen = [...isOpen];
        UpdatedOpen.push(value);
        setIsOpen(UpdatedOpen);
      }
    }
  };
  const validationSchema = Yup.object({
    answer: Yup.string()
      .min(2, 'The content must be at least 2 characters')
      .max(250, 'Too Long!'),
  });

  const initialValues = {
    answer: '',
  };

  const handleButtonClick = (id: string) => {
    postEmployee(id);
  };
  const postEmployee = async (id: string) => {
    try {
      handleAcceptClick(id);
    } catch (error) {
      handleRequestError(error);
    }
  };

  const Icon = (id: number) => {
    return (
      <>
        {isOpen.includes(id) ? <SlArrowUp /> : <SlArrowDown color="#402C5B" />}
      </>
    );
  };

  return (
    <div className="flex w-full flex-col gap-1">
      {props.list?.map((item, index) => {
        return (
          <div key={index}>
            <Accordion
              open={isOpen.includes(index)}
              icon={
                isOpen.includes(index) ? (
                  <div
                    className="flex bg gap-4 items-center justify-center"
                    onClick={() => handleOpen(index)}
                  >
                    <p className="text-blue-gray-200 text-xs">
                      {moment(item.created).format('MMM Do')}
                    </p>
                    <SlArrowUp className=" text-[#402C5B]" />
                  </div>
                ) : (
                  <div
                    className="flex gap-4 items-center justify-center"
                    onClick={() => handleOpen(index)}
                  >
                    <p className=" text-blue-gray-200 text-xs">
                      {moment(item.created).format('MMM Do')}
                    </p>
                    {Icon(index)}
                  </div>
                )
              }
              className={'rounded-lg bg-slate50'}
            >
              <div className="bg-slate50 desktop:pl-4 rounded-md pr-12 pl-3">
                <div className="w-full">
                  <AccordionHeader
                    className={`border-b-0 py-2 desktop:py-3 text-base font-en  ${
                      open === 1
                    }`}
                  >
                    <div className="flex items-center justify-center gap-3">
                      <div className="flex">
                        <div className="flex items-center" key={item.id}>
                          <input
                            id={item.id}
                            checked={Boolean(
                              props.checkedIds.find((id) => id === item.id),
                            )}
                            type="checkbox"
                            onChange={() => props.handleAddId(item.id || '')}
                          />
                          {isOpen.includes(index) ? (
                            <HiOutlineMailOpen className="ml-[9.109px] text-orange500 h-[18.217px] w-[18.212px]" />
                          ) : (
                            <MdOutlineMarkEmailUnread className="ml-[9.109px] text-orange500 h-[18.217px] w-[18.212px]" />
                          )}
                        </div>
                      </div>
                      {isOpen.includes(index) ? (
                        ''
                      ) : (
                        <div className="flex flex-col">
                          <p className="text-xs desktop:text-sm text-blue400 font-normal truncate">
                            {item.details.slice(0, 21) + ' ...'}
                          </p>
                        </div>
                      )}
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="pt-0 text-base font-normal font-title1 text-darkpurpleColor">
                    <Formik
                      initialValues={initialValues}
                      onSubmit={() => handleButtonClick(item.id)}
                      validationSchema={validationSchema}
                      enableReinitialize
                    >
                      {({
                        handleChange,
                        touched,
                        setFieldValue,
                        handleSubmit,
                        errors,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="flex flex-col gap-2">
                            <p className="text-xs text-gray-600 font-normal pt-1">
                              {item.details}
                            </p>

                            {item.image_url.toString() === '' ? (
                              []
                            ) : (
                              <img
                                className="w-12 h-12 rounded-lg"
                                src={`${item.image_url}`}
                                alt=""
                              />
                            )}

                            <p className=" text-sm text-secondaryGreen400 font-normal">
                              #{item.browser},#{item.operating_system}
                            </p>
                          </div>
                          <div className="flex flex-row items-end">
                            <p className="flex text-base pt-4">
                              Sender Email :
                            </p>
                            <div className="ml-4 text-lightGray100">
                              {item.email}
                            </div>
                          </div>

                          <div className="flex pt-2 flex-col">
                            {item.reply === null ? (
                              <>
                                <textarea
                                  id="answer"
                                  value={props.textAreaVal}
                                  className={`${
                                    touched.answer && errors.answer
                                      ? 'border-red-400'
                                      : 'border-[#CCD0D2]'
                                  } border text-left pl-4 border-[#CCD0D2] placeholder:text-sm placeholder:pt-2 pt-2 focus:outline-none focus:shadow-none resize-none text-black text-xl rounded-lg w-full h-[182px] bg-gray-50`}
                                  placeholder="Enter the answer for this issue..."
                                  onChange={(e) => {
                                    props.textAreaOnChange(e);
                                    handleChange(e);
                                    setFieldValue('answer', e.target.value);
                                    setReasonCount(e.target.value.length);
                                  }}
                                  name="answer"
                                />
                                <p
                                  className={`${
                                    touched.answer && errors.answer
                                      ? 'text-red-400'
                                      : 'text-gray500'
                                  } pl-[954px] pt-0`}
                                >{`${reasonCount}/250`}</p>
                              </>
                            ) : (
                              <>
                                <Textarea1
                                  id={item.id}
                                  value={item.reply}
                                  label={''}
                                  placeholder="Enter the answer for this issue..."
                                  onChange={() => props.textAreaOnChange}
                                  disabled={true}
                                  className=" text-gray-600 bg-gray-600"
                                />
                                <p
                                  className={`${
                                    touched.answer && errors.answer
                                      ? 'text-red-400'
                                      : 'text-gray-500'
                                  } pl-[954px] pt-0`}
                                >{`${reasonCount}/250`}</p>
                              </>
                            )}
                          </div>
                          <div className="items-end text-right pt-3 gap-3">
                            {item.reply !== null ? (
                              <>
                                <Button
                                  disabled={true}
                                  width={'w-[143px]'}
                                  hight={'h-[48px]'}
                                  text={'Send'}
                                  fontsize={'text-base'}
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  disabled={false}
                                  width={'w-[143px]'}
                                  hight={'h-[48px]'}
                                  text={'Send'}
                                  fontsize={'text-base'}
                                />
                              </>
                            )}
                          </div>
                        </form>
                      )}
                    </Formik>
                  </AccordionBody>
                </div>
              </div>
            </Accordion>
          </div>
        );
      })}
      {/* Popup to SEND */}
      <div className="absolute top-[400px] right-2">
        {showPopup && (
          <ConfirmPopupComponent
            onClose={handleClosePopup}
            title={'Are you sure you want to Send this Issue? '}
            subtitle={'If you confirm, Issue will be Send & Publish.'}
            onClick={handleClosePopup}
            onClickConfirm={() => props.handleSendPost(ids || '')}
          />
        )}
      </div>
    </div>
  );
}

export default MessageNewEmailIssueComponet;
