export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  Number: string;
}

export function Box({ ...props }: InputProps) {
  return (
    <div className="flex-row items-center w-full">
      <div className="flex flex-col w-full h-full py-3 desktop:py-6 bg-white rounded-xl shadow items-center justify-center">
        <div className="text-orange400 font-bold font-sans text-[40px]">
          {props.Number}
        </div>
        <div className="text-blue500 font-bold font-sans text-[28px] capitalize">
          {props.label}
        </div>
      </div>
    </div>
  );
}

export default Box;
