import Accordion, {
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react/components/Accordion';
import Button from 'components/admin/ButtonComponent';
import { QuestionModel } from 'core/types/user.type';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';

import ConfirmPopupComponent from './ConfirmPopupComponet';
import Textarea1 from './textarea';

interface opendProps {
  handleAddId: (id: string) => void;
  checkedIds: string[];
  list: QuestionModel[];
  textAreaVal: string | undefined;
  handleSendPost: (id: string) => void;
  textAreaOnChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
function MessageOpenedEmailComponet(props: opendProps) {
  const [showPopup, setShowPopup] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleAcceptClick = () => {
    setShowPopup(true);
  };

  const { t } = useTranslation();
  const [open, setOpen] = useState(0);
  const [isOpen, setIsOpen] = useState<number[]>([]);
  const handleOpen = (value: number) => {
    let UpdatedOpen = [];
    if (isOpen.includes(value)) {
      UpdatedOpen = isOpen.filter((item) => item !== value);
      setIsOpen(UpdatedOpen);
    } else {
      if (open === 5) {
        setOpen(value);
        isOpen.push(value);
        UpdatedOpen = [...isOpen];
        setIsOpen(UpdatedOpen);
      } else {
        UpdatedOpen = [...isOpen];
        UpdatedOpen.push(value);
        setIsOpen(UpdatedOpen);
      }
    }
  };

  const Icon = (id: number) => {
    return (
      <>
        {isOpen.includes(id) ? <SlArrowUp /> : <SlArrowDown color="#402C5B" />}
      </>
    );
  };

  return (
    <div className="flex w-full flex-col gap-1">
      {props.list?.map((item, index) => {
        return (
          <div key={index}>
            <Accordion
              open={isOpen.includes(index)}
              icon={
                isOpen.includes(index) ? (
                  <div
                    className="flex gap-4 items-center justify-center"
                    onClick={() => handleOpen(index)}
                  >
                    <p className=" text-blue-gray-200 text-xs">
                      {moment(item.created).format('Do MMM')}
                    </p>
                    <SlArrowUp color="#402C5B" />
                  </div>
                ) : (
                  <div
                    className="flex gap-4 items-center justify-center"
                    onClick={() => handleOpen(index)}
                  >
                    <p className=" text-blue-gray-200 text-xs">
                      {moment(item.created).format('Do MMM')}
                    </p>
                    {Icon(index)}
                  </div>
                )
              }
              className={'rounded-lg bg-slate50'}
            >
              <div className="bg-slate50 desktop:pl-4 rounded-md pr-12 pl-3">
                <div className="w-full">
                  <AccordionHeader
                    className={`border-b-0 py-2 desktop:py-3 text-base font-en  ${
                      open === 1
                    }`}
                  >
                    <div className="flex items-center justify-center gap-3">
                      <div className="flex">
                        <div className="flex items-center" key={item.id}>
                          <input
                            id={item.id}
                            checked={Boolean(
                              props.checkedIds.find((id) => id === item.id),
                            )}
                            type="checkbox"
                            onChange={() => props.handleAddId(item.id || '')}
                          />
                        </div>
                      </div>
                      <HiOutlineMailOpen className="text-orange500 text-xl" />
                      <div className="flex flex-col">
                        {t(item.title)}
                        <p className="text-xs desktop:text-sm text-gray-600 font-normal">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="pt-0 text-base font-normal font-title1 text-darkpurpleColor ">
                    <div className="flex flex-row items-end">
                      <p className="flex text-base font-en pt-[34px]">
                        Sender Email :
                      </p>
                      <div className="ml-4 text-gray-600">{item.email}</div>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-xs text-gray-600 font-normal pt-5">
                        {item.description}
                      </p>
                    </div>

                    <div className="flex pt-10 flex-col w-[120vh]">
                      {item.reply !== null ? (
                        <Textarea1
                          id={item.id}
                          value={item.reply}
                          label={'Reply'}
                          placeholder="Enter the answer for this questions..."
                          onChange={() => props.textAreaOnChange}
                          disabled={true}
                          className=" text-gray-600 bg-gray-600"
                        />
                      ) : (
                        <Textarea1
                          id={item.id}
                          value={props.textAreaVal}
                          label={'Reply'}
                          placeholder="Enter the answer for this questions..."
                          onChange={(e) => props.textAreaOnChange(e)}
                        />
                      )}
                    </div>
                    <div className="flex justify-end pt-3 gap-3">
                      {item.reply !== null ? (
                        <>
                          <Button
                            disabled={true}
                            width={'w-[143px]'}
                            hight={'h-[48px]'}
                            text={'Send'}
                            fontsize={'text-base'}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            disabled={false}
                            width={'w-[163px]'}
                            hight={'h-[48px]'}
                            text={'Send'}
                            fontsize={'text-base'}
                            onClick={() => handleAcceptClick}
                          />
                        </>
                      )}
                    </div>
                  </AccordionBody>
                </div>
              </div>
            </Accordion>
          </div>
        );
      })}
      {/* Popup to SEND */}
      <div className="absolute top-[400px] right-2">
        {showPopup && (
          <ConfirmPopupComponent
            onClose={handleClosePopup}
            title={'Are you sure you want to Send this question? '}
            subtitle={'If you confirm, question will be send.'}
            onClick={handleClosePopup}
            onClickConfirm={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}
      </div>
    </div>
  );
}

export default MessageOpenedEmailComponet;
