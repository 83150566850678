import GaragePartneContainer from 'containers/admin/Community/Partners/garage-partne-container';

const PartenersPage = () => {
  return (
    <div>
      <GaragePartneContainer />
    </div>
  );
};

export default PartenersPage;
