import { Collapse, List } from '@mui/material';
import { ReactElement, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from 'react-icons/io';
// BsChevronDown
export interface IExportPopupProps {
  title: string;
  subtitle: string;
  programName: string;
  programNumber: number;
  children: ReactElement;
  onClose: () => void;
  onClickConfirm: () => void;
}

export default function ExportPopup(props: IExportPopupProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className="fixed z-[1500] top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-20">
      <div className="w-[750px] h-[340px] rounded-lg bg-whiteColor px-10 pt-9 pb-8">
        <div className="flex items-end justify-end">
          <IoMdClose
            className="w-[24px] h-[24px] cursor-pointer"
            onClick={props.onClose}
          />
        </div>
        <div className="text-xl font-bold">{props.title} Export</div>
        <div className="pt-3 text-sm text-greyChateau">{props.subtitle}</div>
        <div className="pt-6 pb-2">{props.programName} number</div>

        <div
          className={`w-96 h-14 py-2 px-4 bg-neutralGre50 items-center justify-between border border-gray500 inline-flex ${
            isOpen ? 'border border-secondaryGreen400 ' : ''
          }`}
        >
          <span
            className={`${
              isOpen ? 'text-gray800' : 'text-gray500'
            } font-normal`}
          >
            {props.programNumber === 0 ? 'select' : props.programNumber}
          </span>
          <div onClick={() => setIsExpanded(!isExpanded)}>
            {isOpen ? (
              <IoIosArrowUp
                className="text-gray800"
                onClick={() => setIsOpen(!isOpen)}
                size={24}
              />
            ) : (
              <IoIosArrowDown
                className="text-gray800"
                onClick={() => setIsOpen(!isOpen)}
                size={24}
              />
            )}
          </div>
        </div>

        <Collapse
          in={isExpanded}
          timeout="auto"
          className="absolute bg-whiteColor w-96"
        >
          <div className="h-80 w-54 overflow-x-scroll">
            {' '}
            <List>{props.children}</List>
          </div>
        </Collapse>
        <div className="flex flex-row justify-end items-center gap-3 px-3 py-5">
          <button
            className="w-[180px] h-[48px] rounded-lg border border-orange500 bg-whiteColor text-orange500 text-base"
            onClick={props.onClose}
          >
            No, cancel
          </button>

          <button
            className="w-[180px] h-[48px] rounded-lg bg-orange500 text-whiteColor text-base"
            onClick={props.onClickConfirm}
          >
            Yes, confirm
          </button>
        </div>
      </div>
    </div>
  );
}
