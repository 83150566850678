import TabbarPreviousApplicationsComponentProps from 'components/admin/TabbarApplicationsComponet';

import IssueNewComponent from './issue-new-component';
import IssueOpenedComponent from './issue-opened-component';
import IssuesStatisticssComponent from './issue-statisticss-component';

function IssueContainer() {
  return (
    <div className="">
      <IssuesStatisticssComponent />
      <div>
        <TabbarPreviousApplicationsComponentProps
          page={<IssueNewComponent />}
          page2={<IssueOpenedComponent />}
          title1={'New'}
          title2={'Opened'}
        />
      </div>
    </div>
  );
}

export default IssueContainer;
