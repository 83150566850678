import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import Loading from 'components/common/loading';
import SearchComponent from 'containers/admin/Community/Partners/search-component';
import PopupNewStartupComponent from 'containers/admin/Programs/Plus/popup-new-startup-component';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCameraVideo } from 'react-icons/bs';
import {
  HiOutlinePencilAlt,
  HiOutlinePhotograph,
  HiOutlineTrash,
} from 'react-icons/hi';
import { RiFileExcel2Line } from 'react-icons/ri';

const FeaturesList = [
  {
    StartupName: 'Moltizam',
    CohortNo: 'Cohort One',
    Logo: 'Pre-seed',
    Video: 'Test',
  },
  {
    StartupName: 'Moltizam',
    CohortNo: 'Cohort One',
    Logo: 'Pre-seed',
    Video: 'Test',
  },
  {
    StartupName: 'Moltizam',
    CohortNo: 'Cohort One',
    Logo: 'Pre-seed',
    Video: 'Test',
  },
  {
    StartupName: 'Moltizam',
    CohortNo: 'Cohort One',
    Logo: 'Pre-seed',
    Video: 'Test',
  },
  {
    StartupName: 'Moltizam',
    CohortNo: 'Cohort One',
    Logo: 'Pre-seed',
    Video: 'Test',
  },
];
function CohortsPlusStartupComponet() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [toggle, setToggle] = useState(
    new Array(FeaturesList.length).fill(false),
  );
  const [val, setVal] = useState('');
  const [filtered, setFiltered] = useState(FeaturesList);
  const [showPopup, setShowPopup] = useState(false);
  const [newStartup, setNewStartup] = useState(false);
  const handleNewStartupClick = () => {
    setNewStartup(true);
  };
  const handleNewClosePopup = () => {
    setNewStartup(false);
  };
  const [editStartup, setEditStartup] = useState(false);
  const handleEditStartupClick = () => {
    setEditStartup(true);
  };
  const handleEditClosePopup = () => {
    setEditStartup(false);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const [exportPopup, setExportPopup] = useState(false);
  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };
  const [deletPopup, setDeletPopup] = useState(false);
  const handleDeletePartnerClick = () => {
    setDeletPopup(true);
  };
  const handleCloseDeletePopup = () => {
    setDeletPopup(false);
  };
  const [visibleItems, setVisibleItems] = useState(4);
  const [loading, setLoading] = useState(false);
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const element = e.currentTarget;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 4);
      }, 1000);
    }
  };

  const handleSearchClick = () => {
    if (val === '') {
      setFiltered(FeaturesList);
      return;
    }
    const filterBySearch = FeaturesList.filter((item) => {
      item.StartupName.toLowerCase().includes(val.toLowerCase());
    });
    setFiltered(filterBySearch);
  };
  const { t } = useTranslation();

  return (
    <div className="pt-[55px] pl-6">
      <div className="flex flex-col w-[1327px] h-full rounded-lg bg-whiteColor">
        <SearchComponent
          onClickValue={(e) => setVal(e.target.value)}
          onClickShearch={handleSearchClick}
          onClick={handleNewStartupClick}
        />
        <div className="flex flex-col">
          <div className="flex flex-row pb-2 justify-evenly">
            <div className="w-[200px] font-bold ml-4 text-2xl">
              {t('startupName')}
            </div>
            <div className="w-[200px] font-bold text-2xl">
              {t('CohortNo .')}
            </div>
            <div className="w-[200px] font-bold text-2xl">{t('Logo')}</div>
            <div className="w-[250px] font-bold text-2xl">{t('Video')}</div>
            <div className="w-[50px]"></div>
          </div>
          <div
            className="h-[384px] overflow-y-auto  mb-3 scrollbar"
            onScroll={handleScroll}
          >
            {filtered.slice(0, visibleItems).map((item, index) => (
              <div key={index} className="flex flex-col items-center py-2">
                <div className="flex flex-row items-center px-10 justify-evenly">
                  <div
                    className={`flex flex-row bg-aliceBlue w-[1254px] h-20 justify-evenly items-center rounded-t-lg ${
                      toggle[index] ? '' : 'rounded-b-lg'
                    }`}
                  >
                    <div className="w-[200px] flex justify-center items-center text-[20px]">
                      {item.StartupName}
                    </div>
                    <div className="flex w-[200px] justify-center items-center pl-[55px] text-[20px]">
                      {item.CohortNo}
                    </div>
                    <div className="flex w-[250px] justify-center items-center cursor-pointer pl-5">
                      <HiOutlinePhotograph className="text-orange500 w-[24px] h-6" />
                    </div>
                    <div className="flex w-[245px] justify-center items-center cursor-pointer pl-5">
                      <BsCameraVideo className="text-orange500 w-[24px] h-6" />
                    </div>
                    <div className="flex flex-row gap-9">
                      <div
                        className="flex flex-row gap-1 cursor-pointer"
                        onClick={handleEditStartupClick}
                      >
                        <HiOutlinePencilAlt className="text-[25px] text-secondaryGreen400" />
                        <p className="">Edit</p>
                      </div>
                      <div
                        className="flex flex-row gap-1 cursor-pointer"
                        onClick={handleDeletePartnerClick}
                      >
                        <HiOutlineTrash className="text-[25px] text-red500" />
                        Delete
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
                {loading && (
                  <div className="fixed flex items-center justify-center inset-[10px]">
                    <Loading />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-end  px-5 py-2.5">
          <Button
            disabled={false}
            width={'w-[305px]'}
            hight={'h-12'}
            text={'Export All'}
            img={<RiFileExcel2Line size={24} />}
            fontsize={'text-base'}
            onClick={handleExportClick}
          />
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {showPopup && (
          <ConfirmPopupComponent
            onClose={handleClosePopup}
            title={'Are you sure you want to accept this startup application? '}
            subtitle={'If you confirm, the startup application will be accept.'}
            onClick={handleClosePopup}
            onClickConfirm={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}
        {deletPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseDeletePopup}
            title={'Are you sure you want to delete this Startup?'}
            subtitle={'If you confirm, startup will be delete.'}
            onClick={handleCloseDeletePopup}
            onClickConfirm={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}
        {/* export exail file */}
        {exportPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseExportPopup}
            title={'Are you sure you want to Export All?'}
            subtitle={
              'If you confirm, all forms on this page will be downloaded.'
            }
            onClick={handleCloseExportPopup}
            onClickConfirm={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}

        {newStartup && (
          <PopupNewStartupComponent
            title={'New Startup'}
            onClose={handleNewClosePopup}
          />
        )}
        {editStartup && (
          <PopupNewStartupComponent
            title={'New Startup'}
            onClose={handleEditClosePopup}
          />
        )}
      </div>
    </div>
  );
}
export default CohortsPlusStartupComponet;
