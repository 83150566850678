import { BASE_URL, makeRequest } from "core/data-access/http.service";
import { HttpMethods } from "core/enums/httpMethods.enum";


export const getExportPun = () => {
    return makeRequest({
      url: `${BASE_URL}/product/export`,
      method: HttpMethods.GET,
    });
  };


  export const getPNUApplication = (
  
    limit: number,
   
    offset: number,
    searchText: string | null,
  ) => {
    return makeRequest({
      url: `${BASE_URL}/product`,
      method: HttpMethods.GET,
      params: {
          
        q: searchText,
        limit: limit,
        offset: offset,
      }
    });
  };

  