import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  data: number[];
  rooms: number;
  loading: boolean;
  noData: boolean;
}

const DoughnutChart = (props: DoughnutChartProps) => {
  const { t } = useTranslation();

  const data = {
    datasets: [
      {
        data: props.data,
        backgroundColor: ['#50C5B1', '#EEF9F7'],
      },
    ],
  };


  const options = {
    responsive: true,
    cutout: '65%',
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        align: 'start' as const,
        labels: {
          boxWidth: 20,
          padding: 10,
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className="relative w-[179px] mx-auto">
      {props.loading ? (
        <div className="flex flex-col items-center animate-pulse gap-y-4">
          <div className="w-40 h-40 bg-gray-200 rounded-full" />
        </div>
      ) : props.noData ? (
        <div className='flex flex-col items-center gap-y-4'>
          <img className='w-64 h-64' src="/assets/images/notFound.png" alt="not found" />
          <div className="w-full h-full text-3xl text-center text-blue500">لا يوجد بيانات</div>
        </div>
      ) : (
        <Doughnut data={data} options={options} />
      )}
      <div className="absolute inset-0 flex items-center justify-center top-2">
        <div className="text-center">
          {props.loading ? (
            <div className="flex flex-col items-center animate-pulse gap-y-4">
            </div>
          ) : (
            <div>
              <span className="block text-2xl font-bold font-en">
                {props.rooms}
              </span>
              <span className="block text-base font-bold font-en">{t("Rooms")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
