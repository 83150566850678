import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import {
  getDisruptStatus,
  postDisruptPublishServise,
  postDisruptUnpublishServise,
} from 'core/data-access/disrupt.service';
import { handleRequestError } from 'core/helpers/error.helper';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import RecevingFormComponent from '../receving-form-component';

function PublishDisruptUnpublishDisrupt() {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [isopen, setIsOpen] = useState(false);
  const handleRecivingClosePopup = () => {
    setEnabled(false);
  };

  const handleRecivingEnablePopup2 = () => {
    setEnabled(true);
  };

  const DistruptStatus = useCallback(async () => {
    try {
      const result = await getDisruptStatus();
      setIsOpen(result.is_open);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);
  const PublishDisrupt = async () => {
    try {
      await postDisruptPublishServise();
      toast.success('Receiving forms has been started successfully.');
      DistruptStatus();
    } catch (error) {
      handleRequestError(error);
    }
  };
  const UnPublishDisrupt = async () => {
    try {
      await postDisruptUnpublishServise();
      toast.success('Receiving forms has been stopped successfully.');
      DistruptStatus();
    } catch (error) {
      handleRequestError(error);
    }
  };
  useEffect(() => {
    DistruptStatus();
  }, [DistruptStatus]);
  return (
    <>
      <RecevingFormComponent
        checked={isopen}
        onClick={() => handleRecivingEnablePopup2()}
        programme={t('ReceivingDisrupt')}
      />
      {enabled && (
        <ConfirmPopupComponent
          onClose={handleRecivingClosePopup}
          title={`Are you sure you want to ${
            !isopen ? 'Enable' : 'Stop'
          } Receiving Forms? `}
          subtitle={`If you confirm, application form will ${
            !isopen ? '' : 'not'
          }  appear on the website.`}
          onClick={handleRecivingClosePopup}
          onClickConfirm={() =>
            isopen ? UnPublishDisrupt() : PublishDisrupt()
          }
        />
      )}
    </>
  );
}

export default PublishDisruptUnpublishDisrupt;
