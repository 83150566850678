import { OverViewIncubatorStatistics } from 'core/types/admin.type';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface ChartIncubatorComponentProps {
  data?: OverViewIncubatorStatistics[];
  YAxis: number;
}
function ChartIncubatorComponent(props: ChartIncubatorComponentProps) {
  return (
    <div className="h-[44vh] bg-white shadow rounded-xl">
      <div className="text-blue500 font-bold font-sans text-xl capitalize text-left p-5">
        Incubators History
      </div>

      <ResponsiveContainer width="90%" height="80%">
        <LineChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: -10,
            right: 20,
            left: 10,
            bottom: 30,
          }}
        >
          <CartesianGrid
            horizontal={false}
            vertical={false}
            strokeDasharray="1 0"
          />

          <XAxis
            dataKey="incubator_number"
            axisLine={false}
            tick={{ color: '#989DA3', fontSize: '14px' }}
            tickLine={false}
          />
          <YAxis
            dataKey="props.incubator_max_count"
            ticks={[0, props?.YAxis]}
            axisLine={false}
            tickCount={7}
            tick={{ color: '#989DA3', fontSize: '14px' }}
            tickLine={false}
            padding={{ bottom: 30 }}
            tickMargin={20}
          />
          <Tooltip />
          <Legend
            iconType="rect"
            verticalAlign="top"
            iconSize={20}
            align="left"
            height={60}
            formatter={(value) => (
              <span className=" text-lightGray100 pl-2">{value}</span>
            )}
          />

          <Line
            name="Accepted"
            type="monotone"
            dataKey="accepted_count"
            stroke="#50C5B1"
            strokeWidth={5}
            dot={false}
          />
          <Line
            name="Rejected"
            type="monotone"
            dataKey="rejected_count"
            stroke="#F18966"
            strokeWidth={5}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
export default ChartIncubatorComponent;
