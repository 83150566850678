// import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
// import ButtonAction from 'components/buttonAction';
import InfoComponents from 'components/InfoComponents';
import StatusLabel from 'components/StatusLabel';
import TableComponent from 'components/TableComponent';
import { TransportationPermitsModel } from 'core/data-access/transportation-permits';
import { handleRequestError } from 'core/helpers/error.helper';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { getAllApplicantsTransportationPermitsServices } from 'services/transportation-permits';

import SearchComponent from './admin/Community/Partners/search-component';

const headers = [
  { key: 'name', title: 'Name' },
  { key: 'nationality', title: 'Nationality' },
  { key: 'vehicle', title: 'vehicle' },
  { key: 'equipment', title: 'equipment' },
];

type ApplicationState = 'ACCEPTED' | 'REJECTED';

function SharedTableContainer() {
  const [permitsData, setPermitsData] = useState<TransportationPermitsModel>();
  const [val, setVal] = useState<string>('');
  const [isLoading, setSkeletonData] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const paginationLimit = 7;
  // const [, setID] = useState('');

  // const [popupState, setPopupState] = useState({
  //   acceptPopup: false,
  //   rejectPopup: false,
  //   selectedID: '',
  // });

  // const handleEnableAcceptPopup = () => {
  //   setPopupState({
  //     ...popupState,
  //     acceptPopup: true,
  //   });
  // };

  // const handleEnableRejectPopup = () => {
  //   setPopupState({
  //     ...popupState,
  //     rejectPopup: true,
  //   });
  // };

  // const handleCloseAcceptPopup = () => {
  //   setPopupState({
  //     ...popupState,
  //     acceptPopup: false,
  //   });
  // };

  // const handleCloseRejectPopup = () => {
  //   setPopupState({
  //     ...popupState,
  //     rejectPopup: false,
  //   });
  // };

  const getData = useCallback(async () => {
    try {
      const permitsData: TransportationPermitsModel =
        await getAllApplicantsTransportationPermitsServices(
          false,
          paginationLimit,
          (page - 1) * paginationLimit,
          val ?? null,
        );

      unstable_batchedUpdates(() => {
        setPermitsData(permitsData);
        setSkeletonData(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [page, val]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="w-full px-4 py-6">
      <div>
        <SearchComponent
          onClickValue={(e) => setVal(e.target.value)}
          onClickShearch={getData}
          onClick={() => setVal(val)}
        />
        <TableComponent
          headers={headers}
          data={
            permitsData?.result_list.map((permit) => ({
              id: permit.id,
              name: permit.name,
              nationality: permit.nationality,
              vehicle: permit.vehicle,
              equipment: permit.materials[0].equipment,
              application_state: permit.application_state as ApplicationState,
            })) || []
          }
          renderStatus={(rowIndex) => (
            <StatusLabel
              status={
                permitsData?.result_list[rowIndex]
                  .application_state as ApplicationState
              }
            />
          )}
          // renderActions={(rowIndex) => (
          //   <ButtonAction
          //     onClickButtonAccept={() => {
          //       handleEnableAcceptPopup();
          //       setID(permitsData.result_list[rowIndex].id);
          //     }}
          //     onClickButtonReject={() => {
          //       handleEnableRejectPopup();
          //       setID(permitsData.result_list[rowIndex].id);
          //     }}
          //   />
          // )}
          renderAdditionalInfo={(rowIndex) => {
            const permit = permitsData?.result_list[rowIndex];
            if (!permit) return <></>;
            return (
              <InfoComponents
                applicationType={'General Information'}
                details={{
                  Name: permit.name,
                  Email: permit.email,
                  mobile: permit.mobile,
                  Vehicle: permit.vehicle,
                  Description: permit.materials[0].description,
                  Equipment: permit.materials[0].equipment,
                  Notes: permit.materials[0].notes,
                  Quantity: permit.materials[0].quantity,
                  Permit_date: permit.permit_date,
                  Reason: permit.reason,
                  From: permit.transfer_from,
                  To: permit.transfer_to,
                }}
              />
            );
          }}
          loading={isLoading}
          totalCount={permitsData?.total_count || 0}
          paginationLimit={paginationLimit}
          page={page}
          onPageChange={setPage}
        />
      </div>
      {/* <div className="absolute top-[400px] right-2">
        {popupState.acceptPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseAcceptPopup}
            title={'accept'}
            subtitle={'accept'}
            onClick={handleCloseAcceptPopup}
            onClickConfirm={() => {
              // acceptApplications(ID);
              setID('');
            }}
          />
        )}
        {popupState.rejectPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseRejectPopup}
            title={'reject'}
            subtitle={'reject'}
            onClick={handleCloseRejectPopup}
            onClickConfirm={() => {
              // rejectApplications(ID);
              setID('');
            }}
          />
        )}
      </div> */}
    </div>
  );
}

export default SharedTableContainer;
