import { IssueEnum } from 'core/enums/application_state.enum';
import { IssueStatisticsModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIssuestatistics } from 'services/issus-service';

function IssuesStatisticssComponent() {
  const { t } = useTranslation();
  const [data, setGetDate] = useState<IssueStatisticsModel>();
  const getData = useCallback(async () => {
    try {
      const StatisticsData: IssueStatisticsModel = await getIssuestatistics(
        IssueEnum.ALL,
      );
      setGetDate(StatisticsData);
    } catch (error) {
      return error;
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div className="px-[4.1%] pt-9 flex flex-row items-center justify-center gap-5">
      <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
        <div className="flex flex-col items-center justify-center pt-5 pb-6 gap-y-3">
          <div className="text-4xl font-bold text-orange500">
            {data?.all_issue}
          </div>
          <div className="text-[28px] font-bold">{t('Allissue')}</div>
        </div>
      </div>
      <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
        <div className="flex flex-col items-center justify-center pt-5 pb-6 gap-y-3">
          <div className="text-4xl font-bold text-orange500">
            {data?.new_issue}
          </div>
          <div className="text-[28px] font-bold">{t('Newissue')}</div>
        </div>
      </div>
      <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
        <div className="flex flex-col items-center justify-center pt-5 pb-6 gap-y-3">
          <div className="text-4xl font-bold text-orange500">
            {data?.opened_issue}
          </div>
          <div className="text-[28px] font-bold">{t('openedIssues')}</div>
        </div>
      </div>
    </div>
  );
}
export default IssuesStatisticssComponent;
