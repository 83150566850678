import DisruptListComponent from 'components/admin/disrupt-componet/disrupt-list-component';
import NextDisruptComponent from 'components/admin/disrupt-componet/next-disrupt-component';
import NextDisruptWithTimelineComponent from 'components/admin/disrupt-componet/next-disrupt-with-timeline-component';
import { getDisruptStatus } from 'core/data-access/disrupt.service';
import { handleRequestError } from 'core/helpers/error.helper';
import { useCallback, useEffect, useState } from 'react';

function DisruptContainer() {
  const [isopen, setIsOpen] = useState(null);

  const DistruptStatus = useCallback(async () => {
    try {
      const result = await getDisruptStatus();
      setIsOpen(result.is_open);
      getDisruptStatus();
    } catch (error) {
      handleRequestError(error);
    }
  }, [setIsOpen]);

  useEffect(() => {
    DistruptStatus();
  }, [DistruptStatus]);

  return (
    <div className="w-full my-4 gap-11">
      {isopen === false ? (
        <NextDisruptComponent />
      ) : (
        <NextDisruptWithTimelineComponent />
      )}
      <DisruptListComponent />
    </div>
  );
}

export default DisruptContainer;
