import TeamDataTableComponet from 'components/admin/team-data-table-componet';

function teamContainer() {
  return (
    <div className="pt-6 pb-16 px-10">
      <TeamDataTableComponet />
    </div>
  );
}

export default teamContainer;
