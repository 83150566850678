import AuthAccessWrapperContainer from 'containers/common/auth-access-wrapper-container copy/auth-access-wrapper-container';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Sidebar from '../common/Sidebar/Sidebar';

const MainLayout = () => {
  const { i18n } = useTranslation();

  return (
    <AuthAccessWrapperContainer>
      <div className="flex mobile:flex-col tablet:flex-col laptop:flex-col w-full">
        <Sidebar />
        <div
          className={`w-main-content  laptop:w-full tablet:w-full mobile:w-full ${
            i18n.language === 'en'
              ? ' ml-[303px] tablet:ml-0 mobile:ml-0 laptop:ml-0'
              : ' mr-[303px] tablet:mr-0 mobile:mr-0 laptop:mr-0'
          }`}
        >
          <Outlet />
        </div>
      </div>
    </AuthAccessWrapperContainer>
  );
};

export default MainLayout;
