import TransportationPermitsContainer from 'containers/admin/Transportation Permits/transportation-permit-container';

const TransportationPermitPage = () => {
  return (
    <div>
      <TransportationPermitsContainer />
    </div>
  );
};

export default TransportationPermitPage;
