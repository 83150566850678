import ButtonComponent from 'components/admin/ButtonComponent';
import { Formik, getIn, useFormik } from 'formik';
import { useState } from 'react';
import { BsCameraVideo } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { TbPhoto } from 'react-icons/tb';
import * as Yup from 'yup';
//please don't edit, I will refactor the code later
interface PopupNewStartupComponentProps {
  title: string;
  onClose: () => void;
}
function PopupNewStartupComponent(props: PopupNewStartupComponentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(true);
  const [reasonCount, setReeasonCount] = useState(0);

  const handleClose = () => {
    props.onClose();
  };
  type InitialValuesType = {
    startupName: string;
    cohortNo: string;
    mobile: string;
    email: string;
    website: string;
    brief: string;
    logo: string;
    video: string;
  };

  const initialValues: InitialValuesType = {
    startupName: '',
    cohortNo: '',
    mobile: '',
    email: '',
    website: '',
    brief: '',
    logo: '',
    video: '',
  };
  const [body] = useState(initialValues);
  const validationSchema = Yup.object({
    startupName: Yup.string().required('Enter the Startup Name'.toString()),
    cohortNo: Yup.number()
      .typeError('The cohort No. must not contain any letters'.toString())
      .required('Enter the Cohort No.'.toString()),
    // mobile: Yup.string().required("Enter the Mobile".toString()),
    mobile: Yup.string()
      .required('Enter the Mobile'.toString())
      .matches(
        /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g,
        'Please enter a valid mobile'.toString(),
      ),
    email: Yup.string()
      .email('Please enter a valid email'.toString())
      .required('Enter the Email'.toString()),
    website: Yup.string()
      .url('Please enter a valid Website, www.thegarage.sa'.toString())
      .min(8, 'The website must be at least 8 characters long')
      .required('Enter the website'.toString()),
    brief: Yup.string()
      .matches(/^\d+$/, 'The Brief must be at least 8 characters long')
      .min(8)
      .max(8)
      .required('Enter the Brief'.toString()),
    logo: Yup.string().required('Upload the logo'.toString()),
    video: Yup.string().required('Upload the video'.toString()),
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      handleClose();
    },
    validationSchema: validationSchema,
  });

  return (
    <>
      {open && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full backdrop-opacity-10 backdrop-invert bg-white/30">
          <div className="rounded-lg bg-whiteColor px-5 py-2 h-[957px] w-[853px]">
            <div className="flex items-end justify-end">
              <IoMdClose
                size={24}
                className="cursor-pointer text-orange500"
                onClick={handleClose}
              />
            </div>
            <div className="text-[32px] font-bold pl-4 -mt-5 mb-5">
              {props.title}
            </div>
            <Formik
              initialValues={formik.initialValues}
              onSubmit={() => {
                formik.submitForm();
              }}
              validationSchema={formik.initialValues}
              enableReinitialize
            >
              <form className="flex flex-col" onSubmit={formik.handleSubmit}>
                <div className="flex justify-center items-start gap-x-5">
                  <div className="flex flex-col w-2/3 gap-2 ">
                    <label className="text-xl">Startup name</label>
                    <input
                      type="text"
                      name="startupName"
                      placeholder="safcsp"
                      className={`w-auto h-12 mb-2 appearance-none border rounded-lg py-3 px-2 bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400
                      ${
                        formik.touched.startupName && formik.errors.startupName
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }
                      `}
                      onChange={(e) => {
                        body.startupName = e.target.value;
                      }}
                    />
                    <p
                      className={`text-error text-sm ${
                        formik.touched.startupName && formik.errors.startupName
                          ? '-mt-4'
                          : ''
                      }`}
                    >
                      {getIn(formik.errors, 'startupName')}
                    </p>
                    <label className="text-xl">Cohort No.</label>
                    <input
                      type="text"
                      name="cohortNo"
                      placeholder="12345"
                      className={`w-auto h-12 mb-2 appearance-none border rounded-lg py-3 px-2 bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400
                      ${
                        formik.touched.cohortNo && formik.errors.cohortNo
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }
                      `}
                      onChange={(e) => {
                        body.cohortNo = e.target.value;
                      }}
                    />
                    <p
                      className={`text-error text-sm ${
                        formik.touched.cohortNo && formik.errors.cohortNo
                          ? '-mt-4'
                          : ''
                      }`}
                    >
                      {getIn(formik.errors, 'cohortNo')}
                    </p>
                    <label className="text-xl">Mobile</label>
                    <input
                      type="tel"
                      name="mobile"
                      placeholder="XXX XXX XXX"
                      className={`w-auto h-12 mb-2 appearance-none border rounded-lg py-3 px-2 bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400
                      ${
                        formik.touched.mobile && formik.errors.mobile
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }
                      `}
                      onChange={(e) => {
                        body.mobile = e.target.value;
                      }}
                    />
                    <p
                      className={`text-error text-sm ${
                        formik.touched.mobile && formik.errors.mobile
                          ? '-mt-4'
                          : ''
                      }`}
                    >
                      {getIn(formik.errors, 'mobile')}
                    </p>
                    <label className="text-xl">Email</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="thegarage@thegarage.sa"
                      className={`w-auto h-12 mb-2 appearance-none border rounded-lg py-3 px-2 bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400
                      ${
                        formik.touched.email && formik.errors.email
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }
                      `}
                      onChange={(e) => {
                        body.email = e.target.value;
                      }}
                    />
                    <p
                      className={`text-error text-sm ${
                        formik.touched.email && formik.errors.email
                          ? '-mt-4'
                          : ''
                      }`}
                    >
                      {getIn(formik.errors, 'email')}
                    </p>
                    <label className="text-xl">Website</label>
                    <input
                      type="text"
                      name="website"
                      placeholder="https://safcsp.org.sa"
                      className={`w-auto h-12 mb-2 appearance-none border rounded-lg py-3 px-2 bg-[#FAFAFB] text-sm focus:outline-none focus:border-secondaryGreen400
                      ${
                        formik.touched.website && formik.errors.website
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }
                      `}
                      onChange={(e) => {
                        body.website = e.target.value;
                      }}
                    />
                    <p
                      className={`text-error text-sm ${
                        formik.touched.website && formik.errors.website
                          ? '-mt-4'
                          : ''
                      }`}
                    >
                      {getIn(formik.errors, 'website')}
                    </p>
                    <label className="text-xl">Brief </label>
                    <textarea
                      name="brief"
                      id="message"
                      className={`border resize-none text-left p-2 focus:outline-none focus:shadow-none  mx-auto container text-black text-xl rounded-lg h-44 bg-gray-50 ${
                        formik.touched.brief && formik.errors.brief
                          ? 'border-error'
                          : 'border-neutralGrey'
                      }`}
                      placeholder="MKHZON is a Multisided platform (MSP) that create ecosystem for B2B growth and sustainability.
                It provides businesses with an easy, convenient, competitive tools and system to transact and preform key business activities."
                      maxLength={250}
                      minLength={2}
                      onChange={(e) => {
                        body.brief = e.target.value;
                        setReeasonCount(e.target.value.length);
                      }}
                    ></textarea>
                    <p className="text-error -mt-1 text-sm ">
                      {getIn(formik.errors, 'brief')}
                    </p>
                    <p
                      className={`pl-[500px] pt-0 ${
                        formik.touched.brief && formik.errors.brief
                          ? 'text-error -mt-7'
                          : 'text-black'
                      }`}
                    >{`${reasonCount}/250 `}</p>
                  </div>
                  <div className="flex justify-start items-start flex-col gap-9 mt-9">
                    <div className=" flex flex-col gap-2">
                      <label
                        className={`flex cursor-pointer items-center justify-center text-[16px] w-[200px] h-[200px] bg-[#FAFAFB] border rounded ${
                          formik.touched.video && formik.errors.video
                            ? 'border-error'
                            : ''
                        }`}
                      >
                        <span className="inline-flex items-baseline">
                          <TbPhoto size={42} />
                        </span>
                        <input
                          {...props}
                          id="image"
                          name="logo"
                          type="file"
                          accept="image/png, image/jpeg"
                          className={`sr-only`}
                        />
                      </label>
                      <p
                        className={`text-error text-sm ${
                          formik.touched.logo && formik.errors.logo
                            ? '-mt-2'
                            : ''
                        }`}
                      >
                        {getIn(formik.errors, 'logo')}
                      </p>
                      <button
                        name="logo"
                        className="w-[200px] h-[48px] rounded-lg bg-orange500 text-whiteColor text-base"
                        onChange={(e) => {
                          body.logo = e.currentTarget.value;
                        }}
                      >
                        Upload logo
                      </button>
                    </div>
                    <div className=" flex flex-col gap-2">
                      <label
                        className={`flex cursor-pointer items-center justify-center text-[16px]  w-[200px] h-[200px] bg-[#FAFAFB] border rounded ${
                          formik.touched.video && formik.errors.video
                            ? 'border-error'
                            : ''
                        }`}
                      >
                        <span className="inline-flex items-baseline">
                          <BsCameraVideo size={42} />
                        </span>
                        <input
                          {...props}
                          id="video"
                          name="video"
                          type="file"
                          accept="video/mp4,video/mkv, video/x-m4v,video/*"
                          className={`sr-only`}
                        />
                      </label>
                      <p
                        className={`text-error text-sm ${
                          formik.touched.logo && formik.errors.logo
                            ? '-my-2'
                            : ''
                        }`}
                      >
                        {getIn(formik.errors, 'video')}
                      </p>
                      <button
                        name="video"
                        className="w-[200px] h-[48px] rounded-lg bg-orange500 text-whiteColor text-base"
                        onChange={(e) => {
                          body.video = e.currentTarget.value;
                        }}
                      >
                        Upload video
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center pt-4">
                  <ButtonComponent
                    disabled={false}
                    width={'w-[305.46px]'}
                    hight={'h-[48px]'}
                    text={'Publish'}
                    fontsize={'text-base'}
                  />
                </div>
              </form>
            </Formik>
          </div>
        </div>
      )}
    </>
  );
}

export default PopupNewStartupComponent;
