import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { HttpMethods } from 'core/enums/httpMethods.enum';
import { MediaNewsModel } from 'core/types/admin.type';
// Create Nwes
export const postNweServices = async (addNwes: MediaNewsModel) => {
  return await makeRequest({
    url: `${BASE_URL}/news`,
    method: HttpMethods.POST,
    data: addNwes,
  });
};

export const getNweServices = async (
  news_type: string,
  limit: number,
  offset: number,
) => {
  return await makeRequest({
    url: `${BASE_URL}/news/admin`,
    method: HttpMethods.GET,
    params: {
      news_type: news_type,
      limit: limit,
      offset: offset,
    },
  });
};

export const getNewsByIdService = async (newsId: string) => {
  return await makeRequest({
    url: `${BASE_URL}/news/${newsId}/admin`,
    method: HttpMethods.GET,
  });
};

export const deleteNewsServices = async (news_id: string) => {
  return makeRequest({
    url: `${BASE_URL}/news/${news_id}`,
    method: HttpMethods.DELETE,
  });
};
export const putNewsServices = async (
  news_id: string,
  data: MediaNewsModel,
) => {
  return makeRequest({
    url: `${BASE_URL}/news/${news_id}`,
    method: HttpMethods.PUT,
    data,
  });
};
