import { Carousel } from '@material-tailwind/react';
import ChartDistruptComponent from 'components/admin/Landing/chart-distrupt-component';
import ChartIncubatorComponent from 'components/admin/Landing/chart-Incubator-coponent';
import ChartPlusComponent from 'components/admin/Landing/chart-plus-component';
import ContactUsComponents from 'components/admin/Landing/contactUs-component';
import FAQcomponant from 'components/admin/Landing/FAQ-componanet';
import LandingStatisticssComponent from 'components/admin/Landing/landing-statistics-component';
import RecentNewscomponents from 'components/admin/Landing/recent-news-component';
import { handleRequestError } from 'core/helpers/error.helper';
import { LandingStatistics } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { getOverViewService } from 'services/landing-services';

function Dashboardcontainer() {
  const [data, setData] = useState<LandingStatistics>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const FetchNewData = useCallback(async () => {
    try {
      const questionData: LandingStatistics = await getOverViewService();
      setData(questionData);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    FetchNewData();
  }, []);
  return (
    <div className="px-5 py-2 desktop:py-7">
      <LandingStatisticssComponent
        all_applications={data?.all_applications}
        all_visitors={data?.all_visitors}
        all_startups={data?.all_startups}
      />

      <div className="flex pl-5 w-[99%] pr-1 justify-between laptop:flex-col gap-x-3">
        <Carousel
          className="h-[44vh] w-full rounded-xl shadow"
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute z-50 flex gap-2 bottom-4 left-2/4 -translate-x-2/4">
              {new Array(length).fill('').map((_, i) => (
                <span
                  key={i}
                  className={`p-1.5 cursor-pointer rounded-full transition-all ${
                    activeIndex === i
                      ? 'w-1 bg-green300'
                      : 'w-1 bg-greyBorderBottom'
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          <ChartDistruptComponent
            data={data?.disrupt_statistics}
            YAxis={data?.disrupt_max_count ?? 0}
          />
          <ChartPlusComponent
            data={data?.plus_statistics}
            YAxis={data?.plus_max_count ?? 0}
          />
          <ChartIncubatorComponent
            data={data?.incubator_statistics}
            YAxis={data?.incubator_max_count ?? 0}
          />
        </Carousel>
        {isLoading ? (
          <div className="">
            {Array(6)
              .fill('')
              .map((_, index) => {
                return <div key={`skeleton-${index}`}></div>;
              })}
          </div>
        ) : (
          <FAQcomponant data={data?.contact_us} />
        )}
      </div>
      <div className="flex justify-between px-5 pt-4 gap-x-3">
        <RecentNewscomponents data={data?.recent_news} />
        <ContactUsComponents />
      </div>
    </div>
  );
}

export default Dashboardcontainer;
