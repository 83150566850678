import TabBarComponent from 'containers/admin/Community/Partners/tabbar-component';

import NewGaragePartnerContainer from './new-garage-partner-container';
import OldGaragePartnerContainer from './old-garage-partner-container';

function GaragePartneContainer() {
  return (
    <div className="w-full">
      <TabBarComponent
        page={<OldGaragePartnerContainer />}
        page2={<NewGaragePartnerContainer />}
        text1={'New garage partner'}
        text2={'Old garage partner'}
      />
    </div>
  );
}

export default GaragePartneContainer;
