import MediaCreateComponent from 'components/admin/Media/media-create-component';
import { getInitialValues } from 'components/admin/Media/media-form-container.data';
import { validationSchemaNewsLetter } from 'components/admin/Media/media-form-container.validation';
import PublishedPopup from 'components/admin/Media/published-popup';
import { handleRequestError } from 'core/helpers/error.helper';
import { MediaNewsModel } from 'core/types/admin.type';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'routes/app.paths';
import { postNweServices } from 'services/news-service';

function CreateNewsLetterContainer() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const handleAcceptClick = () => {
    setShowPopup(true);
  };
  const handleSubmitNewsLetter = async (values: MediaNewsModel) => {
    try {
      await postNweServices(values);
      handleAcceptClick();
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <>
      <MediaCreateComponent
        initialValues={getInitialValues('news_letter')}
        validationSchema={validationSchemaNewsLetter}
        onSubmit={handleSubmitNewsLetter}
      />
      {showPopup && (
        <PublishedPopup
          title={'container.create.news.publish.message'}
          onClickConfirm={() => {
            handleAcceptClick();
            navigate(appRoutesObj.getNewsPath());
          }}
        />
      )}
    </>
  );
}

export default CreateNewsLetterContainer;
