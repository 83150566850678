import MainLayout from 'components/layout/MainLayout';
import FAQPage from 'pages/About us/FAQPage';
import IssuePage from 'pages/About us/IssuePage';
import TeamPage from 'pages/About us/TeamPage';
import NewApplicationsPage from 'pages/admin/NewApplicationsPage';
import OldApplicationsPage from 'pages/admin/OldApplicationsPage';
import BookingPage from 'pages/bookingPage';
import EventsPage from 'pages/Community/EventsPage';
import PartenersPage from 'pages/Community/PartenersPage';
import StartUpPage from 'pages/Community/StartupPage';
import TourPage from 'pages/Community/TourPage';
import VisitPage from 'pages/Community/VisitPage';
import DashBoardPage from 'pages/Dashboard/DashboardPage';
import CreateNewsLetterPage from 'pages/Media/CreateNewsLetterPage';
import EditNewsLetterPage from 'pages/Media/EditNewsLetterPage';
import GalleryPage from 'pages/Media/GalleryPage';
import CreateNewsVideoPage from 'pages/Media/latest-events/CreateNewsVideoPage';
import EditLatestEventsPage from 'pages/Media/latest-events/EditLatestEventsPage';
import LatestEventsPage from 'pages/Media/latest-events/latest-events-page';
import NewsPage from 'pages/Media/NewsPage';
import PnuPage from 'pages/PNU/PnuPage';
import CohortsPlusPage from 'pages/Programs/CohortsPlusPage';
import DisruptPage from 'pages/Programs/DisruptPage';
import IncubatorsPage from 'pages/Programs/IncubatorsPage';
import MISAPage from 'pages/Programs/MISAPage';
import PlusPage from 'pages/Programs/PlusPage';
import TablePage from 'pages/tablePage';
import TransportationPermitPage from 'pages/Transportation Permits/transportation-permits-page';
import React from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { appRoutesObj } from './app.paths';

const NotFoundPage = React.lazy(() => import('pages/404'));
const LogInPage = React.lazy(() => import('pages/user/login-page'));
const ForgetPage = React.lazy(() => import('pages/user/forget-page'));
const NewPassPage = React.lazy(() => import('pages/user/new-password-page'));

const withSuspense = (WrappedComponent: JSX.Element) => {
  return (
    <Suspense
      fallback={<div className="pt-10 text-primary-200">Loading...</div>}
    >
      {WrappedComponent}
    </Suspense>
  );
};

export function AppRouting() {
  return (
    <Suspense
      fallback={<div className="pt-10 text-primary-200">Loading...</div>}
    >
      <BrowserRouter>
        <Routes>
          {/* Login and forget password pages */}
          <Route
            key="loginpage"
            path={appRoutesObj.getLogInPagePath()}
            element={withSuspense(<LogInPage />)}
          />

          <Route
            key="forgetpage"
            path={appRoutesObj.getForgetPasswordPagePath()}
            element={withSuspense(<ForgetPage />)}
          />
          <Route
            key="newpasspage"
            path={appRoutesObj.getSetNewPassPath()}
            element={withSuspense(<NewPassPage />)}
          />

          {/* {token == null ? ( */}
          <Route
            key="homePage"
            path={appRoutesObj.getBasePath()}
            element={withSuspense(<MainLayout />)}
          >
            {' '}
            <Route
              key="dashboard"
              path={appRoutesObj.getDashboardPagePath()}
              element={withSuspense(<DashBoardPage />)}
            ></Route>{' '}
            <Route
              key="notDefined"
              path="*"
              element={withSuspense(<NotFoundPage />)}
            />
            <Route
              key="team"
              path={appRoutesObj.getTeamPagePath()}
              element={withSuspense(<TeamPage />)}
            />
            {/* About Us */}
            <Route
              key="faq"
              path={appRoutesObj.getFAQPath()}
              element={withSuspense(<FAQPage />)}
            />
            <Route
              key="issue"
              path={appRoutesObj.getIssuePath()}
              element={withSuspense(<IssuePage />)}
            />
            {/* Programs */}
            <Route
              key="plus"
              path={appRoutesObj.getPlusPath()}
              element={withSuspense(<PlusPage />)}
            />
            <Route
              key="disrupt"
              path={appRoutesObj.getDisruptDashboardPagePath()}
              element={withSuspense(<DisruptPage />)}
            />
            <Route
              key="incubators"
              path={appRoutesObj.getIncubatorsPath()}
              element={withSuspense(<IncubatorsPage />)}
            />
            <Route
              key="MISA"
              path={appRoutesObj.getMISAPath()}
              element={withSuspense(<MISAPage />)}
            />
            {/* Community */}
            <Route
              key="parteners"
              path={appRoutesObj.getPartnersPath()}
              element={withSuspense(<PartenersPage />)}
            />
            <Route
              key="events"
              path={appRoutesObj.getEventsPath()}
              element={withSuspense(<EventsPage />)}
            />
            <Route
              key="visit"
              path={appRoutesObj.getVisitPath()}
              element={withSuspense(<VisitPage />)}
            />
            <Route
              key="visit"
              path={appRoutesObj.getTourPath()}
              element={withSuspense(<TourPage />)}
            />
            <Route
              key="start-up"
              path={appRoutesObj.getStartUpPath()}
              element={withSuspense(<StartUpPage />)}
            />
            {/* Media */}
            <Route
              key="news"
              path={appRoutesObj.getNewsPath()}
              element={withSuspense(<NewsPage />)}
            />
            <Route
              key="newsLetter"
              path={appRoutesObj.getCreateNewsLetterPath()}
              element={withSuspense(<CreateNewsLetterPage />)}
            />
            <Route
              key="createVideo"
              path={appRoutesObj.getCreateNewsVideoPath()}
              element={withSuspense(<CreateNewsVideoPage />)}
            />
            <Route
              key="editNews"
              path={appRoutesObj.getEditNewsLetterIdPagePath()}
              element={withSuspense(<EditNewsLetterPage />)}
            />
            <Route
              key="editVideo"
              path={appRoutesObj.getEditLatestEventsIdPagePath()}
              element={withSuspense(<EditLatestEventsPage />)}
            />
            <Route
              key="gallery"
              path={appRoutesObj.getGalleryPath()}
              element={withSuspense(<GalleryPage />)}
            />
            <Route
              key="OldApplicationsPage"
              path={appRoutesObj.getOldApplicationsPagePath()}
              element={withSuspense(<OldApplicationsPage />)}
            />
            <Route
              key="NewApplicationsPage"
              path={appRoutesObj.getNewApplicationsPagePath()}
              element={withSuspense(<NewApplicationsPage />)}
            />
            <Route
              key="CohortsPlusPage"
              path={appRoutesObj.getCohortsPlusPagePagePath()}
              element={withSuspense(<CohortsPlusPage />)}
            />
            <Route
              key="CohortsPlusPage"
              path={appRoutesObj.getBookingPagePath()}
              element={withSuspense(<BookingPage />)}
            />
            <Route
              key="transportation-permits"
              path={appRoutesObj.getTransportationPermitsPagePath()}
              element={withSuspense(<TransportationPermitPage />)}
            />
            <Route
              key="table"
              path={appRoutesObj.getTablePagePath()}
              element={withSuspense(<TablePage />)}
            />
            <Route
              key="Latest-Events"
              path={appRoutesObj.getLatestEventsPagePath()}
              element={withSuspense(<LatestEventsPage />)}
            />
            <Route
              key="PnuPage"
              path={appRoutesObj.getPNUPagePath()}
              element={withSuspense(<PnuPage />)}
            />
            {/* default page */}
            <Route
              key={'default'}
              path={''}
              element={<Navigate to={appRoutesObj.getDashboardPagePath()} />}
            />
          </Route>
          {/* ) : null} */}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
