import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineChevronDown } from 'react-icons/hi';

type DropdownProps<T> = {
  id: string;
  openDropdown: string | null;
  setOpenDropdown: (id: string | null) => void;
  data: { [key: string]: T[] } | string[];
  showTitles?: boolean;
  title?: string;
  placeholder: string;
  selectedItem?: (item: T) => string;
  onSelect?: (value: string) => void;
  error?: boolean;
  errorMsg?: string;
  width?: string;
};

function DropdownComponent<T>({
  id,
  openDropdown,
  setOpenDropdown,
  data,
  showTitles = true,
  title,

  selectedItem = (item: T) => (item as any).name,
  onSelect,
  placeholder,
  error,
  errorMsg = '',
  width,
}: DropdownProps<T>) {
  const isOpen = openDropdown === id;
  const [selectedValue, setSelectedValue] = useState<string>('');
  const { t } = useTranslation();

  const toggleDropdown = () => {
    setOpenDropdown(isOpen ? null : id);
  };

  const handleItemSelect = (item: T | string) => {
    const value = typeof item === 'string' ? item : selectedItem(item);
    setSelectedValue(value);
    setOpenDropdown(null);
    if (onSelect) onSelect(value);
  };

  return (
    <div className="relative inline-block">
      {title && <p className="px-2 py-2 font-en">{title}</p>}
      <div
        onClick={toggleDropdown}
        className={`w-[319px] ${width} h-[52px] px-4 py-1 text-sm font-medium text-gray-700 bg-neutralGre50 border
        ${
          isOpen ? 'border-secondaryGreen400' : ''
        }  shadow-sm cursor-pointer flex items-center justify-between`}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <p className="text-sm text-gray500">{selectedValue || placeholder}</p>
        <HiOutlineChevronDown
          size={24}
          className={`transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
          aria-hidden="true"
        />
      </div>
      {isOpen && (
        <div className="absolute right-0 z-50 w-full overflow-y-auto bg-white shadow-lg ring-black max-h-60">
          {Array.isArray(data)
            ? data.map((item, index) => (
                <div key={index} onClick={() => handleItemSelect(item)}>
                  <hr />
                  <p className="px-4 py-2 text-sm h-[38px] text-gray-700 cursor-pointer">
                    {item}
                  </p>
                </div>
              ))
            : Object.keys(data).map((key, index) => (
                <div key={index}>
                  {showTitles && (
                    <h2
                      onClick={(e) => e.stopPropagation()}
                      className="px-4 py-2 h-[38px] text-sm font-bold text-gray-700 bg-bgGray2 cursor-auto"
                    >
                      {key}
                    </h2>
                  )}
                  {data[key].map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className="px-4 py-2 text-sm h-[38px] text-gray-700 cursor-pointer"
                      onClick={() => handleItemSelect(item)}
                    >
                      {selectedItem(item)}
                    </div>
                  ))}
                </div>
              ))}
        </div>
      )}
      {error && errorMsg && (
        <div className="mt-2 text-sm text-red-600">{t(errorMsg)}</div>
      )}
    </div>
  );
}

export default DropdownComponent;
