import { UserRolesEnums } from 'core/enums/roles.enum';

export const useRoles = (roles: string[]) => {
  return {
    isAdmin:
      roles.includes(UserRolesEnums.GARAGE_SUPER_ADMIN) ||
      roles.includes(UserRolesEnums.GARAGE_ADMIN),
    isAdminPermit:
      roles.includes(UserRolesEnums.GARAGE_COMMUNITY) ||
      roles.includes(UserRolesEnums.GARAGE_FACILITIES) ||
      roles.includes(UserRolesEnums.GARAGE_SUPER_ADMIN) ||
      roles.includes(UserRolesEnums.GARAGE_ADMIN),
    isAdminBooking: roles.includes(UserRolesEnums.GARAGE_BOOKING_MANAGEMENT),
  };
};
