import React from 'react';

export interface StatusLabelProps {
  status: 'CONFIRMED' | 'CANCELED' | 'ACCEPTED' | 'REJECTED';
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status }) => {
  let color = '';

  switch (status) {
    case 'CONFIRMED':
      color = 'bg-green-500';
      break;
    case 'CANCELED':
      color = 'bg-red-500';
      break;
    case 'ACCEPTED':
      color = 'bg-[#EEF9F7] text-green500';
      break;
    case 'REJECTED':
      color = 'bg-red-50 text-error';
      break;
    default:
      color = 'bg-gray-300';
  }

  return <span className={`px-4 py-2 rounded-[20px] ${color}`}>{status}</span>;
};

export default StatusLabel;
