import CircleSkeleton from './circle-skeleton';
import LineSkeleton from './line-skeleton';
import SquareSkeleton from './square-skeleton';

export default function CardSkeleton() {
  return (
    <div>
      <div className="rounded-lg shadow-lg bg-white w-full h-[116px]">
        <div className="flex flex-row justify-between mt-10">
          <div className="flex flex-row space-x-4 mt-4">
            <div className="ml-5 mt-8 h-8 w-8">
              <CircleSkeleton />
            </div>
            <div className="w-[100px] h-[88px] ml-4">
              <SquareSkeleton />
            </div>
          </div>
          <div className="flex flex-row justify-between mt-12 mr-8">
            <div className="mr-3 h-5 w-16">
              <LineSkeleton />
            </div>
            <div className="h-5 w-16">
              <LineSkeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
