import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  rejectReason: Yup.string().min(2).max(250).required('This Field is required'),
});

interface RejectPopupComponentProps {
  onClose: () => void;
  onSubmit: (reason: string) => void;
}

const RejectPopupComponent: React.FC<RejectPopupComponentProps> = ({
  onClose,
  onSubmit,
}) => {
  return (
    <div className="fixed z-[1500] top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-20">
      <div className="w-[750px] h-auto rounded-lg bg-whiteColor px-5 py-4">
        <div className="flex items-end justify-end">
          <IoMdClose
            className="w-[24px] h-[24px] cursor-pointer "
            onClick={onClose}
          />
        </div>
        <div className="text-xl font-bold">
          What is the reason for the rejection of the request?
        </div>

        <Formik
          initialValues={{ rejectReason: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values.rejectReason);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mt-4">
                <Field
                  as="textarea"
                  name="rejectReason"
                  className={`font-arabic_normal border text-left pl-2 pt-2 pb-20 border-[#CCD0D2] focus:outline-none focus:shadow-none resize-none text-black text-xl rounded-lg w-full bg-gray-50`}
                  placeholder="Reply"
                />
                <ErrorMessage
                  name="rejectReason"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="flex flex-row justify-end items-center gap-3 px-3 py-5">
                <button
                  type="button"
                  className="w-[180px] h-[48px] rounded-lg border border-orange500 bg-whiteColor text-orange500 text-base"
                  onClick={onClose}
                >
                  No, cancel
                </button>

                <button
                  type="submit"
                  className="w-[180px] h-[48px] rounded-lg bg-orange500 text-whiteColor text-base"
                  disabled={isSubmitting}
                >
                  Yes, Send
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RejectPopupComponent;
