import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { HttpMethods } from 'core/enums/httpMethods.enum';
import { TeamModel } from 'core/types/admin.type';

//get all Team from service
export const getAllTeamServices = (
  limit: number,
  offset: number,
  searchText: string | null,
) => {
  return makeRequest({
    url: `${BASE_URL}/team/admin/list`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      limit: limit,
      offset: offset,
    },
  });
};
// Create Employee
export const postEmployeeServices = async (addEmployee: TeamModel) => {
  return await makeRequest({
    url: `${BASE_URL}/team`,
    method: HttpMethods.POST,
    data: addEmployee,
  });
};

// delete Employee by id
export const deleteEmployeeServices = async (employeeID: string) => {
  return makeRequest({
    url: `${BASE_URL}/team/${employeeID}`,
    method: HttpMethods.DELETE,
  });
};
//update Employee by id
export const putEmployeeServices = (
  editEmployee: TeamModel,
  employeeID: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/team/${employeeID}`,
    method: HttpMethods.PUT,
    data: editEmployee,
  });
};
