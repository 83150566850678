import GalleryMenueComponent from './gallery-menue-component';

export default function GalleryContainer() {
  return (
    <>
      <div className="px-8 py-8">
        <div className="bg-white w-full px-10 pt-8 pb-4 rounded-lg">
          <GalleryMenueComponent />
        </div>
      </div>
    </>
  );
}
