import * as Yup from 'yup';

export const validationSchemaNewsLetter = (typeFile: 'image' | 'video') =>
  Yup.object({
    url: Yup.string().required(
      typeFile === 'image'
        ? 'component.Media.upload.image.required'
        : 'component.Media.upload.video.required',
    ),
    title_ar: Yup.string()
      .required('form.validation.required')
      .min(3, 'form.validation.minimumLetters')
      .max(50, 'form.validation.maximumLetters'),

    description_ar: Yup.string()
      .min(3, 'form.validation.minimumLetters')
      .max(2500, 'form.validation.maximumLetters')
      .required('form.validation.required'),
    title_en: Yup.string()
      .min(3, 'form.validation.minimumLetters')
      .max(50, 'form.validation.maximumLetters')
      .required('form.validation.required'),
    description_en: Yup.string()
      .min(3, 'form.validation.minimumLetters')
      .max(2500, 'form.validation.maximumLetters')
      .required('form.validation.required'),
  });
