import { HiOutlineTrash } from 'react-icons/hi';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { IoEyeOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

interface ActionIconsProps {
  onEdit?: string;
  onDelete?: () => void;
  onView?: () => void;
}

function ActionIcons({ onEdit, onDelete, onView }: ActionIconsProps) {
  return (
    <div className="flex justify-center gap-6">
      {onView && (
        <IoEyeOutline
          size={20}
          onClick={onView}
          className="cursor-pointer text-blue500"
          aria-label="View"
        />
      )}
      {onEdit && (
        <Link to={onEdit}>
          <HiOutlinePencilAlt
            size={20}
            className="cursor-pointer text-secondaryGreen400"
            aria-label="Edit"
          />
        </Link>
      )}

      {onDelete && (
        <HiOutlineTrash
          size={20}
          onClick={onDelete}
          className="cursor-pointer text-error"
          aria-label="Delete"
        />
      )}
    </div>
  );
}

export default ActionIcons;
