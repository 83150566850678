import TitleCardComponet from './titleCardComponet';

interface CohortsPlusComponentprops {
  Title: string;
}

function CohortsPlusComponent(props: CohortsPlusComponentprops) {
  return (
    <div className="pt-[55px]">
      <div className="flex flex-row justify-between px-5 w-[1327px] h-[102px] items-center rounded-lg">
        <TitleCardComponet titel={props.Title} />
      </div>
    </div>
  );
}

export default CohortsPlusComponent;
