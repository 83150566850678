import EmptySearchComponent from 'components/common/empty-search';
import Pagination from 'components/common/Pagination/pagination';
import SearchComponent from 'containers/admin/Community/Partners/search-component';
import { QuestionsState } from 'core/enums/application_state.enum';
import { contactUsEnum } from 'core/enums/example.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllQuestionModel } from 'core/types/user.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { HiOutlineTrash } from 'react-icons/hi';
import { HiOutlineMailOpen } from 'react-icons/hi';
import * as Services from 'services/FAQ-service';

import ConfirmPopupComponent from './ConfirmPopupComponet';
import MessageNewEmailComponet from './message-new-email-componet';
import MessageSkeletonComponent from './message-skeleton-component/message-skeleton-component';

function FaqNew() {
  const paginationLimit = 6;
  const [visibleItems, setVisibleItems] = useState(1);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showReadPopup, setShowReadPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [data, setData] = useState<AllQuestionModel>();
  const [val, setVal] = useState<string>('');

  const handleClosePopup = () => {
    setShowDeletePopup(false);
  };

  const handleAcceptClick = () => {
    setShowDeletePopup(true);
    deletNewData();
  };

  const handleCloseReadPopup = () => {
    setShowReadPopup(false);
  };

  const handleAcceptReadClick = () => {
    isCheckedAll || selectedIds.length > 0
      ? setShowReadPopup(true)
      : setShowReadPopup(false);
  };

  const handleSendData = (id: string) => {
    replayQuestions(id);
  };

  const handleAddNewContactId = (id: string) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((i) => i !== id)
        : [...prevSelectedIds, id],
    );
  };

  const handleCheckeAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedIds(data?.result_list?.map((r) => r.id || '') || []);
      setIsCheckedAll(true);
    } else {
      setSelectedIds([]);
      setIsCheckedAll(false);
    }
  };

  const FetchNewData = useCallback(async () => {
    setIsLoading(true);
    try {
      const questionData: AllQuestionModel = await Services.getQuestionService(
        val ?? null,
        contactUsEnum.NEW,
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
      );
      setData(questionData);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [val, visibleItems, paginationLimit]);

  const deletNewData = async () => {
    try {
      await Services.postQuestionService(contactUsEnum.DEL, selectedIds);
      unstable_batchedUpdates(() => {
        setSelectedIds([]);
        setIsCheckedAll(false);
      });
      FetchNewData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  const ReadOldata = async () => {
    try {
      await Services.postQuestionService(contactUsEnum.READ, selectedIds);
      unstable_batchedUpdates(() => {
        setSelectedIds([]);
      });
      FetchNewData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  const replayQuestions = async (id: string) => {
    try {
      const replies = {
        reply: textAreaValue,
      };
      await Services.replayQuestionService(id, replies.reply || '');
      FetchNewData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  useEffect(() => {
    FetchNewData();
  }, [FetchNewData]);

  return (
    <div className="desktop:pt-6 pt-4">
      <div className="bg-white rounded-[8px] px-6 py-6 desktop:pt-6">
        <p className=" text-lg font-bold font-en pl-7">All Questions</p>
        <div className="flex flex-col w-full rounded-lg px-6">
          <div className="flex gap-6 bg-slate50 items-center justify-start pl-2 py-2 desktop:py-4 desktop:pl-4 rounded-[8px]">
            <div className="flex items-center justify-start gap-5">
              <div className="flex gap-1">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id={'lang'}
                    name="SelectAll"
                    checked={Boolean(isCheckedAll)}
                    onChange={(e) => handleCheckeAll(e.target.checked)}
                  />
                </div>
                <div className="text-sm">
                  <label className="whitespace-nowrap text-[16px] text-gray-900 dark:text-gray-300">
                    Select All
                  </label>
                </div>
              </div>

              <div
                className="flex flex-row gap-1 cursor-pointer"
                onClick={handleAcceptClick}
              >
                <HiOutlineTrash className="text-[25px] text-red500" />
                Delete
              </div>
              <button
                className="px-2 py-2 border whitespace-nowrap border-orange500 text-orange500 flex flex-row items-center justify-center rounded-lg gap-2 text-base"
                onClick={handleAcceptReadClick}
                disabled={selectedIds.length === 0}
              >
                <HiOutlineMailOpen className="text-orange500 w-[24px] h-6" />
                Mark as opened
              </button>
            </div>
            <div className="w-1/2">
              <SearchComponent
                onClickValue={(e) => setVal(e.target.value)}
                onClickShearch={FetchNewData}
              />
            </div>
          </div>

          {isLoading ? (
            <div className="">
              {Array(6)
                .fill('')
                .map((_, index) => (
                  <div key={`skeleton-${index}`}>
                    <MessageSkeletonComponent />
                  </div>
                ))}
            </div>
          ) : data?.total_count === 0 ? (
            <div className="mt-20">
              <EmptySearchComponent />
            </div>
          ) : (
            <div className="pt-1 w-full">
              <MessageNewEmailComponet
                list={data?.result_list || []}
                handleAddId={(id: string) => handleAddNewContactId(id)}
                checkedIds={selectedIds}
                textAreaVal={textAreaValue}
                textAreaOnChange={(e) => setTextAreaValue(e.target.value)}
                handleSendPost={(id: string) => handleSendData(id)}
              />
            </div>
          )}
          <div className="pr-8 pt-5 desktop:pt-0 desktop:pb-7">
            <Pagination
              totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
              page={visibleItems}
              limit={paginationLimit}
              onPageChange={setVisibleItems}
              totalCount={data?.total_count || 0}
              questions={QuestionsState.YES}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {showDeletePopup && (
          <ConfirmPopupComponent
            onClose={handleClosePopup}
            title={'Are you sure you want to delete this question? '}
            subtitle={'If you confirm, question will be delete.'}
            onClick={handleClosePopup}
            onClickConfirm={() => deletNewData()}
          />
        )}
        {showReadPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseReadPopup}
            title={'Are you sure you want to Mark as opened?'}
            subtitle={'If you confirm, question will be opened.'}
            onClick={handleAcceptReadClick}
            onClickConfirm={() => ReadOldata()}
          />
        )}
      </div>
    </div>
  );
}

export default FaqNew;
