import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, IconButton } from '@mui/material';
import assets from 'assets';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SidebarContent from './SidebarContent';

const Sidebar = () => {
  const [openIndex, setOpenIndex] = useState<number>(-1);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const direction = i18n.dir();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'ar' ? 'en' : 'ar';
    i18n.changeLanguage(newLanguage);
    document.documentElement.dir = newLanguage === 'ar' ? 'rtl' : 'ltr';
    localStorage.setItem('language', newLanguage);
  };

  return (
    <>
      <div className="flex items-center justify-between w-full p-4 shadow-md largeLaptop:hidden desktop:hidden">
        <IconButton onClick={() => setDrawerOpen(true)}>
          <MenuIcon />
        </IconButton>
        <img src={assets.images.logo} alt="The Garage" className="h-8" />
      </div>
      <Drawer
        anchor={direction === 'rtl' ? 'right' : 'left'}
        variant="permanent"
        className="hidden largeLaptop:block desktop:block"
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxShadow: 1,
            width: '303px',
            boxSizing: 'border-box',
            direction,
          },
        }}
      >
        <SidebarContent
          openIndex={openIndex}
          setOpenIndex={setOpenIndex}
          enabled={enabled}
          setEnabled={setEnabled}
          toggleLanguage={toggleLanguage}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Drawer>
      <Drawer
        anchor={direction === 'rtl' ? 'right' : 'left'}
        variant="temporary"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          flexShrink: 1,
          '& .MuiDrawer-paper': {
            boxShadow: 1,
            width: '303px',
            boxSizing: 'border-box',
            direction,
          },
        }}
      >
        <SidebarContent
          openIndex={openIndex}
          setOpenIndex={setOpenIndex}
          enabled={enabled}
          setEnabled={setEnabled}
          toggleLanguage={toggleLanguage}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Drawer>
    </>
  );
};

export default Sidebar;
