import React from 'react';
import { useTranslation } from 'react-i18next';
interface RadioButtonComponentProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

function RadioButtonComponent({ label, ...props }: RadioButtonComponentProps) {
  const { t } = useTranslation();

  return (
    <div>
      <label className="inline-flex gap-4 text-sm font-DMSansRegular">
        <input
          {...props}
          type="radio"
          name="toggle"
          className="appearance-none rounded-full border-[1px] border-orange500 h-6 w-6 before:rounded-full before:border-orange500 before:border-[7px] grid place-items-center before:scale-0 before:ease-in-out before:transition-transform checked:before:scale-[1px]"
        />
        {t(`${label}`)}
      </label>
    </div>
  );
}

export default RadioButtonComponent;
