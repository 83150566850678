import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { downloadDataLink } from 'components/common/csv';
import DataListComponent from 'components/common/Data-list/data-list-component';
import EmptySearchComponent from 'components/common/empty-search';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import SkeletonDataComponent from 'components/common/skeleton-element/incubators-skeleton';
import {
  acceptPlusApplication,
  CreateNewPlus,
  getExportNewPlus,
  getPlusApplication,
  plusStatus,
  publishPlus,
  rejectPlusApplication,
  unPublishPlus,
} from 'core/data-access/plus.service';
import {
  ApplicationStateEnum,
  PDFEnum,
} from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { Plus } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlineLink, AiOutlineSearch } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp, IoLogoInstagram } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { TfiTwitter } from 'react-icons/tfi';
import { toast } from 'react-toastify';

import RecevingFormComponent from '../receving-form-component';

function PlusNewApplications() {
  const { t } = useTranslation();
  const [FeaturesList, setFeaturesList] = useState<Plus>();
  const paginationLimit = 4;
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [plusAppId, setPlusAppId] = useState('');
  const [enableCreatePlus, setEnableCreatePlus] = useState<boolean>(false);
  const [val, setVal] = useState('');
  const [filtered, setFiltered] = useState(FeaturesList?.result_list);
  const [enabled, setEnabled] = useState(false);
  const [enabledPlus, setEnabledPlus] = useState(false);
  const [acceptPopup, setAcceptPopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [exportPopup, setExportPopup] = useState(false);
  const [dataExport, setDataExport] = useState<string>('');
  const [toggle, setToggle] = useState(
    new Array(FeaturesList?.total_count).fill(false),
  );
  const [toggleAboutTheStartup, setToggleAboutTheStartup] = useState(
    new Array(FeaturesList?.total_count).fill(false),
  );
  const [toggleGeneralInfo, setToggleGeneralInfo] = useState(
    new Array(FeaturesList?.total_count).fill(false),
  );
  const [toggleStartUpDetails, setToggleStartUpDetails] = useState(
    new Array(FeaturesList?.total_count).fill(false),
  );
  const acceptPlusApplications = async (index: string) => {
    try {
      await acceptPlusApplication(index);
      toast.success('This startup has been accepted successfully!');
      getPlusApplications();
    } catch (error) {
      handleRequestError(error);
    }
  };
  const getDataExport = async () => {
    try {
      const plusDataExport: string = await getExportNewPlus();
      setDataExport(plusDataExport);
      downloadDataLink(plusDataExport, 'New Plus Data');

      toast.success(`Data has been exported successfully!`);
    } catch (error) {
      handleRequestError(error);
    }
  };

  const rejectPlusApplications = async (index: string) => {
    try {
      await rejectPlusApplication(index);
      toast.success('This startup has been Rejected successfully!');
      getPlusApplications();
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleAboutTheStartupClick = (index: number) => {
    const newToggleSmall = [...toggleAboutTheStartup];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleAboutTheStartup(newToggleSmall);
  };
  const handleToggleGeneralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGeneralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGeneralInfo(newToggleSmall);
  };
  const handleToggleStartUpDetailsClick = (index: number) => {
    const newToggleSmall = [...toggleStartUpDetails];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleStartUpDetails(newToggleSmall);
  };
  const handlePlusPopUp = () => {
    setEnabledPlus(true);
  };
  const handleClosePlusPopUp = () => {
    setEnabledPlus(false);
  };
  const handleCreatePlusPopup = () => {
    setEnableCreatePlus(false);
  };
  const plusCheckStatus = async () => {
    // eslint-disable-next-line
    const result = await plusStatus();
    setEnabled(result.is_open);
  };
  const handleClosePlus = async () => {
    // eslint-disable-next-line
    const result = await unPublishPlus().then((res) => {
      toast.success('Receiving forms has been stopped successfully.');
      plusCheckStatus();
      return res;
    });
  };
  const handleReceivingPlus = async () => {
    // eslint-disable-next-line
    const result = await publishPlus().then((res) => {
      toast.success('Receiving forms has been continue successfully.');
      plusCheckStatus();
      return res;
    });
  };
  const handleCloseAcceptPopup = () => {
    setAcceptPopup(false);
  };
  const handleEnableAcceptPopup = () => {
    setAcceptPopup(true);
  };
  const handleCloseRejectPopup = () => {
    setRejectPopup(false);
  };
  const handleEnableRejectPopup = () => {
    setRejectPopup(true);
  };
  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };
  const handleSearchClick = () => {
    if (val === '') {
      setFiltered(FeaturesList?.result_list);
      return;
    }
    const filterBySearch = FeaturesList?.result_list.filter((item) => {
      item.startup_name.toLowerCase().includes(val.toLowerCase());
    });
    setFiltered(filterBySearch);
  };
  const getPlusApplications = useCallback(async () => {
    setIsLoading(true);
    try {
      const PlusSearch: Plus = await getPlusApplication(
        ApplicationStateEnum.PENDING,
        paginationLimit,
        val,
        (page - 1) * paginationLimit,
      );
      unstable_batchedUpdates(() => {
        setFeaturesList(PlusSearch);
        setFiltered(PlusSearch.result_list);
      });
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [setFeaturesList, page, val]);

  const createNewPlus = async () => {
    try {
      return await CreateNewPlus();
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getPlusApplications();
    plusCheckStatus();
  }, [page]);

  return (
    <div className="flex items-center justify-center pt-5">
      <div className="flex flex-col w-full h-full rounded-lg bg-whiteColor">
        <div
          className={`inline-flex justify-start items-start ml-5 mt-8 gap-2 mb-4`}
        >
          <div className="relative flex items-center">
            <AiOutlineSearch className="w-[24px] h-[24px] ml-2 absolute text-gray-400" />
            <input
              onChange={(e) => setVal(e.target.value)}
              type="search"
              id="default-search"
              className="block h-[48px] w-[382px] p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
              placeholder={`${t('UserInputText')}`}
            />
          </div>
          <Button
            disabled={false}
            width={'w-[146px]'}
            hight={'h-[48px]'}
            text={`${t('search')}`}
            fontsize={'text-base'}
            onClick={handleSearchClick}
          />
        </div>
        <div className="px-6">
          <div className="grid grid-cols-5 w-full font-bold text-lg pr-[15%] py-5 items-center">
            <div className="font-bold text-center whitespace-nowrap">
              {t('startupName')}
            </div>
            <div className="font-bold text-center whitespace-nowrap">
              {t('startupSector')}
            </div>
            <div className="font-bold text-center">{t('name')}</div>
            <div className="font-bold text-center text-l whitespace-nowrap">
              {t('startupprofile')}
            </div>
            <div className="font-bold text-center">{t('allstatus')}</div>
          </div>
          <div className="">
            {isLoading ? (
              <>
                {Array(4)
                  .fill('')
                  .map((_, index) => {
                    return (
                      <div
                        key={index}
                        className="flex flex-col items-center mb-4"
                      >
                        <SkeletonDataComponent />
                      </div>
                    );
                  })}
              </>
            ) : filtered?.length === 0 ? (
              <div className="flex justify-center mx-auto">
                <EmptySearchComponent />
              </div>
            ) : filtered && filtered?.length > 0 ? (
              <>
                {filtered?.map((item, index) => (
                  <div key={index} className="flex flex-col items-center mb-4">
                    <DataListComponent
                      index={index}
                      toggle={toggle}
                      id={item.id}
                      item1={item.startup_name}
                      item2={item.startup_major}
                      item3={item.first_name + ' ' + item.last_name}
                      Icon={PDFEnum.YES}
                      isNew={true}
                      item5={item.application_state}
                      onClickButtonAccept={() => {
                        handleEnableAcceptPopup();
                        setPlusAppId(item.id);
                      }}
                      onClickButtonReject={() => {
                        handleEnableRejectPopup();
                        setPlusAppId(item.id);
                      }}
                      onClickArrowDown={() =>
                        handleToggleClick(index, !toggle[index])
                      }
                      onClickArrowUp={() =>
                        handleToggleClick(index, !toggle[index])
                      }
                    />

                    {toggle[index] && (
                      <div className="flex justify-center w-full h-full px-4 pb-4 rounded-b-lg bg-greyMenuColor">
                        <div className="flex flex-col w-full py-2">
                          <div
                            className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                          >
                            <div className="flex flex-row justify-between w-full px-4">
                              <div className="text-secondaryGreen400">
                                About the startup
                              </div>
                              {toggleAboutTheStartup[index] ? (
                                <IoIosArrowUp
                                  className="flex w-[50px] cursor-pointer pl-5"
                                  onClick={() =>
                                    handleToggleAboutTheStartupClick(index)
                                  }
                                />
                              ) : (
                                <IoIosArrowDown
                                  className="flex w-[50px] cursor-pointer pl-5"
                                  onClick={() =>
                                    handleToggleAboutTheStartupClick(index)
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {toggleAboutTheStartup[index] && (
                            <div className="flex flex-col w-full py-4 border bg-whiteColor border-gray500">
                              <div className="px-3 text-base">
                                {item.about_startup}
                              </div>
                              <div>
                                <div className="flex items-end justify-end gap-2 px-5">
                                  <IoLogoInstagram className="w-[25px] h-[25px] text-orange500" />
                                  <AiOutlineLink className="w-[25px] h-[25px] text-orange500" />
                                  <TfiTwitter className="w-[25px] h-[25px] text-orange500" />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="grid grid-cols-2 gap-5 pt-9">
                            <div className="flex flex-col">
                              <div
                                className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                              >
                                <div className="flex flex-row justify-between w-full px-4">
                                  <div className="text-secondaryGreen400">
                                    {t('generalinfo')}
                                  </div>
                                  {toggleGeneralInfo[index] ? (
                                    <IoIosArrowUp
                                      className="flex w-[50px] cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleGeneralInfoClick(index)
                                      }
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="flex w-[50px] cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleGeneralInfoClick(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {toggleGeneralInfo[index] && (
                                <div className="flex flex-col w-full gap-2 px-3 py-3 border bg-whiteColor border-gray500">
                                  <div>{t('firstName')}</div>
                                  <div>{item.first_name}</div>
                                  <hr className="w-full px-0 opacity" />
                                  <div>{t('lastname')}</div>
                                  <div>{item.last_name}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('nationality')}</div>
                                  <div>{item.nationality}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('idType')}</div>
                                  <div>{item.identity_type}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('idNumber')}</div>
                                  <div>{item.identity_number}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('mobilenumber')}</div>
                                  <div>{item.mobile}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('mobilenumber')}</div>
                                  <div>{item.email}</div>
                                </div>
                              )}
                            </div>

                            <div className="flex flex-col">
                              <div
                                className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                              >
                                <div className="flex flex-row justify-between w-full px-4">
                                  <div className="text-secondaryGreen400">
                                    {t('startupDetails')}
                                  </div>
                                  {toggleStartUpDetails[index] ? (
                                    <IoIosArrowUp
                                      className="flex w-[50px] cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleStartUpDetailsClick(index)
                                      }
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="flex w-[50px] cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleStartUpDetailsClick(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {toggleStartUpDetails[index] && (
                                <div className="flex flex-col w-full gap-2 px-3 py-3 border bg-whiteColor border-gray500">
                                  <div className="text-base font-bold">
                                    {t('yearofestablishment')}
                                  </div>
                                  <div>{item.year_of_establishment}</div>
                                  <hr className="w-full px-0 opacity-50" />
                                  <div>{t('startupsize')}</div>
                                  <div>{item.startup_capacity}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('nationality')}</div>
                                  <div>{item.hear_about_garage}</div>
                                  <hr className="w-full opacity-50" />
                                  <div>{t('whydoyouwanttojoinAlGarage')}</div>
                                  <div>{item.reason_to_join}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <Loading />
            )}
          </div>
          <div className="px-1">
            <Pagination
              totalPage={Math.ceil(
                (FeaturesList?.total_count || 0) / paginationLimit,
              )}
              page={page}
              limit={paginationLimit}
              onPageChange={setPage}
              totalCount={FeaturesList?.total_count || 0}
            />
          </div>
        </div>
        <div className="flex items-center justify-between px-5">
          <div className="">
            <RecevingFormComponent
              checked={enabled}
              onClick={() => {
                handlePlusPopUp();
              }}
              programme={t('receivingforms')}
            />
          </div>
          <div className="pb-2">
            {' '}
            <Button
              disabled={false}
              width={'w-[284px]'}
              img={<RiFileExcel2Line size={24} />}
              hight={'h-[48px]'}
              text={'Export All'}
              fontsize={'text-base'}
              onClick={handleExportClick}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {acceptPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseAcceptPopup}
            title={'Are you sure you want to accept this startup application? '}
            subtitle={'If you confirm, the startup application will be accept.'}
            onClick={handleCloseAcceptPopup}
            onClickConfirm={() => {
              acceptPlusApplications(plusAppId);
              setPlusAppId('');
            }}
          />
        )}
        {rejectPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseRejectPopup}
            title={'AAre you sure you want to reject this startup application?'}
            subtitle={'If you confirm, the startup application will be reject.'}
            onClick={handleCloseRejectPopup}
            onClickConfirm={() => {
              rejectPlusApplications(plusAppId);
              setPlusAppId('');
            }}
          />
        )}

        {exportPopup && (
          <ConfirmPopupComponent
            fileName="New Plus Data"
            onClose={handleCloseExportPopup}
            title={'Are you sure you want to Export latest version req?'}
            subtitle={
              'If you confirm, all forms on this page will be downloaded.'
            }
            onClick={handleCloseExportPopup}
            onClickConfirm={getDataExport}
            data={dataExport}
          />
        )}
        {enabledPlus && (
          <ConfirmPopupComponent
            onClose={() => {
              handleClosePlusPopUp();
            }}
            title={'Are you sure you want to Stop Receiving Forms? '}
            subtitle={
              'If you confirm, application form will not appear on the website.'
            }
            onClick={handleClosePlusPopUp}
            onClickConfirm={() => {
              enabled ? handleClosePlus() : handleReceivingPlus();
            }}
          />
        )}
        {enableCreatePlus && (
          <ConfirmPopupComponent
            onClose={handleCreatePlusPopup}
            title={'Are you sure you want to Create New Plus?'}
            subtitle={'If you confirm, will Create a new Plus'}
            onClick={handleCreatePlusPopup}
            onClickConfirm={() => createNewPlus()}
          />
        )}
      </div>
    </div>
  );
}
export default PlusNewApplications;
