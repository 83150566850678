export enum ApplicationStateEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  ALL = 'ALL',
}

export enum ApplicationEnum {
  PUBLISH = 'PUBLISH',
  DRAFT = 'DRAFT',
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum IDTypeEnum {
  PASSPORT = 'Passport',
  NATIONALID = 'National ID',
  IQAMA = 'Iqama',
}

export enum BrowserEnum {
  CHROME = 'CHROME',
  FIREFOX = 'FIREFOX',
  SAFARI = 'SAFARI',
  Other = 'other',
}

export enum OperatingSystemEnum {
  WINDOWS = 'WINDOWS',
  MACOS = 'MACOS',
  LINUX = 'LINUX',
  Other = 'other',
}
export enum IssueEnum {
  ALL = 'ALL',
  PLUS = 'PLUS',
  INCUBATOR = 'INCUBATOR',
  DISRUPT_COMPANY = 'DISRUPT_COMPANY',
  DISRUPT_GUEST = 'DISRUPT_GUEST',
  DISRUPT_HIDDEN = 'DISRUPT_HIDDEN',
  TOUR = 'TOUR',
  CONTACT_US = 'CONTACT_US',
  PARTNER = 'PARTNER',
  VISIT = 'VISIT',
  ACCESS = 'ACCESS',
  EVENT = 'EVENT',
  ACCESS_DEMO = 'ACCESS_DEMO',
  TRANSPORTATION_PERMIT = 'TRANSPORTATION_PERMIT',
}
export enum PDFEnum {
  YES = 'YES',
  NO = 'NO',
}
export enum StatusEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  BUTTON = 'BUTTON',
}
export enum QuestionsState {
  YES = 'YES',
  NO = 'NO',
}

export enum BookingStateEnum {
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  ALL = 'ALL',
}

export enum BookingDurationEnum {
  asc = 'asc',
  desc = 'desc',
}
