import PlusNewApplications from 'components/admin/plus-components/PlusNewApplications';
import PlusOldApplications from 'components/admin/plus-components/PlusOldApplications';
import TabbarPreviousApplicationsComponentProps from 'components/admin/TabbarApplicationsComponet';
import { getPlusApplicationStatisticsService } from 'core/data-access/plus.service';
import { PlusStatisticsModel } from 'core/types/admin.type';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';

function PlusContainer() {
  const [statistics, setStatistics] = useState<PlusStatisticsModel>();

  const getPlusApplicationsStatistics = useCallback(async () => {
    const res = await getPlusApplicationStatisticsService();
    setStatistics(res);
  }, []);

  useEffect(() => {
    getPlusApplicationsStatistics();
  }, []);

  return (
    <div className="px-5 py-9 desktop:py-7">
      <div className="flex flex-row items-center justify-center px-12 gap-5 mobile:flex-col">
        <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
          <div className="flex flex-col items-center justify-center gap-y-3 pt-5 pb-6">
            <div className="text-4xl font-bold text-orange500">
              {statistics?.application_count}
            </div>
            <div className="text-[28px] font-bold">{t('allApplications')}</div>
          </div>
        </div>
        <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
          <div className="flex flex-col items-center justify-center gap-y-3 pt-5 pb-6">
            <div className="text-4xl font-bold text-orange500">
              {statistics?.accepted_count}
            </div>
            <div className="text-[28px] font-bold">{t('allAccepted')}</div>
          </div>
        </div>
        <div className={`bg-whiteColor w-full h-full rounded-[10px] shadow`}>
          <div className="flex flex-col items-center justify-center gap-y-3 pt-5 pb-6">
            <div className="text-4xl font-bold text-orange500">
              {statistics?.rejected_count}
            </div>
            <div className="text-[28px] font-bold">{t('allrejected')}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center px-3">
        <TabbarPreviousApplicationsComponentProps
          page={<PlusNewApplications />}
          page2={<PlusOldApplications />}
          title1={`${t('newapplications')}`}
          title2={`${t('oldapplications')}`}
        />
      </div>
    </div>
  );
}

export default PlusContainer;
