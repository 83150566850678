import ButtonComponent from 'components/admin/ButtonComponent';
import UploadComponent from 'containers/admin/Aboutus/Team/upload-component';
import { postDisruptcompanies } from 'core/data-access/disrupt.service';
import { handleRequestError } from 'core/helpers/error.helper';
import { disruptAddCompanies } from 'core/types/admin.type';
import { Formik, getIn, useFormik } from 'formik';
import { ChangeEvent, useState } from 'react';
import * as Icon from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import ConfirmPopupComponent from '../ConfirmPopupComponet';

interface StartupPostComponentProps {
  title: string;
  onClose: () => void;
  onSuccess: () => void;
}

function StartupPostComponent(props: StartupPostComponentProps) {
  const handleClose = () => {
    props.onClose();
  };

  // const handleDeleteEmployeeClick = () => {
  //   setDeleteEmployeePopup(true);
  // };

  const handleDeleteClosePopup = () => {
    setDeleteEmployeePopup(false);
  };

  const initialValues: disruptAddCompanies = {
    images: '',
    disrupt_version: '',
  };

  const [deleteEmployeePopup, setDeleteEmployeePopup] = useState(false);
  const validationSchema = Yup.object({
    images: Yup.array().required('Upload the image').max(8),
    disrupt_version: Yup.string().required('Enter disrupt number'),
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageIndex2, setCurrentImageIndex2] = useState(0);
  const pageSize = 4;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnChange: false, // Disable validation on input change
    validateOnBlur: false, // Disable validation on input blur
    onSubmit: async () => {
      await handleClickSend();
    },
  });

  const handleClickSend = () => {
    setDeleteEmployeePopup(true);
  };

  const editPicture = async () => {
    try {
      await postDisruptcompanies(formik.values);
      toast.success('Disrupt has been published successfully!');
    } catch (error) {
      handleRequestError(error);
    }
  };

  const goToPreviousImage = () => {
    if (currentImageIndex <= 0) {
      return;
    }
    setCurrentImageIndex(currentImageIndex - 1);
  };

  const goToNextImage = () => {
    if (
      currentImageIndex >=
      Math.ceil((formik.values.images as string[]).length / pageSize) - 1
    ) {
      return;
    }
    setCurrentImageIndex(currentImageIndex + 1);
  };

  const images = (formik.values.images as string[]).slice(
    currentImageIndex * pageSize,
    (currentImageIndex + 1) * pageSize,
  );

  return (
    <>
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full backdrop-opacity-10 backdrop-invert bg-white/30">
        <div className="rounded-lg bg-whiteColor px-5 py-2 h-[644px] w-[621px]">
          <div className="flex items-end justify-end pt-4">
            <IoMdClose
              size={24}
              className="cursor-pointer text-orange500"
              onClick={handleClose}
            />
          </div>
          <div className="text-[32px] text-start font-bold pl-4 -mt-8 mb-5">
            {props.title}
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async () => {
              const errors = await formik.validateForm();

              if (Object.keys(errors).length === 0) {
                formik.submitForm();
                handleDeleteClosePopup();
              } else {
                formik.setTouched({
                  disrupt_version: true,
                  images: true,
                });
              }
            }}
          >
            <form className="flex flex-col" onSubmit={formik.handleSubmit}>
              <div className="flex flex-col items-start justify-start gap-x-5">
                <div className="flex flex-col w-2/3 gap-2 text-center"></div>
                <label className="flex items-start px-4 text-[20px] justify-start w-full text-start">
                  Disrupt number
                </label>
                <input
                  className={`w-full h-12 px-3 border appearance-none bg-gray500 bg-opacity-20 rounded-xl
                  ${
                    formik.touched.disrupt_version &&
                    formik.errors.disrupt_version
                      ? 'border-[#D50000]'
                      : 'border-neutralGrey'
                  }
                `}
                  placeholder="9"
                  type="number"
                  name="disrupt_version"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('disrupt_version', e.target.value)
                  }
                />
                <p
                  className={`text-error text-sm pl-2 ${
                    formik.touched.disrupt_version &&
                    formik.errors.disrupt_version
                      ? ''
                      : ''
                  }`}
                >
                  {getIn(formik.errors, 'disrupt_version')}
                </p>
                <div className="flex flex-col items-start justify-start pt-5 pl-6">
                  <UploadComponent
                    disabled={(formik.values.images as string[]).length === 8}
                    className={''}
                    name={'images'}
                    hight="h-[220px]"
                    onChange={(img: string) =>
                      formik.setFieldValue('images', [
                        ...(formik.values.images as string[]),
                        img,
                      ])
                    }
                    id={'image'}
                    labelClassName={`${
                      formik.touched.images && formik.errors.images
                        ? 'border-error'
                        : ''
                    }`}
                    image={formik.values.images[currentImageIndex2]}
                    button={'Upload Image'}
                    buttonSize="w-[260px]"
                    formik={
                      <p className="text-sm text-error">
                        {getIn(formik.errors, 'images')}
                      </p>
                    }
                    type={''}
                    onClick={() => {
                      throw new Error('Function not implemented.');
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-start gap-3 pt-2">
                <Icon.HiOutlineChevronLeft
                  onClick={goToPreviousImage}
                  className={`cursor-pointer ${
                    currentImageIndex > 1 ? 'text-orange-500' : 'text-gray-500'
                  }`}
                />
                {images.length === 0
                  ? ''
                  : images.map((image, index) => (
                      <div
                        key={index}
                        className={`flex items-center justify-center w-[54px] h-[52px] rounded-lg bg-slate50 ${
                          index + currentImageIndex * pageSize ===
                          currentImageIndex2
                            ? 'border-[1px] border-orange-500'
                            : ''
                        }`}
                        onClick={() => {
                          setCurrentImageIndex2(
                            index + currentImageIndex * pageSize,
                          );
                        }}
                      >
                        <img src={image} alt="" />
                      </div>
                    ))}
                <Icon.HiOutlineChevronRight
                  onClick={goToNextImage}
                  className={`cursor-pointer ${
                    currentImageIndex <
                    Math.ceil(
                      (formik.values.images as string[]).length / pageSize,
                    ) -
                      1
                      ? 'text-orange-500'
                      : 'text-gray-500'
                  }`}
                />
              </div>
              <div className="flex flex-col items-center justify-center pt-3">
                <ButtonComponent
                  disabled={false}
                  width={'w-[305.46px]'}
                  hight={'h-[48px]'}
                  text={'Publish'}
                  fontsize={'text-base'}
                />
              </div>
            </form>
          </Formik>
        </div>
        <div>
          {deleteEmployeePopup && (
            <ConfirmPopupComponent
              onClose={handleDeleteClosePopup}
              title={'Are you sure you want to publish this disrupt?'}
              subtitle={'If you confirm, disrupt will be published.'}
              onClick={handleDeleteClosePopup}
              onClickConfirm={() => {
                editPicture();
                handleClose();
              }}
              onSuccess={() => {
                handleDeleteClosePopup();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default StartupPostComponent;
