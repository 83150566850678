import Mail from 'assets/images/Mail-icon.png';
import GenralBoxComponent from 'components/admin/box-Genaral-component';
import { ContactUs } from 'core/types/admin.type';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { appRoutesObj } from 'routes/app.paths';

interface ContactProps {
  data?: ContactUs[];
}
function FAQcomponant(props: ContactProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-[50%] bg-white rounded-lg shadow pt-4">
      <GenralBoxComponent
        Heading="Contact Us"
        HeadingColor="BLACK"
        supLabel="Latest FAQ"
      />
      <div className="flex flex-col w-full items-center">
        <div className="grid grid-row-4 pb-2 gap-2 w-full px-4 laptop:w-full laptop:px-5">
          {props.data?.map((item, index) => {
            return (
              <div key={index}>
                <div className="w-full h-[6.5vh] flex flex-row gap-4 justify-start pl-4 bg-slate50 rounded-lg pt-3 laptop:w-full">
                  <img src={Mail} alt="mail" className=" w-7 h-7" />
                  <div className=" text-black font-bold text-xs">
                    {item.title}
                    <div className="font-normal text-xs">
                      {item.description_en}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Link to={appRoutesObj.getFAQPath()}>
          <button className="font-bold text-base text-orange400 flex justify-center mx-auto">
            {t('View More')}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default FAQcomponant;
