interface RecevingFormProps {
  programme: string;
  checked: boolean;
  onClick: () => void;
}
function RecevingFormComponent(props: RecevingFormProps) {
  return (
    <div className="flex flex-row gap-2">
      <span className="font-bold">{props.programme}</span>
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={props.checked}
          readOnly
        />
        <div
          onClick={props.onClick}
          className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange500"
        ></div>
      </label>
    </div>
  );
}

export default RecevingFormComponent;
