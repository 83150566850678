import BookingContiner from 'containers/admin/booking/booking-container';
import BookingListContainer from 'containers/admin/booking/booking-list-container';
import { useState } from 'react';

function BookingPage() {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const startDateString = startDate
    ? startDate.toISOString().split('T')[0]
    : '';
  const handleDateSelect = (date: Date | null) => {
    setStartDate(date);
  };

  return (
    <>
      <BookingContiner startDate={startDate} onSelect={handleDateSelect} />
      <BookingListContainer date={startDateString} />
    </>
  );
}

export default BookingPage;
