import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { downloadDataLink } from 'components/common/csv';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import TeamSkeletonComponent from 'components/common/skeleton-element/team-skeleton';
import { TransportationPermitsModel } from 'core/data-access/transportation-permits';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import {
  getAllApplicantsTransportationPermitsServices, getTransportationPermitsExportServices,
} from 'services/transportation-permits';

import SearchComponent from '../Community/Partners/search-component';

function OldTransportationPermitReservationContainer() {
  const { t } = useTranslation();
  const [val, setVal] = useState<string>('');
  const [permitsData, setPermitsData] = useState<TransportationPermitsModel>();
  const [toggle, setToggle] = useState(new Array(permitsData?.result_list.length).fill(false));
  const [toggleGenralInfo, setToggleGenralInfo] = useState(new Array(permitsData?.result_list.length).fill(false));
  const [dataExport, setDataExport] = useState<string>('');
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  const paginationLimit = 7;
  const place = [1, 1, 1, 1];
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [visibleItems] = useState(7);
  const [loading] = useState(false);
  const [filtered, setFiltered] = useState(permitsData?.result_list);

  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };
  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };

  const getDataExport = async () => {
    try {
      const permitDataExport: string = await getTransportationPermitsExportServices();
      setDataExport(permitDataExport);

      downloadDataLink(permitDataExport, 'Transportation Data');
      toast.success(`Old Data has been exported successfully!`);
    } catch (error) {
      handleRequestError(error);
    }
  };
  const getData = useCallback(async () => {
    try {
      const permitsData: TransportationPermitsModel =
        await getAllApplicantsTransportationPermitsServices(
          false,
          paginationLimit,
          (page - 1) * paginationLimit,
          val ?? null,
        );

      unstable_batchedUpdates(() => {
        setPermitsData(permitsData);
        setFiltered(permitsData.result_list);
        setSkeletonData(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [page,val]);
  useEffect(() => {
    getData();
  }, [getData,val]);

  return (
    <>
      <div className="flex items-center justify-center pt-6 desktop:py-7">
        <div className="flex flex-col w-full px-10 pt-8 pb-2 bg-white rounded-lg">
        <SearchComponent
            onClickValue={(e) => setVal(e.target.value)}
            onClickShearch={getData}
            onClick={() => setVal(val)}
          />
          <div className="flex flex-col w-full">
          <div className="grid items-center w-full grid-cols-6 py-3 text-2xl font-bold">
            <div className="font-bold text-center">{t('app.shared.name')}</div>
              <div className="font-bold text-center">{t('app.shared.nationality')}</div>
              <div className="text-2xl font-bold text-center">
                {t('app.shared.car')}
              </div>
              <div className="font-bold text-center">{t('app.shared.material')}</div>
              <div className="font-bold text-center">{t('app.shared.status')}</div>
            </div>
            {skeletonData ? (
              <div className="flex flex-col items-center py-2">
                {place.map((e, index) => {
                  return (
                    <div key={index}>
                      <TeamSkeletonComponent />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {filtered?.length === 0 ? (
                  <div className="flex flex-col items-center justify-center w-auto gap-5 h-96">
                    <div className="h-[273px] w-[337px] mb-7">
                      <img src="/assets/images/notFound.png" alt="not found" />
                    </div>
                    <h1 className="text-2xl font-bold text-blue300 ">
                      We couldn’t find what you’re looking for
                    </h1>
                  </div>
                ) : (
                  filtered?.slice(0, visibleItems).map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center mb-4"
                    >
                      <div className="flex flex-row items-center justify-between w-full">
                        <div
                          className={`grid grid-cols-6 justify-center items-center bg-greyMenuColor h-full w-full py-4 rounded-t-lg ${
                            toggle[index] ? '' : 'rounded-b-lg'
                          }`}
                        >
                          <div className="text-center">
                            {item.name.substring(0, 30)}
                          </div>
                          <div className="text-center">
                            {item.nationality.substring(0.3)}
                          </div>
                          <div className="text-center">
                            {item.vehicle.substring(0.3)}
                          </div>
                          <div className="text-center">
                            {item.materials[0].equipment.substring(0.3)}
                          </div>

                          <div className="flex flex-wrap items-center justify-center">
                            {item.application_state ===
                            ApplicationStateEnum.ACCEPTED ? (
                              <div className="w-[95px] h-[32px] bg-green-50 text-green-500 rounded-[20px] text-center">
                                {t('accepted')}
                              </div>
                            ) : ApplicationStateEnum.REJECTED ? (
                              <div className="w-[95px] h-[32px] bg-red-50 text-red-500 rounded-[20px] text-center">
                                {t('rejected')}
                              </div>
                            ) : (
                              <div className="w-[95px] h-[32px] bg-red-50 text-gray-500 rounded-[20px] text-center">
                                {t('pending')}
                              </div>
                            )}
                          </div>
                          <div className="pt-2 px-9 desktop:px-32">
                            {toggle[index] ? (
                              <IoIosArrowUp
                                className="flex w-[24px] h-[24px] cursor-pointer"
                                onClick={() => handleToggleClick(index, false)}
                              />
                            ) : (
                              <IoIosArrowDown
                                className="flex w-[24px] h-[24px] cursor-pointer"
                                onClick={() => handleToggleClick(index, true)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {loading && (
                        <div className="fixed flex items-center justify-center inset-[10px]">
                          <Loading />
                        </div>
                      )}
                      {toggle[index] && (
                        <div className="flex justify-center w-full h-full px-4 pb-4 rounded-b-lg bg-slate50">
                          <div className="flex flex-col w-full py-2">
                            <div className="flex flex-row gap-5 pt-9">
                              <div className="flex flex-col w-full">
                                <div
                                  className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                                >
                                  <div className="flex flex-row items-center justify-between w-full px-4">
                                    <div className="text-secondaryGreen400">
                                    {t('generalinfo')}
                                    </div>
                                    {toggleGenralInfo[index] ? (
                                      <IoIosArrowUp
                                        className="flex w-[50px] cursor-pointer pl-5"
                                        onClick={() =>
                                          handleToggleGenralInfoClick(index)
                                        }
                                      />
                                    ) : (
                                      <IoIosArrowDown
                                        className="flex w-[50px] cursor-pointer pl-5"
                                        onClick={() =>
                                          handleToggleGenralInfoClick(index)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                {toggleGenralInfo[index] && (
                                  <div className="flex flex-col w-full gap-2 px-3 py-4 bg-white border border-gray500">
                                    <div>{t('app.shared.driverName')}</div>
                                    <div className=" text-greyChateau">
                                      {item.name}
                                    </div>
                                    <hr className="w-full px-0 opacity" />

                                    <div>{t('app.shared.email')}</div>
                                    <div className=" text-greyChateau">
                                      {item.email}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('mobilenumber')}</div>
                                    <div className=" text-greyChateau">
                                      {item.mobile}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.nationality')}</div>
                                    <div className=" text-greyChateau">
                                      {item.nationality}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.car')}</div>
                                    <div className=" text-greyChateau">
                                      {item.vehicle}
                                    </div>

                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.description')}</div>
                                    <div className=" text-greyChateau">
                                      {item.materials[0].description}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.material')}</div>
                                    <div className=" text-greyChateau">
                                      {item.materials[0].equipment}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.note')}</div>
                                    <div className=" text-greyChateau">
                                      {item.materials[0].notes}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.quantity')}</div>
                                    <div className=" text-greyChateau">
                                      {item.materials[0].quantity}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.date')}</div>
                                    <div className=" text-greyChateau">
                                      {item.permit_date.toString()}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.reason')}</div>
                                    <div className=" text-greyChateau">
                                      {item.reason}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.from')}</div>
                                    <div className=" text-greyChateau">
                                      {item.transfer_from}
                                    </div>
                                    <hr className="w-full opacity-50" />
                                    <div>{t('app.shared.to')}</div>
                                    <div className=" text-greyChateau">
                                      {item.transfer_to}
                                    </div>

                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>

          <div className="px-6">
            <Pagination
              totalPage={Math.ceil(
                (permitsData?.total_count || 0) / paginationLimit,
              )}
              page={page}
              limit={paginationLimit}
              onPageChange={setPage}
              totalCount={permitsData?.total_count || 0}
            />
          </div>
          <div className="flex items-center justify-end px-3 ">

            <Button
              disabled={false}
              width={'w-[305px]'}
              hight={'h-12'}
              text={'Export All'}
              img={<RiFileExcel2Line size={24} />}
              fontsize={'text-base'}
              onClick={() => handleExportClick()}
            />
          </div>
        </div>
        {exportPopup && (
        <ConfirmPopupComponent
          fileName="Events Data"
          onClose={handleCloseExportPopup}
          title={'Are you sure you want to Export All?'}
          subtitle={
            'If you confirm, all forms on this page will be downloaded.'
          }
          onClick={handleCloseExportPopup}
          onClickConfirm={getDataExport}
          data={dataExport}
        />
      )}
      </div>
    </>
  );
}

export default OldTransportationPermitReservationContainer;
