import { Box } from 'components/user/box-component';
import { useTranslation } from 'react-i18next';
interface statisticss {
  all_applications?: number;
  all_visitors?: number;
  all_startups?: number;
}
function LandingStatisticssComponent(props: statisticss) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-3 p-5 text-center">
      <Box
        label={t('allApplications')}
        Number={props?.all_applications?.toString() || ''}
      />
      <Box
        label={t('allVisitors')}
        Number={props?.all_visitors?.toString() || ''}
      />
      <Box
        label={t('allstartups')}
        Number={props?.all_startups?.toString() || ''}
      />
    </div>
  );
}

export default LandingStatisticssComponent;
