class AppRoutes {
  /**
   * The AppRoutes class defines the `getInstance` method that lets clients access
   * the unique AppRoutes instance.
   */
  private static instance: AppRoutes;
  /**
   * The AppRoutes's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  /**
   * The static method that controls the access to the singleton instance.
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AppRoutes {
    if (!this.instance) {
      return (AppRoutes.instance = new AppRoutes());
    }
    return this.instance;
  }

  // paths
  getBasePath(): string {
    return '/';
  }

  getExamplePagePath(): string {
    return `/address/:addressId/view`;
  }

  getExamplePageUrl(): string {
    return this.getExamplePagePath();
  }

  // Example on a path with dynamic variables
  getExampleByIdPagePath(): string {
    return `/example/:id`;
  }
  // login and forget password
  getLogInPagePath(): string {
    return `/login`;
  }
  getForgetPasswordPagePath(): string {
    return `/forget`;
  }
  getSetNewPassPath(): string {
    return `/set-new-password`;
  }

  getExampleByIdPageUrl(id: string): string {
    return this.getExampleByIdPagePath().replace(':id', id);
  }
  // ./Example
  // DashBoard
  getDashboardPagePath(): string {
    return `/dashboard`;
  }

  getDashboardPageUrl(): string {
    return this.getDashboardPagePath();
  }
  // AboutUs
  getTeamPagePath(): string {
    return `/aboutus/team`;
  }

  getTeamPageUrl(): string {
    return this.getTeamPagePath();
  }
  getFAQPath(): string {
    return `/aboutus/FAQ`;
  }
  getFAQPageUrl(): string {
    return this.getFAQPath();
  }
  getIssuePath(): string {
    return `/aboutus/issue`;
  }
  getIssuePageUrl(): string {
    return this.getIssuePath();
  }
  // Community
  getEventsPath(): string {
    return `/community/event`;
  }

  getEventsPageUrl(): string {
    return this.getEventsPath();
  }
  getPartnersPath(): string {
    return `/community/partners`;
  }

  getPartnersPageUrl(): string {
    return this.getPartnersPath();
  }
  getStartUpPath(): string {
    return `/community/startup`;
  }

  getStartUpPageUrl(): string {
    return this.getStartUpPath();
  }
  getVisitPath(): string {
    return `/community/visit`;
  }

  getVisitPageUrl(): string {
    return this.getVisitPath();
  }
  getTourPath(): string {
    return `/community/tour`;
  }

  getTourPageUrl(): string {
    return this.getTourPath();
  }
  // Media
  getMediaPath(): string {
    return `/media/media`;
  }

  getMediaPageUrl(): string {
    return this.getMediaPath();
  }
  getNewsPath(): string {
    return `/media/news`;
  }

  getNewPageUrl(): string {
    return this.getNewsPath();
  }
  // create news letter
  getCreateNewsLetterPath(): string {
    return `/media/news/create`;
  }
  getCreateNewsLetterPageUrl(): string {
    return this.getCreateNewsLetterPath();
  }
  // edit news letter
  getEditNewsLetterIdPagePath(): string {
    return this.getNewsPath() + "/:newsId";
  }
  getEditNewsLetterIdPageUrl(newsId: string): string {
    return this.getEditNewsLetterIdPagePath().replace(':newsId', newsId);
  }
  //  video letter
  getCreateNewsVideoPath(): string {
    return `/latest-events/create`;
  }
  getCreateNewsVideoPageUrl(): string {
    return this.getCreateNewsVideoPath();
  }

  getLatestEventsPagePath(): string {
    return '/latest-events';
  }
  getLatestEventsPagePageeUrl(): string {
    return this.getLatestEventsPagePath();
  }

  getEditLatestEventsIdPagePath(): string {
    return this.getLatestEventsPagePath() + "/:newsId";
  }
  getEditLatestEventsIdPageUrl(newsId: string): string {
    return this.getEditLatestEventsIdPagePath().replace(':newsId', newsId);
  }
  //
  getGalleryPath(): string {
    return `/media/gallery`;
  }

  getGalleryPageUrl(): string {
    return this.getGalleryPath();
  }
  // Programs

  // Disrupt
  getDisruptDashboardPagePath(): string {
    return '/programs/disrupt-dashboard';
  }

  getDisruptDashboardPagePathUrl(): string {
    return this.getDisruptDashboardPagePath();
  }

  getIncubatorsPath(): string {
    return `/programs/incubators`;
  }
  getIncubatorsPageUrl(): string {
    return this.getIncubatorsPath();
  }
  getPlusPath(): string {
    return `/programs/plus`;
  }
  getPlusPageUrl(): string {
    return this.getPlusPath();
  }
  getMISAPath(): string {
    return `/media/visit`;
  }
  getMISAPageUrl(): string {
    return this.getMISAPath();
  }

  getEntryPermissionPagePath(): string {
    return `/entry-permission`;
  }
  getEntryPermissionPageUrl(): string {
    return this.getEntryPermissionPagePath();
  }

  getEventsReservationPagePath(): string {
    return `/events-reservation`;
  }
  getEventsReservationPageUrl(): string {
    return this.getEventsReservationPagePath();
  }
  // ./Old Applications
  getOldApplicationsPagePath(): string {
    return '/programs/disrupt-guests';
  }

  getPreviousApplicationsPageUrl(): string {
    return this.getOldApplicationsPagePath();
  }
  // New Applications
  getNewApplicationsPagePath(): string {
    return '/programs/disrupt-startups';
  }

  getNewApplicationsPageUrl(): string {
    return this.getNewApplicationsPagePath();
  }
  //CohortsPlusPage
  getCohortsPlusPagePagePath(): string {
    return '/CohortsPlus';
  }

  getCohortsPlusPagePageUrl(): string {
    return this.getCohortsPlusPagePagePath();
  }
  getBookingPagePath(): string {
    return '/Booking';
  }

  getBookingPageUrl(): string {
    return this.getBookingPagePath();
  }
  getTransportationPermitsPagePath(): string {
    return '/transportation-permits';
  }

  getTransportationPermitsPageUrl(): string {
    return this.getTransportationPermitsPagePath();
  }
  getTablePagePath(): string {
    return '/table';
  }

  getTablePageeUrl(): string {
    return this.getTablePagePath();
  }

    getPNUPagePath(): string {
      return '/pnu';
    }
    getPNUPageUrl(): string {
      return this.getPNUPagePath();
    }
}

export const appRoutesObj = AppRoutes.getInstance();
