import ButtonComponent from 'components/admin/ButtonComponent';
import Breadcrumb from 'components/common/ui/bread-crumb-navigation';
import InputField from 'components/common/ui/InputField';
import TextAreaField from 'components/common/ui/TextAreaField';
import UploadComponent from 'containers/admin/Aboutus/Team/upload-component';
import { UploadImageTypeEnum } from 'core/enums/example.enum';
import { MediaNewsModel } from 'core/types/admin.type';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';


interface IMediaCreateComponent {
  initialValues: MediaNewsModel;
  validationSchema: any;
  typeFile?: 'image' | 'video';
  onSubmit: (values: MediaNewsModel) => Promise<void>;
}

function MediaCreateComponent({
  initialValues,
  validationSchema,
  onSubmit,
  typeFile = 'image',
}: IMediaCreateComponent) {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="px-4">
      <Breadcrumb />
      <div className="px-4 py-8 bg-white rounded-lg">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <div className="grid grid-cols-3 gap-10 tablet:grid-cols-1">
            <div className="col-span-2 tablet:col-auto tablet:order-2">
              <InputField
                label={'component.Media.craete.input.arabic'}
                name="title_ar"
                placeholder={`${t(
                  'component.Media.craete.input.placeholder',
                )}`}
                isValid={formik.touched.title_ar && !formik.errors.title_ar}
                errorMsg={
                  formik.touched.title_ar && formik.errors.title_ar
                    ? formik.errors.title_ar
                    : ''
                }
                value={formik.values.title_ar || ''}
                onChange={formik.handleChange}
              />
              <TextAreaField
                name="description_ar"
                label="component.Media.craete.textArea.arabic"
                placeholder={`${t(
                  'component.Media.craete.textArea.placeholder',
                )}`}
                limit={2500}
                minLength={3}
                onChange={formik.handleChange}
                isValid={
                  formik.touched.description_ar && !formik.errors.description_ar
                }
                errorMsg={
                  formik.touched.description_ar && formik.errors.description_ar
                    ? formik.errors.description_ar
                    : ''
                }
                value={formik.values.description_ar || ''}
              />
              <InputField
                label={'component.Media.craete.input.english'}
                name="title_en"
                placeholder={`${t(
                  'component.Media.craete.input.placeholder',
                )}`}
                isValid={formik.touched.title_en && !formik.errors.title_en}
                errorMsg={
                  formik.touched.title_en && formik.errors.title_en
                    ? formik.errors.title_en
                    : ''
                }
                value={formik.values.title_en || ''}
                onChange={formik.handleChange}
              />
              <TextAreaField
                name="description_en"
                label="component.Media.craete.textArea.english"
                placeholder={`${t(
                  'component.Media.craete.textArea.placeholder',
                )}`}
                limit={2500}
                minLength={3}
                onChange={formik.handleChange}
                isValid={
                  formik.touched.description_en && !formik.errors.description_en
                }
                errorMsg={
                  formik.touched.description_en && formik.errors.description_en
                    ? formik.errors.description_en
                    : ''
                }
                value={formik.values.description_en || ''}
              />
            </div>
            <div className="grid justify-end tablet:justify-center">
              <UploadComponent
                typeFile={typeFile}
                type={UploadImageTypeEnum.News}
                name="url"
                onChange={(img: string) => formik.setFieldValue('url', img)}
                id="url"
                labelClassName={`w-[267px] h-[220px] rounded-lg ${
                  formik.touched.url && formik.errors.url ? 'border-error' : ''
                }`}
                buttonSize="w-[267px] h-[48px]"
                image={formik.values.url || ''}
                button={
                  typeFile === 'video'
                    ? t('component.Media.upload.video')
                    : t('component.Media.upload.image')
                }
                formik={
                  formik.touched.url && formik.errors.url ? (
                    <p className="px-3 text-sm text-error">
                      {t(
                        typeFile === 'image'
                          ? 'component.Media.upload.image.required'
                          : 'component.Media.upload.video.required',
                      )}
                    </p>
                  ) : null
                }
              />
            </div>
            <div className="grid justify-end order-3 w-full col-span-3 mt-auto tablet:col-span-full tablet:justify-center">
              <ButtonComponent
                disabled={false}
                width="w-[267px]"
                hight="h-[48px]"
                text={t('component.Media.publish')}
                fontsize="text-base"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MediaCreateComponent;
