import Button from 'components/admin/ButtonComponent';
import { t } from 'i18next';
import { ChangeEventHandler } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiOutlinePlus } from 'react-icons/hi';

interface SerarchAndButtonProps {
  InputOnChange: ChangeEventHandler<HTMLInputElement> | undefined;
  SearchOnClick: () => void;
  buttonOnClick: () => void;
}
function SearchAndCreateIncubatorComponent(props: SerarchAndButtonProps) {
  return (
    <div
      className={`inline-flex justify-start items-start ml-7 mt-8 gap-2 mb-4`}
    >
      <div className="relative flex items-center">
        <AiOutlineSearch className="w-[24px] h-[24px] ml-2 absolute text-gray-400" />
        <input
          type="search"
          id="default-search"
          className="block h-[48px] w-[382px] p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
          placeholder={`${t('search')}`}
          required
          onChange={props.InputOnChange}
        />
      </div>
      <Button
        disabled={false}
        width={'w-[146px]'}
        hight={'h-[48px]'}
        text={`${t('search')}`}
        fontsize={''}
        onClick={props.SearchOnClick}
      />
      <div className="h-12 w-px bg-greyBorderBottom" />
      <button
        className="flex flex-row w-36 h-12 justify-center gap-2 items-center border-orange500 border-[1px] rounded-lg text-orange500 cursor-pointer"
        onClick={props.buttonOnClick}
      >
        <HiOutlinePlus /> New Incubator
      </button>
    </div>
  );
}

export default SearchAndCreateIncubatorComponent;
