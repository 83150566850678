import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import IncubatorsSkeleton from 'components/common/skeleton-element/incubators-skeleton';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllPartnerModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { getAllPartnerServices } from 'services/partner-service';

import SearchComponent from './search-component';

function OldGaragePartnerContainer() {
  const { t } = useTranslation();
  const place = [1, 1, 1, 1];
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const [partnerData, setPartnerData] = useState<AllPartnerModel>();
  const paginationLimit = 5;
  const [visibleItems, setVisibleItems] = useState(1);
  const [loading] = useState(false);
  const [val, setVal] = useState('');
  const [filtered, setFiltered] = useState(partnerData?.result_list);

  const [toggle, setToggle] = useState(
    new Array(partnerData?.result_list.length).fill(false),
  );
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(partnerData?.result_list.length).fill(false),
  );

  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };

  const getData = useCallback(async () => {
    try {
      const data: AllPartnerModel = await getAllPartnerServices(
        ApplicationStateEnum.ALL,
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
        val ?? null,
      );
      unstable_batchedUpdates(() => {
        setPartnerData(data);
        setFiltered(data.result_list);
        setSkeletonData(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [setPartnerData, visibleItems, val]);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div className="">
      {/* laptop:w-9/12 */}
      <div className="pt-6 desktop:py-7 flex justify-center items-center">
        <div className="flex flex-col rounded-lg bg-white w-full px-10 pt-8 pb-2">
          <SearchComponent
            onClickValue={(e) => setVal(e.target.value)}
            onClickShearch={getData}
            // onClick={() => {
            //   throw new Error('Function not implemented.');
            // }}
          />
          <div className="flex flex-col w-full">
            <div className="grid grid-cols-3 w-full font-bold text-lg py-7 items-center">
              <div className="text-center text-base">{t('Partner')}</div>
              <div className="text-center text-lg">{t('Link')}</div>
              <div className="text-center text-sm">{t('Status')}</div>
            </div>
            {skeletonData ? (
              <div className="flex flex-col items-center">
                {place.map((e, index) => {
                  return (
                    <div key={index}>
                      <IncubatorsSkeleton />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="">
                {partnerData?.total_count === 0 ? (
                  <div className=" ml-[35%] w-auto ">
                    <div className="h-[273px] w-[337px] mb-7">
                      <img src="/assets/images/notFound.png" alt="not found" />
                    </div>
                    <h1 className="text-2xl text-blue300 font-bold ">
                      {' We couldn’t find what you’re looking for '}
                    </h1>
                  </div>
                ) : (
                  filtered?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center mb-4"
                    >
                      <div
                        className={`grid grid-cols-3 bg-greyMenuColor h-full w-full py-1 rounded-t-lg ${
                          toggle[index] ? '' : 'rounded-b-lg'
                        }`}
                      >
                        <div className="flex justify-center items-center">
                          {item.company}
                        </div>
                        <div className="flex justify-center items-center">
                          {item.partnership_type}
                        </div>

                        <div className="flex justify-center items-center">
                          {item.application_state}
                          {toggle[index] ? (
                            <IoIosArrowUp
                              className="flex justify-center items-center w-[50px] cursor-pointer"
                              size={24}
                              onClick={() => handleToggleClick(index, false)}
                            />
                          ) : (
                            <IoIosArrowDown
                              className="flex justify-center items-center w-[50px] cursor-pointer"
                              size={24}
                              onClick={() => handleToggleClick(index, true)}
                            />
                          )}
                        </div>
                      </div>

                      {loading && (
                        <div className="fixed flex items-center justify-center inset-[10px]">
                          <Loading />
                        </div>
                      )}
                      {/*drop down list */}
                      {toggle[index] && (
                        <div className="flex flex-col w-full py-4 px-8 bg-slate50 shadow">
                          <div className="flex flex-row bg-neutralGre50 border h-[52px] border-secondaryGreen400 justify-between text-center items-center px-4">
                            <div className="flex text-secondaryGreen400">
                              {t('generalinfo')}
                            </div>
                            {toggleGenralInfo[index] ? (
                              <IoIosArrowUp
                                size={24}
                                className=""
                                onClick={() =>
                                  handleToggleGenralInfoClick(index)
                                }
                              />
                            ) : (
                              <IoIosArrowDown
                                size={24}
                                onClick={() =>
                                  handleToggleGenralInfoClick(index)
                                }
                              />
                            )}
                          </div>
                          {toggleGenralInfo[index] && (
                            <div className="flex flex-col divide-y w-full justify-between bg-white border border-gray500 px-3 gap-2 py-4">
                              <div>
                                {t('name')}
                                <p className=" text-textGrayColor">
                                  {item.first_name + ' ' + item.last_name}
                                </p>
                              </div>
                              <div>
                                {t('Email address')}
                                <p className=" text-textGrayColor">
                                  {item.email}
                                </p>
                              </div>
                              <div>
                                {t('Mobile number')}
                                <p className=" text-textGrayColor">
                                  {item.mobile}
                                </p>
                              </div>
                              <div>
                                {t('company')}
                                <p className=" text-textGrayColor">
                                  {item.company}
                                </p>
                              </div>
                              <div>
                                {t('Partnership Type')}
                                <p className=" text-textGrayColor">
                                  {item.partnership_type}
                                </p>
                              </div>
                              <div>
                                {t('position')}
                                <p className=" text-textGrayColor">
                                  {item.position}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
          <div className="px-4">
            <Pagination
              totalPage={Math.ceil(
                (partnerData?.total_count || 0) / paginationLimit,
              )}
              page={visibleItems}
              limit={paginationLimit}
              onPageChange={setVisibleItems}
              totalCount={partnerData?.total_count || 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default OldGaragePartnerContainer;
