import BookingListComponent from 'components/admin/booking/booking-list-component';
import PopupEditBookingComponent from 'components/admin/booking/popup-edit-booking-component';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import MessageSkeletonComponent from 'components/admin/message-skeleton-component/message-skeleton-component';
import EmptySearchComponent from 'components/common/empty-search';
import Pagination from 'components/common/Pagination/pagination';
import SearchComponent from 'containers/admin/Community/Partners/search-component';
import {
  BookingDurationEnum,
  BookingStateEnum,
} from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import {
  AllBookingModel,
  ResoursModel,
  UpdateBookingModel,
} from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlineXMark } from 'react-icons/hi2';
import {
  getBookingService,
  getResourceByIdService,
  putBookingByIdService,
  putBookingService,
} from 'services/booking.service';

interface BookingListContainerProps {
  date: string;
}

function BookingListContainer({ date }: BookingListContainerProps) {
  const { t } = useTranslation();
  const paginationLimit = 7;
  const [visibleItems, setVisibleItems] = useState(1);
  const [data, setData] = useState<AllBookingModel>();
  const [resours, setResours] = useState<ResoursModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [val, setVal] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [editBookingIndex] = useState<number>(0);
  const [editBookingPopup, setEditBookingPopup] = useState<boolean>(false);
  const [resourceID, setResourceID] = useState<string>('');
  const [editBookingID, setEditBookingID] = useState<string>('');
  const [sortDurationOrder, setSortDurationOrder] =
    useState<BookingDurationEnum>(BookingDurationEnum.asc);
  const [sortStatusOrder, setSortStatusOrder] =
    useState<BookingStateEnum | null>(BookingStateEnum.ALL);

  const handleEditBookingClick = (id: string) => {
    const selectedBooking = data?.result_list.find(
      (booking) => booking.id === id,
    );
    if (selectedBooking) {
      unstable_batchedUpdates(() => {
        setEditBookingID(id);
        setResourceID(selectedBooking.resource_id || '');
        setEditBookingPopup(true);
      });
    }
  };

  const handleDeleteBookingClick = (id: string) => {
    const selectedBooking = data?.result_list.find(
      (booking) => booking.id === id,
    );
    setSelectedIds([selectedBooking?.id || '']);
    setShowDeletePopup(true);
  };
  const handleEditClosePopup = () => {
    setEditBookingPopup(false);
  };
  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
    setSelectedIds([]); // Clear selected IDs
    setIsCheckedAll(false); 
  };
  const handleAcceptClick = () => {
    setShowDeletePopup(true);
  };
  const handleCheckAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedIds(data?.result_list?.map((r) => r.id || '') || []);
      setIsCheckedAll(true);
    } else {
      setSelectedIds([]);
      setIsCheckedAll(false);
    }
  };

  const handleAddId = (id: string) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((i) => i !== id)
        : [...prevSelectedIds, id],
    );
  };

  const handleSortDuration = () => {
    setSortDurationOrder((prevOrder) =>
      prevOrder === BookingDurationEnum.asc
        ? BookingDurationEnum.desc
        : BookingDurationEnum.asc,
    );
  };

  const handleSortStatus = () => {
    setSortStatusOrder((prevOrder) => {
      switch (prevOrder) {
        case BookingStateEnum.CONFIRMED:
          return BookingStateEnum.CANCELED;
        case BookingStateEnum.CANCELED:
          return BookingStateEnum.ALL;
        case BookingStateEnum.ALL:
        default:
          return BookingStateEnum.CONFIRMED;
      }
    });
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const dataBooking: AllBookingModel = await getBookingService(
        searchValue,
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
        BookingStateEnum.ALL,
        BookingDurationEnum.asc,
        date,
      );
      setData(dataBooking);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, [visibleItems, searchValue, date]);

  const deleteBooking = async () => {
    try {
      await putBookingService(selectedIds);
      unstable_batchedUpdates(() => {
        setSelectedIds([]);
        setIsCheckedAll(false);
      });
      getData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  const getResourceById = useCallback(
    async (resourceId: string) => {
      try {
        const resours = await getResourceByIdService(resourceId, date);
        setResours(resours);
      } catch (error) {
        handleRequestError(error);
      }
    },
    [resours, resourceID],
  );

  const editBooking = async (values: UpdateBookingModel, bookingId: string) => {
    try {
      await putBookingByIdService(values, bookingId);
      getData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  useEffect(() => {
    if (editBookingPopup && resourceID) {
      getResourceById(resourceID);
    }
    getData();
  }, [editBookingPopup, resourceID, editBookingID, getData, date]);

  return (
    <div className="w-full px-4 pt-3">
      <div className="bg-white h-[564px] rounded-lg px-3 py-5 pt-6">
        <div className="flex flex-col">
          <div className="flex items-center justify-between w-full gap-6 whitespace-nowrap">
            <label className="flex whitespace-nowrap w-fit gap-2 items-center text-base text-[#54595E]">
              <input
                type="checkbox"
                id="lang"
                name="SelectAll"
                className="w-4 h-4 border-[2px]"
                checked={isCheckedAll}
                onChange={(e) => handleCheckAll(e.target.checked)}
              />
              {t('component.booking.selectAll')}
            </label>
            <SearchComponent
              onClickValue={(e) => setVal(e.target.value)}
              onClickShearch={() => setSearchValue(val)}
            />
            {(isCheckedAll || selectedIds.length > 0) && (
              <button
                className="flex flex-row items-center justify-center h-12 gap-2 px-2 py-2 text-base border rounded-lg whitespace-nowrap border-orange500 text-orange500"
                onClick={handleAcceptClick}
              >
                <HiOutlineXMark size={24} className="text-orange500" />
                {t('component.booking.button.cancel')}
              </button>
            )}
          </div>
          {isLoading ? (
            <div>
              {Array.from({ length: 7 }).map((_, index) => (
                <div key={`skeleton-${index}`}>
                  <MessageSkeletonComponent />
                </div>
              ))}
            </div>
          ) : (
            <div>
              {data?.total_count === 0 ? (
                <div className="mt-20">
                  <EmptySearchComponent />
                </div>
              ) : (
                <div className="z-50 w-full pt-1">
                  <BookingListComponent
                    list={data?.result_list || []}
                    handleAddId={handleAddId}
                    checkedIds={selectedIds}
                    EditBooking={handleEditBookingClick}
                    CancelBooking={handleDeleteBookingClick}
                    sortStatus={handleSortStatus}
                    sortDuration={handleSortDuration}
                    sortDurationOrder={sortDurationOrder}
                    sortStatusOrder={sortStatusOrder}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="pt-5 mx-auto desktop:pt-0 desktop:pb-7">
        <Pagination
          totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
          page={visibleItems}
          limit={paginationLimit}
          onPageChange={setVisibleItems}
          totalCount={data?.total_count || 0}
        />
      </div>
      <div className="absolute top-[400px] right-2">
        {showDeletePopup && (
          <ConfirmPopupComponent
            onClose={handleCloseDeletePopup}
            title="component.ConfirmPopupComponent.booking.title"
            subtitle="component.ConfirmPopupComponent.booking.subtitle"
            onClickConfirm={() => deleteBooking()}
          />
        )}
        {editBookingPopup && (
          <PopupEditBookingComponent
            title={t('component.booking.popup.modifyBookingTime.title')}
            data={data?.result_list[editBookingIndex] as UpdateBookingModel}
            onClose={handleEditClosePopup}
            onClickConfirm={editBooking}
            resourceId={resourceID}
            bookingId={editBookingID}
            list={resours?.events || []}
          />
        )}
      </div>
    </div>
  );
}

export default BookingListContainer;
