import i18n from 'core/configs/i18n';
import { GalleryTypeEnum } from 'core/enums/example.enum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface GalleryPopupMenueProps {
  onGallaryChange: (val: GalleryTypeEnum) => void;
  selectedGalleryType: GalleryTypeEnum;
}

function GalleryPopupMenue({
  onGallaryChange,
  selectedGalleryType,
}: GalleryPopupMenueProps) {
  const { t } = useTranslation();
  const [openTab, setOpenTab] = useState<GalleryTypeEnum>(selectedGalleryType);

  return (
    <div>
      <div
        className={`flex flex-row space-x-4 h-[24px] justify-end items-center mt-[16px] mobile:w-auto 
        -ml-2 w-[390px] pb-4
        `}
      >
        <div className="container">
          <div className="flex flex-row gap-2">
            <div>
              <div
                onClick={() => {
                  setOpenTab(GalleryTypeEnum.Plus);
                  onGallaryChange(GalleryTypeEnum.Plus);
                }}
                className={`inline-block py-1 cursor-pointer ${
                  i18n.language === 'ar'
                    ? 'font-ar text-lg'
                    : 'font-en text-base mobile:text-sm'
                } ${
                  openTab === GalleryTypeEnum.Plus
                    ? 'border border-orange500 rounded-2xl bg-orange500 text-white px-3'
                    : 'text-orange500 border font-normal rounded-2xl border-orange500 px-3'
                }`}
              >
                {t('Plus')}
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  setOpenTab(GalleryTypeEnum.Disrupt);
                  onGallaryChange(GalleryTypeEnum.Disrupt);
                }}
                className={`inline-block  px-2 py-1 cursor-pointer ${
                  i18n.language === 'ar'
                    ? 'font-ar text-lg'
                    : 'font-en text-base mobile:text-sm'
                } ${
                  openTab === GalleryTypeEnum.Disrupt
                    ? ' border border-orange500 rounded-2xl bg-orange500 text-white px-3'
                    : 'text-orange500 border font-normal rounded-2xl border-orange500 px-3 '
                }`}
              >
                {t('Disrupt')}
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  setOpenTab(GalleryTypeEnum.INCUBATOR);
                  onGallaryChange(GalleryTypeEnum.INCUBATOR);
                }}
                className={`inline-block px-2 py-1 cursor-pointer ${
                  i18n.language === 'ar'
                    ? 'font-ar text-lg'
                    : 'font-en text-base mobile:text-sm'
                } ${
                  openTab === GalleryTypeEnum.INCUBATOR
                    ? 'border border-orange500 rounded-2xl bg-orange500 text-white px-3'
                    : 'text-orange500 border font-normal rounded-2xl border-orange500 px-3'
                }`}
              >
                {t('Incubator')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GalleryPopupMenue;
