import ButtonComponent from 'components/admin/ButtonComponent';
import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { downloadDataLink } from 'components/common/csv';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import IncubatorsSkeleton from 'components/common/skeleton-element/incubators-skeleton';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { BookAVisitIsOpenModel, BookVisitModel } from 'core/types/user.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { appRoutesObj } from 'routes/app.paths';
import * as Services from 'services/visit-services';
import { getBookVisitExportServices } from 'services/visit-services';

function NewVisitContainer() {
  const place = [1, 1, 1, 1];
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const refresh = localStorage.getItem('refresh');
  const redirect = localStorage.getItem('redirect');
  const Nav = useNavigate();
  const [bookVisitModel, setBookVisitModel] = useState<BookVisitModel>();
  const [, setFiltered] = useState(bookVisitModel?.result_list);
  const paginationLimit = 5;
  const [page, setPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [visibleItems] = useState(7);
  const [loading] = useState(false);
  const [val, setVal] = useState('');
  const [acceptPopup, setAcceptPopup] = useState(false);
  const [dataExport, setDataExport] = useState<string>('');
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  const { t } = useTranslation();

  const [toggle, setToggle] = useState(
    new Array(bookVisitModel?.result_list.length).fill(false),
  );
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(bookVisitModel?.result_list.length).fill(false),
  );
  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };
  const handleSearchClick = () => {
    if (val === '') {
      setFiltered(bookVisitModel?.result_list);
      return;
    }
    const filterBySearch = bookVisitModel?.result_list.filter((item) => {
      item.first_name.toLowerCase().includes(val.toLowerCase());
    });
    setFiltered(filterBySearch);
  };

  const handleCloseAcceptPopup = () => {
    setAcceptPopup(false);
  };

  const handleCloseRejectPopup = () => {
    setRejectPopup(false);
  };
  const handleExportClick = () => {
    setExportPopup(true);
  };
  const getDataExport = async () => {
    try {
      const visitDataExport: string = await getBookVisitExportServices();
      setDataExport(visitDataExport);
      downloadDataLink(visitDataExport, 'Visits Data');

      toast.success(`Data has been exported successfully!`);
    } catch (error) {
      handleRequestError(error);
    }
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };
  const getData = useCallback(async () => {
    try {
      const visitData: BookVisitModel = await Services.getVisitService(
        ApplicationStateEnum.PENDING,
        paginationLimit,
        (page - 1) * paginationLimit,
        null,
      );
      unstable_batchedUpdates(() => {
        setBookVisitModel(visitData);
        setFiltered(visitData.result_list);
        setSkeletonData(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [page]);

  const getIsOpen = useCallback(async () => {
    try {
      const isOpen: BookAVisitIsOpenModel =
        await Services.getBookVisitIsOpenServices();
      setIsOpen(isOpen.is_open);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);
  useEffect(() => {
    getData();
    getIsOpen();
  }, [getData, getIsOpen]);

  if (refresh === 'true') {
    getData();
    localStorage.setItem('refresh', 'false');
  }
  if (redirect === 'true') {
    Nav(appRoutesObj.getLogInPagePath());
    localStorage.clear();
  }
  return (
    <div>
      <div className="pt-6 desktop:py-7 flex justify-center items-center">
        <div className="flex flex-col rounded-lg bg-white w-full px-10 pt-8 pb-2">
          <div className="flex flex-row justify-start items-start pb-3 gap-4">
            <input
              onChange={(e) => setVal(e.target.value)}
              type="search"
              id="default-search"
              className="block h-12 w-1/2 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
              placeholder={`${t('search')}`}
              required
            />
            <ButtonComponent
              disabled={false}
              width={'w-[143px]'}
              hight={'h-[43px]'}
              text={`${t('search')}`}
              fontsize={'text-base'}
              onClick={handleSearchClick}
            />
          </div>
          <div className="flex flex-col w-full">
            <div className="grid grid-cols-6 w-full font-bold text-2xl py-3 pr-48 pl-7 gap-20 items-center">
              <div className="text-center">{t('Name')}</div>
              <div className="text-center">{t('Nationality')}</div>
              <div className="text-center">{t('Company')}</div>
              <div className="text-center whitespace-nowrap">
                {t('Visit Date')}
              </div>
              <div className="text-center whitespace-nowrap">
                {t('Invited By')}
              </div>
              <div className="text-center">{t('Status')}</div>
            </div>
            {skeletonData ? (
              <div className="flex flex-col items-center ">
                {place.map((e, index) => {
                  return (
                    <div key={index}>
                      <IncubatorsSkeleton />
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                {bookVisitModel?.total_count === 0 ? (
                  <div className="w-auto h-96 flex flex-col gap-5 items-center justify-center">
                    <div className="h-[273px] w-[337px] mb-7">
                      <img src="/assets/images/notFound.png" alt="not found" />
                    </div>
                    <h1 className="text-2xl text-blue300 font-bold ">
                      We couldn’t find what you’re looking for
                    </h1>
                  </div>
                ) : (
                  bookVisitModel?.result_list
                    .slice(0, visibleItems)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center mb-4"
                      >
                        <div
                          className={`grid grid-cols-7 justify-center items-center bg-greyMenuColor h-full w-full py-4 rounded-t-lg ${
                            toggle[index] ? '' : 'rounded-b-lg'
                          }`}
                        >
                          <div className="text-center">{item.first_name}</div>
                          <div className="text-center">{item.last_name}</div>
                          <div className="text-center">{item.nationality}</div>
                          <div className="text-center">{item.company}</div>
                          <div className="text-center">{item.visitDate}</div>
                          <div className="flex items-center justify-center gap-2">
                            <button className="w-[95px] h-[37px] bg-darkRedColor text-whiteColor rounded-[4px] ">
                              {t('Reject')}
                            </button>
                            <button className="w-[95px] h-[37px] bg-lightGreenColor text-whiteColor rounded-[4px]">
                              {t('Accept')}
                            </button>
                          </div>
                          <div className="pt-2 pl-9 desktop:pl-32">
                            {toggle[index] ? (
                              <IoIosArrowUp
                                className="flex w-[24px] h-[24px] cursor-pointer"
                                onClick={() => handleToggleClick(index, false)}
                              />
                            ) : (
                              <IoIosArrowDown
                                className="flex w-[24px] h-[24px] cursor-pointer"
                                onClick={() => handleToggleClick(index, true)}
                              />
                            )}
                          </div>
                        </div>

                        {loading && (
                          <div className="fixed flex items-center justify-center inset-[10px]">
                            <Loading />
                          </div>
                        )}
                        {toggle[index] && (
                          <div className="px-4 pb-4 bg-slate50 w-full flex justify-center h-full rounded-b-lg">
                            <div className="flex flex-col py-2">
                              <div className="flex flex-row gap-5 pt-9">
                                <div className="flex flex-col">
                                  <div
                                    className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-[1200px] h-[52px] items-center`}
                                  >
                                    <div className="flex flex-row justify-between w-full items-center px-4">
                                      <div className="text-secondaryGreen400">
                                        General info
                                      </div>
                                      {toggleGenralInfo[index] ? (
                                        <IoIosArrowUp
                                          className="flex w-[50px] cursor-pointer pl-5"
                                          onClick={() =>
                                            handleToggleGenralInfoClick(index)
                                          }
                                        />
                                      ) : (
                                        <IoIosArrowDown
                                          className="flex w-[50px] cursor-pointer pl-5"
                                          onClick={() =>
                                            handleToggleGenralInfoClick(index)
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {toggleGenralInfo[index] && (
                                    <div className="flex flex-col w-[1200px] bg-white border border-gray500 px-3 gap-2 py-4">
                                      <div>{t('Mobile Number')}</div>
                                      <div className=" text-greyChateau">
                                        {item.mobileNumber}
                                      </div>
                                      <hr className="w-full px-0 opacity" />
                                      <div>{t('Gender')}</div>
                                      <div className=" text-greyChateau">
                                        {item.gender}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Email')}</div>
                                      <div className=" text-greyChateau">
                                        {item.email}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Visit Date')}</div>
                                      <div className=" text-greyChateau">
                                        {item.visitDate}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('ID Number')}</div>
                                      <div className=" text-greyChateau">
                                        {item.Idnumber}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('ID Type')}</div>
                                      <div className=" text-greyChateau">
                                        {item.Idtype}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Nationality')}</div>
                                      <div className=" text-greyChateau">
                                        {item.nationality}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                )}
              </>
            )}
          </div>
          <div className="px-1">
            <Pagination
              totalPage={Math.ceil(
                (bookVisitModel?.total_count || 0) / paginationLimit,
              )}
              page={page}
              limit={paginationLimit}
              onPageChange={setPage}
              totalCount={bookVisitModel?.total_count || 0}
            />
          </div>
          <div className="flex justify-between items-center px-3 ">
            <div className="flex flex-row gap-2 pl-4">
              <span className="font-bold">{t('Receiving forms')}</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isOpen}
                  readOnly
                />
                <div
                  onClick={() => {
                    setEnabled(!enabled);
                  }}
                  className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange500"
                ></div>
              </label>
            </div>
            <Button
              disabled={false}
              width={'w-[305px]'}
              hight={'h-12'}
              text={'Export All'}
              img={<RiFileExcel2Line size={24} />}
              fontsize={'text-base'}
              onClick={() => handleExportClick()}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {acceptPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseAcceptPopup}
            title={'Are you sure you want to accept this startup application? '}
            subtitle={'If you confirm, the startup application will be accept.'}
            onClick={handleCloseAcceptPopup}
            onClickConfirm={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}
        {exportPopup && (
          <ConfirmPopupComponent
            fileName="Tour Data"
            onClose={handleCloseExportPopup}
            title={'Are you sure you want to Export All?'}
            subtitle={
              'If you confirm, all forms on this page will be downloaded.'
            }
            onClick={handleCloseExportPopup}
            onClickConfirm={getDataExport}
            data={dataExport}
          />
        )}
        {rejectPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseRejectPopup}
            title={'AAre you sure you want to reject this startup application?'}
            subtitle={'If you confirm, the startup application will be reject.'}
            onClick={handleCloseRejectPopup}
            onClickConfirm={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}
      </div>
    </div>
  );
}

export default NewVisitContainer;
