import { GalleryTypeEnum } from 'core/enums/example.enum';
import { MouseEventHandler } from 'react';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
interface galleryCardprops {
  id?: string;
  image_url: string;
  gallery_type: GalleryTypeEnum;
  index: number;
  onClickEdit: MouseEventHandler;
  onClickDelete: MouseEventHandler;
}
function GalleryCardCopmonent(props: galleryCardprops) {
  return (
    <div className="w-full">
      <div className="rounded-lg shadow bg-greyMenuColor py-3 pr-9 pl-3">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row space-x-4">
            <h1 className="ml-5 mt-6">{props.index}</h1>
            <img
              className="w-[78px] h-[78px] rounded-lg"
              src={props.image_url}
              alt=""
            />
          </div>
          <div className="flex flex-row items-center justify-between gap-1 cursor-pointer">
            <div
              className="flex flex-row text-center gap-2"
              onClick={props.onClickEdit}
            >
              <HiOutlinePencilAlt
                size={17}
                className="text-secondaryGreen400"
              />
              <p className="text-xs">Edit</p>
            </div>
            <div
              className="flex flex-row text-center gap-2"
              onClick={props.onClickDelete}
            >
              <HiOutlineTrash size={17} className="text-red500" />
              <p className="text-xs">Delete</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GalleryCardCopmonent;
