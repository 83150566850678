import Button from 'components/admin/ButtonComponent';
import ButtonComponent from 'components/admin/ButtonComponent';
import DataListComponent from 'components/common/Data-list/data-list-component';
import EmptySearchComponent from 'components/common/empty-search';
import ExportDisruptPopup from 'components/common/export-popup/export-disrupt-popup';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import SkeletonDataComponent from 'components/common/skeleton-element/incubators-skeleton';
import { getDisruptGuest } from 'core/data-access/disrupt.service';
import { PDFEnum } from 'core/enums/application_state.enum';
import { DisruptExportEnum } from 'core/enums/disrupt.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { DisrupGueststModel } from 'core/types/admin.type';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';

function DisruptOldApplicationsGuests() {
  const place = [1, 1, 1, 1];
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const { t } = useTranslation();
  const data = useState<DisrupGueststModel[]>([]) || null;
  const [toggle, setToggle] = useState(new Array(data.length).fill(false));
  const loading = false;

  const paginationLimit = 4;
  const [page, setPage] = useState<number>(1);
  const [fulldata, setFullData] = useState<DisrupGueststModel>();
  const [val, setVal] = useState<string>('');
  const [, setFiltered] = useState(fulldata?.result_list);
  const toggledata: SetStateAction<[]> = [];
  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };
  const [toggle2, setToggle2] = useState(
    new Array(toggledata.length).fill(false),
  );
  const handleToggleClick2 = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle2(newToggle);
  };
  const getGueste = useCallback(async () => {
    try {
      const Disrupt: DisrupGueststModel = await getDisruptGuest(
        paginationLimit,
        (page - 1) * paginationLimit,
        val,
        null,
        null,
      );
      setFullData(Disrupt);
      setSkeletonData(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, [page]);

  const handleSearchClick = () => {
    if (val === '') {
      setFiltered(fulldata?.result_list);
      return;
    }
    const filterBySearch = fulldata?.result_list.filter((item) => {
      item.first_name.toLowerCase().includes(val.toLowerCase());
    });
    setFiltered(filterBySearch);
  };
  const getSearchData = async () => {
    try {
      const DisruptGuest: DisrupGueststModel = await getDisruptGuest(
        paginationLimit,
        (page - 1) * paginationLimit,
        val,
        null,
        null,
      );

      unstable_batchedUpdates(() => {
        setFullData(DisruptGuest);
        setFiltered(DisruptGuest.result_list);
        setSkeletonData(false);
      });
      handleSearchClick();
    } catch (error) {
      handleRequestError(error);
    }
  };
  useEffect(() => {
    getGueste();
  }, [getGueste]);
  return (
    <div className="pt-5 flex justify-center items-center">
      <div className="flex flex-col w-full h-full rounded-lg bg-whiteColor px-8 pt-4 pb-7">
        <div className="flex flex-row justify-start items-start pt-1 mb-4 gap-4">
          <input
            onChange={(e) => setVal(e.target.value)}
            type="search"
            id="default-search"
            className="block h-12 w-1/2 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
            placeholder={`${t('search')}`}
            required
          />
          <ButtonComponent
            disabled={false}
            width={'w-[143px]'}
            hight={'h-[43px]'}
            text={`${t('search')}`}
            fontsize={'text-base'}
            onClick={getSearchData}
          />
        </div>
        <div className="grid grid-cols-5 w-full font-bold text-lg pr-[15%] pl-14 pb-2 items-center">
          <div>{t('name')}</div>
          <div>{t('occupation')}</div>
          <div className="pl-3">{t('company')}</div>
          <div className="pl-10">{t('position')}</div>
          <div className="pl-[35%]">{t('status')}</div>
        </div>
        {skeletonData ? (
          <div className="flex flex-col items-center ">
            {place.map((e, index) => {
              return (
                <div key={index}>
                  <SkeletonDataComponent />
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            {fulldata?.result_list === undefined || null ? (
              <Loading />
            ) : fulldata.total_count === 0 ? (
              <div className="flex justify-center mx-auto">
                <EmptySearchComponent />
              </div>
            ) : (
              fulldata?.result_list?.map((item, index) => (
                <div
                  key={item.id}
                  className="flex flex-col w-full items-center py-3"
                >
                  <DataListComponent
                    index={index}
                    toggle={toggle}
                    id={item.id}
                    item1={item.first_name + ' ' + item.last_name}
                    item2={item.occupation}
                    item3={item.company_name}
                    Icon={PDFEnum.NO}
                    item4={item.position}
                    isNew={false}
                    item5={item.application_state}
                    onClickArrowDown={() =>
                      handleToggleClick(index, !toggle[index])
                    }
                    onClickArrowUp={() =>
                      handleToggleClick(index, !toggle[index])
                    }
                  />

                  {loading && (
                    <div className="fixed flex items-center justify-center inset-[10px]">
                      <Loading />
                    </div>
                  )}
                  {toggle[index] && (
                    <div className="px-10 py-4 bg-greyMenuColor w-full shadow flex justify-center h-full rounded-b-lg">
                      <div className="flex flex-col py-2 w-full">
                        <div
                          className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-full h-[52px] items-center`}
                        >
                          <div className="flex flex-row justify-between w-full px-4">
                            <div className="text-secondaryGreen400 text-sm font-normal">
                              {t('SelectEevent')}
                            </div>
                            {toggle2[index] ? (
                              <IoIosArrowUp
                                className="flex w-[50px] cursor-pointer pl-5 text-2xl text-blue500"
                                onClick={() => handleToggleClick2(index, false)}
                              />
                            ) : (
                              <IoIosArrowDown
                                key={index}
                                className="flex w-[50px] cursor-pointer pl-5 text-2xl text-blue500"
                                onClick={() => handleToggleClick2(index, true)}
                              />
                            )}
                          </div>
                        </div>
                        {toggle2[index] && (
                          <div className="px-4 pt-2 bg-white w-full overflow-y-scroll border border-gray500 flex justify-start items-start h-[335px]">
                            <div className="flex flex-col w-full gap-3 ">
                              <div className="font-normal">
                                {t('firstName')}
                              </div>
                              <div className="text-textGrayColor">
                                {item.first_name}
                              </div>
                              <hr className="w-full px-2 opacity-50" />
                              <div className="font-normal">{t('lastname')}</div>
                              <div className="text-textGrayColor">
                                {item.last_name}
                              </div>
                              <hr className="w-full px-2 opacity-50" />
                              <div className="font-normal">
                                {t('mobilenumber')}
                              </div>
                              <div className="text-textGrayColor">
                                {item.mobile}
                              </div>
                              <hr className="w-full px-2 opacity-50" />
                              <div className="font-normal">
                                {t('emailaddress')}
                              </div>
                              <div className="text-textGrayColor">
                                {item.email}
                              </div>
                              <hr className="w-full px-2 opacity-50" />
                              <div className="font-normal">
                                {t('nationality')}
                              </div>
                              <div className="text-textGrayColor">
                                {item.nationality}
                              </div>
                              <hr className="w-full px-2 opacity-50" />
                              <div className="font-normal">{t('gender')}</div>
                              <div className="text-textGrayColor">
                                {item.gender}
                              </div>
                              <hr className="w-full px-2 opacity-50" />
                              <div className="font-normal">
                                {t('occupation')}
                              </div>
                              <div className="text-textGrayColor">
                                {item.occupation}
                              </div>
                              <hr className="w-full px-2 opacity-50" />
                              <div className="font-normal">{t('position')}</div>
                              <div className="text-textGrayColor">
                                {item.position}
                              </div>
                              <hr className="w-full px-2 opacity-50" />
                              <div className="font-normal">
                                {t('whydoyouwanttoattendAlGarageDisrupt')}
                              </div>
                              <div className="text-base text-textGrayColor">
                                {item.reason_to_join}
                              </div>
                              <hr className="w-full px-2 opacity-50" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        )}
        <div className="pr-5">
          <Pagination
            totalCount={fulldata?.total_count || 0}
            totalPage={Math.ceil(
              (fulldata?.total_count || 0) / paginationLimit,
            )}
            page={page}
            limit={paginationLimit}
            onPageChange={setPage}
          />
        </div>
        <div className="flex justify-end items-center">
          <Button
            disabled={fulldata?.total_count === 0 ? true : false}
            width={'w-[305px]'}
            hight={'h-12'}
            text={'Export All'}
            img={<RiFileExcel2Line size={24} />}
            fontsize={'text-base'}
            onClick={() => {
              handleExportClick();
            }}
          />
        </div>
      </div>
      {exportPopup && (
        <ExportDisruptPopup
          applicationType={DisruptExportEnum.GUEST}
          pathExport={'disrupt'}
          title={'Disrupt'}
          subtitle={'The disrupt you picked will be exported.'}
          programName={'Disrupt'}
          fileName="Disrupt Application"
          pathList={'disrupt'}
          onClose={handleCloseExportPopup}
          onClickConfirm={handleCloseExportPopup}
        />
      )}
    </div>
  );
}
export default DisruptOldApplicationsGuests;
