import DisruptContainer from 'containers/admin/Programs/Disrupt/disrupt-dashboard-container';

const DisruptPage = () => {
  return (
    <div className="flex min-h-screen">
      <DisruptContainer />
    </div>
  );
};

export default DisruptPage;
