import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { appRoutesObj } from 'routes/app.paths'; // Ensure this file contains the necessary path functions

// Slash component to display '/' between breadcrumb items
function Slash() {
  return <span className="px-1 text-slate-500 dark:text-white">/</span>;
}

// Function to determine breadcrumb labels and paths based on the current pathname
const handleRoute = (
  pathname: string,
): { basePath: string; parts: string[]; dynamicParams?: string[] } => {
  if (pathname.startsWith('/media/news')) {
    const isCreate = pathname === '/media/news/create';
    const isEdit = pathname.startsWith('/media/news/') && !isCreate;

    return {
      basePath: appRoutesObj.getNewsPath(),
      parts: isCreate
        ? ['container.media.listnews.News', 'container.media.listnews.createNews']
        : isEdit
        ? ['container.media.listnews.News', 'container.media.listnews.editNews']
        : ['container.media.listnews.News'],
      dynamicParams: isEdit ? [pathname.split('/').pop() || ''] : [],
    };
  }

  if (pathname.startsWith('/latest-events')) {
    const isCreate = pathname === '/latest-events/create';
    const isEdit = pathname.startsWith('/latest-events/') && !isCreate;

    return {
      basePath: appRoutesObj.getLatestEventsPagePath(),
      parts: isCreate
        ? ['container.media.latest.events', 'container.media.latest.createEvents']
        : isEdit
        ? ['container.media.latest.events', 'container.media.latest.editEvents']
        : ['container.media.latest.events'],
      dynamicParams: isEdit ? [pathname.split('/').pop() || ''] : [],
    };
  }

  return { basePath: '', parts: [] };
};

// Breadcrumb component that displays breadcrumb navigation
export default function Breadcrumb() {
  const { t } = useTranslation(); // Use translation for localization
  const location = useLocation(); // Get the current location/pathname
  const { basePath, parts, dynamicParams } = handleRoute(location.pathname); // Get breadcrumb base path, parts, and dynamic params based on the pathname

  // Function to determine the correct URL based on the part and index
  const getLinkPath = (part: string, index: number): string => {
    switch (true) {
      case index === 0:
        return basePath; // Link to the base path
      case index === 1 && part === 'Create News':
        return appRoutesObj.getCreateNewsLetterPath(); // Link to the Create News page
      case index === 1 && part === 'Edit News':
        return dynamicParams?.length
          ? appRoutesObj.getEditNewsLetterIdPageUrl(dynamicParams[0]) // Link to the Edit News page with dynamic newsId
          : ''; // Handle cases where dynamic parameter might be missing
      case index === 1 && part === 'Create Events':
        return appRoutesObj.getCreateNewsVideoPath(); // Link to the Create Events page
      case index === 1 && part === 'Edit Events':
        return dynamicParams?.length
          ? appRoutesObj.getEditLatestEventsIdPageUrl(dynamicParams[0]) // Link to the Edit Events page with dynamic eventId
          : ''; // Handle cases where dynamic parameter might be missing
      default:
        return ''; // Default case (should not occur if handleRoute is correct)
    }
  };

  return (
    <div className="py-4 px-5 text-xl">
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Slash />}{' '}
          {/* Display slash between breadcrumb items */}
          <Link
            className={
              index === parts.length - 1 ? 'font-bold' : '' // Highlight the last item
            }
            to={getLinkPath(part, index)} // Use the function to determine the correct link path
          >
            {t(part)} {/* Translate breadcrumb labels */}
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
}
