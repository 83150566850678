import ViewMediaComponent from "components/view-media-component";
import { truncateText } from "core/helpers/text.helper";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import { IoPlayOutline } from "react-icons/io5";
import { Link } from "react-router-dom";


interface CardLatestEventProps {
    title: string;
    url: string;
    content: string
    onEdit: string;
    onDelete: () => void;
    loading: boolean;
}

function CardLatestEvent({ title, url, content, onEdit, onDelete, loading }: CardLatestEventProps) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const handleIconClick = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <>
            <div className="relative flex flex-col w-full p-3 rounded bg-slate50 gap-y-3">
                {loading ? (
                    <div className="flex flex-col items-center justify-center space-y-4 animate-pulse">
                        <div className="w-full h-[100px] bg-gray-300 rounded-lg" />
                        <div className="w-6 h-6 bg-gray-300 rounded-full" />
                        <div className="w-full h-6 bg-gray-300 rounded-lg" />
                        <div className="flex justify-between w-full">
                            <div className="w-1/2 h-6 bg-gray-300 rounded-lg" />
                            <div className="w-1/2 h-6 bg-gray-300 rounded-lg" />
                        </div>
                    </div>
                ) : (
                    <>
                        <video onClick={handleIconClick} className="object-cover w-full h-[100px] rounded-lg cursor-pointer">
                            <source src={url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div onClick={handleIconClick}
                            className="absolute top-[25%] flex cursor-pointer justify-center items-center right-[45%] bg-white border-[2px] border-black rounded-full w-6 h-6"
                        >
                            <IoPlayOutline
                                size={18}
                                className="pl-[2px] text-black"
                            />
                        </div>
                        <p className="text-base font-en">{truncateText(title, 10)}</p>
                        <div className="flex justify-between">
                            <Link to={onEdit} className="flex items-center cursor-pointer">
                                <HiOutlinePencilAlt size={22} className="text-secondaryGreen400" />
                                <p className="font-normal font-en">{t("component.latest.events.edit")}</p>
                            </Link>
                            <div className={`flex items-center cursor-pointer
                ${i18n.language === 'en' ? `pr-4` : ``}
              `} onClick={onDelete}>
                                <HiOutlineTrash size={22} className="text-error" />
                                <p className="font-en">{t("component.latest.events.delete")}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {isPopupOpen && (
                <ViewMediaComponent
                    title={title}
                    url={url}
                    onClose={handleClosePopup}
                    content={content}
                    mediaType={"video"} />
            )}
        </>
    );
}

export default CardLatestEvent;