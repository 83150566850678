import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { editDisrupt, getDisrupt } from 'core/data-access/disrupt.service';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllDisruptShowModel, timeForDisrupt } from 'core/types/admin.type';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { toast } from 'react-toastify';

import NextDisruptPopupComponent from './next-disrupt-popup-component';
import StartupPostComponet from './startup-post-componet';
function NextDisruptWithTimelineComponent() {
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [openPopUp2, setOpenPopUp2] = useState<boolean>(false);
  const [openPopUpEditConfirm, setOpenPopUpEditConfirm] =
    useState<boolean>(false);
  const currentYearStart = new Date().getFullYear();
  const [selectedDateStart, setSelectedDateStart] = useState<string>(
    `${currentYearStart}-01-01T00:00`,
  );
  const handleDateChangeStart = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedDateStart(event.target.value);
  };
  const currentYearEnd = new Date().getFullYear();
  const [selectedDateEnd, setSelectedDateEnd] = useState<string>(
    `${currentYearEnd}-01-01T00:00`,
  );
  const [publishedData, setPublishedData] = useState<
    {
      id: string;
      dateStart: string;
      timeStart: string;
      dateEnd: string;
      timeEnd: string;
      disruptNumber: number;
      status: string;
    }[]
  >([]);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const handleDateChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDateEnd(event.target.value);
  };
  const [id, setId] = useState<string>('');

  const initialValues: timeForDisrupt = {
    start_at: '',
    end_at: '',
  };

  const handleSubmit = async () => {
    try {
      await editDisrupt(id, {
        start_at: selectedDateStart,
        end_at: selectedDateEnd,
      });
      toast.success('Next disrupt brief has been published successfully!');
    } catch (error) {
      return error;
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      await handleSubmit();
    },
  });

  const close = () => {
    setOpenPopUp2(false);
  };

  const handleEditConfirmClick = () => {
    setOpenPopUpEditConfirm(true);
  };
  const handleCloseEditConfirmPopup = () => {
    setOpenPopUpEditConfirm(false);
  };
  const handleCloseEditPopUp = () => {
    setOpenPopUp(false);
  };

  const [data, setData] = useState<AllDisruptShowModel>();
  const getData = async () => {
    try {
      const Disrupt: AllDisruptShowModel = await getDisrupt(20, 0, null);
      setData(Disrupt);
    } catch (error) {
      handleRequestError(error);
    }
  };

  useEffect(() => {
    if (data && data.result_list) {
      const dataObjects2 = data.result_list.map((data) => {
        const dateTimeStart = new Date(data.start_at);
        const datePartStart = dateTimeStart.toDateString();
        const timePartStart = dateTimeStart.toLocaleTimeString();

        const dateTimeEnd = new Date(data.end_at);
        const datePartEnd = dateTimeEnd.toDateString();
        const timePartEnd = dateTimeEnd.toLocaleTimeString();

        const disruptNumber = data.disrupt_number;
        const status = data.status;
        const id = data.id;

        return {
          id: id,
          dateStart: datePartStart,
          timeStart: timePartStart,
          dateEnd: datePartEnd,
          timeEnd: timePartEnd,
          disruptNumber: disruptNumber,
          status: status,
        };
      });

      const publishedData = dataObjects2.filter(
        (item) => item.status === 'PUBLISHED',
      );
      setPublishedData(publishedData);
      const publishedIds = publishedData.map((item) => item.id);
      setId(publishedIds.join(', '));
    }
  }, [data]);

  useEffect(() => {
    if (data && data.result_list) {
      const firstItem = data.result_list[0];
      const startAt: number = new Date(firstItem.start_at).getTime();
      const currentTime: number = new Date().getTime();

      let timeDifference = startAt - currentTime;

      const interval = setInterval(() => {
        const currentTime = new Date().getTime();
        timeDifference = startAt - currentTime;

        if (timeDifference <= 0) {
          clearInterval(interval);
          setTimeRemaining({
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          });
        } else {
          const totalSeconds = Math.floor(timeDifference / 1000);
          const days = Math.floor(totalSeconds / (3600 * 24));
          const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;
          setTimeRemaining({
            days,
            hours,
            minutes,
            seconds,
          });
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }

    return () => null;
  }, [data]);

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="px-8">
      <div className="h-full w-full bg-whiteColor rounded-lg px-6 pt-7 pb-[17.5px]">
        <div className="flex flex-row justify-between">
          <p className="text-2xl font-bold text-gray800">Next disrupt</p>
          {/* Two Buttons with Text */}
          <div className="flex flex-row gap-2 pb-4">
            {' '}
            <div
              onClick={() => setOpenPopUp(true)}
              className="flex flex-row justify-center gap-2 items-center bg-orange500 px-6 py-1.5 rounded-lg text-whiteColor text-[16px] cursor-pointer"
            >
              <FiEdit size={'17px'} /> Edit disrupt
            </div>
            {/* Edit Disrupt Pop-up */}
            {openPopUp ? (
              <NextDisruptPopupComponent
                title="Edit Disrupt Info"
                closeModal={() => handleCloseEditPopUp()}
              >
                <form
                  className="flex flex-col"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleEditConfirmClick();
                  }}
                >
                  {/* date */}
                  <label className="py-2 pb-2 text-[20px] ml-2">Start at</label>
                  <input
                    type="datetime-local"
                    id="meeting-time"
                    name="meeting-time"
                    className="h-[50px] border rounded-lg border-gray500 px-4 bg-gray-50"
                    value={selectedDateStart}
                    min={`${currentYearStart}-01-01T00:00`}
                    onChange={handleDateChangeStart}
                  />
                  {/* Time */}
                  <label className="py-2 text-[20px] pt-6 ml-2">End at</label>
                  <input
                    type="datetime-local"
                    id="meeting-time"
                    name="meeting-time"
                    className="h-[50px] border rounded-lg border-gray500 px-4 bg-gray-50"
                    value={selectedDateEnd}
                    min={`${currentYearEnd}-01-01T00:00`}
                    onChange={handleDateChangeEnd}
                  />

                  <div className="flex flex-col items-center justify-center pt-8 pb-2">
                    <input
                      className="w-[305.46px] h-12 text-whiteColor rounded-lg bg-orange500 cursor-pointer"
                      type="submit"
                      value="Publish"
                    />
                  </div>
                </form>
              </NextDisruptPopupComponent>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* upcoming disrupt */}
        <div className="w-full h-full px-4 py-2 rounded-lg bg-slate50 flex flex-row justify-between items-center">
          {/* disrupt info */}
          <div className="">
            <div className="grid grid-cols-2 justify-between w-[51.5%] h-[51.5%] pt-[2%] pl-[1.24%] pr-[80.5%] pb-[2.83%] gap-y-[39px] bg-backgroundWhite rounded-lg text-xl text-gray800 text-start">
              {publishedData.map((item, index) => {
                return (
                  <div key={index} className="grid grid-cols-2 gap-64">
                    <div className="whitespace-nowrap">
                      <p className="font-normal text-sm">
                        Date: {item.dateStart}
                      </p>
                      <p className="pt-[39.5px] text-sm font-normal">
                        location: The Garage{' '}
                      </p>
                    </div>
                    <div className="whitespace-nowrap">
                      <p className="font-normal text-sm">
                        Time: {item.timeStart} , {item.timeEnd}{' '}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* toggle button */}
            <div className=""></div>
          </div>
          <div>
            {timeRemaining.days === 0 &&
            timeRemaining.hours === 0 &&
            timeRemaining.minutes === 0 &&
            timeRemaining.seconds === 0 ? (
              <>
                <div className="pr-9">
                  <Button
                    disabled={false}
                    width={'w-[118px]'}
                    hight={'h-[48px]'}
                    text={'Add company'}
                    fontsize={'text-base'}
                    onClick={() => setOpenPopUp2(true)}
                  />
                </div>
                {openPopUp2 ? (
                  <>
                    <div className="absolute z-50">
                      <StartupPostComponet
                        title={'Add company'}
                        onClose={() => close()}
                        onSuccess={() => close()}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {/* TimeLine */}
                <div className="flex flex-col gap-[3px] bg-whiteColor h-[9%] w-[95%] rounded-lg py-2 px-2">
                  <div className="text-base font-bold text-start text-gray800">
                    TIMELINE
                  </div>
                  <div className="flex flex-col font-bold justify-center pl-5 ">
                    <div
                      className={
                        'flex flex-row text-grayColor justify-center items-center text-xs gap-x-2 px-4 pb-1 text-center'
                      }
                    >
                      <div className="flex flex-col">
                        <p className="font-normal text-xs ">DATE</p>
                        <p className="text-xl text-darkGrayColor pt-2">
                          {timeRemaining.days.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </p>
                      </div>
                      <p className="text-xl text-darkGrayColor pt-5"> :</p>

                      <div className="flex flex-col">
                        <p className="font-normal text-xs ">HOUR</p>
                        <p className="text-xl text-darkGrayColor pt-2">
                          {timeRemaining.hours.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </p>
                      </div>
                      <p className="text-xl text-darkGrayColor pt-5 "> :</p>

                      <div className="flex flex-col">
                        <p className="font-normal text-xs text">MINUTE</p>
                        <p className="text-xl text-darkGrayColor pt-2">
                          {timeRemaining.minutes.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </p>
                      </div>
                      <p className="text-xl text-darkGrayColor pt-5"> :</p>
                      <div className="flex flex-col">
                        <p className="font-normal text-xs ">SECOND</p>
                        <p className="text-xl text-darkGrayColor pt-2">
                          {timeRemaining.seconds.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {openPopUpEditConfirm && (
          <ConfirmPopupComponent
            onClose={handleCloseEditConfirmPopup}
            title={'Are you sure you want to publish next disrupt edit ?'}
            subtitle={'If you confirm, next disrupt edit will be published.'}
            onClick={handleCloseEditConfirmPopup}
            onClickConfirm={() => {
              formik.handleSubmit();
              handleCloseEditPopUp();
            }}
            onSuccess={() => {
              handleCloseEditConfirmPopup();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default NextDisruptWithTimelineComponent;
