import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { StatisticsBookingTypeEnum } from 'core/enums/example.enum';
import { HttpMethods } from 'core/enums/httpMethods.enum';
import { BookingManagementModel } from 'core/types/booking-management.type';

export const postBookingManagementServices = (
  BookingManagement: BookingManagementModel,
  resource_id: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/booking/management/${resource_id}`,
    method: HttpMethods.POST,
    data: BookingManagement,
  });
};

export const getBookingManagementServices = (
  booking_date: string,
  limit: number,
) => {
  return makeRequest({
    url: `${BASE_URL}/resource`,
    method: HttpMethods.GET,
    params: {
      booking_date: booking_date,
      limit: limit,
    },
  });
};

export const getBookingStatisticsServices = (
  statistics_type: StatisticsBookingTypeEnum,
  date_time: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/booking/statistics`,
    method: HttpMethods.GET,
    params: {
      statistics_type: statistics_type,
      date_time: date_time || new Date(),
    },
  });
};

export const getBookingAvailableServices = () => {
  return makeRequest({
    url: `${BASE_URL}/booking/available`,
    method: HttpMethods.GET,
  });
};
