import { useTranslation } from 'react-i18next';

interface ButtonComponentProps {
  disabled: boolean;
  width: string;
  hight: string;
  text: string;
  img?: JSX.Element;
  fontsize: string;
  onClick?: () => void;
}
function ButtonComponent(props: ButtonComponentProps) {
  const { i18n } = useTranslation();

  return (
    <div className="">
      <button
        className={`${props.hight} ${props.width}  disabled:cursor-not-allowed bg-orange500 rounded-lg cursor-pointer`}
        disabled={props.disabled}
        type="submit"
        onClick={props.onClick}
      >
        <span
          className={`flex justify-center items-center mx-auto gap-2 ${i18n.language === 'en'
            ? `${props.fontsize} text-whiteColor mobile:text-base`
            : `${props.fontsize} text-whiteColor mobile:text-base`
            }`}
        >
          {props.img ? props.img : null}

          {props.text}
        </span>
      </button>
    </div>
  );
}
export default ButtonComponent;
