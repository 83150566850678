import { convertTimeToSeconds } from 'core/helpers/time.helper';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  roomId: Yup.string().required('Room is required'),
  date: Yup.date()
    .required('Date is required')
    .min(new Date().toISOString().split('T')[0], 'Date cannot be in the past'),
  timePeriod: Yup.string().required('Time period is required'),
  extraField: Yup.string()
    .required('ExtraField is required')
    .test('is-greater', 'End time must be after start time', function (value) {
      const { timePeriod } = this.parent;
      if (!value || !timePeriod) return true;
      return convertTimeToSeconds(value) > convertTimeToSeconds(timePeriod);
    }),
});
