export const downloadDataLink = (data: string, fileName: string) => {
  // const filename = `${fileName}.csv`;

  // const blob = new Blob([data], {
  //   type: 'data:text/csv;charset=utf-8,' + '\uFEFF' + encodeURIComponent(data),
  // });

  // const elem = window.document.createElement('a');
  // elem.href = window.URL.createObjectURL(blob);
  // elem.download = filename;
  // document.body.appendChild(elem);
  // elem.click();
  // document.body.removeChild(elem);

  const csvContent =
    'data:text/csv;charset=utf-8,' + '\uFEFF' + encodeURIComponent(data);
  const link = document.createElement('a');
  link.href = csvContent;
  link.download = `${fileName}.csv`;
  link.click();
};
