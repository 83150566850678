import PlusContainer from '../../containers/admin/Programs/Plus/plus-container';

const PlusPage = () => {
  return (
    <div className="flex flex-col w-full">
      <PlusContainer />
    </div>
  );
};

export default PlusPage;
