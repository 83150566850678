import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import { downloadDataLink } from 'components/common/csv';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import IncubatorsSkeleton from 'components/common/skeleton-element/incubators-skeleton';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { OpenAndCloseEnum } from 'core/enums/open-and-close-state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { data } from 'core/statics/new-data';
import { AllPartnerModel, PartnerIsOpenModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import {
  getAllPartnerServices,
  getPartnerExportServices,
  getPartnerIsOpenServices,
  postPartnerAcceptOrReject,
  postPartnerOpenAndCloseServices,
} from 'services/partner-service';

import NewPartnerComponent from './newparten-component';
import SearchComponent from './search-component';

function NewGaragePartnerContainer() {
  const { t } = useTranslation();
  const place = [1, 1, 1, 1];
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const [partnerData, setPartnerData] = useState<AllPartnerModel>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataExport, setDataExport] = useState<string>('');
  const [enabled, setEnabled] = useState(false);
  const [exportPopup, setExportPopup] = useState(false);
  const [newPartner, setNewPartner] = useState(false);
  const [acceptPopup, setAcceptPopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [loading] = useState(false);
  const [partnerId, setPartnerId] = useState<string>('');
  const [val, setVal] = useState('');
  const [filtered, setFiltered] = useState(partnerData?.result_list);
  const [toggle, setToggle] = useState(new Array(data.length).fill(false));
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(data.length).fill(false),
  );
  const [visibleItems, setVisibleItems] = useState(1);
  const paginationLimit = 5;
  const handlePartnerToogalClick = () => {
    setEnabled(!enabled);
  };
  const handleCloseToogalPopup = () => {
    setEnabled(false);
  };

  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  // const handleNewPartnerClick = () => {
  //   setNewPartner(true);
  // };
  const handleNewClosePopup = () => {
    setNewPartner(false);
  };
  const handleAcceptClick = (partnerId: string) => {
    setAcceptPopup(true);
    setPartnerId(partnerId);
  };
  const handleCloseAcceptPopup = () => {
    setAcceptPopup(false);
  };
  const handleRejectClick = (partnerId: string) => {
    setRejectPopup(true);
    setPartnerId(partnerId);
  };
  const handleCloseRejectPopup = () => {
    setRejectPopup(false);
  };

  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };
  const partnerAcceptOrReject = async (status: string, partnerId: string) => {
    try {
      await postPartnerAcceptOrReject(status, partnerId);
      getData();
    } catch (error) {
      handleRequestError(error);
    }
  };

  const getData = useCallback(async () => {
    try {
      const data: AllPartnerModel = await getAllPartnerServices(
        ApplicationStateEnum.PENDING,
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
        val ?? null,
      );
      unstable_batchedUpdates(() => {
        setPartnerData(data);
        setFiltered(data.result_list);
        setSkeletonData(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [visibleItems, val]);

  const getIsOpen = useCallback(async () => {
    try {
      const isOpen: PartnerIsOpenModel = await getPartnerIsOpenServices();
      setIsOpen(isOpen.is_open);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);
  const postPartnerOpenAndClose = async (open: OpenAndCloseEnum) => {
    try {
      await postPartnerOpenAndCloseServices(open);
      toast.success(
        `Receiving forms has been ${
          open === OpenAndCloseEnum.CLOSE ? 'stoped' : 'enable'
        } successfully.`,
      );
      getIsOpen();
    } catch (error) {
      handleRequestError(error);
    }
  };
  const getDataExport = async () => {
    try {
      const partnerDataExport: string = await getPartnerExportServices();
      setDataExport(partnerDataExport);
      downloadDataLink(partnerDataExport, 'Partner Data');

      toast.success(`Data has been exported successfully!`);
    } catch (error) {
      handleRequestError(error);
    }
  };

  useEffect(() => {
    getData();
    getIsOpen();
  }, [getData, getIsOpen]);

  return (
    <div>
      <div className="pt-6 desktop:py-7 flex justify-center items-center">
        <div className="flex flex-col rounded-lg bg-white w-full px-10 pt-8 pb-2">
          <SearchComponent
            onClickValue={(e) => setVal(e.target.value)}
            onClickShearch={getData}
            // onClick={handleNewPartnerClick}
          />
          <div className="flex flex-col w-full">
            <div className="grid grid-cols-3 w-full font-bold text-lg py-7  items-center justify-between">
              <div className="text-center">{t('Partner')}</div>
              <div className="text-center">{t('Link')}</div>

              <div className="text-center ">{t('Status')}</div>
            </div>
            {skeletonData ? (
              <div className="flex flex-col items-center">
                {place.map((e, index) => {
                  return (
                    <div key={index}>
                      <IncubatorsSkeleton />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="">
                {partnerData?.total_count === 0 ? (
                  <div className=" ml-[35%] w-auto ">
                    <div className="h-[273px] w-[337px] mb-7">
                      <img src="/assets/images/notFound.png" alt="not found" />
                    </div>
                    <h1 className="text-2xl text-blue300 font-bold ">
                      {' We couldn’t find what you’re looking for '}
                    </h1>
                  </div>
                ) : (
                  filtered?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center mb-4"
                    >
                      <div
                        className={`grid grid-cols-3 bg-greyMenuColor  h-full w-full py-1 rounded-t-lg ${
                          toggle[index] ? '' : 'rounded-b-lg'
                        }`}
                      >
                        <div className="flex justify-center items-center whitespace-nowrap">
                          {item.company}
                        </div>
                        <div className="flex justify-center items-center whitespace-nowrap">
                          {item.partnership_type}
                        </div>

                        <div className="flex flex-row items-center justify-center gap-1  ">
                          <button
                            className="w-[95px] h-[37px] bg-darkRedColor text-whiteColor rounded-[4px]"
                            onClick={() => handleRejectClick(item.id)}
                          >
                            {t('Reject')}
                          </button>
                          <button
                            className="w-[95px] h-[37px] bg-lightGreenColor text-whiteColor rounded-[4px]"
                            onClick={() => handleAcceptClick(item.id)}
                          >
                            {t('Accept')}
                          </button>
                          {toggle[index] ? (
                            <IoIosArrowUp
                              className="flex items-center w-[24px] h-[24px] cursor-pointer ml-10"
                              onClick={() => handleToggleClick(index, false)}
                            />
                          ) : (
                            <IoIosArrowDown
                              className="flex items-center w-[24px] h-[24px] cursor-pointer ml-10"
                              onClick={() => handleToggleClick(index, true)}
                            />
                          )}
                        </div>
                      </div>
                      {loading && (
                        <div className="fixed flex items-center justify-center inset-[10px]">
                          <Loading />
                        </div>
                      )}
                      {toggle[index] && (
                        <div className="flex flex-col w-full py-4 px-8 bg-slate50 shadow">
                          <div className="flex flex-row bg-neutralGre50 border h-[52px] border-secondaryGreen400 justify-between text-center items-center px-4">
                            <div className="flex text-secondaryGreen400">
                              {t('generalinfo')}
                            </div>
                            {toggleGenralInfo[index] ? (
                              <IoIosArrowUp
                                size={24}
                                className=""
                                onClick={() =>
                                  handleToggleGenralInfoClick(index)
                                }
                              />
                            ) : (
                              <IoIosArrowDown
                                size={24}
                                onClick={() =>
                                  handleToggleGenralInfoClick(index)
                                }
                              />
                            )}
                          </div>
                          {toggleGenralInfo[index] && (
                            <div className="flex flex-col divide-y w-full justify-between bg-white border border-gray500 px-3 gap-2 py-4">
                              <div>
                                {t('name')}
                                <p className=" text-textGrayColor">
                                  {item.first_name + ' ' + item.last_name}
                                </p>
                              </div>
                              <div>
                                {t('Email address')}
                                <p className=" text-textGrayColor">
                                  {item.email}
                                </p>
                              </div>
                              <div>
                                {t('Mobile number')}
                                <p className=" text-textGrayColor">
                                  {item.mobile}
                                </p>
                              </div>
                              <div>
                                {t('company')}
                                <p className=" text-textGrayColor">
                                  {item.company}
                                </p>
                              </div>
                              <div>
                                {t('Partnership Type')}
                                <p className=" text-textGrayColor">
                                  {item.partnership_type}
                                </p>
                              </div>
                              <div>
                                {t('position')}
                                <p className=" text-textGrayColor">
                                  {item.position}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
          <div className="pr-1">
            <Pagination
              totalPage={Math.ceil(
                (partnerData?.total_count || 0) / paginationLimit,
              )}
              page={visibleItems}
              limit={paginationLimit}
              onPageChange={setVisibleItems}
              totalCount={partnerData?.total_count || 0}
            />
          </div>

          <div className="flex w-full justify-between items-center pt-4">
            {/*toggle */}
            <div className="flex flex-row gap-2">
              <span className="font-bold text-2xl">{t('Receiving forms')}</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isOpen}
                  readOnly
                />
                <div
                  onClick={handlePartnerToogalClick}
                  className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange500"
                ></div>
              </label>
            </div>
            {/* Button */}
            <Button
              disabled={partnerData?.total_count === 0 ? true : false}
              width={'w-[284px]'}
              img={<RiFileExcel2Line size={24} />}
              hight={'h-[48px]'}
              text={'Export All'}
              fontsize={'text-base'}
              onClick={() => handleExportClick()}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {newPartner && (
          <NewPartnerComponent
            onClose={handleNewClosePopup}
            title={'New Partner'}
            onClick={handleNewClosePopup}
            onClickConfirm={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}
        {acceptPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseAcceptPopup}
            title={'Are You sure you want to accept this partner?'}
            subtitle={'If you confirm, the partner will be accepted'}
            onClick={handleCloseAcceptPopup}
            onClickConfirm={() => partnerAcceptOrReject('accept', partnerId)}
          />
        )}
        {rejectPopup && (
          <ConfirmPopupComponent
            onClose={handleCloseRejectPopup}
            title={'Are You sure you want to reject this partner?'}
            subtitle={'If you confirm, the partner will be rejected'}
            onClick={handleCloseRejectPopup}
            onClickConfirm={() => partnerAcceptOrReject('reject', partnerId)}
          />
        )}

        {exportPopup && (
          <ConfirmPopupComponent
            fileName="Partner Data"
            onClose={handleCloseExportPopup}
            title={'Are you sure you want to Export All?'}
            subtitle={
              'If you confirm, all forms on this page will be downloaded.'
            }
            onClick={handleCloseExportPopup}
            onClickConfirm={getDataExport}
            data={dataExport}
          />
        )}

        {enabled && (
          <ConfirmPopupComponent
            onClose={handleCloseToogalPopup}
            title={
              isOpen === true
                ? 'Are you sure you want to Stop Receiving Forms?'
                : 'Are you sure you want to Enable Receiving Forms? '
            }
            subtitle={
              'If you confirm, application form will not appear on the website.'
            }
            onClick={handleCloseToogalPopup}
            onClickConfirm={() =>
              postPartnerOpenAndClose(
                isOpen === true
                  ? OpenAndCloseEnum.CLOSE
                  : OpenAndCloseEnum.OPEN,
              )
            }
          />
        )}
      </div>
    </div>
  );
}

export default NewGaragePartnerContainer;
