import TabBarComponent from 'containers/admin/Community/Partners/tabbar-component';

import NewTransportationPermitReservationContainer from './new-transportation-reservation-container';
import OldTransportationPermitReservationContainer from './old-transportation-reservation-container';

function TransportationPermitsContainer() {
  return (
    <div className="w-full">
      <TabBarComponent
        text1="container.NewTransportationPermitReservationContainer.newTransPermit"
        text2="container.OldTransportationPermitReservationContainer.oldTransPermit"
        page={<OldTransportationPermitReservationContainer />}
        page2={<NewTransportationPermitReservationContainer />}
      />
    </div>
  );
}

export default TransportationPermitsContainer;
