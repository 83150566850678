import { BASE_URL } from 'core/data-access/http.service';
import { uploadImage } from 'core/data-access/uplode.service';
import { handleRequestError } from 'core/helpers/error.helper';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';

export interface UploadComponentProps {
  className?: string;
  labelClassName: string;
  image: string;
  name: string;
  button: string;
  buttonSize?: string;
  disabled?: boolean;
  id: string;
  hight?: string;
  width?: string;
  formik: React.ReactNode;
  onClick?: React.MouseEventHandler;
  onChange: (val: string) => void;
  type: string;
  typeFile?: 'image' | 'video';
}

function UploadComponent({
  onChange,
  name,
  button,
  labelClassName,
  image,
  disabled,
  id,
  formik,
  type,
  buttonSize,
  typeFile = 'image',
  ...props
}: UploadComponentProps) {
  const { t } = useTranslation();
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.item(0);
    const fileType = typeFile;

    if (file) {
      const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes
      if (fileType === 'video' && fileSizeMB > 35) {
        setError(t("component.Media.upload.videoSizeError"));
        return;
      } else if (fileType === 'image' && fileSizeMB > 2) {
        setError(t("component.Media.upload.imageSizeError"));
        return;
      }
    }
    if (file) {


      const extenstionType = `${typeFile}/`;
      const imgExtension = file.type.replace(extenstionType, '');
      if (typeFile === 'video') {
        setIsVideoLoading(true);
      } else {
        setIsImageLoading(true);
      }
      try {
        const filePath = await uploadImage(
          fileType,
          imgExtension,
          file,
          `${BASE_URL}/upload`,
        );
        onChange(filePath);
        setError(null);
      } catch (error) {
        handleRequestError(error);
      } finally {
        if (typeFile === 'video') {
          setIsVideoLoading(false);
        } else {
          setIsImageLoading(false);
        }
      }
    }
  };

  const getPlaceholderImage = () => {
    switch (type) {
      case 'team':
        return '/assets/images/imageTeam.png';
      case 'gallery':
        return '/assets/images/media.png';
      case 'news':
        return typeFile === 'video'
          ? '/assets/images/videoPlaceholder.png'
          : '/assets/images/media.png';
      default:
        return '/assets/images/imageTeam.png';
    }
  };


  return (
    <div className="flex flex-col gap-2">
      <label
        className={`flex cursor-pointer items-center justify-center text-[16px] w-[260px] h-[230px]  bg-neutralGre50 border rounded-xl ${labelClassName} ${error ? 'border-red-500' : ''}`}
      >
        <span className="inline-flex items-baseline">
          {isVideoLoading && (
            <FaSpinner className="text-3xl text-black animate-spin" />
          )}
          {isImageLoading && (
            <FaSpinner className="text-3xl text-black animate-spin" />
          )}
          {!image && !isVideoLoading && !isImageLoading && typeFile === 'video' && (
            <img
              className={`rounded-lg w-[267px] h-[220px]`}
              src={getPlaceholderImage()}
              alt="Video placeholder"
            />
          )}
          {image && typeFile === 'video' && (
            <video
              className={`rounded-lg w-[267px] h-full`}
              src={image}
              controls
            />
          )}
          {!image && !isVideoLoading && !isImageLoading && typeFile === 'image' && (
            <img
              className={`rounded-lg `}
              src={getPlaceholderImage()}
              alt="Image placeholder"
            />
          )}
          {image && typeFile === 'image' && (
            <img
              className={`rounded-lg w-[267px] h-[220px]`}
              src={image}
              alt=""
            />
          )}
        </span>
      </label>
      {error ? (
        <p className="px-1 -mt-1 pb-1 text-sm break-words whitespace-normal text-error max-w-[267px]">
          {t(error)}
        </p>
      ) : (
        formik
      )}
      <label>
        <div
          className={`flex justify-center  items-center w-[267px] cursor-pointer h-[48px] rounded-lg bg-orange500 text-whiteColor text-base text-center ${buttonSize} ${error ? '-mt-3' : ''}`}
        >
          {button}
        </div>
        <input
          {...props}
          id={id}
          disabled={disabled}
          name={name}
          type="file"
          accept={typeFile === 'image' ? 'image/png, image/jpeg' : 'video/mp4'}
          onChange={handleChange}
          className={`${props.className} sr-only`}
        />
      </label>

      {disabled && (
        <p className="text-sm text-red-500">
          we added the maximum allowed images
        </p>
      )}

    </div>
  );
}

export default UploadComponent;
