import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineAdjustments } from 'react-icons/hi';
import { IoCheckmarkSharp } from 'react-icons/io5';

interface DropdownProps {
  options: string[];
  onChange: (selectedOption: string | null) => void;
}

function DropdownComponent(props: DropdownProps) {
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<string | null>(
    props.options[0],
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    props.onChange(option);
  };

  return (
    <div className="relative inline-block">
      <HiOutlineAdjustments
        onClick={() => setIsOpen(!isOpen)}
        size={24}
        className={`w-auto flex items-center justify-between px-2 bg-white h-[29px] cursor-pointer ${
          isOpen ? 'rounded-t' : 'rounded'
        }`}
      />
      {isOpen && (
        <div
          className={`absolute z-10 w-auto bg-white rounded-bl rounded-br shadow-lg ${
            i18n.language === 'en' ? 'right-0' : 'left-0'
          }`}
        >
          {props.options.map((option) => (
            <button
              key={option}
              onClick={() => handleOptionClick(option)}
              className={`flex w-full items-center h-8 font-en text-[13px] justify-between cursor-pointer ${
                selectedOption === option ? 'text-orange500' : 'text-black'
              }`}
            >
              <p className="px-4 pt-1">{option}</p>
              {selectedOption === option && (
                <IoCheckmarkSharp size={18} className="mr-2 text-orange500" />
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownComponent;
