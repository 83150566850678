import { makeRequest } from 'core/data-access/http.service';
import { HttpMethods } from 'core/enums/httpMethods.enum';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getOverViewService = async () => {
  return await makeRequest({
    url: `${BASE_URL}/garage/statistics`,
    method: HttpMethods.GET,
  });
};
