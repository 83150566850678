import i18n from 'core/configs/i18n';
import { t } from 'i18next';
import React, { useState } from 'react';
interface TabbarPreviousApplicationsComponentPropsProps {
  page: JSX.Element;
  page2: JSX.Element;
  title1: string;
  title2: string;
}
function TabbarPreviousApplicationsComponentProps(
  props: TabbarPreviousApplicationsComponentPropsProps,
) {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="flex justify-center px-[3%] desktop:px-[2.5%] pt-5 desktop:py-7">
      <div className="w-full h-full rounded-lg">
        <div className="mx-auto cursor-pointer">
          <div className="flex justify-evenly bg-Neutralgray rounded-lg shadow">
            <div
              className={`flex w-full h-full justify-center items-center font-bold leading-loose ${
                toggle
                  ? 'text-gray500'
                  : 'text-orange500 transition-all duration-200 bg-whiteColor rounded-lg'
              } ${
                i18n.language === 'en'
                  ? 'text-2xl mobile:text-xs font-en'
                  : 'text-2xl mobile:text-xs font-ar'
              }`}
              onClick={() => setToggle(false)}
            >
              {t(props.title1)}
            </div>
            <div
              className={`flex w-full h-full justify-center items-center font-bold leading-loose ${
                toggle
                  ? 'text-orange500 transition-all duration-200 bg-whiteColor rounded-lg'
                  : 'text-gray500'
              } ${
                i18n.language === 'en'
                  ? 'text-2xl mobile:text-xs font-en'
                  : 'text-2xl mobile:text-xs font-ar'
              }`}
              onClick={() => setToggle(true)}
            >
              {t(props.title2)}
            </div>
          </div>
        </div>
        {toggle ? props.page2 : props.page}
      </div>
    </div>
  );
}

export default TabbarPreviousApplicationsComponentProps;
