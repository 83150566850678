import { HttpMethods } from 'core/enums/httpMethods.enum';
import { disruptAddCompanies, timeForDisrupt } from 'core/types/admin.type';

import { BASE_URL, makeRequest } from './http.service';

//create disrupt
export const postDisruptServise = async (data: timeForDisrupt) => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt`,
    method: HttpMethods.POST,
    data,
  });
};

//Get disrupt
export const getDisrupt = (
  limit: number,
  offset: number,
  searchText: number | null,
) => {
  return makeRequest({
    url: `${BASE_URL}/disrupt`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      limit: limit,
      offset: offset,
    },
  });
};
//disrupt publish
export const postDisruptPublishServise = async () => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt/publish`,
    method: HttpMethods.POST,
  });
};

//disrupt unpublish
export const postDisruptUnpublishServise = async () => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt/unpublish`,
    method: HttpMethods.POST,
  });
};

// Create Companies
export const postDisruptcompanies = async (data: disruptAddCompanies) => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt/companies`,
    method: HttpMethods.POST,
    data,
  });
};
// Update companies
export const putDisruptCompanies = (
  data: timeForDisrupt,
  disrupt_id: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/disrupt/companies/${disrupt_id}`,
    method: HttpMethods.PUT,
    data,
    params: { disrupt_id: disrupt_id },
  });
};
// Delete companies
export const DeleteDisruptCompanies = (
  disrupt_companies_id: string,
  data: disruptAddCompanies,
) => {
  return makeRequest({
    url: `${BASE_URL}/disrupt/companies/${disrupt_companies_id}`,
    method: HttpMethods.DELETE,
    data,
    params: { disrupt_companies_id: disrupt_companies_id },
  });
};

//Get all company
export const getDisruptCompany = (
  limit: number,
  offset: number,
  searchText: string | null,
  application_state: string | null,
  disrupt_number?: number | null,
) => {
  return makeRequest({
    url: `${BASE_URL}/disrupt/company/applications/list`,
    method: HttpMethods.GET,
    // params: application_state?{application_state:application_state}:{},
    params: {
      q: searchText,
      limit: limit,
      offset: offset,
      application_state: application_state,
      disrupt_number: disrupt_number,
    },
  });
};
//Get all guest
export const getDisruptGuest = (
  limit: number,
  offset: number,
  searchText: string | null,
  disrupt_number: number | null,
  application_state: string | null,
) => {
  return makeRequest({
    url: `${BASE_URL}/disrupt/guest/applications/list`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      disrupt_number: disrupt_number,
      application_state: application_state,
      limit: limit,
      offset: offset,
    },
  });
};

// Company Accepted
export const postDisruptApplicationCompanyAccepted = async (
  disrupt_application_id: string,
) => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt/company/application/${disrupt_application_id}/accept`,
    method: HttpMethods.POST,
    params: { disrupt_application_id: disrupt_application_id },
  });
};

//Company Rejected
export const postDisruptApplicationCompanyRejected = async (
  disrupt_application_id: string,
) => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt/company/application/${disrupt_application_id}/reject`,
    method: HttpMethods.POST,
    params: { disrupt_application_id: disrupt_application_id },
  });
};

//Guest Accepted
export const postDisruptApplicationGuestAccepted = async (
  disrupt_application_id: string,
) => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt/guest/application/${disrupt_application_id}/accept`,
    method: HttpMethods.POST,
    params: { disrupt_application_id: disrupt_application_id },
  });
};

//Guest Rejected
export const postDisruptApplicationGuestRejected = async (
  disrupt_application_id: string,
) => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt/guest/application/${disrupt_application_id}/reject`,
    method: HttpMethods.POST,
    params: { disrupt_application_id: disrupt_application_id },
  });
};

// Disrupt Status
export const getDisruptStatus = () => {
  return makeRequest({
    url: `${BASE_URL}/disrupt/status`,
    method: HttpMethods.GET,
  });
};

//edit disrupt
export const editDisrupt = (disrupt_id: string, data: timeForDisrupt) => {
  return makeRequest({
    url: `${BASE_URL}/disrupt/${disrupt_id}`,
    method: HttpMethods.PUT,
    data,
    params: { disrupt_id: disrupt_id },
  });
};

export const getDisruptDisruptExport = async () => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt/export`,
    method: HttpMethods.GET,
  });
};
export const getDisruptStatistics = async () => {
  return await makeRequest({
    url: `${BASE_URL}/disrupt/applications/statistics`,
    method: HttpMethods.GET,
  });
};
