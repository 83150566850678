import IssueContainer from 'containers/admin/Aboutus/Issue/issue-container';

const IssuePage = () => {
  return (
    <div>
      <IssueContainer />
    </div>
  );
};
export default IssuePage;
