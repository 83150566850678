import Button from 'components/admin/ButtonComponent';
import DataListComponent from 'components/common/Data-list/data-list-component';
import EmptySearchComponent from 'components/common/empty-search';
import ExportDisruptPopup from 'components/common/export-popup/export-disrupt-popup';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import SkeletonDataComponent from 'components/common/skeleton-element/incubators-skeleton';
import { getDisruptCompany } from 'core/data-access/disrupt.service';
import { PDFEnum } from 'core/enums/application_state.enum';
import { DisruptExportEnum } from 'core/enums/disrupt.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { DisruptModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlineLink } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp, IoLogoInstagram } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { TfiTwitter } from 'react-icons/tfi';
import { Link } from 'react-router-dom';

function DisruptOldApplicationsStartups() {
  const place = [1, 1, 1, 1];
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const [data, setData] = useState<DisruptModel>();
  const [, setFiltered] = useState(data?.result_list);

  const [val, setVal] = useState<string>('');

  const [toggle, setToggle] = useState(
    new Array(data?.result_list?.length).fill(false),
  );
  const [toggleAboutTheStartup, setToggletoggleAboutTheStartup] = useState(
    new Array(data?.result_list?.length).fill(false),
  );
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(data?.result_list?.length).fill(false),
  );
  const [toggleStartUpDetails, settoggleStartUpDetails] = useState(
    new Array(data?.result_list?.length).fill(false),
  );
  const paginationLimit = 4;
  const [page, setPage] = useState<number>(1);
  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };

  const handleToggleAboutTheStartupClick = (index: number) => {
    const newToggleSmall = [...toggleAboutTheStartup];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggletoggleAboutTheStartup(newToggleSmall);
  };

  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };

  const handleToggleStartUpDetailsClick = (index: number) => {
    const newToggleSmall = [...toggleStartUpDetails];
    newToggleSmall[index] = !newToggleSmall[index];
    settoggleStartUpDetails(newToggleSmall);
  };
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  const handleExportClick = () => {
    setExportPopup(true);
  };
  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };

  const getGueste = useCallback(async () => {
    try {
      const acceptedData = await getDisruptCompany(
        paginationLimit,
        (page - 1) * paginationLimit,
        null,
        null,
        null,
      );
      setData(acceptedData);
      setSkeletonData(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, [page]);
  const refresh = localStorage.getItem('refresh');
  if (refresh === 'true') {
    getGueste();
    localStorage.setItem('refresh', 'false');
  }
  const { t } = useTranslation();
  useEffect(() => {
    getGueste();
  }, [getGueste]);

  const handleSearchClick = () => {
    if (val === '') {
      setFiltered(data?.result_list);
      return;
    }
    const filterBySearch = data?.result_list.filter((item) => {
      item.startup_name.toLowerCase().includes(val.toLowerCase());
    });
    setFiltered(filterBySearch);
  };

  const getSearchData = async () => {
    try {
      const DisruptStartups: DisruptModel = await getDisruptCompany(
        paginationLimit,
        (page - 1) * paginationLimit,
        val,
        null,
      );

      unstable_batchedUpdates(() => {
        setData(DisruptStartups);
        setFiltered(DisruptStartups?.result_list);
      });
      handleSearchClick();
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <div className="pt-5 flex justify-center items-center">
      <div className="flex flex-col w-full h-full rounded-lg bg-whiteColor px-8 pt-4 pb-7">
        <div className="flex flex-row justify-start items-start pb-[26px] pt-1 gap-4">
          <input
            onChange={(e) => setVal(e.target.value)}
            type="search"
            id="default-search"
            className="block h-12 w-1/2 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
            placeholder={`${t('search')}`}
            required
          />
          <Button
            disabled={false}
            width={'w-[143px]'}
            hight={'h-12'}
            text={`${t('search')}`}
            fontsize={'text-base'}
            onClick={getSearchData}
          />
        </div>
        <div className="grid grid-cols-5 w-full font-bold text-lg pr-[15%] pl-9 pb-2 items-center">
          <div>{t('startupName')}</div>
          <div>{t('startupMajor')}</div>
          <div>{t('Startup stage')}</div>
          <div>{t('startupprofile')}</div>
          <div className="pl-16">{t('status')}</div>
        </div>
        {skeletonData ? (
          <div className="flex flex-col items-center ">
            {place.map((e, index) => {
              return (
                <div key={index}>
                  <SkeletonDataComponent />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {data?.result_list === undefined || null ? (
              <Loading />
            ) : data?.total_count === 0 ? (
              <div className="flex justify-center mx-auto">
                <EmptySearchComponent />
              </div>
            ) : (
              <>
                {data?.result_list?.map((item, index) => (
                  <div
                    key={item.id}
                    className="flex flex-col w-full items-center py-3"
                  >
                    <DataListComponent
                      index={index}
                      toggle={toggle}
                      id={item.id}
                      item1={item.startup_name}
                      item2={item.startup_major}
                      item3={item.startup_last_fundraising_round}
                      Icon={PDFEnum.YES}
                      isNew={false}
                      item5={item.application_state}
                      onClickArrowDown={() =>
                        handleToggleClick(index, !toggle[index])
                      }
                      onClickArrowUp={() =>
                        handleToggleClick(index, !toggle[index])
                      }
                    />

                    {toggle[index] && (
                      <div className="px-4 pb-4 bg-slate50 w-full flex justify-center h-full rounded-b-lg">
                        <div className="flex flex-col py-2">
                          <div
                            className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-[1168px] h-[52px] items-center`}
                          >
                            <div className="flex flex-row justify-between w-full px-4">
                              <div className="text-secondaryGreen400">
                                About the startup
                              </div>
                              {toggleAboutTheStartup[index] ? (
                                <IoIosArrowUp
                                  className="flex w-[50px] h-5 cursor-pointer pl-5"
                                  onClick={() =>
                                    handleToggleAboutTheStartupClick(index)
                                  }
                                />
                              ) : (
                                <IoIosArrowDown
                                  className="flex w-[50px] h-5 cursor-pointer pl-5"
                                  onClick={() =>
                                    handleToggleAboutTheStartupClick(index)
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {toggleAboutTheStartup[index] && (
                            <div className="flex flex-col w-[1168px] h-[120px] bg-whiteColor border border-gray500 py-4">
                              <div className="px-3 text-base">
                                {item.about_startup}
                              </div>
                              <div>
                                <div className="flex items-end justify-end gap-2 px-5 py-4">
                                  <Link
                                    to={`https://www.instagram.com/${item.instagram}/?hl=ar`}
                                    target="_blank"
                                  >
                                    <IoLogoInstagram className="w-[25px] h-[25px] text-orange500" />
                                  </Link>
                                  <Link to={item.website_link} target="_blank">
                                    <AiOutlineLink className="w-[25px] h-[25px] text-orange500" />
                                  </Link>
                                  <Link
                                    to={`https://twitter.com/${item.twitter}`}
                                    target="_blank"
                                  >
                                    <TfiTwitter className="w-[25px] h-[25px] text-orange500" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="flex flex-row gap-5 pt-9">
                            <div className="flex flex-col">
                              <div
                                className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-[573px] h-[52px] items-center`}
                              >
                                <div className="flex flex-row justify-between w-full px-4">
                                  <div className="text-secondaryGreen400">
                                    {t('generalinfo')}
                                  </div>
                                  {toggleGenralInfo[index] ? (
                                    <IoIosArrowUp
                                      className="flex w-[50px] h-5 cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleGenralInfoClick(index)
                                      }
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="flex w-[50px] h-5 cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleGenralInfoClick(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {toggleGenralInfo[index] && (
                                <div className="flex flex-col w-[573px] bg-whiteColor border border-gray500 gap-2 py-3">
                                  <div className="px-3">{t('firstName')}</div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.first_name}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">{t('lastname')}</div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.last_name}
                                  </div>
                                  <hr className="w-full" />{' '}
                                  <div className="px-3">{t('nationality')}</div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.nationality}
                                  </div>
                                  <hr className="w-full" />{' '}
                                  <div className="px-3">{t('gender')}</div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.gender}
                                  </div>
                                  <hr className="w-full" />{' '}
                                  <div className="px-3">{t('Position')}</div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.position}
                                  </div>
                                  <hr className="w-full" />{' '}
                                  <div className="px-3">
                                    {t('mobilenumber')}
                                  </div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.mobile}
                                  </div>
                                  <hr className="w-full" />{' '}
                                  <div className="px-3">{t('email')}</div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.email}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="flex flex-col">
                              <div
                                className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 w-[573px] h-[52px] items-center`}
                              >
                                <div className="flex flex-row justify-between w-full px-4">
                                  <div className="text-secondaryGreen400">
                                    {t('startupDetails')}
                                  </div>
                                  {toggleStartUpDetails[index] ? (
                                    <IoIosArrowUp
                                      className="flex w-[50px] h-5 cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleStartUpDetailsClick(index)
                                      }
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="flex w-[50px] h-5 cursor-pointer pl-5"
                                      onClick={() =>
                                        handleToggleStartUpDetailsClick(index)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {toggleStartUpDetails[index] && (
                                <div className="flex flex-col w-[573px] bg-whiteColor border border-gray500 gap-2 py-3">
                                  <div className="px-3 text-base">
                                    {t('yearofestablishment')}
                                  </div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.year_of_establishment}
                                  </div>
                                  <hr className="w-full mr-9" />
                                  <div className="px-3">
                                    {t('Startup Stage')}
                                  </div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.startup_last_fundraising_round}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">
                                    {t('How did you hear about AlGarage ?')}
                                  </div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.hear_about_garage}
                                  </div>
                                  <hr className="w-full" />
                                  <div className="px-3">
                                    {t('whydoyouwanttojoinAlGarage')}
                                  </div>
                                  <div className="px-3 text-textGrayColor">
                                    {item.reason_to_join}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </>
        )}
        <div className="pr-5">
          <Pagination
            totalCount={data?.total_count || 0}
            totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
            page={page}
            limit={paginationLimit}
            onPageChange={setPage}
          />
        </div>
        <div className="flex items-center justify-end">
          <Button
            disabled={false}
            width={'w-[305px]'}
            hight={'h-12'}
            text={'Export All'}
            img={<RiFileExcel2Line size={24} />}
            fontsize={'text-base'}
            onClick={() => {
              handleExportClick();
            }}
          />
        </div>
      </div>
      {exportPopup && (
        <ExportDisruptPopup
          applicationType={DisruptExportEnum.COMPANY}
          pathExport={'disrupt'}
          title={'Disrupt'}
          subtitle={'The disrupt you picked will be exported.'}
          programName={'Disrupt'}
          fileName="Disrupt Application"
          pathList={'disrupt'}
          onClose={handleCloseExportPopup}
          onClickConfirm={handleCloseExportPopup}
        />
      )}
    </div>
  );
}
export default DisruptOldApplicationsStartups;
