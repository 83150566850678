export enum example {
  example = 'example',
}

export enum LanguagesEnum {
  EN = 'en',
  AR = 'ar',
}
export enum contactUsEnum {
  DEL = 'DELETED',
  NEW = 'NEW',
  READ = 'READ',
}

export enum GalleryTypeEnum {
  Disrupt = 'Disrupt',
  Plus = 'Plus',
  INCUBATOR = 'Incubator',
  THE_GARAGE = 'the_garage',
}
export enum UploadImageTypeEnum {
  Team = 'team',
  Media = 'media',
  News = 'news',
}
export enum StatisticsBookingTypeEnum {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}
export enum SortbyBookingTypeEnum {
  MOSTBOOKED = 'MOST_BOOKED',
  LESSBOOKED = 'LESS_BOOKED',
}
