import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface BarChartProps {
  dataWeekly: {
    labels: string[];
    data: number[];
  };
  dataMonthly: {
    labels: string[];
    data: number[];
  };
  onTabChange: (tab: 'weekly' | 'monthly') => void;
  loading: boolean;
}

const BarChart = ({ dataWeekly, dataMonthly, onTabChange, loading }: BarChartProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('weekly');

  const noData =
    (activeTab === 'weekly' && (!dataWeekly.data || dataWeekly.data.length === 0)) ||
    (activeTab === 'monthly' && (!dataMonthly.data || dataMonthly.data.length === 0));


  const isScrollable = useMemo(() => {
    const currentLabels =
      activeTab === 'monthly' ? dataMonthly.labels : dataWeekly.labels;

    return currentLabels.length > 8;
  }, [activeTab, dataWeekly.labels, dataMonthly.labels]);

  const chartData = {
    labels: activeTab === 'monthly' ? dataMonthly.labels : dataWeekly.labels,
    datasets: [
      {
        data: activeTab === 'monthly' ? dataMonthly.data : dataWeekly.data,
        backgroundColor: activeTab === 'monthly' ? '#EB896B' : '#50C5B1',
        barThickness: 20,
        borderRadius: 7,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        align: 'start' as const,
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        type: 'category' as const,
        labels: chartData.labels,
        ticks: {
          autoSkip: false,
          color: '#989DA3',
        },
        grid: {
          display: false,
          color: '#EFF0F1',
        },
        offset: true,
      },
      y: {
        type: 'linear' as const,
        grid: {
          display: true,
          color: '#EFF0F1',
        },
        ticks: {
          color: '#989DA3',
        },
      },
    },
  };
  const handleTabClick = (tab: 'weekly' | 'monthly') => {
    setActiveTab(tab);
    onTabChange(tab);
  };

  return (
    <div className="w-full h-full p-4">
      <div className="mb-4 text-base font-bold capitalize">
        {t('RoomsBooked')}
      </div>
      <div className="flex w-full gap-4 mb-4">
        <button
          onClick={() => handleTabClick('weekly')}
          className={`font-en ${activeTab === 'weekly'
            ? 'font-bold text-black border-b-4 border-secondaryGreen400'
            : 'text-gray-500'
            }`}
        >
          {t('Weekly')}
        </button>
        <button
          onClick={() => handleTabClick('monthly')}
          className={`font-en ${activeTab === 'monthly'
            ? 'font-bold text-black border-b-4 border-secondaryGreen400'
            : 'text-gray-500'
            }`}
        >
          {t('Monthly')}
        </button>
      </div>
      <div className={`w-full ${isScrollable ? 'overflow-x-auto' : ''}`}>
        <div className={`h-[320px] ${isScrollable ? 'min-w-[800px]' : ''}`}>
          {loading ? (
            <div className="flex flex-col items-center animate-pulse gap-y-4">
              <div className="w-full h-64 bg-gray-200 rounded-lg" />
              <div className="w-full h-6 bg-gray-200 rounded-full" />
            </div>
          ) : noData ? (
            <div className='flex flex-col items-center gap-y-4'>
              <img className='w-64 h-64' src="/assets/images/notFound.png" alt="not found" />
              <div className="w-full h-full text-3xl text-center text-blue500">لا يوجد بيانات</div>
            </div>
          ) : (
            <Bar data={chartData} options={options} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BarChart;
