import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import CardLatestEvent from 'components/admin/latest-events/card-latest-events';
import EmptySearchComponent from 'components/common/empty-search';
import Pagination from 'components/common/Pagination/pagination';
import { handleRequestError } from 'core/helpers/error.helper';
import { AllMediaNewsModel } from 'core/types/admin.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'routes/app.paths';
import { deleteNewsServices, getNweServices } from 'services/news-service';

function LatestEventsContainer() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<AllMediaNewsModel>();
  const paginationLimit = 15
  const [visibleItems, setvisibleItems] = useState(1);
  const [deleteNewsPopup, setDeleteNewsPopup] = useState<{
    isOpen: boolean;
    id?: string;
  }>({ isOpen: false });


  const getData = useCallback(async () => {
    try {
      const Disrupt: AllMediaNewsModel = await getNweServices(
        'news_video',
        paginationLimit,
        (visibleItems - 1) * paginationLimit,
      );
      unstable_batchedUpdates(() => {
        setData(Disrupt);
        setLoading(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [visibleItems, paginationLimit]);

  const handleDeleteClick = (id: string) => {
    setDeleteNewsPopup({ isOpen: true, id });
  };

  const handleDelete = async () => {
    if (deleteNewsPopup.id) {
      try {
        await deleteNewsServices(deleteNewsPopup.id);
        getData();
      } catch (error) {
        handleRequestError(error);
      } finally {
        setDeleteNewsPopup({ isOpen: false });
      }
    }
  };


  useEffect(() => {
    getData();
  }, [getData]);


  if (loading) {
    return (
      <div className='w-full px-4 py-6'>
        <div className="flexflex-col h-[calc(100vh-300px)] justify-center w-full pt-24 gap-y-8">
          <div className="flex flex-col items-center space-y-4 animate-pulse">
            <div className="grid w-full grid-cols-5 gap-3 tablet:grid-cols-3 mobile:grid-cols-2 place-items-center">
              {Array.from({ length: 15 }).map((_, index) => (
                <div key={index} className="flex flex-col w-full p-3 rounded bg-slate50 gap-y-3">
                  <div className="w-full h-[100px] bg-gray-300 rounded-lg" />
                  <div className="w-full h-6 bg-gray-300 rounded-lg" />
                  <div className="flex justify-between w-full gap-4">
                    <div className="w-1/2 h-6 bg-gray-300 rounded-lg" />
                    <div className="w-1/2 h-6 bg-gray-300 rounded-lg" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!data || data.total_count === 0) {
    return (
      <div className='w-full px-4 py-6'>
        <p className="text-xl font-bold font-en">
          {t('container.media.latest.events')}
        </p>
        <div className="flex flex-col items-center h-[calc(100vh-300px)] justify-center w-full pt-24 gap-y-8">
          <EmptySearchComponent message='component.Media.No.Videos' />
          <button
            onClick={() => navigate(appRoutesObj.getCreateNewsVideoPath())}
            className="p-3 text-white bg-orange500 w-[143px] rounded-lg"
          >
            {t('container.media.latest.events.create.video')}
          </button>
        </div>
      </div>
    );
  }


  return (
    <div className="w-full px-4 py-6">
      <div className="flex items-center justify-between pb-6">
        <p className="text-xl font-bold font-en">
          {t('container.media.latest.events')}
        </p>
        <button
          onClick={() => navigate(appRoutesObj.getCreateNewsVideoPath())}
          className="p-3 text-white bg-orange500 w-[143px] rounded-lg"
        >
          {t('container.media.latest.events.create.video')}
        </button>
      </div>
      <div className="px-3 py-4 bg-white rounded-lg min-h-[656px]">
        <div className="grid grid-cols-5 gap-3 tablet:grid-cols-3 tablet:place-items-center mobile:grid-cols-2 mobile:place-items-center place-items-center">
          {data?.result_list.map((video) => (
            <CardLatestEvent
              key={video.id}
              title={currentLanguage === 'en' ? video.title_en : video.title_ar}
              url={video.url}
              onEdit={appRoutesObj.getEditLatestEventsIdPageUrl(video.id || '')}
              onDelete={() => handleDeleteClick(video.id || '')}
              content={currentLanguage === 'en'
                ? video.description_en
                : video.description_ar}
              loading={loading}
            />
          ))}
        </div>
      </div>
      <div className="pt-6">
        <Pagination
          totalCount={data?.total_count || 0}
          totalPage={Math.ceil((data?.total_count || 0) / paginationLimit)}
          page={visibleItems}
          limit={paginationLimit}
          onPageChange={setvisibleItems}
        />
      </div>
      {deleteNewsPopup.isOpen && (
        <ConfirmPopupComponent
          onClose={() => setDeleteNewsPopup({ isOpen: false })}
          title={t('container.media.listnews.delete.news')}
          onClickConfirm={handleDelete}
        />
      )}
    </div>
  );
}

export default LatestEventsContainer;
