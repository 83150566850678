interface Data {
  partner: string;
  link: string;
  logo: string;
  information: {
    mobileNumber: string;
    emailaddress: string;
    gender: string;
    nationality: string;
    note: string;
  };
}

export const data: Data[] = [
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
  {
    partner: 'safcsp',
    link: 'https://safcsp.org.sa',
    logo: 'lo',
    information: {
      emailaddress: 'aaaa@Mail.com',
      mobileNumber: '55555555555',
      gender: 'male',
      nationality: 'Saudi',
      note: 'Lorem ipsum dolor sit amet consectetur. \n Sit viverra proin aliquet tellus pulvinar fringilla.',
    },
  },
];
