import { makeRequest } from 'core/data-access/http.service';
import { HttpMethods } from 'core/enums/httpMethods.enum';
const BASEURL = process.env.REACT_APP_BASE_URL;

export const exportDisruptApplicationService = (
  path: string,
  application_type?: string,
  disrupt_id?: string,
) => {
  return makeRequest({
    url: `${BASEURL}/${path}/application/export`,
    method: HttpMethods.GET,
    params: {
      application_type: application_type ? application_type : null,

      disrupt_id: disrupt_id ? disrupt_id : null,
    },
  });
};

export const exportPlusApplicationService = (
  path: string,
  plus_id?: string,
) => {
  return makeRequest({
    url: `${BASEURL}/${path}/application/export`,
    method: HttpMethods.GET,
    params: {
      plus_id: plus_id,
    },
  });
};

export const exportIncubatorApplicationService = (
  path: string,
  incubator_id?: string,
) => {
  return makeRequest({
    url: `${BASEURL}/${path}/application/export`,
    method: HttpMethods.GET,
    params: {
      incubator_id: incubator_id,
    },
  });
};

export const programsListExportService = (
  path: string,
  application_state?: string,
) => {
  return makeRequest({
    url: `${BASEURL}/${path}`,
    method: HttpMethods.GET,
    params: application_state
      ? {
          application_state: application_state,
        }
      : {
          limit: 20,
          offset: 0,
        },
  });
};
