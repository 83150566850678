import { BASE_URL, makeRequest } from 'core/data-access/http.service';
import { HttpMethods } from 'core/enums/httpMethods.enum';

export const getAllApplicantsTransportationPermitsServices = (
  applicationState: boolean,
  limit: number,
  offset: number,
  searchText: string | null,
) => {
  return makeRequest({
    url: `${BASE_URL}/visit/permit/application`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      is_pending: applicationState,
      limit: limit,
      offset: offset,
    },
  });
};

export const getTransportationPermitsExportServices = (export_status?:string) => {
  return makeRequest({
    url: `${BASE_URL}/visit/export/permit`,
    method: HttpMethods.GET,
   params:{"export_status":export_status}
  });
};

export const postTransportationPermitsAcceptOrReject = (
  status: string,
  permit_id: string,
  reject_reason?: string,
) => {
  return makeRequest({
    url: `${BASE_URL}/visit/permit/${permit_id}/${status}`,
    method: HttpMethods.PUT,
    data: { reject_reason: reject_reason },
  });
};
