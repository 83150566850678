import ButtonComponent from 'components/admin/ButtonComponent';
import Button from 'components/admin/ButtonComponent';
import ConfirmPopupComponent from 'components/admin/ConfirmPopupComponet';
import Loading from 'components/common/loading';
import Pagination from 'components/common/Pagination/pagination';
import IncubatorsSkeleton from 'components/common/skeleton-element/incubators-skeleton';
import { ApplicationStateEnum } from 'core/enums/application_state.enum';
import { handleRequestError } from 'core/helpers/error.helper';
import { BookVisitModel } from 'core/types/user.type';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiFileExcel2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'routes/app.paths';
import * as Services from 'services/visit-services';
import { getBookVisitExportServices } from 'services/visit-services';

function OldVisitContainer() {
  const { t } = useTranslation();
  const place = [1, 1, 1, 1];
  const [skeletonData, setSkeletonData] = useState<boolean>(true);
  const Nav = useNavigate();
  const paginationLimit = 5;
  const [page, setPage] = useState<number>(1);
  const [bookVisitData, setBookVisitData] = useState<BookVisitModel>();
  const [visibleItems] = useState<number>(7);
  const [loading] = useState<boolean>(false);
  const [val, setVal] = useState<string>('');
  const [, setFiltered] = useState(bookVisitData?.result_list);
  const refresh = localStorage.getItem('refresh');
  const redirect = localStorage.getItem('redirect');
  const [dataExport, setDataExport] = useState<string>('');
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  const [toggle, setToggle] = useState(
    new Array(bookVisitData?.result_list.length).fill(false),
  );
  const [toggleGenralInfo, setToggleGenralInfo] = useState(
    new Array(bookVisitData?.result_list.length).fill(false),
  );

  const handleToggleClick = (index: number, value: boolean) => {
    const newToggle = [...toggle];
    newToggle[index] = value;
    setToggle(newToggle);
  };
  const handleToggleGenralInfoClick = (index: number) => {
    const newToggleSmall = [...toggleGenralInfo];
    newToggleSmall[index] = !newToggleSmall[index];
    setToggleGenralInfo(newToggleSmall);
  };
  const handleSearchClick = () => {
    if (val === '') {
      setFiltered(bookVisitData?.result_list);
      return;
    }
    const filterBySearch = bookVisitData?.result_list.filter((item) => {
      item.first_name.toLowerCase().includes(val.toLowerCase());
    });
    setFiltered(filterBySearch);
  };
  const getData = useCallback(async () => {
    try {
      const visitData: BookVisitModel = await Services.getVisitService(
        ApplicationStateEnum.ACCEPTED,
        20,
        0,
        null,
      );

      unstable_batchedUpdates(() => {
        setBookVisitData(visitData);
        setFiltered(visitData.result_list);
        setSkeletonData(false);
      });
    } catch (error) {
      handleRequestError(error);
    }
  }, [setBookVisitData]);

  if (refresh === 'true') {
    getData();
    localStorage.setItem('refresh', 'false');
  }
  if (redirect === 'true') {
    Nav(appRoutesObj.getLogInPagePath());
    localStorage.clear();
  }

  const getDataExport = async () => {
    try {
      const visitDataExport: string = await getBookVisitExportServices();
      setDataExport(visitDataExport);
    } catch (error) {
      handleRequestError(error);
    }
  };
  const handleExportClick = () => {
    setExportPopup(true);
  };

  const handleCloseExportPopup = () => {
    setExportPopup(false);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="pt-6 desktop:py-7 flex justify-center items-center">
      <div className="flex flex-col rounded-lg bg-white w-full px-10 pt-8 pb-2">
        <div className="flex flex-row justify-start items-start pb-3 gap-4">
          <input
            onChange={(e) => setVal(e.target.value)}
            type="search"
            id="default-search"
            className="block h-12 w-1/2 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:placeholder-gray-400"
            placeholder={`${t('search')}`}
            required
          />
          <ButtonComponent
            disabled={false}
            width={'w-[143px]'}
            hight={'h-[43px]'}
            text={`${t('search')}`}
            fontsize={'text-base'}
            onClick={handleSearchClick}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="grid grid-cols-6 w-full font-bold text-2xl py-3 pr-48 pl-7 gap-20 items-center">
            <div className="text-center">{t('Name')}</div>
            <div className="text-center">{t('Nationality')}</div>
            <div className="text-center">{t('Company')}</div>
            <div className="text-center whitespace-nowrap">
              {t('Visit Date')}
            </div>
            <div className="text-center whitespace-nowrap">
              {t('Invited By')}
            </div>
            <div className="text-center">{t('Status')}</div>
          </div>
          {skeletonData ? (
            <div className="flex flex-col items-center ">
              {place.map((e, index) => {
                return (
                  <div key={index}>
                    <IncubatorsSkeleton />
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div>
                {bookVisitData?.total_count === 0 ? (
                  <div className="w-auto h-96 flex flex-col gap-5 items-center justify-center">
                    <div className="h-[273px] w-[337px] mb-7">
                      <img src="/assets/images/notFound.png" alt="not found" />
                    </div>
                    <h1 className="text-2xl text-blue300 font-bold ">
                      We couldn’t find what you’re looking for
                    </h1>
                  </div>
                ) : (
                  bookVisitData?.result_list
                    .slice(0, visibleItems)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center mb-4"
                      >
                        <div
                          className={`grid grid-cols-7 justify-center items-center bg-greyMenuColor h-full w-full py-4 rounded-t-lg ${
                            toggle[index] ? '' : 'rounded-b-lg'
                          }`}
                        >
                          <div className="text-center">{item.first_name}</div>
                          <div className="text-center">{item.last_name}</div>
                          <div className="text-center">{item.nationality}</div>
                          <div className="text-center">{item.company}</div>
                          <div className="text-center">{item.visitDate}</div>
                          <div className="flex items-center justify-center desktop:pl-16">
                            {item.application_state ===
                            ApplicationStateEnum.ACCEPTED ? (
                              <div className="flex h-full py-1 px-5 bg-green50 text-green500 rounded-[20px] items-center justify-center">
                                {t('accepted')}
                              </div>
                            ) : (
                              <div className="flex h-full py-1 px-5 bg-red50 text-red500 rounded-[20px] items-center justify-center">
                                {t('reject')}
                              </div>
                            )}
                          </div>
                          <div className="pt-2 pl-9 desktop:pl-32">
                            {toggle[index] ? (
                              <IoIosArrowUp
                                className="flex justify-end items-end w-[50px] cursor-pointer pl-5"
                                onClick={() => handleToggleClick(index, false)}
                              />
                            ) : (
                              <IoIosArrowDown
                                className="flex justify-end items-end w-[50px] cursor-pointer pl-5"
                                onClick={() => handleToggleClick(index, true)}
                              />
                            )}
                          </div>
                        </div>
                        {loading && (
                          <div className="fixed flex items-center justify-center inset-[10px]">
                            <Loading />
                          </div>
                        )}
                        {toggle[index] && (
                          <div className="px-4 pb-4 bg-slate50 w-full desktop:w-full flex justify-center h-full rounded-b-lg">
                            <div className="flex flex-col py-2">
                              <div className="flex flex-row gap-5 pt-9">
                                <div className="flex flex-col">
                                  <div
                                    className={`flex flex-row bg-NeutralGre50 border border-secondaryGreen400 desktop:w-[1200px] w-[1320px] h-[52px] items-center`}
                                  >
                                    <div className="flex flex-row justify-between w-full items-center px-4">
                                      <div className="text-secondaryGreen400">
                                        General info
                                      </div>
                                      {toggleGenralInfo[index] ? (
                                        <IoIosArrowUp
                                          className="flex w-[50px] cursor-pointer pl-5"
                                          onClick={() =>
                                            handleToggleGenralInfoClick(index)
                                          }
                                        />
                                      ) : (
                                        <IoIosArrowDown
                                          className="flex w-[50px] cursor-pointer pl-5"
                                          onClick={() =>
                                            handleToggleGenralInfoClick(index)
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {toggleGenralInfo[index] && (
                                    <div className="flex flex-col desktop:w-[1200px] w-[1320px] bg-whiteColor border border-gray500 px-3 gap-2 py-4">
                                      <div>{t('Mobile Number')}</div>
                                      <div className=" text-greyChateau">
                                        {item.mobileNumber}
                                      </div>
                                      <hr className="w-full px-0 opacity" />
                                      <div>{t('Gender')}</div>
                                      <div className=" text-greyChateau">
                                        {item.gender}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Email')}</div>
                                      <div className=" text-greyChateau">
                                        {item.email}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Visit Date')}</div>
                                      <div className=" text-greyChateau">
                                        {item.visitDate}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('ID Number')}</div>
                                      <div className=" text-greyChateau">
                                        {item.Idnumber}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('ID Type')}</div>
                                      <div className=" text-greyChateau">
                                        {item.Idtype}
                                      </div>
                                      <hr className="w-full opacity-50" />
                                      <div>{t('Nationality')}</div>
                                      <div className=" text-greyChateau">
                                        {item.nationality}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                )}
              </div>
            </>
          )}
          <Pagination
            totalPage={Math.ceil(
              (bookVisitData?.total_count || 0) / paginationLimit,
            )}
            page={page}
            limit={paginationLimit}
            onPageChange={setPage}
            totalCount={bookVisitData?.total_count || 0}
          />

          <div className="flex items-center justify-end px-5 py-2.5">
            <Button
              disabled={false}
              width={'w-[305px]'}
              hight={'h-12'}
              text={'Export All'}
              img={<RiFileExcel2Line size={24} />}
              fontsize={'text-base'}
              onClick={() => handleExportClick()}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[400px] right-2">
        {exportPopup && (
          <ConfirmPopupComponent
            fileName="Tour Data"
            onClose={handleCloseExportPopup}
            title={'Are you sure you want to Export All?'}
            subtitle={
              'If you confirm, all forms on this page will be downloaded.'
            }
            onClick={handleCloseExportPopup}
            onClickConfirm={getDataExport}
            data={dataExport}
          />
        )}
      </div>
    </div>
  );
}

export default OldVisitContainer;
