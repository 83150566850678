import { makeRequest } from 'core/data-access/http.service';
import { IssueEnum } from 'core/enums/application_state.enum';
import { HttpMethods } from 'core/enums/httpMethods.enum';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getIssueService = (
  searchText: string | null,
  type: IssueEnum,
  message_status: string,
  limit: number,
  offset: number,
) => {
  return makeRequest({
    url: `${BASE_URL}/issue/${type}`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
      message_status: message_status,
      limit: limit,
      offset: offset,
    },
  });
};

export const postIssueService = async (
  new_status: string,
  IssueIds: string[],
) => {
  return makeRequest({
    url: `${BASE_URL}/issue/toggle`,
    method: HttpMethods.POST,
    params: { new_status },
    data: { ids: IssueIds },
  });
};

export const replayIssuesService = async (issue_id: string, reply: string) => {
  return makeRequest({
    url: `${BASE_URL}/issue/${issue_id}/reply`,
    method: HttpMethods.POST,
    data: { reply },
  });
};
export const getIssuestatistics = (type: IssueEnum) => {
  return makeRequest({
    url: `${BASE_URL}/issue/statistics`,
    method: HttpMethods.GET,
    params: {
      q: type,
    },
  });
};
