import ButtonComponent from 'components/admin/ButtonComponent';
import UploadComponent from 'containers/admin/Aboutus/Team/upload-component';
import { GalleryTypeEnum, UploadImageTypeEnum } from 'core/enums/example.enum';
import { CreateGalleryModel } from 'core/types/admin.type';
import { Formik, getIn, useFormik } from 'formik';
import { useState } from 'react';
import * as Icon from 'react-icons/hi';
import { HiOutlinePhoto } from 'react-icons/hi2';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import { postPictureServices } from 'services/gallery-service';
import * as Yup from 'yup';

import GalleryPopupMenue from './gallery-popup-menue';
interface GalleryNewContainerProps {
  title: string;
  onClose: () => void;
  onSuccess: () => void;
}
function GalleryNewContainer(props: GalleryNewContainerProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const handleClose = () => {
    props.onClose();
  };
  const initialValues: CreateGalleryModel = {
    images: [],
    gallery_type: GalleryTypeEnum.Plus,
  };
  const validationSchema = Yup.object({
    images: Yup.array()
      .of(Yup.string())
      .required('Upload the image'.toString())
      .max(4)
      .min(1, 'Upload Iogo'.toString()),
    gallery_type: Yup.string().required('Select Gallery Type'.toString()),
  });
  const handleClickSend = () => {
    postPicture();
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      await handleClickSend();
    },
  });
  //add  picture group limit 8
  const postPicture = async () => {
    try {
      await postPictureServices(formik.values);
      toast.success('Picture has been published successfully!');
      props.onSuccess();
      formik.resetForm();
    } catch (error) {
      ('');
    }
  };
  //arrow function
  const images = Array.isArray(formik.values.images)
    ? formik.values.images
    : [formik.values.images];

  const goToPreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };
  const goToNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };
  const selectedImg = (imgId: number) => {
    if (currentImageIndex === imgId) {
      return 'border border-orange500';
    } else return '';
  };
  const deleteImg = (imgId: number) => {
    const updatedImages = [...formik.values.images];
    updatedImages.splice(imgId, 1); // Remove the item at the specified index
    formik.setFieldValue('images', updatedImages); // Update the formik field value with the updated array
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex + 1);
      images.length - 1;
    } else {
      setCurrentImageIndex(0);
    }
  };

  const photoArray = [1, 2, 3, 4];

  return (
    <>
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full backdrop-opacity-10 backdrop-invert bg-white/30">
        <div className="rounded-lg bg-whiteColor p-6 h-[600px] w-[621px]">
          <div className="flex items-end justify-end">
            <IoMdClose
              size={24}
              className="cursor-pointer text-orange500"
              onClick={handleClose}
            />
          </div>
          <div className="text-[32px] font-bold pl-1 -mt-5 mb-5">
            {props.title}
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={() => formik.submitForm()}
            validationSchema={formik.initialValues}
            enableReinitialize
          >
            <form className="flex flex-col" onSubmit={formik.handleSubmit}>
              <div className="mb-5 pl-2 font-bold">
                <GalleryPopupMenue
                  onGallaryChange={(val: GalleryTypeEnum) =>
                    formik.setFieldValue('gallery_type', val)
                  }
                  selectedGalleryType={formik.values.gallery_type}
                />
              </div>
              {/* logos uploading and displaying */}

              <div className="flex flex-col justify-center items-start pl-4">
                {images.length != 0 ? (
                  <div className="absolute top-[330px] left-[930px]">
                    <IoMdClose
                      size={24}
                      className="cursor-pointer text-white"
                      onClick={() => deleteImg(currentImageIndex)}
                    />
                  </div>
                ) : (
                  <></>
                )}

                <UploadComponent
                  type={UploadImageTypeEnum.News}
                  disabled={formik.values.images.length === 4}
                  buttonSize={'w-[267px] mb-2'}
                  hight="max-h-[229px]"
                  className={``}
                  name={'images'}
                  onChange={(img: string) =>
                    formik.setFieldValue('images', [
                      ...(formik.values.images as string[]),
                      img,
                    ])
                  }
                  id={'image'}
                  labelClassName={`w-[267px] h-[220px] rounded-lg ${
                    formik.touched.images && formik.errors.images
                      ? 'border-error '
                      : ''
                  }`}
                  image={formik.values.images[currentImageIndex]}
                  button={'Upload Image'}
                  onClick={() => {
                    throw new Error('Function not implemented.');
                  }}
                  formik={
                    <p className="text-error text-sm ">
                      {getIn(formik.errors, 'images')}
                    </p>
                  }
                />
                {/* Displaying list of uploaded logos */}
                <div className="flex flex-row justify-center items-center gap-2 ">
                  <Icon.HiOutlineChevronLeft
                    size={20}
                    onClick={goToPreviousImage}
                    className={`cursor-pointer ${
                      currentImageIndex > 0
                        ? 'text-orange500'
                        : 'text-neutralGrey'
                    }`}
                  />
                  {/* add place holder for the uploead img */}
                  {formik.values.images.length === 0 ? (
                    <>
                      <div className="flex rounded-md gap-3">
                        {photoArray.map((photo, index) => (
                          <div
                            key={index}
                            className="bg-neutralGre50 rounded w-12 h-12 flex justify-center py-3 "
                          >
                            <HiOutlinePhoto size={24} />
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    (formik.values.images as string[]).map((item, index) => {
                      return (
                        <div key={index} className="flex rounded-md gap-3">
                          <img
                            key={currentImageIndex}
                            className={`rounded w-12 h-12 flex justify-center py-3 ${selectedImg(
                              index,
                            )}`}
                            src={`${item}`}
                            alt=""
                          />
                        </div>
                      );
                    })
                  )}
                  <Icon.HiOutlineChevronRight
                    size={20}
                    onClick={goToNextImage}
                    className={`cursor-pointer ${
                      currentImageIndex < images.length - 1
                        ? 'text-orange500'
                        : 'text-neutralGrey'
                    }`}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center pt-4">
                <ButtonComponent
                  disabled={false}
                  width={'w-[305.46px]'}
                  hight={'h-[48px]'}
                  text={'Publish'}
                  fontsize={'text-base'}
                />
              </div>
            </form>
          </Formik>
        </div>
      </div>
    </>
  );
}
export default GalleryNewContainer;
