export const errorStrings = {
  something_went_wrong: 'حدث خطأ، الرجاء المحاولة مرة أخرى',
  error_codes: {
    common_1: 'user not found please check the passed id',
    common_2: 'اسم المستخدم او البريد الالكتروني مستعمل',
    common_3: 'the mobile number already used',
    common_4: 'this email is already used by another user',
    common_5: 'email or password incorrect',
    common_6: 'refresh token incorrect',
    common_7: 'not implemented',
    common_8: 'recaptcha is not valid',
    common_9: 'Application that have been closed cannot be accept',
    common_10: 'Application that have been closed cannot be reject',
    common_11: "you've reached limit of application",
    other_1: 'The maximum number of ecosystem is 1',
    'other-2': 'not contact in the database',
    'other-3': 'the contact is already opened',
    'other-4': 'the contact is already closed',
    'other-5': 'you cannot create a duplicate contact in the database',
    'other-6': 'access demo has no application',
    plus_1: 'plus share opinion published already',
    plus_2: 'plus share opinion unpublished already',
    plus_3: 'plus already published',
    plus_4: 'plus already draft',
    'plus-5': 'plus has no applications',
    'plus-6': 'plus number is not found',
    'incubator-1': 'incubator already published',
    'incubator-2': 'incubator already draft',
    'incubator-3': 'incubator has no applications',
    'disrupt-1': 'disrupt already published',
    'disrupt-2': 'disrupt already draft',
    'disrupt-3': 'disrupt has no applications',
    'disrupt-4': 'there is no disrupt',
    'disrupt-5': 'the disrupt version is not found',
    'community-1': 'you cannot create a duplicate tour in the database',
    'community-2': 'no tour in the database',
    'community-3': 'the tour is already closed',
    'community-4': 'the tour is already opened',
    'community-5': 'tour has no applications',
    'community-6': 'you cannot create a duplicate partner in the database',
    'community-7': 'no partner in the database',
    'community-8': 'the partner is already opened',
    'community-9': 'the partner is already close',
    'community-10': 'you cannot create a duplicate event in the database',
    'community-11': 'no event in the database',
    'community-12': 'the event is already opened',
    'community-13': 'the event is already close',
    'community-14': 'event has no applications',
    'community-15': 'partner has no applications',
    'community-16': 'already replied',
    'visit-1': 'visit already exists',
    'visit-2': 'no visit found',
    'visit-3': 'visit already open',
    'visit-4': 'visit already closed',
    'visit-5': 'visit has no application',
    'upload-1': 'asset name does not include file extension',
    'upload-2': 'asset name already exist',
    'user-1': 'user already in this group',
    'user-2': 'role not exist in realm',
    'user-3': 'role already exist in realm',
    'scanner-1': 'garage event is closed',
    'scanner-2': 'barcode already scanned',
    'activity-1': 'activity already publish',
    'activity-2': 'version is require',
    'activity-3': 'type is require',
    'activity-4': 'activity already draft',
    'consultation-1': 'consultation already published',
    'consultation-2': 'consultation has no application',
    'consultation-3': 'consultation already draft',
    'consultation-4': 'consultation number already exist',
    'consultation-5':
      'consultation number it not must be greater than last consult number by more than 1',
    'consultation-6':
      'a consultation that has been made published cannot be removed',
    'consultation-7': 'the consultation number must exceed one',
    'consultation-8': 'the last consultation cannot be deleted',
    'building-1': 'building already exist',
    'building-2': 'duplicate floor number or name',
    'building-3': 'duplicate resource number',
    'building-4': 'can not create floor without building',
    'building-5': 'duplicate resource calendar id',
    'building-6': 'can not change calendar id',
    'building-7': 'date is in the past',
    'booking-1':
      'There is currently a booking, thus you are unable to create one.',
    'booking-2': 'recaptcha is not valid',
    'booking-3': 'For this booking, there is no resource.',
    'booking-4': 'This resource does not allow reservations to be made',
    'booking-5': 'event not found',
    'booking-6': 'past booking can not be deleted',
    'booking-7': 'past booking can not be updated',
    'booking-8':
      'there seems to be an error while connecting to the Google client.',
    'booking-9': 'booking is expired',
    'booking-10': 'permission required to book this resource',
    'organization-1': 'organization already exist',
    'organization-2': 'can not create program without organization',
    'organization-3': 'program already exist',
    'organization-4': 'company name already exist',
    'organization-5': 'company email already exist',
    'organization-6': 'company mobile already exist',
    'organization-7': 'you are not a member of this company',
    'organization-8': 'user added to company',
    'organization-9': 'user already added to organization',
    'report-1': 'the post author is unable to include a report.',
    'post-1': 'it is not authorized for you to remove the post.',
  },
};
