import {
  convertTimeToDateTime,
  convertTo12HourFormat,
} from 'core/helpers/customHook';
import { availableTimeSlots } from 'core/statics/time-data';
import {
  UnavailableTimesModel,
  UpdateBookingModel,
} from 'core/types/admin.type';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import DropdownComponent from './dropdown-with-title';

interface PopupEditBookingComponentProps {
  resourceId: string;
  bookingId: string;
  title: string;
  data: UpdateBookingModel;
  list: UnavailableTimesModel[];
  onClose: () => void;
  onClickConfirm: (values: UpdateBookingModel, id: string) => void;
}

function PopupEditBookingComponent(props: PopupEditBookingComponentProps) {
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const initialValues: UpdateBookingModel = {
    start_at: '',
    end_at: '',
    title: props.data.title,
    summary: props.data.summary,
    visitors: props.data.visitors || [],
    rrule: props.data.rrule || [],
  };

  const validationSchema = Yup.object({
    start_at: Yup.string().required('Start time is required'),
    end_at: Yup.string().required('End time is required'),
  });

  function getUnavailableTimes(
    events: { start_time: string; end_time: string }[],
  ): string[] {
    const unavailableTimes = new Set<string>();
    events.forEach((event) => {
      unavailableTimes.add(convertTo12HourFormat(event.start_time));
      unavailableTimes.add(convertTo12HourFormat(event.end_time));
    });
    return Array.from(unavailableTimes);
  }

  const unavailableTimes = getUnavailableTimes(props.list);
  const currentDateTime = new Date();

  const availableTimes = availableTimeSlots.filter((time) => {
    const timeDate = new Date(
      convertTimeToDateTime(
        time,
        currentDateTime.toISOString().split('T')[0],
        0,
      ),
    );

    return timeDate > currentDateTime && !unavailableTimes.includes(time);
  });

  function getAvailableEndTimes(selectedStartTime: string): string[] {
    const startTimeIndex = availableTimes.indexOf(selectedStartTime);
    if (startTimeIndex === -1) return [];

    return availableTimes.filter((time, index) => {
      const timeDate = new Date(
        convertTimeToDateTime(
          time,
          currentDateTime.toISOString().split('T')[0],
          0,
        ),
      );

      return (
        index > startTimeIndex &&
        timeDate >
          new Date(
            convertTimeToDateTime(
              selectedStartTime,
              currentDateTime.toISOString().split('T')[0],
              0,
            ),
          )
      );
    });
  }

  function filterAvailableTimes(startTime: string, endTime: string): string[] {
    const startDateTime = new Date(
      convertTimeToDateTime(
        startTime,
        currentDateTime.toISOString().split('T')[0],
        0,
      ),
    );
    const endDateTime = new Date(
      convertTimeToDateTime(
        endTime,
        currentDateTime.toISOString().split('T')[0],
        0,
      ),
    );

    return availableTimes.filter((time) => {
      const timeDate = new Date(
        convertTimeToDateTime(
          time,
          currentDateTime.toISOString().split('T')[0],
          0,
        ),
      );

      return (
        !(timeDate >= startDateTime && timeDate <= endDateTime) &&
        !unavailableTimes.includes(time)
      );
    });
  }

  return (
    <div className="fixed z-[1500] top-0 left-0 flex items-center justify-center w-full h-full bg-black/20">
      <div className="w-[750px] h-auto rounded-lg bg-whiteColor px-10 pt-9">
        <div className="text-xl text-textGray font-bold mb-3">
          {t(`${props.title}`)}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const baseDate = new Date();
            const startAtDateTime = convertTimeToDateTime(
              values.start_at,
              baseDate.toISOString().split('T')[0],
              3,
            );
            const endAtDateTime = convertTimeToDateTime(
              values.end_at,
              baseDate.toISOString().split('T')[0],
              3,
            );
            setSubmitting(false);

            await props.onClickConfirm(
              {
                ...values,
                start_at: startAtDateTime,
                end_at: endAtDateTime,
              },
              props.bookingId,

            );

            setSubmitting(false);
            props.onClose();
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col items-start justify-start gap-x-14">
                <p className="p-2">
                  {t('component.PopupEditBookingComponent.subTitle')}
                </p>
                <div className="flex gap-4">
                  <DropdownComponent
                    id="start_at"
                    openDropdown={openDropdown}
                    setOpenDropdown={setOpenDropdown}
                    data={filterAvailableTimes(
                      formik.values.start_at,
                      formik.values.end_at,
                    )}
                    showTitles={false}
                    title={''}
                    width="w-[300px]"
                    onSelect={(value) => {
                      formik.setFieldValue('start_at', value);
                      formik.setFieldValue('end_at', ''); // Reset end_at when start_at changes
                    }}
                    error={!!formik.errors.start_at && formik.touched.start_at}
                    errorMsg={formik.errors.start_at}
                    placeholder={t('From') || ''}
                  />
                  <DropdownComponent
                    id="end_at"
                    openDropdown={openDropdown}
                    setOpenDropdown={setOpenDropdown}
                    data={getAvailableEndTimes(formik.values.start_at)}
                    showTitles={false}
                    title={''}
                    width="w-[300px]"
                    onSelect={(value) => formik.setFieldValue('end_at', value)}
                    error={!!formik.errors.end_at && formik.touched.end_at}
                    errorMsg={formik.errors.end_at}
                    placeholder={t('To') || ''}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-end gap-3 px-3 py-6">
                <button
                  type="button"
                  className="w-[180px] h-[48px] rounded-lg border border-orange500 bg-whiteColor text-orange500 text-base"
                  onClick={props.onClose}
                >
                  {t('app.shared.button.cancel')}
                </button>
                <button
                  type="submit"
                  className="w-[180px] h-[48px] rounded-lg bg-orange500 text-whiteColor text-base"
                >
                  {t('app.shared.button.confirm')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default PopupEditBookingComponent;
